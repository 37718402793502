import { ChewronLeftIcon, DownloadIcon } from '@/components/icons';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import { loadReport, loadTeamDashboardReport } from '@/redux/actions/company';
import {
  getCompaniesTabs,
  getCompany,
} from '@/redux/selectors/companySelectors';
import { EUserRole } from '@/types/consts';
import { IUser } from '@/types/models';
import { Box, Button, ButtonProps, Grid, Typography } from '@mui/material';
import { format, subMonths } from 'date-fns';
import { useCallback } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

const ToolsGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.default};
`;

const InforfationWrapper = styled(Grid)``;

export const BackToPage = styled(Button)<ButtonProps & LinkProps>`
  border: none;
  min-width: 190px;
`;

const CompanyNameProp = styled(Typography)`
  color: ${({ theme }) => theme.header.info.greetings.color};
  white-space: pre;
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

const CompanyNameValue = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const DownloadReportButton = styled(Button)`
  min-width: 96px;
  white-space: nowrap;
`;
export const DownloadDashboardButton = styled(Button)`
  min-width: 120px;
  white-space: nowrap;
`;

const Tools = () => {
  const dispatch = useAppDispatch();
  const companyTabs = useAppSelector(getCompaniesTabs);

  const company = useAppSelector(getCompany);
  const { role } = useAuth().user as IUser;

  const showDashboardDownload = companyTabs === 'team-dashboard';

  const handleLoadReport = useCallback(() => {
    if (company?.id) {
      dispatch(
        loadReport({
          companyId: company?.id,
          from: format(subMonths(new Date(), 3), 'yyyy-MM-dd'),
          to: format(new Date(), 'yyyy-MM-dd'),
        })
      )
        .unwrap()
        .then((reportData: any) => {
          const blob = new Blob([reportData], {
            type: 'text/csv;charset=utf-8;',
          });

          const link = document.createElement('a');
          if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `Report(${company.name}).csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        });
    }
  }, [company?.id]);

  const handleDashboardDownload = useCallback(() => {
    if (company?.id) {
      dispatch(loadTeamDashboardReport(company.id))
        .unwrap()
        .then((reportData: any) => {
          const blob = new Blob([reportData], {
            type: 'text/csv;charset=utf-8;',
          });

          const link = document.createElement('a');
          if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute(
              'download',
              `Team Dashboard Report(${company.name}).csv`
            );
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        });
    }
  }, [company?.id]);

  return (
    <ToolsGrid
      container
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection={'row'}
      py={3}
    >
      <Grid item container justifyContent={'center'} md={3}>
        <BackToPage
          LinkComponent={Link}
          to={'/admin/companies'}
          startIcon={<ChewronLeftIcon />}
          color={'secondary'}
        >
          Back to Search Companies
        </BackToPage>
      </Grid>
      <Grid
        item
        container
        justifyContent={'center'}
        alignItems={'center'}
        md={6}
      >
        <InforfationWrapper
          container
          justifyContent={'center'}
          alignItems={'center'}
          width={'auto'}
          flexWrap={'nowrap'}
          gap={4}
        >
          <Grid container alignItems={'center'} flexWrap={'nowrap'}>
            <CompanyNameProp>Company Name: </CompanyNameProp>
            <CompanyNameValue>{company?.name}</CompanyNameValue>
          </Grid>
          {role.name === EUserRole.SUPER_ADMIN && (
            <DownloadReportButton
              color={'secondary'}
              variant={'outlined'}
              startIcon={<DownloadIcon />}
              onClick={handleLoadReport}
            >
              Report
            </DownloadReportButton>
          )}
          {showDashboardDownload && role.name === EUserRole.SUPER_ADMIN && (
            <DownloadDashboardButton
              color={'secondary'}
              variant={'outlined'}
              startIcon={<DownloadIcon />}
              onClick={handleDashboardDownload}
            >
              <Box>
                <Typography>Dashboard</Typography>
                <Typography>Download</Typography>
              </Box>
            </DownloadDashboardButton>
          )}
        </InforfationWrapper>
      </Grid>
      <Grid item md={3}></Grid>
    </ToolsGrid>
  );
};

export default Tools;

export enum EKnowledgeBaseItemTypes {
  GUIDE = 'guide',
  MODULE = 'module',
  VIDEO = 'video',
}

export interface IKnowledgeBaseItem {
  id: number;
  type: EKnowledgeBaseItemTypes;
  points: number;
  alreadyCompleted: boolean;
  alreadyReaded: boolean;
  previewUrl?: string;
  fileUrl?: string;
  title?: string;
  description: string;
  createdAt: string;
}

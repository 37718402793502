import { MyWorkspaceBlock } from '@/pages/dashboard/MyWorkspace/components';
import { Box } from '@mui/material';
import styled from 'styled-components';

export const DashboardWorkspaceBlock = styled(MyWorkspaceBlock)`
  margin: 20px 0;
  min-height: 100%;
  border-radius: 16px;
  position: -webkit-sticky;
`;
export const DashboardTitleWrapper = styled(Box)`
  border-radius: 16px 16px 0px 0px;
  z-index: 5;
  position: relative;
  background-color: #ebeff7;
  height: 50px;
  display: flex;
  .title {
    margin-left: 28px;
    align-items: center;
    display: flex;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 45px;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const ChatDashboardWrapper = styled(Box)`
  height: 513px;
  margin-top: 18px;
  position: relative;
  background-color: white;
  border-radius: 16px;
  margin-bottom: 45px;
`;

export const AttentionWrapperBlock = styled.button`
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  z-index: 5;
  position: relative;
  display: flex;
  justify-content: center;

  .attention {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

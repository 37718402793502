import { ECompanyShippingAddresses } from '@/redux/constants';
import instance from '@/redux/middlewares/api';
import { RootState } from '@/redux/store';
import { EUserRole } from '@/types/consts';
import {
  IGetCompanyShippingAddressParams,
  IShippingAddress,
  IShippingAddressCreate,
  IShippingAddressUpdate,
} from '@/types/models';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getShippingAddressUrlByRoleName = (roleName?: string) => {
  if (roleName === EUserRole.TEAMLEAD) {
    return 'team-admin/companies';
  }

  if (roleName === EUserRole.SUPER_ADMIN) {
    return 'super-admin/companies';
  }

  if (roleName === EUserRole.RW_ADMIN) {
    return 'rw-admin/companies';
  }

  return 'users';
};

const createCompanyShippingAddress = createAsyncThunk(
  ECompanyShippingAddresses.createCompanyShippingAddress,
  async (createShippingAddressData: IShippingAddressCreate, thunkAPI) => {
    try {
      const { user } = thunkAPI.getState() as RootState;
      const role = user.user?.role.name;

      const getShippingAddressUrl = getShippingAddressUrlByRoleName(role);
      const shippingAddress = await instance.post<IShippingAddress>(
        `${getShippingAddressUrl}/${createShippingAddressData.companyId}/shipping-address/new`,
        createShippingAddressData
      );

      return shippingAddress.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getCompanyShippingAddressById = createAsyncThunk(
  ECompanyShippingAddresses.getCompanyShippingAddress,
  async (params: IGetCompanyShippingAddressParams, thunkAPI) => {
    try {
      const { user } = thunkAPI.getState() as RootState;
      const role = user.user?.role.name;

      const getShippingAddressUrl = getShippingAddressUrlByRoleName(role);
      const shippingAddress = await instance.get<IShippingAddress>(
        `${getShippingAddressUrl}/${params.companyId}/shipping-address/${params.id}`
      );

      return shippingAddress.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const updateCompanyShippingAddress = createAsyncThunk(
  ECompanyShippingAddresses.updateCompanyShippingAddress,
  async (updateShippingAddressData: IShippingAddressUpdate, thunkAPI) => {
    try {
      const { user } = thunkAPI.getState() as RootState;
      const role = user.user?.role.name;

      const getShippingAddressUrl = getShippingAddressUrlByRoleName(role);
      const shippingAddress = await instance.patch<IShippingAddress>(
        `${getShippingAddressUrl}/shipping-address/${updateShippingAddressData.id}`,
        updateShippingAddressData
      );

      return shippingAddress.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export {
  createCompanyShippingAddress,
  getCompanyShippingAddressById,
  updateCompanyShippingAddress,
};

import { Box } from '@mui/material';
import styled from 'styled-components';

export const WorkspaceContainer = styled(Box)`
  height: 100%;
  background: rgb(0, 153, 255, 0.07);
  border-radius: ${({ theme }) => theme.spacing(3)};
  border: 1px solid ${({ theme }) => theme.palette.border.main};

  padding: ${({ theme }) => theme.spacing(6, 7, 3, 9)};
`;
export const StatisticProgress = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

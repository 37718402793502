import { Avatar } from '@mui/material';
import styled from 'styled-components';
export enum AvatarType {
  COMPANY = 'Company',
  USER = 'User',
}
export const CustomAvatar = styled(Avatar)<{
  diameter: number | string;
  avatartype: AvatarType;
}>`
  width: ${({ diameter }) => diameter};
  height: ${({ diameter }) => diameter};
  background-color: ${({ avatartype }) =>
    avatartype === AvatarType.COMPANY ? '#2C9AE2' : '#FFA800'};
  color: ${({ avatartype }) =>
    avatartype === AvatarType.COMPANY ? '#95ccf1' : '#FFE0A5'};
  border: 1px solid #ffffff;
`;

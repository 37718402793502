import { IKnowledgeBaseItem } from '@/components/knowledgeBase/models';
import {
  completeKnowledgeBaseItem,
  finishReadKnowledgeBaseItem,
  getKnowledgeBaseData,
  knowledgeBaseStat,
} from '@/redux/actions/knowledge-base';
import { IKnowledgeStat } from '@/types/models';
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export interface KnowledgeBaseState {
  items?: Array<IKnowledgeBaseItem> | null;
  statistic: Array<IKnowledgeStat> | null;
}

const knowledgeBaseInitialState: KnowledgeBaseState = {
  items: null,
  statistic: null,
};

export const { actions, reducer } = createSlice({
  name: 'knowledgeBase',
  initialState: knowledgeBaseInitialState,
  reducers: {
    resetKnowledgeBaseState: () => ({
      ...knowledgeBaseInitialState,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKnowledgeBaseData.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }

        state.items = _.orderBy(
          payload.map((item: any) => ({
            ...item,
            type: item.type.name,
            points: item.type.rewardPoints,
          })),
          [
            'alreadyCompleted',
            function (object) {
              return new Date(object.createdAt);
            },
          ],
          ['asc', 'desc']
        );
      })
      .addCase(
        completeKnowledgeBaseItem.fulfilled,
        (state, { payload, meta }) => {
          if (!payload || !state.items) {
            return;
          }

          const newItems = [
            ...state.items.filter((item) => item.id !== Number(meta.arg)),
            {
              ...payload,
              type: payload.type.name,
              points: payload.type.rewardPoints,
              alreadyCompleted: true,
            },
          ];

          state.items = _.orderBy(
            newItems,
            [
              'alreadyCompleted',
              function (object) {
                return new Date(object.createdAt);
              },
            ],
            ['asc', 'desc']
          );
        }
      )
      .addCase(
        finishReadKnowledgeBaseItem.fulfilled,
        (state, { payload, meta }) => {
          if (!payload || !state.items) {
            return;
          }
          state.items = state.items.map((item) => {
            if (item.id === Number(meta.arg)) {
              item.alreadyReaded = true;
            }
            return item;
          });
        }
      )
      .addCase(knowledgeBaseStat.fulfilled, (state, { payload }) => {
        state.statistic = payload;
      });
  },
});

export const { resetKnowledgeBaseState } = actions;

export default reducer;

import ProgressMeter from '@/components/progressMeter/ProgressMeter/ProgressMeter';
import RewardPointsProgress from '@/components/progressMeter/RewardPoints/RewardPointsProgress';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { getMyDailyStreak, getMyProgress } from '@/redux/actions/activity';
import { myProgressSelector } from '@/redux/selectors/activitySelector';
import { EActivityLevel, EPeriod } from '@/types/consts';
import {
  getEndOfMonth,
  getEndOfWeek,
  getStartOfMonth,
  getStartOfWeek,
} from '@/utils/dateHelpers';
import { Tab, Tabs } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import {
  MetersWidgetContainer,
  ProgressMeterWrapper,
  TabsContainer,
} from './components';

const ProgressMeterWidget: React.FC = () => {
  const dispatch = useAppDispatch();
  const progress = useAppSelector(myProgressSelector);
  const [period, setPeriod] = useState<EPeriod>(EPeriod.DAY);

  useEffect(() => {
    dispatch(
      getMyDailyStreak({
        from: format(getStartOfMonth(new Date()), 'yyyy-MM-dd'),
        to: format(getEndOfMonth(new Date()), 'yyyy-MM-dd'),
      })
    );
  }, []);

  useEffect(() => {
    const start =
      period === EPeriod.WEEK ? getStartOfWeek(new Date()) : new Date();
    const end = period === EPeriod.WEEK ? getEndOfWeek(new Date()) : new Date();
    dispatch(
      getMyProgress({
        from: format(start, 'yyyy-MM-dd'),
        to: format(end, 'yyyy-MM-dd'),
        dataLevel: EActivityLevel.DYNAMIC,
      })
    );
  }, [period]);

  return (
    <MetersWidgetContainer>
      <TabsContainer component={'section'}>
        <Tabs
          value={period}
          onChange={(e, value) => setPeriod(value)}
          centered
          textColor={'secondary'}
        >
          <Tab value={EPeriod.DAY} label="Day" />
          <Tab value={EPeriod.WEEK} label="Week" />
        </Tabs>
      </TabsContainer>
      <ProgressMeterWrapper>
        <ProgressMeter
          social={progress.social}
          mental={progress.mental}
          physical={progress.physical}
        />
      </ProgressMeterWrapper>
      <RewardPointsProgress />
    </MetersWidgetContainer>
  );
};

export default ProgressMeterWidget;

import styled from 'styled-components';

export const RewardPointsHistoryContainer = styled.div`
  width: 100%;
`;

export const BlockLabel = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const DateColumn = styled.div`
  width: 300px;
  display: block;
  margin-right: 15px;

  .date-picker {
    width: 110px;
    input {
      font-size: 12px;
    }

    &:first-child {
      margin: 0 5px 0 0 !important;
    }

    &:last-child {
      margin: 0 0 0 5px !important;
    }
  }
`;

export const TaskColumn = styled.div`
  margin-right: 10px;
  display: flex;
  flex-grow: 1;
  justify-self: start;
`;

export const SimpleTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  .number {
    margin-right: 18px;
  }
`;

export const SimpleTableHeaderRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 14px;
  color: ${(props) => props.theme.palette.text.secondary};
  margin-bottom: 15px;
  line-height: 32px;
`;

export const SimpleTableWrapper = styled.div`
  padding: 8px 23px 8px 0;
`;

export const SimpleTableBody = styled.div`
  height: 330px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

import {
  Box,
  Button as MuiButton,
  ButtonProps,
  Chip,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps,
  DialogTitle as MuiDialogTitle,
  FormControl,
  Grid,
  IconButton as MuiIconButton,
  Slider,
  Typography,
} from '@mui/material';
import { LinkProps } from 'react-router-dom';
import styled from 'styled-components';

interface FormControlAdditionalProps {
  hiddenLabel?: boolean;
  error?: boolean;
  width?: string;
}

export const Main = styled('main')`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const UsersGrid = styled(Box)`
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
  align-items: center;
`;

export const UsersTableSection = styled(Box)`
  flex: 1;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const Dialog = styled(MuiDialog)<DialogProps>`
  & .MuiDialog-paper {
    overflow-y: visible;
    padding: ${({ theme }) => theme.spacing(8, 16)};
    width: 520px;
  }
`;

export const DialogTitle = styled(MuiDialogTitle)`
  padding: ${({ theme }) => theme.spacing(0)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  font-size: ${({ theme }) => theme.typography.h1.fontSize};
  font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
  text-align: center;
`;

export const DialogSubTitle = styled(MuiDialogTitle)`
  max-width: 400px;
  margin: ${({ theme }) => theme.spacing(0, 'auto')};
  padding: ${({ theme }) => theme.spacing(1, 0)};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
  text-align: center;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const IconButton = styled(MuiIconButton)`
  position: absolute;
  top: 18px;
  right: 16px;
`;

export const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  overflow-y: visible;
`;

export const DialogActions = styled(MuiDialogActions)`
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

export const CreateUserButton = styled(MuiButton)`
  background-color: unset;
  color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const ButtonText = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const BillingAddress = styled(Box)`
  padding: ${({ theme }) => theme.spacing(1, 0, 10)};
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const BillingAddressContainer = styled(Box)`
  max-width: 768px;
  margin: 0 auto;
`;

export const DrawerContainer = styled(Box)`
  max-width: 830px;
  padding: ${({ theme }) => theme.spacing(7, 9)};
`;

export const DrawerHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ToolsGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const InforfationWrapper = styled(Grid)``;

export const BackToSearchUsersButton = styled(MuiButton)<
  ButtonProps & LinkProps
>`
  border: none;
`;

export const CompanyNameProp = styled(Typography)`
  color: ${({ theme }) => theme.header.info.greetings.color};
  white-space: pre;
`;

export const CompanyNameValue = styled(Typography)`
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Placeholder = styled('em')`
  color: ${({ theme }) => theme.palette.selector.light};
  font-style: italic;
  opacity: 0.42;
`;

export const UpdateButton = styled(MuiButton)`
  margin-right: 10px;
`;

export const ProgressMeterSection = styled('section')`
  background: #f5f8f9;
  border-radius: 16px;
  padding: ${({ theme }) => theme.spacing(8, 10)};
`;

export const Points = styled.div`
  font-size: 18px;
  line-height: 32px;
  color: ${(props) => props.theme.palette.text.secondary};
  margin-right: 5px;
`;

export const ProgressContainer = styled(Grid)`
  border: 1px solid #0099ff;
  border-radius: 16px;
  padding: ${({ theme }) => theme.spacing(4, 6)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  margin-top: ${({ theme }) => theme.spacing(5)};
`;

export const PointsProgressBar = styled(Slider)`
  height: 6px;

  &.Mui-disabled {
    color: #5d6081;
  }

  & .MuiSlider-rail {
    border: ${(props) => `1px solid ${props.theme.knowledgeBase.color}`};
    background-color: transparent;
  }

  & .MuiSlider-thumb {
    width: 0;
  }

  & .MuiSlider-valueLabel {
    display: none;
    font-size: 10px;
    bottom: 0px;
    background-color: unset;
    color: ${(props) => props.theme.palette.border.main};
    transform: translateY(20px);
    & * {
      background: transparent;
      color: ${(props) => props.theme.palette.border.main};
    }
  }

  & .MuiSlider-mark {
    width: 6px;
    height: 6px;
    border-radius: 50%;

    &[data-index='0'] {
      visibility: hidden;
    }
  }

  & .MuiSlider-markLabel {
    transform: translateX(0);
  }

  & .MuiSlider-track {
    background-color: ${(props) => props.theme.palette.border.main};
    border: none;
  }
`;

export const PointsProggressLabel = styled(Typography)`
  color: ${(props) => props.theme.palette.border.main};
`;

export const PointsProggressTitle = styled(Typography)`
  color: ${(props) => props.theme.palette.secondary.main};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const CompanyNameChip = styled(Chip)`
  height: 30px;
`;

export const BoxAvatar = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 10px;
`;

export const BoxAvatarName = styled(Box)`
  display: flex;
  flexwrap: wrap;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

export const FormControlWrapper = styled(
  FormControl
)<FormControlAdditionalProps>`
  margin-top: ${({ hiddenLabel }) => (hiddenLabel ? '24.25px' : 0)};
  margin-bottom: ${({ error }) => (error ? '3.771px' : '30px')};
  width: ${({ width }) => (width ? width : '360px')};

  &:has(> label) {
    margin-top: 0 !important;
  }
`;

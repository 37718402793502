import { EKnowledgeBaseItemTypes } from '@/components/knowledgeBase/models';
import { Paper, StyledComponentProps, Typography } from '@mui/material';
import styled from 'styled-components';

export interface WithType extends StyledComponentProps {
  type: EKnowledgeBaseItemTypes;
}

export const KnowledgeBaseRoot = styled(Paper)`
  padding: 28px 28px;
  height: 100%;
  color: ${(props) => props.theme.palette.secondary.main};

  .divider {
    margin: 32px 0;
  }

  .styled-button {
    margin: 10px auto;
  }

  .description {
    margin-top: 25px;
    font-size: 14px;
  }
`;

export const KnowledgeBaseHeaderBlock = styled.div<WithType>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .close {
    margin-left: auto;
    width: 20px;
    height: 20px;
  }

  .points-block {
    padding: 12px 0;
    min-width: 90px;
    margin-left: 10px;

    .label {
      font-weight: bold;
      border-radius: 2px;
      width: 43px;
      height: 18px;
      text-align: center;
      margin-right: 5px;
      color: white;
      font-size: 10px;
      text-transform: capitalize;
      background-color: ${(props) =>
        props.theme.knowledgeBase[props.type].color};
    }

    .points {
      line-height: 18px;
      font-weight: bold;
      color: ${(props) => props.theme.knowledgeBase[props.type].color};
      font-size: 12px;
    }
  }

  .title {
    flex-grow: 1;
    margin: 0 20px;
  }
`;

export const KnowledgeBaseList = styled.div`
  flex: 50%;
  padding: 0 45px;
`;

export const BarWrapper = styled.div`
  margin-left: auto;
  width: 50%;
`;

export const KnowledgeText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const KnowledgeBoldGreenText = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-weight: bold;
`;

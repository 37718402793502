import { ECountry } from '@/redux/constants';
import instance from '@/redux/middlewares/api';
import { resetCountryState } from '@/redux/reducers/country';
import { ICountry } from '@/types/models';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getCountries = createAsyncThunk(
  ECountry.getCountries,
  async (_, thunkAPI) => {
    try {
      const countries = await instance.get<ICountry[]>('countries');
      return countries.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const createCountry = createAsyncThunk(
  ECountry.createCountry,
  async (stateData: ICountry, thunkAPI) => {
    try {
      const state = await instance.post<ICountry>(
        `super-admin/countries/new`,
        stateData
      );
      return state.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getCountry = createAsyncThunk(
  ECountry.getCountry,
  async (stateId: number, thunkAPI) => {
    try {
      const state = await instance.get(`countries/${stateId}`);
      return state.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const updateCountry = createAsyncThunk(
  ECountry.updateCountry,
  async (updatedCountryData: ICountry, thunkAPI) => {
    try {
      const state = await instance.patch<ICountry>(
        `super-admin/countries/${updatedCountryData.id}`,
        updatedCountryData
      );
      return state.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const deleteCountry = createAsyncThunk(
  ECountry.deleteCountry,
  async (stateId: number, thunkAPI) => {
    try {
      await instance.delete(`super-admin/countries/${stateId}`);
      return thunkAPI.dispatch(resetCountryState());
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export {
  getCountries,
  createCountry,
  getCountry,
  updateCountry,
  deleteCountry,
};

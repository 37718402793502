import styled from 'styled-components';

export const KnowledgeBasePreviewBlock = styled.div`
  width: 100%;
  margin-top: 7px;
  background-color: ${(props) => props.theme.palette.custom.light};
  border-radius: 16px;
  color: ${(props) => props.theme.knowledgeBase.color};

  .items-wrapper {
    max-height: 585px;
    overflow-y: auto;
  }
  .content {
    padding: 18px 31px;
  }

  .header {
    margin-bottom: 15px;
  }
`;

import { Box, Slider, StyledComponentProps } from '@mui/material';
import styled from 'styled-components';

interface WithoutPadding extends StyledComponentProps {
  withoutpadding: boolean;
}

export const ColorfulHorizontalProgressBar = styled(Slider)<WithoutPadding>`
  height: 6px;
  padding: ${(props) => (props.withoutpadding ? '0' : '12px 0')};

  &.Mui-disabled {
    color: ${(props) => props.theme.palette.border.main};
  }

  & .MuiSlider-rail {
    border: ${(props) => `1px solid ${props.theme.knowledgeBase.color}`};
    background-color: transparent;
  }

  & .MuiSlider-thumb {
    width: 0;
  }

  & .MuiSlider-valueLabel {
    font-size: 10px;
    bottom: 0px;
    background-color: unset;
    color: ${(props) => props.theme.palette.text.secondary};
    transform: translateY(20px);

    & * {
      background: transparent;
      color: ${(props) => props.theme.palette.text.secondary};
    }
  }
`;

export const ProgressWrapper = styled(Box)`
  color: ${(props) => props.theme.palette.border.main};

  .progress {
    min-width: 120px;
    line-height: 32px;
  }
`;

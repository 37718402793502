import React from 'react';
import { ProgressContainer } from './components';
import DoughnutMeter from './DoughnutMeter';

interface ProgressMeterProps {
  social: number;
  mental: number;
  physical: number;
}

const ProgressMeter: React.FC<ProgressMeterProps> = ({
  social,
  mental,
  physical,
}) => {
  return (
    <ProgressContainer>
      <DoughnutMeter value={mental} title="Mental" mainColor="#2C9AE2" />
      <DoughnutMeter value={physical} title="Physical" mainColor="#4FC74D" />
      <DoughnutMeter value={social} title="Social" mainColor="#F6BE54" />
    </ProgressContainer>
  );
};

export default ProgressMeter;

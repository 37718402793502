import { AvatarType, CustomAvatar } from '@/components/UI/CustomAvatar';
import DataGrid from '@/components/UI/DataGrid';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import { getUsersByParams } from '@/redux/actions/users';
import { getSearchedUsers } from '@/redux/selectors/users';
import { ELimits, EUserRole } from '@/types/consts';
import { IUser } from '@/types/models';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import { getRoutePrefixByRoleName } from '@/utils/routeRoleHelpers';
import { formatPhoneNumber } from '@/utils/textHelpers';
import { Box, Grid, Typography } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { BoxAvatar, BoxAvatarName, CompanyNameChip } from './components';

const columns: GridColDef[] = [
  {
    field: 'userName',
    headerName: 'Name',
    flex: 2,
    renderCell: (params: GridRenderCellParams) => {
      return <CustomGridCellUserName {...params} />;
    },
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 1,
    cellClassName: 'phone',
    valueFormatter: (params) => formatPhoneNumber(params.value),
  },
  { field: 'email', headerName: 'Email', flex: 2 },
  {
    field: 'companiesNames',
    headerName: 'Companies',
    flex: 3,
    renderCell: (params: GridRenderCellParams) => {
      return <CustomGridCell {...params} />;
    },
  },
];

const CustomGridCellUserName: React.FC<GridRenderCellParams> = (params) => {
  return (
    <Grid container alignItems={'center'} gap={2} padding="10px 0 10px 0">
      <Box display="flex" flexDirection="row" width="100%">
        <BoxAvatar>
          <CustomAvatar
            diameter={24}
            avatartype={AvatarType.USER}
            alt="Remy Sharp"
            src={params.value.logo}
          >
            {params.value.logo}
          </CustomAvatar>
        </BoxAvatar>
        <BoxAvatarName>
          <Typography>{params.value.firstName}</Typography>
          <Typography>{params.value.lastName}</Typography>
        </BoxAvatarName>
      </Box>
    </Grid>
  );
};

const CustomGridCell: React.FC<GridRenderCellParams> = (params) => {
  if (!params.value || !params.value.length) {
    return (
      <Grid container minHeight={54} alignItems={'center'}>
        N/A
      </Grid>
    );
  }

  return (
    <Grid container py={2} alignItems={'center'} gap={'8px'}>
      {params.value.map((companyName: string, id: number) => (
        <CompanyNameChip
          key={id}
          id={'rw-company-name-' + id}
          label={companyName}
        />
      ))}
    </Grid>
  );
};

const rowsMapper = (users: IUser[]) =>
  users.map((user) => ({
    id: user.id,
    userName: {
      logo: user.profileImage
        ? user.profileImage
        : getUserAvatarLetter({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          }),
      firstName: user.firstName || '',
      lastName: user.lastName || '',
    },
    companyName: (user.companies && user.companies[0]?.name) || 'N/A',
    phone: user.phone || '',
    email: user.email,
    companiesNames:
      user.role.name === EUserRole.RW_ADMIN
        ? user.rwCompanies.map((company) => company.name)
        : user.companies.map((company) => company.name),
  }));

const RWAdminsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(getSearchedUsers);
  const { userRole } = useAuth();
  const navigate = useNavigate();

  const handleRowClick = (params: GridRowParams) => {
    navigate(
      getRoutePrefixByRoleName(userRole) + `/user/${params.id}/information`
    );
  };

  useEffect(() => {
    dispatch(
      getUsersByParams({ roles: [EUserRole.RW_ADMIN], limit: ELimits.USER })
    );
  }, [dispatch]);

  return (
    <Box>
      {users && (
        <DataGrid
          columns={columns}
          rows={rowsMapper(users)}
          pageSize={50}
          rowsPerPageOptions={[50]}
          onRowClick={handleRowClick}
          getRowHeight={() => 'auto'}
          getRowSpacing={() => ({
            top: 2,
            bottom: 2,
          })}
        />
      )}
    </Box>
  );
};

export default RWAdminsTable;

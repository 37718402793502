import styled from 'styled-components';

export const OneItemBlock = styled.div`
  .grid-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
  }

  .picture {
    border-radius: 4px;
    padding: 10px;
    margin: 0;
    width: 128px;
    min-height: auto;
    height: 128px;
    overflow: hidden;
    background-color: white;
  }

  .checkbox-wrapper {
    flex-direction: column;
    justify-content: center;
  }

  .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

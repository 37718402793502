import {
  endOfMonth,
  format,
  formatISO,
  hoursToMinutes,
  intervalToDuration,
  isDate,
  isMonday,
  isSunday,
  isValid,
  nextSunday,
  parseISO,
  previousMonday,
  startOfMonth,
  subDays,
} from 'date-fns';

export const getStartOfWeek = (fromDate: Date) => {
  return isMonday(fromDate) ? fromDate : previousMonday(fromDate);
};

export const getStartOfMonth = (fromDate: Date) => {
  return startOfMonth(fromDate);
};

export const getEndOfWeek = (fromDate: Date) => {
  return isSunday(fromDate) ? fromDate : nextSunday(fromDate);
};

export const getEndOfMonth = (fromDate: Date) => {
  return endOfMonth(fromDate);
};

export const getDateMinusSixtyDays = (fromDate: Date) => {
  return subDays(fromDate, 60);
};

export const inputDateFormater = (value: string | Date | null) => {
  if (!value) {
    return value;
  }
  if (!isDate(value) || !isValid(value)) {
    return value;
  }

  return formatISO(value as Date);
};

export const parseBackDate = (value: string | null) => {
  if (!value) {
    return value;
  }
  return parseISO(value);
};

export const minutesToTimeFormat = (value: number | string | Date) => {
  if (typeof value === 'string' || isDate(value)) {
    return value;
  }
  const duration = intervalToDuration({ start: 0, end: +value * 60 * 1000 });
  const date = new Date(0);
  date.setHours(Number(duration.hours));
  date.setMinutes(Number(duration.minutes));
  return format(date, 'p');
};

export const timeToMinutes = (time: Date | null | string) => {
  if (!time) {
    return time;
  }
  if (typeof time === 'string' || !isDate(time) || !isValid(time)) {
    return time;
  }
  const hours = hoursToMinutes(time.getHours());
  return hours + time.getMinutes();
};

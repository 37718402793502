import RewardPointsHistoryWrapper from '@/components/progressMeter/RewardPointsHistory/RewardPointsHistoryWrapper';
import { PointsActivityContainer } from '@/components/user-profile/reward-points/components';
import MonthlyPointsActivity from '@/components/user-profile/reward-points/MonthlyPointsActivity';
import TotalRewardPoints from '@/components/user-profile/reward-points/TotalRewardPoints';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { getUserById, getUserRewardPoints } from '@/redux/actions/users';
import { adminSelectedUser } from '@/redux/selectors/users';
import { getUserSelector } from '@/redux/selectors/userSelectors';
import { IUser } from '@/types/models';
import { Container, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const UserRewardPoints = () => {
  const { userId } = useParams<{ userId: string }>();
  const dispatch = useAppDispatch();
  const { role } = useAppSelector(getUserSelector) as IUser;
  const user = useAppSelector(adminSelectedUser);

  useEffect(() => {
    if (!user) {
      userId && dispatch(getUserById(+userId));
    }
    user && dispatch(getUserRewardPoints(user.id));
  }, [role, user, userId]);

  return (
    <Container>
      <Grid
        container
        columnGap={2}
        flexWrap={'nowrap'}
        alignItems={'stretch'}
        my={6}
      >
        <Grid item md={6}>
          <MonthlyPointsActivity userId={user?.id} />
        </Grid>
        <Grid item md={6}>
          <TotalRewardPoints userId={user?.id} />
        </Grid>
      </Grid>
      <PointsActivityContainer>
        <RewardPointsHistoryWrapper userId={user?.id} />
      </PointsActivityContainer>
    </Container>
  );
};

export default UserRewardPoints;

import ScrollbarComponent from '@/components/scrollbar/Scrollbar';
import { useSelect } from '@mui/base';
import { Backdrop, Box } from '@mui/material';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { UnreadBadge } from '../../components';
import { ICompanyWithUnread } from './CompanySelectorWarapper';
import { ChewronLeftIcon } from '@/components/icons';

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const Root = styled('div')`
  width: 100%;
  position: relative;
  display: inline-block;
  vertical-align: baseline;
  color: #000;
  margin-bottom: 1em;
`;

const Toggle = styled('div')`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.drawer + 2};
  min-height: calc(1.5em + 22px);
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  text-align: left;
  line-height: 1.5;
  border: 1px solid
    ${({ theme }) => (theme.palette.mode === 'dark' ? grey[700] : grey[200])};
  color: ${({ theme }) =>
    theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: var(
    --color,
    ${({ theme }) => (theme.palette.mode === 'dark' ? grey[900] : '#fff')}
  );
  display: inline-flex;
  align-items: center;
  cursor: default;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  & .placeholder {
    opacity: 0.8;
  }
`;

const ToggleField = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ListBoxContainer = styled(Box)`
  position: absolute;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.drawer + 2};
  height: auto;
  background-color: 'red';
  border: 1px solid
    ${({ theme }) => (theme.palette.mode === 'dark' ? grey[700] : grey[200])};
  overflow: hidden;
  border-radius: 12px;
  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
`;

const Listbox = styled('ul')`
  background-color: ${({ theme }) => theme.palette.neutral.main};
  min-height: calc(1.5em + 22px);
  max-height: 300px;
  padding: 12px;
  text-align: left;
  line-height: 1.5;
  color: ${({ theme }) =>
    theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  padding: 5px;
  margin: 0;
  outline: 0px;
  list-style: none;

  & > li {
    padding: 8px;
    border-radius: 0.45em;

    &:hover {
      background: ${({ theme }) =>
        theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    }

    &[aria-selected='true'] {
      background: ${({ theme }) =>
        theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    }
  }
`;

interface Props {
  options: ICompanyWithUnread[];
  defaultCompany?: number;
  onCompanySelect: (userId: number | null) => void;
}

const ChatCompanySelect: React.FC<Props> = ({
  options,
  defaultCompany,
  onCompanySelect,
}) => {
  const listboxRef = useRef<HTMLUListElement>(null);
  const [listboxVisible, setListboxVisible] = useState(false);

  const {
    getButtonProps,
    getListboxProps,
    getOptionProps,
    value: selectedCompany,
  } = useSelect<ICompanyWithUnread>({
    listboxRef,
    options: options.map((user) => ({
      value: user,
      label: user.name,
    })),
    defaultValue: options?.find(({ id }) => id === defaultCompany) || null,
  });

  useEffect(() => {
    if (listboxVisible) {
      listboxRef.current?.focus();
    }
  }, [listboxVisible]);

  useEffect(() => {
    setListboxVisible(false);
    onCompanySelect(selectedCompany?.id || null);
  }, [selectedCompany, onCompanySelect]);

  return (
    <Root onClick={() => setListboxVisible(true)}>
      <Toggle {...getButtonProps()}>
        {selectedCompany ? (
          <ToggleField>
            <Box>{selectedCompany.name}</Box>
            <ChewronLeftIcon
              style={{
                transform: listboxVisible ? 'rotate(90deg)' : 'rotate(-90deg)',
              }}
            />
          </ToggleField>
        ) : (
          <span className="placeholder">Select Company</span>
        )}
      </Toggle>
      <Backdrop
        sx={{
          background: 'transparent',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={listboxVisible}
        onClick={() => setListboxVisible(false)}
        onMouseOver={() => setListboxVisible(false)}
      ></Backdrop>
      <ListBoxContainer className={listboxVisible ? '' : 'hidden'}>
        <ScrollbarComponent>
          <Listbox {...getListboxProps()}>
            {options.map((company) => (
              <li
                key={company.id}
                {...getOptionProps({
                  label: company.name,
                  value: company,
                })}
              >
                <Box display="flex" justifyContent="space-between">
                  {company.name}
                  {!!company.unread && (
                    <UnreadBadge>{company.unread}</UnreadBadge>
                  )}
                </Box>
              </li>
            ))}
          </Listbox>
        </ScrollbarComponent>
      </ListBoxContainer>
    </Root>
  );
};

export default React.memo(ChatCompanySelect, (prevProp, newProp) => {
  const isEqualValues =
    prevProp.defaultCompany === newProp.defaultCompany &&
    prevProp.options.length === newProp.options.length &&
    prevProp.options.every(({ id, name, unread }) => {
      const sameItem = newProp.options.find((newOption) => newOption.id === id);
      return sameItem && sameItem.name === name && sameItem.unread === unread;
    });
  return isEqualValues;
});

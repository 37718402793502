import { IClassNameProp } from '@/components/icons/breaktimers/CustomComponents/constants';
import React from 'react';

const IconFailed: React.FC<IClassNameProp> = ({
  className,
}: IClassNameProp) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="-1 -1 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="10" cy="10" r="10" stroke="#FF375B" strokeWidth="2" />
      <rect
        x="13"
        y="-4"
        width="2"
        height="8"
        rx="1"
        fill="#FF375B"
        transform="rotate(45)"
      />
      <rect
        x="-1"
        y="10"
        width="2"
        height="8"
        rx="1"
        fill="#FF375B"
        transform="rotate(-45)"
      />
    </svg>
  );
};

export default IconFailed;

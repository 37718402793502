import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { searchCompanies } from '@/redux/actions/company';
import { setSearchText } from '@/redux/reducers/company';
import { getCompanySearchText } from '@/redux/selectors/companySelectors';
import { ELimits } from '@/types/consts';
import { ICompanySearchParams } from '@/types/models';
import { Box, Button as MuiButton, OutlinedInput } from '@mui/material';
import React, { KeyboardEvent, useState } from 'react';
import styled from 'styled-components';

const SearchBarGrid = styled(Box)`
  display: grid;
  grid-template-columns: 5fr 360px 1fr 4fr;
  gap: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(6)};
  margin-bottom: ${({ theme }) => theme.spacing(13)};
`;

const SearchButton = styled(MuiButton)`
  background-color: ${({ theme }) => theme.palette.background.default};
  border: none;
  height: 100%;
  width: 160px;

  &:hover {
    background-color: #dde5ed;
  }
`;

const SearchBar = () => {
  const searchText = useAppSelector(getCompanySearchText);
  const [value, setValue] = useState<string>(searchText);
  const dispatch = useAppDispatch();

  const handleChangeValue = (event: { target: { value: string } }) => {
    setValue(event.target.value);
  };

  const handleClick = () => {
    dispatch(setSearchText(value));
    const searchParams: ICompanySearchParams = {
      company: value,
      limit: ELimits.COMPANY,
    };

    return dispatch(searchCompanies(searchParams));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <SearchBarGrid>
      <Box></Box>
      <Box>
        <OutlinedInput
          value={value}
          placeholder={'Company'}
          onChange={handleChangeValue}
          onKeyDown={handleKeyDown}
          fullWidth
        />
      </Box>
      <Box>
        <SearchButton onClick={handleClick} color={'secondary'}>
          Search
        </SearchButton>
      </Box>
    </SearchBarGrid>
  );
};

export default SearchBar;

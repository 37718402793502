import ResendLinkButton from '@/components/admin/user/ResendLinkUserButton';
import ConfirmModal, { ModalStatuses } from '@/components/dialogs/ConfirmModal';
import { BillingContactIcon, DeleteCompanyIcon } from '@/components/icons';
import { AvatarType, CustomAvatar } from '@/components/UI/CustomAvatar';

import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { changeRole, getUserById, updateUser } from '@/redux/actions/users';
import { adminSelectedUser, getOneUserLoaded } from '@/redux/selectors/users';
import theme from '@/theme/theme';
import {
  changeRolesListName,
  DEFAULT_TOAST_TIME,
  EUserRole,
} from '@/types/consts';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import { formatPhoneNumber } from '@/utils/textHelpers';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Switch from '@/components/UI/Switch';

const DeleteButton = styled(Button)`
  width: 100%;
  height: 42px;
  background-color: #feebef;
  color: ${({ theme }) => theme.palette.redColor.main};

  &:hover {
    background-color: #c2d2e1;
  }
`;

const UserEmail = styled(Typography)`
  color: #0099ff;
  text-decoration: underline;
  text-align: center;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

const UserPhone = styled(Typography)`
  color: #5b587d;
  text-align: center;
`;

interface IUserPopoverContent {
  userId: number | null;
  handleDelete: () => void;
}

const UserPopoverContent: React.FC<IUserPopoverContent> = ({
  userId,
  handleDelete,
}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(adminSelectedUser);
  const loading = useAppSelector(getOneUserLoaded);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [roleToChange, setRole] = useState('');

  const [isSupport, setIsSupport] = useState<boolean>(false);

  useEffect(() => {
    user && setIsSupport(user.isPlatformSupport);
  }, [user]);

  const handleOpenConfirm = (value: string) => {
    setRole(value);
    setConfirmationOpen(true);
  };

  const changeRoleCallback = useCallback(() => {
    dispatch(
      changeRole({ id: Number(userId), role: roleToChange as EUserRole })
    )
      .unwrap()
      .then(() => {
        setConfirmationOpen(false);
        toast.success('Role changed successfully!', {
          position: 'top-right',
          autoClose: DEFAULT_TOAST_TIME,
          hideProgressBar: false,
        });
        window.location.reload();
      })
      .catch((e: any) => {
        toast.error(e.error, {
          position: 'top-right',
        });
      });
  }, [roleToChange]);

  useEffect(() => {
    userId && dispatch(getUserById(userId));
  }, [dispatch, userId]);

  if (loading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  const handleChangeSupport = () => {
    setIsSupport((prevState) => !prevState);
    if (user) {
      dispatch(
        updateUser({
          id: user.id,
          isPlatformSupport: !isSupport,
        })
      );
    }
  };

  return (
    <Box sx={{ width: '250px' }}>
      <CustomAvatar
        diameter={46}
        avatartype={AvatarType.USER}
        sx={{
          margin: theme.spacing(0, 'auto'),
        }}
        alt="Remy Sharp"
      >
        {!user
          ? ''
          : getUserAvatarLetter({
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
            })}
      </CustomAvatar>
      {user?.email && <UserEmail>{user.email}</UserEmail>}
      {user?.phone && (
        <UserPhone my={1}>{formatPhoneNumber(user.phone)}</UserPhone>
      )}
      {user?.companies[0]?.billingContactId === user?.id && (
        <UserPhone my={1}>
          <BillingContactIcon /> Billing Contact
        </UserPhone>
      )}
      <Grid container justifyContent="center">
        <Grid
          item
          md={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ margin: 2 }}
        >
          <Switch
            sx={{ marginRight: 1 }}
            disabled={loading}
            name={'userIsChatSupport'}
            placeholder={'User Is Chat Support'}
            checked={isSupport}
            onChange={handleChangeSupport}
          />
          <b>User Is Chat Support</b>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" sx={{ marginBottom: '10px' }}>
        <Grid item md={8}>
          <Select
            onChange={(event) => handleOpenConfirm(event.target.value)}
            displayEmpty
            name={'role'}
            value={user?.role ? user.role.name : 'user'}
            fullWidth
            renderValue={(selected) => {
              const role = changeRolesListName.find(
                ({ name }) => name === selected
              );
              return role?.view_name;
            }}
            input={<OutlinedInput />}
          >
            {changeRolesListName.map((role) => (
              <MenuItem key={role.name} value={role.name}>
                {role.view_name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        {!user?.isConfirm && (
          <Grid item md={6}>
            <ResendLinkButton userId={user?.id as number} />
          </Grid>
        )}
        <Grid item md={4}>
          <DeleteButton
            type={'button'}
            variant={'contained'}
            color={'primary'}
            onClick={handleDelete}
          >
            <DeleteCompanyIcon />
          </DeleteButton>
        </Grid>
      </Grid>
      <ConfirmModal
        confirmStatus={ModalStatuses.PRIMARY}
        text={
          'You are trying to change users role, this action affects on company structure and access levels. To confirm changing users role click Continue.'
        }
        isOpen={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={changeRoleCallback}
      />
    </Box>
  );
};

export default UserPopoverContent;

import { Box, IconButton } from '@mui/material';
import styled from 'styled-components';

export const PersonalActivityContainer = styled.div`
  background-color: #f3f6f8cc;
  border-radius: 16px;
  padding: 29px 22px 21px 23px;
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 20px;
  color: ${(props) => props.theme.palette.text.primary};
  font-weight: 500;
`;

export const SubTitle = styled.h6`
  text-align: center;
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.primary};
  font-weight: 400;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 9px;
`;

export const TabsContainer = styled(Box)`
  & .MuiTabs-root {
    padding: 17px;
    background: transparent;
  }
`;

export const DiagramContainer = styled.div`
  background: ${(props) => props.theme.palette.neutral.main};
  box-shadow: 0px 24px 64px rgba(0, 5, 62, 0.15);
  border-radius: 16px;
  padding: 10px 35px;
`;

export const PeriodSwitcher = styled.div`
  height: 38px;
  width: 240px;
  background: #00000010;
  border-radius: 58px;
  padding: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 10px;
`;

export const PeriodLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
`;

export const PeriodIconButton = styled(IconButton)`
  background-color: ${(props) => props.theme.palette.neutral.main};
  color: ${(props) => props.theme.palette.text.secondary};
  border-radius: 50%;
  width: 24px;
  height: 24px;
`;

import {
  ActivityLogContainer,
  BackgroundHalf,
  CompanyProgressContainer,
  ContentContainer,
  DailyProgressContainer,
  GeneralContainer,
} from '@/components/admin/company/components';
import { ProgressContainer } from '@/components/admin/user/components';
import KnolwledgeCenterProggress from '@/components/admin/user/KnolwledgeCenterProggress';
import UserSelect from '@/components/form-elements/UserSelect';
import ActivityBreakdownWrapper from '@/components/progressMeter/ActivityBreakdown/ActivityBreakDownWrapper';
import {
  MessageStatTitle,
  StatLabel,
  StatValue,
} from '@/components/progressMeter/components';
import { Title } from '@/components/progressMeter/PersonalActivity/components';
import PersonalActivity from '@/components/progressMeter/PersonalActivity/PersonalActivity';
import ProgressMeter from '@/components/progressMeter/ProgressMeter/ProgressMeter';
import {
  Days,
  StreakDays,
} from '@/components/progressMeter/RewardPoints/components';
import WorkSpaceProgress from '@/components/progressMeter/WorkSpaceProgress';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useCompany from '@/hooks/useCompany';
import useGetWorkspaceProgress from '@/hooks/useGetWorkspaceProgress';
import { TabsContainer } from '@/pages/dashboard/ProgressMeterWidget/components';
import {
  getCompanyMessagingStat,
  getCompanyProgress,
} from '@/redux/actions/company';
import {
  getUserDailyStreak,
  getUserMessagesStat,
  getUserProgress,
  getUsersByParams,
} from '@/redux/actions/users';
import {
  companyMessagingStats,
  companyProgressSelector,
} from '@/redux/selectors/companySelectors';
import {
  adminUserDailyStreak,
  adminUserMessagingStats,
  adminUserProgressSelector,
} from '@/redux/selectors/users';
import {
  EActivityLevel,
  ECompanyActivitySelect,
  EPeriod,
} from '@/types/consts';
import { IActivityProgress, IMessagesStat, IUser } from '@/types/models';
import { getEndOfWeek, getStartOfWeek } from '@/utils/dateHelpers';
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from '@mui/material';
import { format, formatDuration } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { numberWithCommas } from './helpers';

export const CompanyActivity: React.FC = () => {
  const dispatch = useAppDispatch();
  const [groupMode, setGroupMode] = useState<ECompanyActivitySelect>(
    ECompanyActivitySelect.TEAM
  );
  const { company } = useCompany();
  const [companyTeam, setCompanyTeam] = useState<IUser[] | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const { totalLength, currentLength, countCategories } =
    useGetWorkspaceProgress(userId);
  const [progress, setProgress] = useState<Omit<
    IActivityProgress,
    'date'
  > | null>(null);
  const [messageStat, setMessageStat] = useState<IMessagesStat | null>(null);
  const [period, setPeriod] = useState<EPeriod>(EPeriod.DAY);

  const userProgress = useAppSelector(adminUserProgressSelector);
  const companyProgress = useAppSelector(companyProgressSelector);
  const dailyStreak = useAppSelector(adminUserDailyStreak);
  const userMessageStat = useAppSelector(adminUserMessagingStats);
  const companyMessageStat = useAppSelector(companyMessagingStats);

  useEffect(() => {
    const dateRange = {
      from: format(new Date(), 'yyyy-MM-dd'),
      to: format(new Date(), 'yyyy-MM-dd'),
      dataLevel: EActivityLevel.DYNAMIC,
    };
    if (groupMode === ECompanyActivitySelect.INDIVIDUAL && userId) {
      dispatch(
        getUserProgress({
          ...dateRange,
          userId,
        })
      );
      dispatch(
        getUserDailyStreak({
          from: format(new Date(), 'yyyy-MM-dd'),
          to: format(new Date(), 'yyyy-MM-dd'),
          userId,
        })
      );
      dispatch(getUserMessagesStat(userId));
    }
    if (groupMode === ECompanyActivitySelect.TEAM && company?.id) {
      dispatch(
        getCompanyProgress({
          ...dateRange,
          companyId: company?.id,
        })
      );
      dispatch(getCompanyMessagingStat(company?.id));
    }
  }, [dispatch, groupMode, userId, company?.id]);

  useEffect(() => {
    const start =
      period === EPeriod.WEEK ? getStartOfWeek(new Date()) : new Date();
    const end = period === EPeriod.WEEK ? getEndOfWeek(new Date()) : new Date();
    const progressParams = {
      from: format(start, 'yyyy-MM-dd'),
      to: format(end, 'yyyy-MM-dd'),
      dataLevel: EActivityLevel.DYNAMIC,
    };
    if (groupMode === ECompanyActivitySelect.INDIVIDUAL && userId) {
      dispatch(
        getUserProgress({
          ...progressParams,
          userId,
        })
      );
    }
    if (groupMode === ECompanyActivitySelect.TEAM && company?.id) {
      dispatch(
        getCompanyProgress({
          ...progressParams,
          companyId: company?.id,
        })
      );
    }
  }, [period, userId, company?.id]);

  useEffect(() => {
    if (company?.name) {
      dispatch(getUsersByParams({ company: company?.name }))
        .unwrap()
        .then(({ users }) => {
          if (users) {
            setCompanyTeam(users);
          }
        });
    }
  }, [dispatch, company?.name]);

  useEffect(() => {
    setProgress(
      groupMode === ECompanyActivitySelect.INDIVIDUAL
        ? userProgress
        : companyProgress
    );
  }, [groupMode, userProgress, companyProgress]);

  useEffect(() => {
    setMessageStat(
      groupMode === ECompanyActivitySelect.INDIVIDUAL
        ? userMessageStat
        : companyMessageStat
    );
  }, [groupMode, userMessageStat, companyMessageStat]);

  return (
    <GeneralContainer>
      <BackgroundHalf />
      <ContentContainer sx={{ py: 6 }}>
        <Box display="flex" justifyContent="center">
          <Select
            value={groupMode}
            onChange={(e) =>
              setGroupMode(e.target.value as ECompanyActivitySelect)
            }
          >
            <MenuItem value={ECompanyActivitySelect.TEAM}>
              Show Team Activity
            </MenuItem>
            <MenuItem value={ECompanyActivitySelect.INDIVIDUAL}>
              Show Individual Activity
            </MenuItem>
          </Select>
          {groupMode === ECompanyActivitySelect.INDIVIDUAL && companyTeam && (
            <UserSelect
              options={companyTeam}
              onUserSelect={(userId) => setUserId(userId)}
            />
          )}
        </Box>
        {company ? (
          <>
            {progress && (
              <DailyProgressContainer
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box alignSelf="flex-start">
                  <Title>Daily Progress</Title>
                </Box>
                <Box my="auto">
                  <TabsContainer flexGrow="1" component={'section'}>
                    <Tabs
                      value={period}
                      onChange={(e, value) => setPeriod(value)}
                      centered
                      textColor={'secondary'}
                    >
                      <Tab value={EPeriod.DAY} label="Day" />
                      <Tab value={EPeriod.WEEK} label="Week" />
                    </Tabs>
                  </TabsContainer>
                  <ProgressMeter
                    social={progress?.social}
                    mental={progress?.mental}
                    physical={progress?.physical}
                  />
                </Box>
                {groupMode === ECompanyActivitySelect.INDIVIDUAL && userId && (
                  <Box alignSelf="flex-end" justifySelf="flex-end">
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                    >
                      <StreakDays>
                        100% Goal Reached
                        <Days>
                          {formatDuration({
                            days: dailyStreak?.totalDaysCompleted,
                          }) || '0 days'}
                        </Days>
                      </StreakDays>
                      <StreakDays>
                        Longest Consecutive Streak
                        <Days>
                          {formatDuration({
                            days: dailyStreak?.maxDaysInARowCompleted,
                          }) || '0 days'}
                        </Days>
                      </StreakDays>
                    </Box>
                  </Box>
                )}
              </DailyProgressContainer>
            )}
            <PersonalActivity
              userId={
                groupMode === ECompanyActivitySelect.INDIVIDUAL ? userId : null
              }
              companyId={
                groupMode === ECompanyActivitySelect.TEAM ? company?.id : null
              }
            />
            <ActivityLogContainer>
              <ActivityBreakdownWrapper
                userId={
                  groupMode === ECompanyActivitySelect.INDIVIDUAL
                    ? userId
                    : null
                }
                companyId={
                  groupMode === ECompanyActivitySelect.TEAM ? company?.id : null
                }
                isCompanyView={true}
              />
            </ActivityLogContainer>
            {groupMode === ECompanyActivitySelect.INDIVIDUAL && userId && (
              <CompanyProgressContainer>
                <ProgressContainer container flexWrap={'nowrap'} columnGap={12}>
                  <Grid item md={6}>
                    <KnolwledgeCenterProggress userId={userId} />
                  </Grid>
                  <Grid item md={6}>
                    <WorkSpaceProgress from={20} completed={countCategories} />
                  </Grid>
                </ProgressContainer>
              </CompanyProgressContainer>
            )}
            {messageStat && (
              <Box paddingX="70px" marginTop="36px">
                <MessageStatTitle>Messaging</MessageStatTitle>
                <Box display="flex">
                  <Box display="flex">
                    <StatLabel>Messages Sent This Week</StatLabel>
                    <StatValue>
                      {numberWithCommas(messageStat.weekCount)}
                    </StatValue>
                  </Box>
                  <Box display="flex" marginLeft="30px">
                    <StatLabel>Messages Sent This Month</StatLabel>
                    <StatValue>
                      {numberWithCommas(messageStat.monthCount)}
                    </StatValue>
                  </Box>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <CircularProgress />
        )}
      </ContentContainer>
    </GeneralContainer>
  );
};

export default CompanyActivity;

import { WorkspaceState } from '@/redux/reducers/workspace';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const allItemsSelector = createSelector(
  (state: RootState) => state.workSpace,
  ({ items }: WorkspaceState) => items
);

export const currentItemsSelector = createSelector(
  (state: RootState) => state.workSpace,
  ({ currentItems }: WorkspaceState) => currentItems
);

export const allCategoriesSelector = createSelector(
  (state: RootState) => state.workSpace,
  ({ categories }: WorkspaceState) => categories
);

export const workSpaceCountCategoriesSelector = createSelector(
  (state: RootState) => state.workSpace,
  ({ countCategories }) => countCategories
);

export const workSpaceLoadingSelector = createSelector(
  (state: RootState) => state.workSpace,
  ({ loading }) => loading
);

export const workSpaceDailyChack = createSelector(
  (state: RootState) => state.workSpace,
  ({ dailyCheck }) => dailyCheck
);

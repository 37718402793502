import components from '@/theme/components';
import header from '@/theme/header';
import knowledgeBase from '@/theme/knowledgeBase';
import palette from '@/theme/palette';
import sidebar from '@/theme/sidebar';
import typography from '@/theme/typography';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  spacing: 5,
  palette,
  typography,
  components,
  sidebar,
  header,
  knowledgeBase,
});

export default theme;

import { Box } from '@mui/material';
import styled from 'styled-components';

export const MyActivityContainer = styled(Box)`
  margin-top: 48px;
`;

export const GeneralInfoBlock = styled(Box)`
  background-color: #f3f6f8cc;
  border-radius: 16px;
  margin-bottom: 40px;
  & > * {
    flex-basis: 50%;
  }
`;

export const ProgressMeterInfo = styled(Box)`
  flex-grow: 1;
  padding: 26px 30px 34px;
`;

export const ProgressMeterContainer = styled(Box)`
  margin: auto 0;
`;

export const MessageStatTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 18px;
`;

export const StatLabel = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-right: 6px;
`;

export const StatValue = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

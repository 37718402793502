import {
  CopyIcon,
  DeleteIcon,
  LinkIcon,
  PinIcon,
  PinnedMessageIcon,
} from '@/components/icons';
import { UserContextMenu } from '@/components/team-admin/components';
import { AvatarType, CustomAvatar } from '@/components/UI/CustomAvatar';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import { deleteMessage, pinMessage, unpinMessage } from '@/redux/actions/chat';
import { getMyTeamMember } from '@/redux/selectors/userSelectors';
import { EMessageType, EMOJI_REGEX, EUserRole } from '@/types/consts';
import { IImageData, IMessage, IMessageData, IUser } from '@/types/models';
import { uidToId } from '@/utils/chatHelpers';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import { urlify } from '@/utils/textHelpers';
import { Box } from '@mui/material';
import { format, fromUnixTime } from 'date-fns';
import React, { FC, useCallback, useMemo } from 'react';
import {
  ContextMenu,
  ContextMenuIcon,
  ContextMenuItem,
  ImagePreview,
  MenuItemIcon,
  MessageBubble,
  MessageBubbleContainer,
  MessageUserName,
  SendTime,
} from '../components';
import { pinnedMessageSelector } from '@/redux/selectors/chatSelector';
import { unpinDeletedMessage } from '@/redux/reducers/chat';

interface IMessageBubbleProps {
  message: IMessage;
  isMyMessage: boolean;
  isRwAdminMessage: boolean;
  key: string;
  isGroupChat: boolean;
  setIsScrolled?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Message: FC<IMessageBubbleProps> = ({
  message,
  isMyMessage,
  isRwAdminMessage,
  isGroupChat,
  setIsScrolled,
}) => {
  const dispatch = useAppDispatch();
  const me = useAuth().user as IUser;
  const teamMember = useAppSelector(getMyTeamMember(uidToId(message.sender)));
  const pinnedMessage = useAppSelector(pinnedMessageSelector);
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  const linkForImage =
    message.type === EMessageType.IMAGE &&
    'msgLink' in message.data &&
    message.data.msgLink;

  const user = useMemo(() => {
    if (isMyMessage) {
      return me;
    }

    return teamMember;
  }, [isMyMessage, isRwAdminMessage, teamMember, me]);

  const closeMenu = () => {
    setAnchorMenu(null);
    setIsScrolled && setIsScrolled(true);
  };

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const copyMessage = useCallback(() => {
    if (message.type === EMessageType.TEXT) {
      navigator.clipboard.writeText((message.data as IMessageData).text);
      closeMenu();
    }
  }, [message]);

  const deleteMsg = useCallback(() => {
    dispatch(deleteMessage(message.id));
    if (pinnedMessage?.id === message.id) {
      dispatch(unpinDeletedMessage());
    }
    closeMenu();
  }, [message]);

  const pinMsg = useCallback(() => {
    if (pinnedMessage?.chatId === message.receiver) {
      dispatch(unpinMessage({ messageId: +pinnedMessage.id }))
        .unwrap()
        .then(() => {
          dispatch(pinMessage({ messageId: +message.id }));
        });
    } else {
      dispatch(pinMessage({ messageId: +message.id }));
    }
    closeMenu();
  }, [message, pinnedMessage]);

  const unpinMsg = useCallback(() => {
    dispatch(unpinMessage({ messageId: +message.id }));
    closeMenu();
  }, [message]);

  const isOnlyEmojiMessage = useMemo(() => {
    const text = (message.data as IMessageData).text;
    return !!text?.match(EMOJI_REGEX);
  }, [message.data]);

  const isPinOptionAvailable = useMemo(
    () => me.role.name === EUserRole.RW_ADMIN && isMyMessage && isGroupChat,
    [me?.role?.name, isMyMessage, isGroupChat]
  );

  const isPinnedMsg = pinnedMessage && pinnedMessage?.id === message.id;

  return (
    <MessageBubbleContainer
      myMessage={isMyMessage}
      id={`message-${message.id}`}
      isColoredMessage={isPinnedMsg}
    >
      <Box display="flex" flexDirection="column" maxWidth="80%">
        <Box
          display="flex"
          flexDirection={isMyMessage ? 'row' : 'row-reverse'}
          justifyContent="flex-end"
        >
          <SendTime>
            {format(fromUnixTime(message.sentAt), 'MM/dd/yyyy hh:mm a')}
          </SendTime>
          <Box
            display="flex"
            flexDirection={isMyMessage ? 'row' : 'row-reverse'}
          >
            <MessageUserName myMessage={isMyMessage}>
              {user?.firstName
                ? `${user?.firstName} ${user.lastName}`
                : user?.email}
            </MessageUserName>
            <CustomAvatar
              diameter={24}
              avatartype={AvatarType.USER}
              sx={{
                fontSize: 16,
              }}
              alt="Remy Sharp"
              src={user?.profileImage || undefined}
            >
              {!user
                ? ''
                : getUserAvatarLetter({
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                  })}
            </CustomAvatar>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={isMyMessage ? 'row' : 'row-reverse'}
          justifyContent="flex-end"
          width="100%"
        >
          {(message.type === EMessageType.TEXT || isMyMessage) && (
            <ContextMenuIcon
              disableRipple
              disableFocusRipple
              aria-owns={
                Boolean(anchorMenu) ? `menu-message-${message.id}` : undefined
              }
              aria-haspopup="true"
              onClick={toggleMenu}
            >
              <UserContextMenu />
            </ContextMenuIcon>
          )}
          <ContextMenu
            id={`menu-message-${message.id}`}
            anchorEl={anchorMenu}
            open={Boolean(anchorMenu)}
            onClose={closeMenu}
          >
            {message.type === EMessageType.TEXT && (
              <ContextMenuItem onClick={() => copyMessage()}>
                <MenuItemIcon>
                  <CopyIcon />
                </MenuItemIcon>
                Copy
              </ContextMenuItem>
            )}
            {isMyMessage && (
              <ContextMenuItem onClick={() => deleteMsg()}>
                <MenuItemIcon>
                  <DeleteIcon />
                </MenuItemIcon>
                Delete
              </ContextMenuItem>
            )}
            {isPinOptionAvailable && (
              <ContextMenuItem
                onClick={() =>
                  pinnedMessage && pinnedMessage?.id === message.id
                    ? unpinMsg()
                    : pinMsg()
                }
              >
                {pinnedMessage && pinnedMessage?.id === message.id ? (
                  <>
                    <MenuItemIcon>
                      <PinIcon />
                    </MenuItemIcon>
                    Unpin
                  </>
                ) : (
                  <>
                    <MenuItemIcon>
                      <PinIcon />
                    </MenuItemIcon>
                    Pin
                  </>
                )}
              </ContextMenuItem>
            )}
          </ContextMenu>
          <MessageBubble myMessage={isMyMessage}>
            {message.type === EMessageType.TEXT ? (
              <Box
                fontSize={isOnlyEmojiMessage ? '32px' : '14px'}
                dangerouslySetInnerHTML={{
                  __html: urlify((message.data as IMessageData).text),
                }}
              ></Box>
            ) : (
              <>
                <Box minHeight={200} height="20vh">
                  {linkForImage ? (
                    <a href={linkForImage} target="_blank" rel="noreferrer">
                      <ImagePreview src={(message.data as IImageData).url} />
                    </a>
                  ) : (
                    <ImagePreview src={(message.data as IImageData).url} />
                  )}
                </Box>
                {message.data.text && (
                  <Box mt={1} maxWidth={450}>
                    {message.data.text}
                  </Box>
                )}
              </>
            )}
            {linkForImage ? (
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                {linkForImage && (
                  <a href={linkForImage} target="_blank" rel="noreferrer">
                    <Box display="flex" alignItems="center">
                      <LinkIcon stroke="#27A80A" />
                    </Box>
                  </a>
                )}
                {isPinnedMsg && (
                  <Box ml={1}>
                    <PinnedMessageIcon />
                  </Box>
                )}
              </Box>
            ) : (
              <>
                {isPinnedMsg && (
                  <Box position="absolute" bottom="9px" right="9px">
                    <PinnedMessageIcon />
                  </Box>
                )}
              </>
            )}
          </MessageBubble>
        </Box>
      </Box>
    </MessageBubbleContainer>
  );
};

export default Message;

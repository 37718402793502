import {
  ACPowerManagement15,
  AdjustableDesk1,
  AntiFatigueMat21,
  Art29,
  DeskLight11,
  DiffuserSteamerPurifier32,
  DisplayMonitorExternal8,
  Dumbbells25,
  ErgonomicChair2,
  ErgonomicChairPillow20,
  ErgonomicWristRest19,
  ExternalSpeakerSystem7,
  ExternalWebcam3,
  FitnessTracker23,
  HeadphonesEarbuds4,
  HeadphoneStand16,
  KeyboardExternal9,
  LaptopStand18,
  Mat24,
  MouseExternal10,
  NootbookAC,
  OutdoorBike28,
  PhoneStand17,
  Plant30,
  PoweredUSBHub14,
  Scene,
  SecretBox,
  SecretSticker,
  Speakerphone6,
  StressReliever31,
  Training27,
  Treadmill26,
  USBMicrophone5,
  VideoLight12,
  WaterBottle22,
  ZoomerangCallStation13,
} from '@/components/icons/workspace';
import {
  ACPowerManagement,
  AntiFatigueMat,
  Art,
  Bicycle,
  ChairAdjustable,
  DeskAdjustable,
  DeskLight,
  DiffuserSteamer,
  ErgonomicChairPillow,
  ErgonomicWristRest,
  ExternalSpeakerSystem,
  FitnessTracker,
  HeadphoneMicComboWired,
  HeadphoneStand,
  KeyboardStandard,
  LaptopStand,
  Light,
  MonitorsDisplays,
  MouseStandard,
  PhoneStandWithPhone,
  Plants,
  PoweredUSBHub,
  SecreteBox,
  SecretePaper,
  SmallHandWeights,
  Speakerphone,
  StationaryBike,
  StressRelievers,
  Treadmill,
  USBMicrophone,
  VideoLights,
  WaterBottle,
  Webcam,
  YogaMat,
  ZoomerangCallStation,
} from '@/components/icons/workspaceItems';
import { WorkspaceItem } from '@/pages/dashboard/MyWorkspace/types';

export enum SecreteItems {
  BOX = 'Treasure Chest on bookshelf',
  STICKER = '? post it note on Bulletin Board',
}

export const initialValue: WorkspaceItem[] = [
  {
    name: 'Scene',
    status: true,
    component: Scene,
    zIndex: 1,
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
    cursor: 'initial',
    grayColor: 0,
    bright: 211,
  },
  {
    name: 'Laptop',
    status: true,
    component: NootbookAC,
    zIndex: 3,
    clipPath:
      'polygon(60% 48.5%, 63% 42%, 67% 45%, 66.5% 50.5%, 63.5% 52%, 60% 49.5%)',
    cursor: 'initial',
    grayColor: 400,
    bright: 189,
  },
  {
    name: 'Adjustable Desk',
    status: false,
    component: AdjustableDesk1,
    clipPath:
      'polygon(30% 59%, 32% 46%, 66% 46%, 68% 59%, 68% 72.5%, 63% 72.5%, 61% 60%, 35% 60%, 35% 72.5%, 33% 72.5%)',
    zIndex: 2,
    picture: DeskAdjustable,
    cursor: 'pointer',
    grayColor: 400,
    bright: 120,
  },
  {
    name: 'Ergonomic Chair',
    status: false,
    component: ErgonomicChair2,
    zIndex: 3,
    clipPath:
      'polygon(36% 56%, 38% 56%, 40% 58%, 45% 65%, 50% 68%, 51% 70%, 51.5% 73%, 49% 75%, 49% 77.5%, 51% 78%, 52% 82%, 50% 90%, 40% 90%, 35% 85%)',
    cursor: 'pointer',
    picture: ChairAdjustable,
    grayColor: 387,
    bright: 191,
  },
  {
    name: 'External Webcam',
    status: false,
    component: ExternalWebcam3,
    zIndex: 2,
    clipPath: 'polygon(47% 35%, 49.5% 35%, 49.5% 37%, 47% 37%)',
    cursor: 'pointer',
    picture: Webcam,
    grayColor: 400,
    bright: 263,
  },
  {
    name: 'Headphones/Earbuds',
    status: false,
    component: HeadphonesEarbuds4,
    zIndex: 3,
    clipPath:
      'polygon(24% 43%, 26% 43%, 39% 20%, 43.5% 20%, 43.5% 26.5%, 39% 26.5%, 39% 20%, 26% 43%, 26% 47.5%, 22.5% 47.5%, 22.5% 46%)',
    cursor: 'pointer',
    picture: HeadphoneMicComboWired,
    grayColor: 400,
    bright: 151,
  },
  {
    name: 'USB Microphone',
    status: false,
    component: USBMicrophone5,
    clipPath: 'polygon(64% 49%, 66% 49%, 66% 57%, 63% 57%)',
    zIndex: 4,
    cursor: 'pointer',
    picture: USBMicrophone,
    grayColor: 400,
    bright: 225,
  },
  {
    name: 'Speakerphone',
    status: false,
    component: Speakerphone6,
    zIndex: 2,
    clipPath: 'ellipse(1.5% 1.2% at 60.2% 42.5%)',
    cursor: 'pointer',
    picture: Speakerphone,
    grayColor: 400,
    bright: 332,
  },
  {
    name: 'External Speaker System',
    status: false,
    component: ExternalSpeakerSystem7,
    zIndex: 3,
    clipPath:
      'polygon(37% 45%, 40.5% 45%, 40.5% 50%, 56% 50%, 56% 45%, 60% 45%, 60% 50%, 37% 50%)',
    cursor: 'pointer',
    picture: ExternalSpeakerSystem,
    grayColor: 400,
    bright: 273,
  },
  {
    name: 'Display Monitor - External',
    status: false,
    component: DisplayMonitorExternal8,
    zIndex: 3,
    clipPath:
      'polygon(41.5% 37%, 55.5% 37%, 55.5% 49%, 51% 49%, 51% 51.5%, 45.5% 51.5%, 45.5% 49%, 41.5% 49%)',
    cursor: 'pointer',
    picture: MonitorsDisplays,
    grayColor: 400,
    bright: 307,
  },
  {
    name: 'Keyboard - External',
    status: false,
    component: KeyboardExternal9,
    zIndex: 3,
    clipPath: 'polygon(42% 52%, 51.5% 52%, 51.5% 55%, 42% 55%)',
    cursor: 'pointer',
    picture: KeyboardStandard,
    grayColor: 400,
    bright: 141,
  },
  {
    name: 'Mouse - External',
    status: false,
    component: MouseExternal10,
    zIndex: 3,
    clipPath: 'polygon(52.5% 52%, 54% 52%, 54% 55%, 52.5% 55%)',
    cursor: 'pointer',
    picture: MouseStandard,
    grayColor: 400,
    bright: 351,
  },
  {
    name: 'Desk Light',
    status: false,
    component: DeskLight11,
    zIndex: 3,
    clipPath:
      'polygon(34% 38%, 39% 41%, 39% 42%, 35.5% 42%, 35.5% 46.5%, 36.5% 46.5%, 36.5% 50%, 33% 50%)',
    cursor: 'pointer',
    picture: DeskLight,
    grayColor: 400,
    bright: 257,
  },
  {
    name: 'Video Light',
    status: false,
    component: VideoLight12,
    zIndex: 2,
    clipPath: 'polygon(23% 32%, 26.5% 32%, 26.5% 39.5%, 23% 39.5%)',
    cursor: 'pointer',
    picture: VideoLights,
    grayColor: 400,
    bright: 229,
  },
  {
    name: 'Video Call Light System',
    status: false,
    component: ZoomerangCallStation13,
    zIndex: 2,
    clipPath:
      'polygon(38.5% 26.5%, 44% 26.5%, 44% 33%, 62% 41%, 62% 39%, 70% 44%, 70% 46%, 66.5% 43%, 66.5% 45%, 64% 43%, 64% 41.5%, 62% 41%, 44% 33%, 38.5% 34%)',
    cursor: 'pointer',
    picture: ZoomerangCallStation,
    grayColor: 400,
    bright: 93,
  },
  {
    name: 'Powered USB Hub',
    status: false,
    component: PoweredUSBHub14,
    zIndex: 3,
    clipPath: 'polygon(53.5% 48%, 56.5% 48%, 56.5% 51.5%, 53.5% 51.5%)',
    cursor: 'pointer',
    picture: PoweredUSBHub,
    grayColor: 400,
    bright: 189,
  },
  {
    name: 'AC Power Management',
    status: false,
    component: ACPowerManagement15,
    zIndex: 2,
    clipPath: 'polygon(58% 64%, 62% 64%, 64% 70%, 57% 70%)',
    cursor: 'pointer',
    picture: ACPowerManagement,
    grayColor: 400,
    bright: 215,
  },
  {
    name: 'Headphone Stand',
    status: false,
    component: HeadphoneStand16,
    zIndex: 2,
    clipPath:
      'polygon(21.5% 42%, 23% 42%, 25% 44%, 25% 45%, 22.5% 44%, 22.5% 46%, 23.5% 47%, 23.5% 48.5%, 21.5% 48.5%)',
    cursor: 'pointer',
    picture: HeadphoneStand,
    grayColor: 400,
    bright: 40,
  },
  {
    name: 'Phone Stand',
    status: false,
    component: PhoneStand17,
    zIndex: 4,
    clipPath: 'polygon(34% 48%, 37% 48%, 37% 53%, 34% 53%)',
    cursor: 'pointer',
    picture: PhoneStandWithPhone,
    grayColor: 400,
    bright: 329,
  },
  {
    name: 'Laptop Stand',
    status: false,
    component: LaptopStand18,
    zIndex: 3,
    clipPath:
      'polygon(62% 51%, 63% 51%, 65.5% 51%, 65.5% 52.5%, 62% 54.5%, 60% 52%)',
    cursor: 'pointer',
    picture: LaptopStand,
    grayColor: 400,
    bright: 110,
  },
  {
    name: 'Ergonomic Wrist Rest',
    status: false,
    component: ErgonomicWristRest19,
    zIndex: 3,
    clipPath: 'polygon(42% 55%, 51.5% 55%, 51.5% 56.5%, 42% 56.5%)',
    cursor: 'pointer',
    picture: ErgonomicWristRest,
    grayColor: 400,
    bright: 127,
  },
  {
    name: 'Ergonomic Chair Pillow',
    status: false,
    component: ErgonomicChairPillow20,
    zIndex: 3,
    clipPath:
      'polygon(43% 66.5%, 44% 65.5%, 46% 67%, 46.5% 68%, 48% 68%, 49% 69%, 46% 72%, 47% 73%, 47% 73%, 48% 72%, 48% 73%, 46% 75%, 44.5% 73.5%, 44.5% 71%)',
    cursor: 'pointer',
    picture: ErgonomicChairPillow,
    grayColor: 400,
    bright: 278,
  },
  {
    name: 'Anti-Fatigue Mat',
    status: false,
    component: AntiFatigueMat21,
    zIndex: 2,
    clipPath: 'polygon(39.5% 70.5%, 57.5% 70.5%, 58.7% 77.5%, 38% 77.5%)',
    cursor: 'pointer',
    picture: AntiFatigueMat,
    grayColor: 400,
    bright: 196,
  },
  {
    name: 'Water Bottle',
    status: false,
    component: WaterBottle22,
    zIndex: 3,
    clipPath: 'polygon(32% 44.5%, 33.7% 44.5%, 33.7% 50.5%, 32% 50.5%)',
    cursor: 'pointer',
    picture: WaterBottle,
    grayColor: 400,
    bright: 226,
  },
  {
    name: 'Fitness Tracker',
    status: false,
    component: FitnessTracker23,
    zIndex: 3,
    clipPath: 'polygon(30% 52%, 32% 52%, 37% 56%, 35% 56%)',
    cursor: 'pointer',
    picture: FitnessTracker,
    grayColor: 400,
    bright: 158,
  },
  {
    name: 'Yoga Mat',
    status: false,
    component: Mat24,
    zIndex: 2,
    clipPath: 'polygon(95.5% 93%, 92% 79%, 95% 74%, 99% 90%)',
    cursor: 'pointer',
    picture: YogaMat,
    grayColor: 400,
    bright: 100,
  },
  {
    name: 'Small Hand Weights',
    status: false,
    component: Dumbbells25,
    zIndex: 2,
    clipPath:
      'polygon(48.5% 26.5%, 48.5% 20%, 54% 20%, 54% 26.5%, 48.5% 26.5%, 85% 81%, 90% 77%, 93% 82%, 88% 85%, 85% 81%)',
    cursor: 'pointer',
    picture: SmallHandWeights,
    grayColor: 400,
    bright: 86,
  },
  {
    name: 'Treadmill',
    status: false,
    component: Treadmill26,
    zIndex: 2,
    clipPath: 'polygon(43.5% 20%, 49% 20%, 49% 26.5%, 43.5% 26.5%)',
    cursor: 'pointer',
    picture: Treadmill,
    grayColor: 400,
    bright: 106,
  },
  {
    name: 'Stationary Bike',
    status: false,
    component: Training27,
    zIndex: 2,
    clipPath:
      'polygon(3% 90%, 6% 64%, 12% 64%, 12% 71%, 25% 71%, 25% 90%, 27% 90%, 27% 100%, 14% 100%)',
    cursor: 'pointer',
    picture: StationaryBike,
    grayColor: 400,
    bright: 86,
  },
  {
    name: 'Outdoor Bike',
    status: false,
    component: OutdoorBike28,
    zIndex: 2,
    clipPath: 'polygon(48.5% 26%, 53.7% 26%, 53.7% 33%, 48.5% 33%)',
    cursor: 'pointer',
    picture: Bicycle,
    grayColor: 400,
    bright: 140,
  },
  {
    name: 'Art',
    status: false,
    component: Art29,
    clipPath:
      'polygon(14.5% 39%, 14.5% 33.5%, 21% 33.5%, 21% 39%, 29% 36%, 29% 24.5%, 35% 24.5%, 61% 27.5%, 70.5% 27.5%, 70.5% 33%, 61% 33%, 61% 27.5%, 35% 24.5%, 35% 36%, 29% 36%, 21% 39%)',
    cursor: 'pointer',
    zIndex: 2,
    picture: Art,
    grayColor: 400,
    bright: 98,
  },
  {
    name: 'Plants',
    status: false,
    component: Plant30,
    clipPath:
      'polygon(84% 74%, 28% 21%, 20% 21%, 20% 12%, 28% 12%, 43.5% 26%, 48.7% 26%, 48.7% 33%, 43.5% 33%, 43.5% 26%, 28% 12%, 28% 21%, 84% 74%, 80% 42%, 93% 42%, 90% 74%)',
    zIndex: 2,
    cursor: 'pointer',
    picture: Plants,
    grayColor: 400,
    bright: 72,
  },
  {
    name: 'Stress Reliever',
    status: false,
    component: StressReliever31,
    clipPath: 'circle(1% at 21.7% 55.5%)',
    zIndex: 2,
    cursor: 'pointer',
    picture: StressRelievers,
    grayColor: 400,
    bright: 103,
  },
  {
    name: 'Diffuser/Steamer/Purifier',
    status: false,
    component: DiffuserSteamerPurifier32,
    zIndex: 2,
    clipPath: 'polygon(67% 38%, 70% 38%, 70% 44%, 67% 44%)',
    cursor: 'pointer',
    picture: DiffuserSteamer,
    grayColor: 400,
    bright: 85,
  },
  {
    name: 'Treasure Chest on bookshelf',
    status: false,
    component: SecretBox,
    clipPath:
      'polygon(11.6% 52.8%, 11.5% 51.3%, 17.0% 51.3%, 17.4% 52.8%, 17.3% 57.1%, 11.3% 57.2%)',
    zIndex: 2,
    cursor: 'pointer',
    picture: SecreteBox,
    grayColor: 400,
    bright: 85,
  },
  {
    name: '? post it note on Bulletin Board',
    status: false,
    component: SecretSticker,
    clipPath: 'polygon(53.5% 26.5%, 59% 26.5%, 59% 33%, 53.5% 33%)',
    zIndex: 2,
    cursor: 'pointer',
    picture: SecretePaper,
    grayColor: 400,
    bright: 74,
  },
  {
    id: 500,
    name: 'Light',
    status: false,
    component: Light,
    clipPath: 'polygon(9.5% 49%, 19% 49%, 19% 59%, 9.5% 59%)',
    zIndex: 3,
    picture: SecreteBox,
    cursor: 'pointer',
    grayColor: 0,
    bright: 100,
  },
];

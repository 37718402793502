import {
  Dialog,
  DialogContent,
  DialogSubTitle,
  DialogTitle,
  IconButton,
} from '@/components/admin/user/components';
import { CloseIcon } from '@/components/icons';
import { Box, Button } from '@mui/material';
import React from 'react';

interface ErrorModalProps {
  text: string;
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  withCloseIcon?: boolean;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  isOpen,
  onClose,
  text,
  title = 'Warning',
  withCloseIcon = true,
}: ErrorModalProps) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth={'lg'}
    >
      <DialogTitle>
        {title && <div>{title}</div>}
        {withCloseIcon && (
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogSubTitle>{text}</DialogSubTitle>
      <DialogContent>
        <Box display={'flex'} justifyContent="center">
          <Button variant="outlined" onClick={onClose} color="secondary">
            Ok
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorModal;

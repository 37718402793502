import IconDone from '@/components/icons/breaktimers/CustomComponents/IconDone';
import { pdfImage, PlayIcon } from '@/components/icons/knowledge-base';
import {
  KnowledgeBaseSmallPreviewBlock,
  PointsBlock,
} from '@/components/knowledgeBase/KnowledgeBaseSmallPreviewItem/components';
import {
  EKnowledgeBaseItemTypes,
  IKnowledgeBaseItem,
} from '@/components/knowledgeBase/models';
import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const KnowledgeBaseSmallPreviewItem: React.FC<IKnowledgeBaseItem> = ({
  type,
  previewUrl,
  alreadyCompleted,
  points,
  id,
  title,
}: IKnowledgeBaseItem) => {
  const navigate = useNavigate();

  return (
    <KnowledgeBaseSmallPreviewBlock
      type={type}
      onClick={() => navigate('/knowledge-base/' + id)}
      hasPreview={!!previewUrl}
    >
      <Box className={`preview-image`}>
        <img
          className="image"
          src={
            type === EKnowledgeBaseItemTypes.GUIDE && !previewUrl
              ? pdfImage
              : previewUrl
          }
        />
        {type === EKnowledgeBaseItemTypes.VIDEO && (
          <PlayIcon className="playIcon" />
        )}
      </Box>
      <PointsBlock type={type}>
        <Box display="flex">
          <Box className={`label`}>{type}</Box>
          <Box className="points">{points + ' pts'}</Box>
          {alreadyCompleted && (
            <Box className="completed-box" display="flex">
              <Box>Completed</Box>
              <IconDone className="checkmark" />
            </Box>
          )}
        </Box>
        <Box className={`description`}>{title}</Box>
      </PointsBlock>
    </KnowledgeBaseSmallPreviewBlock>
  );
};

export default KnowledgeBaseSmallPreviewItem;

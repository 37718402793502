import { CompanyPointOutlinedField } from '@/components/admin/company/components';
import { NumberFormatCustom } from '@/components/form-elements/NumberInput';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { updateCompanyFlexPoints } from '@/redux/actions/company';
import { toggleModalFlex } from '@/redux/reducers/componentsManage';
import {
  getCompany,
  getCompanyFlexPoints,
} from '@/redux/selectors/companySelectors';
import { DEFAULT_TOAST_TIME } from '@/types/consts';
import { FormHelperText, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import { ConfirmButton, CancelButton } from '@/components/buttons/components';

const EditFlexPointsRewardForm = () => {
  const dispatch = useAppDispatch();
  const flexPoints = useAppSelector(getCompanyFlexPoints);
  const company = useAppSelector(getCompany);

  const handleOnSubmit = async (values: any) => {
    if (!company) {
      return;
    }
    try {
      dispatch(
        updateCompanyFlexPoints({
          amount: values.flexPointsReward,
          companyId: company?.id,
        })
      ).unwrap();

      toast.success('Available flex points were successfully updated!', {
        position: 'top-right',
        autoClose: DEFAULT_TOAST_TIME,
        hideProgressBar: false,
      });

      handleClose();
    } catch (err: any) {
      toast.error(err, {
        position: 'top-right',
      });
    }
  };

  const handleClose = () => {
    dispatch(toggleModalFlex(false));
  };

  return (
    <Formik
      initialValues={{
        flexPointsReward: flexPoints,
      }}
      onSubmit={handleOnSubmit}
    >
      {({ errors, handleBlur, handleChange, touched, values }) => {
        return (
          <Form>
            <Grid container justifyContent={'center'}>
              <Grid item md={4} mt={4}>
                <CompanyPointOutlinedField
                  name={'flexPointsReward'}
                  placeholder={'Flex Points'}
                  value={values.flexPointsReward}
                  error={Boolean(
                    touched.flexPointsReward && errors.flexPointsReward
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={'number'}
                  inputComponent={NumberFormatCustom as any}
                />
                {touched.flexPointsReward && errors.flexPointsReward && (
                  <FormHelperText id={'flexPointsReward'} error>
                    {errors.flexPointsReward}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={'center'}
              alignItems={'center'}
              flexWrap={'nowrap'}
              columnGap={4}
              mt={8}
            >
              <Grid item md={6} container justifyContent={'flex-end'}>
                <ConfirmButton fullWidth type={'submit'}>
                  Confirm
                </ConfirmButton>
              </Grid>
              <Grid item md={6}>
                <CancelButton fullWidth onClick={handleClose}>
                  Cancel
                </CancelButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditFlexPointsRewardForm;

import UserPopoverContent from '@/components/admin/company/UserPopoverContent';
import ConfirmModal, { ModalStatuses } from '@/components/dialogs/ConfirmModal';
import {
  BillingContactIcon,
  ChatSupportIcon,
  DeleteCompanyIcon,
  InfoRedIcon,
  StarIcon,
  UserContextMenuIcon,
} from '@/components/icons';
import ScrollbarComponent from '@/components/scrollbar/Scrollbar';
import {
  IconButtonBox,
  TeamList,
  TeamListItem,
} from '@/components/team-admin/components';
import { AvatarType, CustomAvatar } from '@/components/UI/CustomAvatar';

import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { deleteUser, getUsersByParams } from '@/redux/actions/users';
import { getCompany as getCompanyAction } from '@/redux/selectors/companySelectors';
import { DEFAULT_TOAST_TIME, EUserRole, rolesListName } from '@/types/consts';
import { IUser } from '@/types/models';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';

import {
  IconButton,
  Popover as MuiPopover,
  PopoverProps,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const UserContextMenu = styled(UserContextMenuIcon)`
  &:hover {
    & > g {
      opacity: 1;
    }
  }
`;

const Popover = styled(MuiPopover)<PopoverProps>`
  & .MuiPopover-paper {
    box-shadow: 0px 24px 64px rgba(0, 5, 62, 0.15);
    border-radius: ${({ theme }) => theme.spacing(3)};
    padding: ${({ theme }) => theme.spacing(4)};
  }
`;

const StyledScrollbarComponent = styled(ScrollbarComponent)`
  border-right: 1px solid #e2e8ed;
`;

const CompanyTeamList = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(getCompanyAction);

  const anchorEl = useRef(null);

  const [isOpen, setOpen] = useState<boolean>(false);
  const [userId, setUserId] = useState<number | null>(null);
  const [companyTeam, setCompanyTeam] = useState<IUser[]>([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleOpenConfirm = () => {
    setConfirmationOpen(true);
  };

  const handleOpen = (event: any, id: number) => {
    anchorEl.current = event.target;
    setUserId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUserId(null);
    company &&
      dispatch(getUsersByParams({ company: company.name }))
        .unwrap()
        .then(({ users }) => {
          if (users) {
            setCompanyTeam(users);
          }
        });
  };

  useEffect(() => {
    company &&
      dispatch(getUsersByParams({ company: company.name }))
        .unwrap()
        .then(({ users }) => {
          if (users) {
            setCompanyTeam(users);
          }
        });
  }, [dispatch, company]);

  const handleDelete = useCallback(() => {
    dispatch(deleteUser({ id: Number(userId), isSoft: true }))
      .unwrap()
      .then(() => {
        setConfirmationOpen(false);
        toast.success('User was successfully deleted!', {
          position: 'top-right',
          autoClose: DEFAULT_TOAST_TIME,
          hideProgressBar: false,
        });
        handleClose();
        if (companyTeam) {
          setCompanyTeam(companyTeam.filter((user) => user.id !== userId));
        }
      });
  }, [userId]);

  return (
    <div>
      <StyledScrollbarComponent>
        {!companyTeam && (
          <Skeleton animation="wave" width={'100%'} height={40} />
        )}
        <TeamList disablePadding>
          {companyTeam &&
            companyTeam.map(
              ({
                id,
                firstName,
                lastName,
                role,
                email,
                profileImage,
                isConfirm,
                isPlatformSupport,
              }) => {
                const name =
                  firstName && lastName ? `${firstName} ${lastName}` : email;

                return (
                  <TeamListItem key={id} disablePadding>
                    <CustomAvatar
                      diameter={24}
                      avatartype={AvatarType.USER}
                      alt="Remy Sharp"
                      src={profileImage || undefined}
                    >
                      {getUserAvatarLetter({ firstName, lastName, email })}
                    </CustomAvatar>
                    <Typography
                      variant={'body1'}
                      component={'span'}
                      sx={{
                        maxWidth: 200,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {name}
                    </Typography>
                    {role ? (
                      <Typography
                        variant={'body1'}
                        component={'span'}
                        textAlign={'center'}
                        whiteSpace={'nowrap'}
                        display={'flex'}
                        alignItems={'center'}
                        columnGap={0.5}
                        justifyContent={'center'}
                      >
                        {!isConfirm && (
                          <Tooltip title="This user has not accepted their invitation">
                            <InfoRedIcon />
                          </Tooltip>
                        )}
                        {isPlatformSupport && <ChatSupportIcon />}
                        {id === company?.billingContactId && (
                          <BillingContactIcon />
                        )}
                        {role.name === EUserRole.TEAMLEAD && <StarIcon />}
                        {
                          rolesListName.find(
                            (roleData) => roleData.name === role.name
                          )?.view_name
                        }
                      </Typography>
                    ) : (
                      <Typography
                        variant={'body1'}
                        component={'span'}
                        textAlign={'center'}
                      >
                        User
                      </Typography>
                    )}
                    <IconButtonBox>
                      <IconButton
                        aria-label={'user-context-menu'}
                        ref={anchorEl}
                        onClick={(event) => handleOpen(event, id)}
                      >
                        <UserContextMenu />
                      </IconButton>
                    </IconButtonBox>
                  </TeamListItem>
                );
              }
            )}
        </TeamList>
      </StyledScrollbarComponent>
      <Popover
        open={isOpen && !!userId}
        anchorEl={anchorEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <UserPopoverContent userId={userId} handleDelete={handleOpenConfirm} />
      </Popover>
      <ConfirmModal
        textConfirm="Delete User"
        confirmStatus={ModalStatuses.WARNING}
        title="Warning!"
        text={
          'Deleted user can be restored only by RemoteWorx super admin. To confirm user deletion click Delete User.'
        }
        confirmIcon={<DeleteCompanyIcon />}
        isOpen={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleDelete}
      />
    </div>
  );
};

export default CompanyTeamList;

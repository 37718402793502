import useAppDispatch from '@/hooks/useAppDispatch';
import useAuth from '@/hooks/useAuth';
import { resetUserListState } from '@/redux/reducers/users';
import { EUserRole } from '@/types/consts';
import { Box, Tab, TabProps, Tabs } from '@mui/material';
import React from 'react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const Link = styled(NavLink)<TabProps & NavLinkProps>``;

const LinkTab = (props: TabProps & NavLinkProps) => {
  return <Tab component={Link} {...props} />;
};

const superAdminLinks = [
  {
    label: 'Company',
    href: '/admin/companies',
  },
  {
    label: 'User',
    href: '/admin/users',
  },
  {
    label: 'RW Admin',
    href: '/admin/rw-admins',
  },
  {
    label: 'Deleted Users',
    href: '/admin/deleted-users',
  },
];

const rwAdminLinks = [
  {
    label: 'My Companies',
    href: '/admin/companies',
  },
  {
    label: 'Users',
    href: '/admin/users',
  },
];

const SearchToolsTabs = () => {
  const { pathname } = useLocation();
  const [value, setValue] = React.useState<string>(pathname);
  const { userRole } = useAuth();
  const links =
    userRole === EUserRole.SUPER_ADMIN ? superAdminLinks : rwAdminLinks;
  const dispatch = useAppDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(resetUserListState());
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={value} onChange={handleChange} aria-label="nav tabs example">
        {links.map(({ label, href }) => (
          <LinkTab key={label} label={label} value={href} to={href} />
        ))}
      </Tabs>
    </Box>
  );
};

export default SearchToolsTabs;

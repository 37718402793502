import {
  Dialog,
  DialogContent,
  DialogSubTitle,
  DialogTitle,
  IconButton,
} from '@/components/admin/user/components';
import { ConfirmModalButton } from '@/components/dialogs/component';
import { CloseIcon } from '@/components/icons';
import theme from '@/theme/theme';
import { Box, Button } from '@mui/material';
import React from 'react';

interface IConfirmModalProps {
  text: string;
  onConfirm: () => void;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  textConfirm?: string;
  confirmStatus: ModalStatuses;
  confirmIcon?: JSX.Element;
  withCloseIcon?: boolean;
}

export enum ModalStatuses {
  WARNING = 'warning',
  PRIMARY = 'primary',
}

type IStatusColor = {
  [key in ModalStatuses]: string;
};

const StatusColor: IStatusColor = {
  [ModalStatuses.WARNING]: theme.palette.redColor.main,
  [ModalStatuses.PRIMARY]: 'primary',
};

const ConfirmModal: React.FC<IConfirmModalProps> = ({
  isOpen,
  onClose,
  text,
  onConfirm,
  title = 'Warning',
  textConfirm = 'Continue',
  confirmStatus,
  confirmIcon,
  withCloseIcon = true,
}: IConfirmModalProps) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth={'lg'}
    >
      <DialogTitle>
        {title && <div>{title}</div>}
        {withCloseIcon && (
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogSubTitle>{text}</DialogSubTitle>
      <DialogContent>
        <Box display="flex" marginTop={2} justifyContent="center">
          <ConfirmModalButton
            variant="contained"
            color={confirmStatus}
            status={confirmStatus}
            onClick={() => onConfirm()}
          >
            <Box color={StatusColor[confirmStatus]}>{textConfirm}</Box>
            {confirmIcon && (
              <Box ml={2} alignItems="center" display="flex">
                {confirmIcon}
              </Box>
            )}
          </ConfirmModalButton>
          <Button variant="outlined" onClick={onClose} color="secondary">
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmModal;

import {
  CalendarIconWrapper,
  Placeholder,
} from '@/components/form-elements/components';
import { IActivityItem } from '@/components/progressMeter/RewardPointsHistory/RewardPointsHistoryWrapper';
import ScrollbarComponent from '@/components/scrollbar/Scrollbar';
import { DatePickerInput } from '@/components/user-profile/reward-points/components';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import {
  getCompanyPointManagementHistory,
  getUsersPointManagementHistory,
} from '@/redux/actions/company';
import { getCompany } from '@/redux/selectors/companySelectors';
import { ECompanyActivitySelect, ELimits } from '@/types/consts';
import { ICompany, IPointsHistory, IUser } from '@/types/models';
import {
  Box,
  Divider,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import {
  DateColumn,
  SimpleTableHeaderRow,
  SimpleTableRow,
  SimpleTableWrapper,
  TaskColumn,
  UserColumn,
} from './components';
import { triggersNames } from './models';

export interface IRewardPointsHistoryProps {
  pointsHistory: IPointsHistory[] | null;
  countAllPointsHistory: number;
  activityDate: IActivityItem;
  recipients: any[];
  historyGroup: ECompanyActivitySelect;
  setActivityDate: (val: any) => void;
  setRecipients: (recipients: any[]) => void;
}

interface IInfiniteLoadingWrapperProps {
  historyGroup: ECompanyActivitySelect;
  hasNextPage: boolean;
  items: Array<IPointsHistory>;
  isNextPageLoading: boolean;
  loadNextPage: () => void;
}

const InfiniteLoadingWrapper: React.FC<IInfiniteLoadingWrapperProps> = ({
  historyGroup,
  hasNextPage,
  isNextPageLoading,
  items,
  loadNextPage,
}: IInfiniteLoadingWrapperProps) => {
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  const Item = ({ index, style }: { index: number; style: any }) => {
    if (!isItemLoaded(index)) {
      return <div>Loading...</div>;
    }

    const { id, trigger, createdAt, points, user, fromUser } = items[index];

    return (
      <SimpleTableRow container key={'row-' + id} style={style}>
        <DateColumn
          item
          xs={historyGroup === ECompanyActivitySelect.TEAM ? 4 : 3}
        >
          {format(parseISO(createdAt), 'MM/dd/yyyy')}
        </DateColumn>
        <TaskColumn
          item
          xs={historyGroup === ECompanyActivitySelect.TEAM ? 4 : 3}
        >
          {trigger ? triggersNames[trigger] : ''}
        </TaskColumn>
        <UserColumn item xs={2}>
          {fromUser.firstName
            ? `${fromUser.firstName} ${fromUser.lastName}`
            : fromUser.email}
        </UserColumn>
        {historyGroup === ECompanyActivitySelect.INDIVIDUAL && !!user && (
          <UserColumn item xs={2}>
            {user.firstName ? `${user.firstName} ${user.lastName}` : user.email}
          </UserColumn>
        )}
        <Grid item xs={1} className="number">
          {Number(points).toLocaleString('en')}
        </Grid>
      </SimpleTableRow>
    );
  };

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      threshold={4}
    >
      {({ onItemsRendered, ref }) => (
        <FixedSizeList
          height={180}
          itemSize={36}
          width="100%"
          itemCount={itemCount}
          onItemsRendered={onItemsRendered}
          ref={ref}
          outerElementType={ScrollbarComponent}
        >
          {Item}
        </FixedSizeList>
      )}
    </InfiniteLoader>
  );
};

const PointsManagementHistory: React.FC<IRewardPointsHistoryProps> = ({
  pointsHistory,
  countAllPointsHistory,
  setActivityDate,
  setRecipients,
  recipients,
  activityDate,
  historyGroup,
}) => {
  const company = useAppSelector(getCompany) as ICompany;
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMoreData, setMoreDataState] = useState<boolean>(true);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (pointsHistory) {
      setMoreDataState(pointsHistory.length < countAllPointsHistory);
    }
  }, [
    pointsHistory,
    pointsHistory?.length,
    activityDate,
    countAllPointsHistory,
    historyGroup,
  ]);

  const loadMore = useCallback(() => {
    setLoading(true);
    const logPagination = {
      limit: ELimits.ACTIVITY,
      from: activityDate.startDate,
      to: activityDate.endDate,
      offset: (pointsHistory as IPointsHistory[]).length,
      id: company.id,
    };
    const action =
      historyGroup === ECompanyActivitySelect.TEAM
        ? dispatch(getCompanyPointManagementHistory(logPagination))
        : dispatch(
            getUsersPointManagementHistory({
              ...logPagination,
              userIds: recipients.length ? recipients : undefined,
            })
          );

    action.unwrap().finally(() => setLoading(false));
  }, [
    dispatch,
    pointsHistory,
    countAllPointsHistory,
    activityDate,
    recipients,
    company.id,
    historyGroup,
  ]);

  const handleChange = (event: SelectChangeEvent<IUser[]>) => {
    const {
      target: { value },
    } = event;
    if (Array.isArray(value)) {
      setRecipients(value);
    }
  };

  return (
    <>
      {pointsHistory ? (
        <SimpleTableWrapper>
          <>
            <SimpleTableHeaderRow container>
              <DateColumn
                item
                xs={historyGroup === ECompanyActivitySelect.TEAM ? 4 : 3}
              >
                <Box>Date</Box>
                <Box display="flex">
                  <DatePicker
                    value={activityDate.startDate}
                    onChange={(value: Date | null) => {
                      if (value !== null) {
                        setActivityDate((prev: any) => ({
                          ...prev,
                          startDate: value,
                        }));
                      }
                    }}
                    InputProps={{
                      placeholder: 'start date',
                    }}
                    components={{
                      OpenPickerIcon: () => <CalendarIconWrapper />,
                    }}
                    maxDate={new Date()}
                    minDate={new Date('01/01/2020')}
                    renderInput={(params) => (
                      <DatePickerInput
                        className="date-picker"
                        placeholder={params.InputProps?.placeholder}
                        onChange={params.inputProps?.onChange}
                        value={params.inputProps?.value}
                        endAdornment={params.InputProps?.endAdornment}
                        inputRef={params.inputRef}
                      />
                    )}
                  />
                  {' - '}
                  <DatePicker
                    value={activityDate.endDate}
                    onChange={(value: Date | null) => {
                      if (value !== null) {
                        setActivityDate((prev: any) => ({
                          ...prev,
                          endDate: value,
                        }));
                      }
                    }}
                    InputProps={{
                      placeholder: 'end date',
                    }}
                    components={{
                      OpenPickerIcon: () => <CalendarIconWrapper />,
                    }}
                    maxDate={new Date()}
                    minDate={new Date('01/01/2020')}
                    renderInput={(params) => (
                      <DatePickerInput
                        className="date-picker"
                        placeholder={params.InputProps?.placeholder}
                        onChange={params.inputProps?.onChange}
                        value={params.inputProps?.value}
                        endAdornment={params.InputProps?.endAdornment}
                        inputRef={params.inputRef}
                      />
                    )}
                  />
                </Box>
              </DateColumn>
              <TaskColumn
                item
                xs={historyGroup === ECompanyActivitySelect.TEAM ? 4 : 3}
              >
                {historyGroup === ECompanyActivitySelect.INDIVIDUAL
                  ? 'Action'
                  : 'Description'}
              </TaskColumn>
              <UserColumn item xs={2}>
                Initiated By
              </UserColumn>
              {historyGroup === ECompanyActivitySelect.INDIVIDUAL && (
                <UserColumn item xs={2} display="flex" flexDirection="column">
                  <div>Recipient</div>
                  {company?.users && (
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple
                      value={recipients}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      size="small"
                      renderValue={(selected) => {
                        if (!selected || selected.length === 0) {
                          return <Placeholder>Select Recipient</Placeholder>;
                        }
                        return (
                          <>
                            {selected
                              .map((item) => {
                                const user = company.users.find(
                                  ({ id }) => id === (item as unknown as number)
                                );

                                return user?.firstName
                                  ? `${user.firstName} ${user.lastName}`
                                  : user?.email;
                              })
                              .join(', ')}
                          </>
                        );
                      }}
                    >
                      <MenuItem disabled value="">
                        <Placeholder>Select Recipient</Placeholder>
                      </MenuItem>
                      {company.users
                        .map(({ id, firstName, lastName, email }) => ({
                          id,
                          name: firstName ? `${firstName} ${lastName}` : email,
                        }))
                        .sort((a, b) =>
                          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                        )
                        .map(({ name, id }) => (
                          <MenuItem key={`recipient-${id}`} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                </UserColumn>
              )}
              <Grid item xs={1.5}>
                {historyGroup === ECompanyActivitySelect.INDIVIDUAL
                  ? 'Reward Points'
                  : 'Balance History'}
              </Grid>
            </SimpleTableHeaderRow>
            <Divider sx={{ marginBottom: 3 }} />
            <InfiniteLoadingWrapper
              items={pointsHistory}
              hasNextPage={hasMoreData}
              isNextPageLoading={loading}
              loadNextPage={loadMore}
              historyGroup={historyGroup}
            />
          </>
        </SimpleTableWrapper>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default PointsManagementHistory;

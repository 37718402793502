import { KnowledgeBasePreviewBlock } from '@/components/knowledgeBase/KnowledgeBaseDashboardBlock/components';
import KnowledgeBaseSmallPreviewItem from '@/components/knowledgeBase/KnowledgeBaseSmallPreviewItem';
import ColorfulHorizontalProgress from '@/components/progressMeter/ColorfulHorizontalProgress/ColorfulHorizontalProgress';
import useAppSelector from '@/hooks/useAppSelector';
import { DashboardTitleWrapper } from '@/pages/dashboard/DashboardWorkspace/component';
import { getKnowledgeBase } from '@/redux/selectors/knowledgeBaseSelector';
import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const KnowledgeBaseDashboardBlock = () => {
  const dashboardItems = useAppSelector(getKnowledgeBase);
  const navigate = useNavigate();

  return (
    <KnowledgeBasePreviewBlock>
      <DashboardTitleWrapper onClick={() => navigate('/knowledge-base')}>
        <Box className="title">Knowledge Base</Box>
      </DashboardTitleWrapper>
      <Box className="content">
        <ColorfulHorizontalProgress
          display="block"
          withoutpadding
          current={
            dashboardItems?.filter((item) => item.alreadyCompleted).length
          }
          max={dashboardItems?.length}
        />
        <Box className="items-wrapper">
          {!dashboardItems && <div>Empty</div>}
          {dashboardItems &&
            dashboardItems
              .filter((_, index) => index <= 3)
              .map((item, idx) => (
                <KnowledgeBaseSmallPreviewItem
                  key={'kb-item-' + idx}
                  {...item}
                />
              ))}
        </Box>
      </Box>
    </KnowledgeBasePreviewBlock>
  );
};

export default KnowledgeBaseDashboardBlock;

import useAppDispatch from '@/hooks/useAppDispatch';
import { getUsersByParams } from '@/redux/actions/users';
import { setSearchMode } from '@/redux/reducers/users';
import { ELimits, EUserRole } from '@/types/consts';
import { IParams } from '@/types/models';
import { Box, Button as MuiButton, OutlinedInput } from '@mui/material';
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const SearchBarGrid = styled(Box)`
  display: grid;
  grid-template-columns: 2fr repeat(2, 360px) repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(6)};
  margin-bottom: ${({ theme }) => theme.spacing(13)};
`;

const SearchButton = styled(MuiButton)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: none;
  height: 100%;
  width: 160px;

  &:hover {
    background-color: #dde5ed;
  }
`;

const SearchBar = () => {
  const { pathname } = useLocation();
  const [valueUserNameOrEmail, setUserNameOrEmailValue] = useState<string>('');
  const [valueCompanyName, setCompanyNameValue] = useState<string>('');

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(setSearchMode(false));
    };
  }, [dispatch]);

  const handleChangeValue = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.target.name === 'user-name') {
      setUserNameOrEmailValue(event.target.value);
    }

    if (event.target.name === 'company-name') {
      setCompanyNameValue(event.target.value);
    }
  };

  const handleClick = () => {
    const isRwAdminsPage = pathname.includes('rw-admins');
    const isDeletedUsersPage = pathname.includes('deleted-users');
    const searchParams: IParams = {
      name: valueUserNameOrEmail,
      limit: ELimits.USER,
    };

    if (isRwAdminsPage) {
      searchParams.roles = [EUserRole.RW_ADMIN];
      searchParams.rwCompany = valueCompanyName;
    } else if (isDeletedUsersPage) {
      searchParams.onlyDeleted = true;
      searchParams.company = valueCompanyName;
    } else {
      searchParams.company = valueCompanyName;
    }
    const search =
      searchParams.name || searchParams.company || searchParams.rwCompany;
    dispatch(setSearchMode(search));
    if (search) {
      return dispatch(getUsersByParams(searchParams));
    }
    return dispatch(getUsers(searchParams));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <SearchBarGrid>
      <Box></Box>
      <Box>
        <OutlinedInput
          name={'user-name'}
          value={valueUserNameOrEmail}
          placeholder={'Name or Email'}
          onChange={handleChangeValue}
          onKeyDown={handleKeyDown}
          fullWidth
        />
      </Box>
      <Box>
        <OutlinedInput
          name={'company-name'}
          value={valueCompanyName}
          placeholder={'Company'}
          onChange={handleChangeValue}
          onKeyDown={handleKeyDown}
          fullWidth
        />
      </Box>
      <Box>
        <SearchButton onClick={handleClick} color={'secondary'}>
          Search
        </SearchButton>
      </Box>
    </SearchBarGrid>
  );
};

export default SearchBar;
function getUsers(searchParams: IParams): any {
  throw new Error('Function not implemented.');
}

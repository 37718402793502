import { UserState } from '@/redux/reducers/user';
import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

export const getUserSelector = createSelector(
  (state: DefaultRootState) => state.user,
  ({ user }: UserState) => user
);
export const getUserLoaded = createSelector(
  (state: DefaultRootState) => state.user,
  ({ loading }: UserState) => !loading
);
export const isAuthorized = createSelector(
  (state: DefaultRootState) => state.user,
  ({ user }: UserState) => !!user
);
export const getUserRole = createSelector(
  (state: DefaultRootState) => state.user,
  ({ user }) => user?.role.name
);

export const getMyTeam = createSelector(
  (state: DefaultRootState) => state.user,
  ({ myTeam }) => myTeam
);

export const getMyTeamMember = (id: number | null) =>
  createSelector(
    (state: DefaultRootState) => state.user,
    ({ myTeam }) => (id ? myTeam?.find((user) => user.id === id) : null)
  );

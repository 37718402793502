import { StyledComponentProps } from '@mui/material';
import styled from 'styled-components';

interface MyWorkspaceItemBlockProps extends StyledComponentProps {
  zIndex: number;
  cLipPath: string;
  cursor: 'initial' | 'pointer';
  grey: boolean;
  gray2: number;
  bright: number;
}

export const MyWorkspaceItemBlock = styled.div<MyWorkspaceItemBlockProps>`
  z-index: ${(props) => `${props.zIndex}`};
  clip-path: ${(props) => `${props.cLipPath}`};
  filter: ${(props) => {
    if (props.grey) {
      return;
    }

    return `brightness(${props.bright}%) grayscale(${props.gray2}%)`;
  }};
  cursor: ${(props) => `${props.cursor}`};
  transition-property: filter;
  transition-duration: 0.3s;
  &.light {
    opacity: 0;
  }

  &:hover {
    &.light {
      animation-name: effect;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      @keyframes effect {
        50% {
          opacity: 1;
        }
      }
    }
  }
}
`;

import {
  Dialog,
  DialogContent,
  DialogSubTitle,
  DialogTitle,
  IconButton,
} from '@/components/admin/user/components';
import { CloseIcon } from '@/components/icons';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { toggleModalFlex } from '@/redux/reducers/componentsManage';
import { selectStateFlexModal } from '@/redux/selectors/componentsManage';
import React from 'react';
import EditFlexPointsRewardForm from '@/components/user-profile/reward-points/EditFlexPointsRewardForm';

const EditFlexPointsRewardModal = () => {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector(selectStateFlexModal);

  const handleClose = () => {
    dispatch(toggleModalFlex(false));
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalState}
      maxWidth={'lg'}
    >
      <DialogTitle>
        Edit Flex Points
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogSubTitle>
        Please enter the Reward Points amount for this company.
      </DialogSubTitle>
      <DialogContent>
        <EditFlexPointsRewardForm />
      </DialogContent>
    </Dialog>
  );
};

export default EditFlexPointsRewardModal;

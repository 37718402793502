import { InputLabel } from '@mui/material';
import styled from 'styled-components';

export const ProfileFormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ShippingAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WorklifeShopLabel = styled(InputLabel)`
  width: calc(50% - 10px);
`;

export const OptBlockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
`;

export const OptContextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ErrorWrapper = styled.div`
  min-height: 30px;
  width: 100%;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
`;

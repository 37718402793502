import {
  Bag,
  Firework,
  Gift,
  Hat,
  Medal,
  Star,
} from '@/components/icons/notifications';

export enum ENotificationTypes {
  MEDAL = 'workspace_level_up',
  HAT = 'hat',
  BAG = 'bag',
  FIREWORK = 'companies_reward_points_available_change',
  STAR = 'user_reward_points_available_change',
  PRESENT = 'user_reward_points_gifted',
  REWARD = 'companies_reward_points_flex_change',
}

export const NotificationIcons = {
  [ENotificationTypes.MEDAL]: {
    color: '#DCF4DB',
    icon: Medal,
  },
  [ENotificationTypes.HAT]: {
    color: '#DACFF0',
    icon: Hat,
  },
  [ENotificationTypes.BAG]: {
    color: '#D7ECFA',
    icon: Bag,
  },
  [ENotificationTypes.FIREWORK]: {
    color: '#D7ECFA',
    icon: Firework,
  },
  [ENotificationTypes.STAR]: {
    color: '#FFEFCF',
    icon: Star,
  },
  [ENotificationTypes.PRESENT]: {
    color: '#FFEFCF',
    icon: Gift,
  },
  [ENotificationTypes.REWARD]: {
    color: '#F2FFEF', // TODO maybe need change
    icon: Bag, // TODO maybe need change
  },
};

import { Switch as MuiSwitch, SwitchProps } from '@mui/material';
import styled from 'styled-components';

const Switch = styled((props: SwitchProps) => (
  <MuiSwitch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))`
  width: 34px;
  height: 18px;
  padding: 0;

  & .Mui-checked {
    transform: translateX(16px);
    color: ${({ theme }) => theme.palette.common.white};

    & + .MuiSwitch-track {
      background-color: #2fd60a;
      opacity: 1;
      border: 0;
    }

    &.Mui-disabled + .MuiSwitch-track {
      opacity: 0.5;
    }
  }

  & .MuiSwitch-switchBase {
    padding: 0;
    margin: 3px;
    transition-duration: 300ms;

    &.Mui-focusVisible .MuiSwitch-thumb {
      color: #33cf4d;
      border: 6px solid #fff;
    }

    &.Mui-disabled .MuiSwitch-thumb {
      color: ${({ theme }) =>
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]};
    }

    &.Mui-disabled + .MuiSwitch-track {
      opacity: ${({ theme }) => (theme.palette.mode === 'light' ? 0.7 : 0.3)};
    }
  }

  & .MuiSwitch-thumb {
    box-sizing: border-box;
    width: 12px;
    height: 12px;
  }

  & .MuiSwitch-track {
    border-radius: 40px;
    background-color: #b9c5d6;
    opacity: 1;
    transition: background-color 500ms;
  }
`;

export default Switch;

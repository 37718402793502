import CreateUserButtonComponent from '@/components/admin/user/CreateUserButton';
import AddTeammembersModal from '@/components/team-admin/AddTeammembersModal';
import CompanyBillingInformationForm from '@/components/team-admin/CompanyBillingInformationForm';
import CompanyShippingInformationForm from '@/components/team-admin/CompanyShippingInformationForm';
import CompanyTeamList from '@/components/team-admin/CompanyTeamList';
import MyCompanyPageWrapper from '@/components/team-admin/MyCompanyPageWrapper';
import useAuth from '@/hooks/useAuth';
import { Container, Divider, Grid, Typography } from '@mui/material';
import React from 'react';

const MyCompany = () => {
  return (
    <MyCompanyPageWrapper>
      <Container>
        <Grid container justifyContent={'center'} gap={10} mt={8}>
          <Grid item md={4}>
            <Grid
              item
              container
              justifyContent={'space-between'}
              alignItems={'center'}
              mb={4}
            >
              <Typography variant={'h3'}>Team</Typography>
              {/*<CreateUserButtonComponent />*/}
              <AddTeammembersModal />
            </Grid>
            <CompanyTeamList />
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>
        <Grid container justifyContent={'center'} gap={10} mt={8}>
          <Grid item md={4}>
            <CompanyBillingInformationForm />
          </Grid>
          <Grid item md={4}>
            <CompanyShippingInformationForm />
          </Grid>
        </Grid>
        <Divider />
      </Container>
    </MyCompanyPageWrapper>
  );
};

export default MyCompany;

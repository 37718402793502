import { SecreteItems } from '@/pages/dashboard/MyWorkspace/constants';
import {
  addUserWorkspaceItem,
  getAllCategories,
  getAllItems,
  getDailyCheck,
  getUserWorkspaceItems,
  getUserWorkspaceLevels,
} from '@/redux/actions/workSpace';
import { createSlice } from '@reduxjs/toolkit';

export interface ICategory {
  id: number;
  name: string;
  completeLimitItem: number;
}

export interface IItem {
  id: number;
  name: string;
  descriptions: string;
  link: string;
  title: string;
  categoryId: number;
  category: ICategory;
}

export interface IItemWithStatus extends IItem {
  status: boolean;
}

export interface WorkspaceState {
  loading: boolean;
  countCategories: number;
  categories: ICategory[];
  items: IItem[];
  currentItems: IItemWithStatus[];
  dailyCheck: boolean | null;
}

const initialState: WorkspaceState = {
  loading: false,
  countCategories: 0,
  categories: [],
  items: [],
  currentItems: [],
  dailyCheck: null,
};

export const { actions, reducer } = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    resetWorkSpace: () => ({
      ...initialState,
      loading: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getUserWorkspaceItems.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserWorkspaceItems.fulfilled, (state, { payload }) => {
      if (payload.length === 0) {
        state.currentItems = state.items.map((oneItem) => {
          return { ...oneItem, status: false };
        });
      }
      state.currentItems = state.items.map((oneItem) => {
        if (
          (oneItem.name === SecreteItems.BOX ||
            oneItem.name === SecreteItems.STICKER) &&
          oneItem.link
        ) {
          return { ...oneItem, status: true };
        } else if (payload.find((item) => item.name === oneItem.name)) {
          return { ...oneItem, status: true };
        }
        return { ...oneItem, status: false };
      });

      state.loading = false;
    });
    builder.addCase(getAllCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCategories.fulfilled, (state, { payload }) => {
      state.categories = payload;
      state.loading = false;
    });
    builder.addCase(getDailyCheck.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDailyCheck.fulfilled, (state, { payload }) => {
      state.dailyCheck = payload;
      state.loading = false;
    });
    builder.addCase(getAllItems.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllItems.fulfilled, (state, { payload }) => {
      state.items = payload;
      state.loading = false;
    });
    builder.addCase(addUserWorkspaceItem.fulfilled, (state, { payload }) => {
      state.currentItems = state.currentItems.map((item) => {
        if (item.id === payload.itemId) {
          return { ...item, status: true };
        } else return item;
      });
    });
    builder.addCase(getUserWorkspaceLevels.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserWorkspaceLevels.fulfilled, (state, { payload }) => {
      state.countCategories = payload;
    });
  },
});

export const { resetWorkSpace } = actions;

export default reducer;

import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogProps,
  FormControl,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface FormControlAdditionalProps {
  hiddenLabel?: boolean;
  error?: boolean;
  width?: string;
}

interface ConfirmTextProps {
  isScrolled?: boolean;
}

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const SubTitle = styled.h1<{ error?: boolean }>`
  color: ${(props) => props.theme.palette.neutral.dark};
  text-align: center;
  margin-bottom: ${({ error }) => (error ? '3px' : '25px')};
`;

export const AuthError = styled.div`
  color: ${(props) => props.theme.palette.error.main};
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px;
`;

export const ForgotTitle = styled(SubTitle)`
  margin-bottom: 57px;
`;

export const FormControlWrapper = styled(
  FormControl
)<FormControlAdditionalProps>`
  margin-top: ${({ hiddenLabel }) => (hiddenLabel ? '24.25px' : 0)};
  margin-bottom: ${({ error }) => (error ? '3.771px' : '30px')};
  width: ${({ width }) => (width ? width : '360px')};

  &:has(> label) {
    margin-top: 0 !important;
  }
`;

export const AuthButton = styled(Button)`
  height: 44px;
  width: 160px;
`;

export const FlexRow = styled(Box)`
  display: flex;
  align-items: center;
`;

export const CenteredButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 29px;
`;

export const ButtonBetween = styled(Button)`
  width: 49%;
`;

export const AuthNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${(props) => props.theme.palette.text.secondary};
  font-style: italic;
  font-size: 10px;
`;

export const ClickHereBlock = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.border.light};
  &:hover {
    font-weight: bold;
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`;

export const LabeledCheckbox = styled(FormControlLabel)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-style: italic;
  font-size: 10px !important;
`;

export const Dialog = styled(MuiDialog)<DialogProps>`
  & .MuiDialog-paper {
    overflow-y: visible;
    padding: ${({ theme }) => theme.spacing(8)};
    width: 100%;
  }
`;

export const ConfirmText = styled(Typography)<ConfirmTextProps>`
  font-style: normal;
  font-weight: ${({ isScrolled }) => (isScrolled ? 500 : 400)};
  color: ${({ isScrolled, theme }) =>
    isScrolled ? theme.palette.text.primary : theme.palette.text.secondary};
`;

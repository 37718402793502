import { AvatarType, CustomAvatar } from '@/components/UI/CustomAvatar';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import { getUserConversation } from '@/redux/actions/chat';
import { setCurrentChat } from '@/redux/reducers/chat';
import {
  conversationsSelector,
  currentChatSelector,
  getCompanyChatSupport,
  membersSelector,
} from '@/redux/selectors/chatSelector';
import { selectState } from '@/redux/selectors/componentsManage';
import { getMyTeam } from '@/redux/selectors/userSelectors';
import { EChatStatus, EConversationType } from '@/types/consts';
import { IGroupChat, IPersonalChat, IUser, IUserChat } from '@/types/models';
import { idToUid, uidToId } from '@/utils/chatHelpers';
import { List } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { BrandLogo, MemberIcon, TeamIcon } from '../../icons';
import {
  Badge as NavItemBadge,
  ListIconIconWrapper,
  ListItemIcon,
  Title,
} from '../SidebarNavListItem';
import {
  BadgeSimple,
  ChatItemContent,
  ChatItemContentBasic,
  ChatNavGroup,
  ChatNavItem,
  ChatNavItemBasic,
  MainTitle,
  NavSection,
  UserChatName,
} from './components';
import UserChatNavItem from './UserChatNavItem';
import moment from 'moment';
import { getChatSupportsByCompanyId } from '@/redux/actions/company';

type SidebarNavSectionProps = {
  title: string;
};

const MessagingNavSection: React.FC<SidebarNavSectionProps> = ({ title }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();
  const rwAdmin = user?.companies[0]?.rwAdminId;
  const open = useAppSelector(selectState);
  const chat = useAppSelector(currentChatSelector);
  const groupChats = useAppSelector(
    conversationsSelector(EConversationType.GROUP)
  );
  const personalChats = useAppSelector(
    conversationsSelector(EConversationType.PERSONAL)
  );
  const members = useAppSelector(membersSelector);
  const companyTeam = useAppSelector(getMyTeam);
  const companySupport = useAppSelector(getCompanyChatSupport);
  const resolved = useResolvedPath('/message-center');
  const match = !!useMatch({ path: resolved.pathname, end: true });
  const companyId = user?.companies[0].id;

  useEffect(() => {
    companyId && dispatch(getChatSupportsByCompanyId(companyId));
  }, [companyId]);

  useEffect(() => {
    if (user?.id) {
      dispatch(getUserConversation(idToUid(user.id)));
    }
  }, [user?.id]);

  const chatSelect = useCallback(
    (chatId: string, isGroupChat: boolean, user?: IUserChat) => {
      if (chatId !== chat?.chatId || !match) {
        dispatch(
          setCurrentChat({
            isGroup: isGroupChat,
            chatId,
            user,
          })
        );
        navigate('/message-center');
      }
    },
    [dispatch, chat?.chatId, match]
  );

  const rwAdminChat = useMemo(() => {
    if (rwAdmin) {
      return members?.filter(({ uid }) => uid === idToUid(rwAdmin));
    }
    return null;
  }, [members, rwAdmin]);

  const regularUsers = useMemo(() => {
    return members?.filter(
      ({ uid }) =>
        !companySupport.includes(uid) && (!rwAdmin || uid !== idToUid(rwAdmin))
    );
  }, [members, rwAdmin]);

  const usersSupportChat = useMemo(() => {
    return members?.filter(({ uid }) => companySupport.includes(uid));
  }, [companySupport, members]);

  const sortedChat = useMemo(() => {
    if (!regularUsers) {
      return null;
    }
    return regularUsers
      ?.map((user: IUserChat) => {
        const conversation = personalChats?.find(
          ({ conversationWith }) =>
            (conversationWith as IPersonalChat).uid === user.uid
        );
        return {
          user,
          conversation,
        };
      })
      .sort((itemA, itemB) => {
        if (
          !itemA.conversation?.lastMessage &&
          !itemB.conversation?.lastMessage
        ) {
          if (itemA.user.status === itemB.user.status) {
            return itemA.user.name.localeCompare(itemB.user.name);
          } else {
            return itemB.user.status === EChatStatus.ONLINE ? 1 : -1;
          }
        }
        if (!itemA.conversation?.lastMessage) {
          return 1;
        }
        if (!itemB.conversation?.lastMessage) {
          return -1;
        }
        const lastActivityA = moment.unix(
          itemA.conversation?.lastMessage.sentAt
        );
        const lastActivityB = moment.unix(
          itemB.conversation?.lastMessage.sentAt
        );
        return lastActivityA.isBefore(lastActivityB) ? 1 : -1;
      });
  }, [regularUsers, personalChats]);

  return (
    <NavSection component={'section'}>
      <MainTitle variant="subtitle2" open={open}>
        {title}
      </MainTitle>

      <List disablePadding>
        {rwAdmin && (
          <ChatNavGroup>
            <ChatNavItemBasic isOpen={open}>
              <ChatItemContentBasic isOpen={open}>
                <ListIconIconWrapper>
                  <ListItemIcon icon={BrandLogo} />
                </ListIconIconWrapper>
                <Title open={open}>RemoteWorx</Title>
              </ChatItemContentBasic>
            </ChatNavItemBasic>

            {rwAdminChat?.map((user: IUserChat) => {
              const conversation = personalChats?.find(
                ({ conversationWith }) =>
                  (conversationWith as IPersonalChat).uid === user.uid
              );
              return (
                <ChatNavItem
                  onClick={() => chatSelect(user.uid, false, user)}
                  isOpen={open}
                  active={!!match && chat?.chatId === user.uid}
                  key={`nav-member-${user.uid}`}
                >
                  <UserChatNavItem
                    user={
                      companyTeam?.find(
                        ({ id }) => uidToId(user.uid) === id
                      ) as IUser
                    }
                    isOnline={user?.status === EChatStatus.ONLINE}
                    unreadCount={+(conversation?.unreadMessageCount || 0)}
                    isOpen={open}
                    active={!!match && chat?.chatId === user.uid}
                  />
                </ChatNavItem>
              );
            })}
            {usersSupportChat?.map((user: IUserChat) => {
              const conversation = personalChats?.find(
                ({ conversationWith }) =>
                  (conversationWith as IPersonalChat).uid === user.uid
              );
              return (
                <ChatNavItem
                  onClick={() => chatSelect(user.uid, false, user)}
                  isOpen={open}
                  active={!!match && chat?.chatId === user.uid}
                  key={`nav-member-${user.uid}`}
                >
                  <UserChatNavItem
                    user={
                      companyTeam?.find(
                        ({ id }) => uidToId(user.uid) === id
                      ) as IUser
                    }
                    isOnline={user?.status === EChatStatus.ONLINE}
                    unreadCount={+(conversation?.unreadMessageCount || 0)}
                    isOpen={open}
                    active={!!match && chat?.chatId === user.uid}
                  />
                </ChatNavItem>
              );
            })}
          </ChatNavGroup>
        )}
        <ChatNavGroup>
          <ChatNavItemBasic isOpen={open}>
            <ChatItemContentBasic isOpen={open}>
              <ListIconIconWrapper>
                <ListItemIcon icon={TeamIcon} />
              </ListIconIconWrapper>
              <Title open={open}>Team Channel</Title>
            </ChatItemContentBasic>
          </ChatNavItemBasic>
          {groupChats
            ?.filter(({ conversationId }) =>
              conversationId.includes(`company-${user?.companies[0]?.id}`)
            )
            .map(({ conversationId, conversationWith, unreadMessageCount }) => (
              <ChatNavItem
                isOpen={open}
                active={
                  !!match &&
                  chat?.chatId === (conversationWith as IGroupChat).guid
                }
                key={`nav-item-${conversationId}`}
                onClick={() =>
                  chatSelect(`${(conversationWith as IGroupChat).guid}`, true)
                }
              >
                <ChatItemContent
                  isOpen={open}
                  active={
                    !!match &&
                    chat?.chatId === (conversationWith as IGroupChat).guid
                  }
                >
                  <CustomAvatar
                    diameter={24}
                    avatartype={AvatarType.COMPANY}
                    sx={{
                      fontSize: 16,
                    }}
                    alt={conversationWith.name}
                    src={(conversationWith as IGroupChat).icon || undefined}
                  >
                    <TeamIcon />
                  </CustomAvatar>
                  <UserChatName isOpen={open}>
                    {conversationWith.name}
                  </UserChatName>
                  {!!Number(unreadMessageCount) && (
                    <NavItemBadge
                      open={open}
                      label={Number(unreadMessageCount)}
                    />
                  )}
                </ChatItemContent>
              </ChatNavItem>
            ))}
        </ChatNavGroup>
        {members && (
          <ChatNavGroup>
            <ChatNavItemBasic isOpen={open}>
              <ChatItemContentBasic isOpen={open}>
                <ListIconIconWrapper>
                  <ListItemIcon icon={MemberIcon} />
                </ListIconIconWrapper>
                <Title open={open}>Team Members</Title>
                {members?.length && (
                  <BadgeSimple open={open}>{members.length}</BadgeSimple>
                )}
              </ChatItemContentBasic>
            </ChatNavItemBasic>
            {sortedChat?.map(({ user, conversation }) => {
              return (
                <ChatNavItem
                  onClick={() => chatSelect(user.uid, false, user)}
                  isOpen={open}
                  active={!!match && chat?.chatId === user.uid}
                  key={`nav-member-${user.uid}`}
                >
                  <UserChatNavItem
                    user={
                      companyTeam?.find(
                        ({ id }) => uidToId(user.uid) === id
                      ) as IUser
                    }
                    isOnline={user?.status === EChatStatus.ONLINE}
                    unreadCount={+(conversation?.unreadMessageCount || 0)}
                    isOpen={open}
                    active={!!match && chat?.chatId === user.uid}
                  />
                </ChatNavItem>
              );
            })}
          </ChatNavGroup>
        )}
      </List>
    </NavSection>
  );
};

export default MessagingNavSection;

import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { SecreteItems } from '@/pages/dashboard/MyWorkspace/constants';
import {
  getUserWorkspaceItems,
  getUserWorkspaceLevels,
} from '@/redux/actions/workSpace';
import {
  currentItemsSelector,
  workSpaceCountCategoriesSelector,
} from '@/redux/selectors/workspaceSelector';
import { useEffect } from 'react';

const useGetWorkspaceProgress = (userId?: number | null) => {
  const dispatch = useAppDispatch();
  const countCategories = useAppSelector(workSpaceCountCategoriesSelector);
  const current = useAppSelector(currentItemsSelector);
  const currentLength = current.filter(
    (oneItem) =>
      oneItem.status === true &&
      !(
        oneItem.name === SecreteItems.BOX ||
        oneItem.name === SecreteItems.STICKER
      )
  ).length;

  const totalLength = current.length - 2;
  useEffect(() => {
    if (userId) {
      dispatch(getUserWorkspaceLevels(userId));
      dispatch(getUserWorkspaceItems(userId));
    } else if (userId === undefined) {
      dispatch(getUserWorkspaceLevels());
      if (current.length === 0) {
        dispatch(getUserWorkspaceItems());
      }
    }
  }, [userId]);

  return {
    countCategories,
    currentLength,
    totalLength,
  };
};

export default useGetWorkspaceProgress;

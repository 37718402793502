import {
  IActivityLogPagination,
  IActivityProgress,
  IActivityRange,
  IDailyStreak,
  IDateRange,
  IRewardPoints,
  IUser,
} from '@/types/models';
import { getUsersUrlByRoleName } from '@/utils/userRoleHelpers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EActivity } from '../constants';
import instance from '../middlewares/api';
import { setLoading } from '../reducers/activity';
import { RootState } from '../store';

const getMyActivity = createAsyncThunk(
  EActivity.myActivity,
  async (
    logPagination: IActivityLogPagination,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState() as RootState;
      const { role, id } = user.user as IUser;

      const response = await instance.get(
        `${getUsersUrlByRoleName(role.name, id)}/activities`,
        {
          params: {
            offset: logPagination.offset,
            limit: logPagination.limit,
            from: logPagination.from?.toDateString(),
            to: logPagination.to?.toDateString(),
          },
        }
      );
      return {
        data: response.data,
        offset: logPagination.offset,
      };
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setLoading(false));
    }
  }
);
interface InGetMyRewardPointsLog {
  logPagination: IActivityLogPagination;
  earned?: boolean;
}

const getMyRewardPointsLog = createAsyncThunk(
  EActivity.myRewardPointsLog,
  async (
    payloadIn: InGetMyRewardPointsLog,
    { dispatch, getState, rejectWithValue }
  ) => {
    dispatch(setLoading(true));
    const { logPagination, earned } = payloadIn;
    try {
      const { user } = getState() as RootState;
      const { role, id } = user.user as IUser;
      let url = `${getUsersUrlByRoleName(role.name, id)}/reward-points-log/`;
      if (earned) {
        url += 'earned';
      }

      const response = await instance.get(url, {
        params: {
          offset: logPagination.offset,
          limit: logPagination.limit,
          from: logPagination.from?.toDateString(),
          to: logPagination.to?.toDateString(),
        },
      });
      return {
        data: response.data,
        offset: logPagination.offset,
      };
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setLoading(false));
    }
  }
);

const getMyProgress = createAsyncThunk(
  EActivity.myProgress,
  async (
    dateRange: IActivityRange,
    { dispatch, getState, rejectWithValue }
  ) => {
    dispatch(setLoading(true));
    try {
      const { user } = getState() as RootState;
      const { role, id } = user.user as IUser;
      const response = await instance.get(
        `${getUsersUrlByRoleName(role.name, id)}/progress-meters?from=${
          dateRange.from
        }&to=${dateRange.to}`
      );
      return {
        data: response.data,
        dataLevel: dateRange.dataLevel,
      };
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setLoading(false));
    }
  }
);

const updateProgress = createAsyncThunk(
  EActivity.updateData,
  async (payload: IActivityProgress[], { rejectWithValue }) => {
    try {
      return payload;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    }
  }
);

const getMyRewardPoints = createAsyncThunk(
  EActivity.myRewardPoints,
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState() as RootState;
      const { role, id } = user.user as IUser;
      const response = await instance.get(
        `${getUsersUrlByRoleName(role.name, id)}/reward-points`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setLoading(false));
    }
  }
);

const updateRewardPoints = createAsyncThunk(
  EActivity.updateRewardPoints,
  async (payload: IRewardPoints, { rejectWithValue }) => {
    try {
      return payload;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    }
  }
);

const getMyDailyStreak = createAsyncThunk(
  EActivity.myDailyStreak,
  async (dateRange: IDateRange, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState() as RootState;
      const { role, id } = user.user as IUser;
      const response = await instance.get<IDailyStreak>(
        `${getUsersUrlByRoleName(role.name, id)}/streaks`,
        {
          params: {
            from: dateRange.from,
            to: dateRange.to,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export {
  getMyActivity,
  getMyProgress,
  getMyRewardPoints,
  updateProgress,
  updateRewardPoints,
  getMyDailyStreak,
  getMyRewardPointsLog,
};

import {
  EBrakeTimerState,
  EBrakeTimerTypes,
  EExtraBrakeTimerTypes,
} from '@/components/breaktimer/constants';
import useNotifications from '@/hooks/useNotification';
import { EBreakTimer } from '@/redux/constants';
import instance from '@/redux/middlewares/api';
import { RootState } from '@/redux/store';
import { EUserRole } from '@/types/consts';
import {
  IBreakTimerActionGetter,
  IBreakTimerGetter,
  IBreakTimerSetting,
} from '@/types/models';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getBrakeTimersData = createAsyncThunk(
  EBreakTimer.getData,
  async (_, thunkAPI) => {
    try {
      const { breakTimers } = thunkAPI.getState() as RootState;
      const { hydrate, move, relax, stretch } = breakTimers;
      const response = await instance.get('break-timers/get-for-current-user', {
        params: {
          date: new Date().toDateString(),
        },
      });
      if (response.data?.actionsBreakTimers?.length) {
        return response.data;
      } else if (!hydrate && !move && !relax && !stretch) {
        console.error('Error Break Timer loading fails, please try load again');
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const changeBreaktimerSettings = createAsyncThunk(
  EBreakTimer.updateSettings,
  async (breaktimers: IBreakTimerSetting[], thunkAPI) => {
    try {
      const response = await instance.post(
        '/break-timers/update-break-timers-settings',
        breaktimers
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const preCompleteBreakTimer = createAsyncThunk(
  EBreakTimer.preComplete,
  async (type: EBrakeTimerTypes, thunkAPI) => {
    try {
      const response = await instance.post(
        '/break-timers/pre-complete-break-timer',
        { type }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const completeBreakTimer = createAsyncThunk(
  EBreakTimer.complete,
  async (type: EBrakeTimerTypes, thunkAPI) => {
    try {
      const response = await instance.post(
        '/break-timers/complete-break-timer',
        { type }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const completeExtraBreakTimer = createAsyncThunk(
  EBreakTimer.completeExtra,
  async (type: EExtraBrakeTimerTypes, thunkAPI) => {
    try {
      const response = await instance.post(
        '/break-timers/complete-break-timer',
        { type }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

interface IBreakTimerWithActionsGetter {
  breakTimer: IBreakTimerGetter;
  actions: Array<IBreakTimerActionGetter>;
}

const updateBreakTimer = createAsyncThunk(
  EBreakTimer.updateData,
  async (payload: IBreakTimerWithActionsGetter, thunkAPI) => {
    try {
      if (!payload.actions?.length) {
        thunkAPI.dispatch(getBrakeTimersData);
      } else {
        return payload;
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

interface ICountDownProps {
  type: EBrakeTimerTypes;
  countdown: number;
}

const setCountdown = createAsyncThunk(
  EBreakTimer.setCountdown,
  async (payload: ICountDownProps, thunkAPI) => {
    try {
      return payload;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const startCountdown = createAsyncThunk(
  EBreakTimer.startCountdown,
  async (payload: ICountDownProps, { dispatch, getState, rejectWithValue }) => {
    try {
      await instance.post('/break-timers/start-break-timer', {
        type: payload.type,
      });
      dispatch(
        setBreakTimerStateAction({
          type: payload.type,
          state: EBrakeTimerState.PROCESSING,
        })
      );
      const { notify } = useNotifications();
      const state = getState() as RootState;
      const { user } = getState() as RootState;
      const role = user.user?.role.name;

      let count = state.breakTimers[payload.type].countdown;

      const interval = setInterval(function () {
        const currentState = getState() as RootState;
        if (!currentState.user.user) {
          clearInterval(interval);
        }
        if (--count <= 0) {
          clearInterval(interval);
          if (
            !(
              currentState.breakTimers.loading ||
              currentState.breakTimers[payload.type].state ===
                EBrakeTimerState.INACTIVE
            )
          ) {
            dispatch(preCompleteBreakTimer(payload.type));
            dispatch(
              setBreakTimerStateAction({
                type: payload.type,
                state: EBrakeTimerState.COMPLETED,
              })
            );
          }

          if (
            user?.user?.breakTimesSounds &&
            role !== EUserRole.SUPER_ADMIN &&
            role !== EUserRole.RW_ADMIN
          ) {
            notify('Good job!', true, require('@/audio/timer_completed.mp3'));
          }
        }

        dispatch(
          setCountdown({
            type: payload.type,
            countdown: count,
          })
        );
      }, 1000);
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    }
  }
);

interface IStateProps {
  type: EBrakeTimerTypes;
  state: EBrakeTimerState;
  countdown?: number;
}

const setBreakTimerStateAction = createAsyncThunk(
  EBreakTimer.setState,
  async (payload: IStateProps, thunkAPI) => {
    try {
      return payload;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export {
  getBrakeTimersData,
  updateBreakTimer,
  preCompleteBreakTimer,
  completeBreakTimer,
  completeExtraBreakTimer,
  changeBreaktimerSettings,
  setCountdown,
  startCountdown,
  setBreakTimerStateAction,
};

import { IAdminNavigationLink } from '@/types/admin';
import { EUserRole, EAdminHeaderKeys } from '@/types/consts';

export const navigationLinks: IAdminNavigationLink[] = [
  {
    key: EAdminHeaderKeys.ACCOUNTS,
    href: '/admin/companies',
    title: 'Accounts',
  },
  {
    key: EAdminHeaderKeys.MESSAGE,
    href: '/admin/message-center',
    title: 'Message Center',
    availableForRole: [EUserRole.RW_ADMIN],
  },
  // will be in the next version
  // {
  //   href: 'admin/my-workspace',
  //   title: 'My Workspace',
  // },
  {
    key: EAdminHeaderKeys.KNOWLEDGE,
    href: '/admin/knowledge-stat',
    title: 'Knowledge Base',
    availableForRole: [EUserRole.SUPER_ADMIN],
  },
];

import useAuth from '@/hooks/useAuth';
import { EUserProfileRoutes } from '@/types/consts';
import { Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHref, useLocation, useNavigate } from 'react-router-dom';
import { TabsContainer } from './components';

function a11yProps(index: number) {
  return {
    id: `user-profile-tab-${index}`,
    'aria-controls': `user-profile-tabpanel-${index}`,
  };
}

const UserProfileTabs = () => {
  const { isTeamLead, user } = useAuth();
  const [value, setValue] = useState<EUserProfileRoutes>(
    EUserProfileRoutes.PROFILE
  );
  const navigate = useNavigate();
  const location = useLocation();
  const href = useHref(location.pathname);

  useEffect(() => {
    const type = href.split('/').slice(-1)[0] as EUserProfileRoutes;
    setValue(type);
  }, [href]);

  const handleChange = (
    _: React.SyntheticEvent,
    newValue: EUserProfileRoutes
  ) => {
    setValue(newValue);
    navigate(`/edit-profile/${newValue}`);
  };

  return (
    <TabsContainer component={'section'}>
      <Tabs
        value={value}
        onChange={handleChange}
        centered
        textColor={'secondary'}
      >
        <Tab
          value={EUserProfileRoutes.PROFILE}
          label="My Information"
          {...a11yProps(0)}
        />
        {isTeamLead && (
          <Tab
            value={EUserProfileRoutes.COMPANY}
            label="My Company"
            {...a11yProps(1)}
          />
        )}
        <Tab
          value={EUserProfileRoutes.ACTIVITY}
          label={isTeamLead ? 'Activity' : 'My Activity'}
          {...a11yProps(2)}
        />
        <Tab
          value={EUserProfileRoutes.POINTS}
          label="Reward Points"
          {...a11yProps(3)}
          disabled={!user?.companies[0]?.rewardPoints}
        />
        {isTeamLead && (
          <Tab
            value={EUserProfileRoutes.POINTS_MANAGEMENT}
            label="Points Management"
            {...a11yProps(4)}
            disabled={!user?.companies[0]?.rewardPoints}
          />
        )}
      </Tabs>
    </TabsContainer>
  );
};

export default UserProfileTabs;

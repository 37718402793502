import { EState } from '@/redux/constants';
import instance from '@/redux/middlewares/api';
import { resetStateState } from '@/redux/reducers/state';
import { IState } from '@/types/models';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getStates = createAsyncThunk(EState.getStates, async (_, thunkAPI) => {
  try {
    const states = await instance.get<IState[]>('states');
    return states.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

const getStatesByCountry = createAsyncThunk(
  EState.getStatesByCountry,
  async (countryId: string, thunkAPI) => {
    try {
      const states = await instance.get<IState[]>(
        '/states/by-country/' + countryId
      );
      return states.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const createState = createAsyncThunk(
  EState.createState,
  async (stateData: IState, thunkAPI) => {
    try {
      const state = await instance.post<IState>(`states/new`, stateData);
      return state.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getState = createAsyncThunk(
  EState.getState,
  async (stateId: number, thunkAPI) => {
    try {
      const state = await instance.get(`states/${stateId}`);
      return state.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const updateState = createAsyncThunk(
  EState.updateState,
  async (updatedStateData: IState, thunkAPI) => {
    try {
      const state = await instance.patch<IState>(
        `states/${updatedStateData.id}`,
        updatedStateData
      );
      return state.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const deleteState = createAsyncThunk(
  EState.deleteState,
  async (stateId: number, thunkAPI) => {
    try {
      await instance.delete(`states/${stateId}`);
      return thunkAPI.dispatch(resetStateState());
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export {
  getStates,
  createState,
  getState,
  updateState,
  deleteState,
  getStatesByCountry,
};

import { CalendarIconWrapper } from '@/components/form-elements/components';
import { actionNames } from '@/components/progressMeter/ActivityBreakdown/models';
import { IActivityItem } from '@/components/progressMeter/RewardPointsHistory/RewardPointsHistoryWrapper';
import {
  EPointsTrigger,
  triggersNames,
} from '@/components/rewardPointsManagement/PointsManagmentHistory/models';
import ScrollbarComponent from '@/components/scrollbar/Scrollbar';
import { DatePickerInput } from '@/components/user-profile/reward-points/components';
import useAppDispatch from '@/hooks/useAppDispatch';
import { getMyRewardPointsLog } from '@/redux/actions/activity';
import { getUserRewardPointsLog } from '@/redux/actions/users';
import { ELimits } from '@/types/consts';
import { IRewardPointsLog } from '@/types/models';
import { Box, Divider, Skeleton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import {
  DateColumn,
  SimpleTableHeaderRow,
  SimpleTableRow,
  SimpleTableWrapper,
  TaskColumn,
} from './components';

export interface IRewardPointsHistoryProps {
  rewardPointsLog: IRewardPointsLog[] | null;
  activityDate: IActivityItem;
  isCompanyView?: boolean;
  setActivityDate: (val: any) => void;
  userId?: number | null;
}

interface IInfiniteLoadingWrapperProps {
  hasNextPage: boolean;
  items: Array<IRewardPointsLog>;
  isNextPageLoading: boolean;
  loadNextPage: () => void;
}

const InfiniteLoadingWrapper: React.FC<IInfiniteLoadingWrapperProps> = ({
  hasNextPage,
  isNextPageLoading,
  items,
  loadNextPage,
}: IInfiniteLoadingWrapperProps) => {
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  const Item = ({ index, style }: { index: number; style: any }) => {
    if (!isItemLoaded(index)) {
      return <div>Loading...</div>;
    }

    const { id, activity, createdAt, points, trigger } = items[index];

    return (
      <SimpleTableRow key={'row-' + id} style={style}>
        <Box display="flex">
          <DateColumn>{format(parseISO(createdAt), 'MM/dd/yyyy')}</DateColumn>
          <TaskColumn>
            {activity
              ? actionNames[activity.action]
              : trigger
              ? triggersNames[trigger]
              : ''}
          </TaskColumn>
        </Box>
        <div className="number">
          {(trigger && trigger === EPointsTrigger.TRANSFER_POINTS ? '-' : '+') +
            Number(points).toLocaleString('en')}
        </div>
      </SimpleTableRow>
    );
  };

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      threshold={4}
    >
      {({ onItemsRendered, ref }) => (
        <FixedSizeList
          height={360}
          itemSize={36}
          width="100%"
          itemCount={itemCount}
          onItemsRendered={onItemsRendered}
          ref={ref}
          outerElementType={ScrollbarComponent}
        >
          {Item}
        </FixedSizeList>
      )}
    </InfiniteLoader>
  );
};

const RewardPointsHistory: React.FC<IRewardPointsHistoryProps> = ({
  rewardPointsLog,
  setActivityDate,
  activityDate,
  userId,
}) => {
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMoreData, setMoreDataState] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setInitialLoading(true);
    setMoreDataState(true);
  }, [activityDate.endDate, activityDate.startDate]);

  useEffect(() => {
    if (rewardPointsLog && initialLoading) {
      setMoreDataState(
        !!rewardPointsLog?.length && rewardPointsLog.length >= ELimits.ACTIVITY
      );
      setInitialLoading(false);
    }
  }, [
    rewardPointsLog?.length,
    initialLoading,
    activityDate.endDate,
    activityDate.startDate,
  ]);

  const loadMore = useCallback(() => {
    setLoading(true);
    const logPagination = {
      limit: ELimits.ACTIVITY,
      offset: (rewardPointsLog as IRewardPointsLog[]).length,
      from: activityDate.startDate,
      to: activityDate.endDate,
    };

    const loadingPromise = !userId
      ? dispatch(getMyRewardPointsLog({ logPagination, earned: false }))
      : dispatch(
          getUserRewardPointsLog({
            ...logPagination,
            userId,
          })
        );
    loadingPromise
      .unwrap()
      .then((result) => {
        setMoreDataState(
          result.data.length && result.data.length >= ELimits.ACTIVITY
        );
      })
      .finally(() => setLoading(false));
  }, [rewardPointsLog, activityDate.endDate, activityDate.startDate]);

  return (
    <>
      {rewardPointsLog ? (
        <SimpleTableWrapper>
          <>
            <SimpleTableHeaderRow>
              <Box display="flex">
                <DateColumn>
                  <Box>Date</Box>
                  <Box display="flex">
                    <DatePicker
                      value={activityDate.startDate}
                      onChange={(value: Date | null) => {
                        if (value !== null) {
                          setActivityDate((prev: any) => ({
                            ...prev,
                            startDate: value,
                          }));
                        }
                      }}
                      InputProps={{
                        placeholder: 'start date',
                      }}
                      components={{
                        OpenPickerIcon: () => <CalendarIconWrapper />,
                      }}
                      maxDate={new Date()}
                      minDate={new Date('01/01/2020')}
                      renderInput={(params) => (
                        <DatePickerInput
                          className="date-picker"
                          placeholder={params.InputProps?.placeholder}
                          onChange={params.inputProps?.onChange}
                          value={params.inputProps?.value}
                          endAdornment={params.InputProps?.endAdornment}
                          inputRef={params.inputRef}
                        />
                      )}
                    />
                    {' - '}
                    <DatePicker
                      value={activityDate.endDate}
                      onChange={(value: Date | null) => {
                        if (value !== null) {
                          setActivityDate((prev: any) => ({
                            ...prev,
                            endDate: value,
                          }));
                        }
                      }}
                      InputProps={{
                        placeholder: 'end date',
                      }}
                      components={{
                        OpenPickerIcon: () => <CalendarIconWrapper />,
                      }}
                      maxDate={new Date()}
                      minDate={new Date('01/01/2020')}
                      renderInput={(params) => (
                        <DatePickerInput
                          className="date-picker"
                          placeholder={params.InputProps?.placeholder}
                          onChange={params.inputProps?.onChange}
                          value={params.inputProps?.value}
                          endAdornment={params.InputProps?.endAdornment}
                          inputRef={params.inputRef}
                        />
                      )}
                    />
                  </Box>
                </DateColumn>
                <Box>Action</Box>
              </Box>
              <Box>Reward Points</Box>
            </SimpleTableHeaderRow>
            <Divider sx={{ marginBottom: 3 }} />
            <InfiniteLoadingWrapper
              items={rewardPointsLog}
              hasNextPage={hasMoreData}
              isNextPageLoading={loading}
              loadNextPage={loadMore}
            />
          </>
        </SimpleTableWrapper>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default RewardPointsHistory;

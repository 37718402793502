import { CometChat } from '@cometchat-pro/chat';

export const messageCenterInit = (callback: () => void) => {
  const appID = process.env.REACT_APP_COMETCHAT_ID;
  const region = process.env.REACT_APP_COMETCHAT_REGION;
  const appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(region)
    .build();
  CometChat.init(appID, appSetting)
    .then(
      () => {
        console.log('Initialization completed successfully');
      },
      (error) => {
        console.log('Initialization failed with error:', error);
        // Check the reason for error and take appropriate action.
      }
    )
    .finally(() => callback());
};

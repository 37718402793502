import GlobalStyle from '@/components/GlobalStyle';
import {
  CenterPlantIcon,
  LeftPlantIcon,
  NotFoundPageIcon,
  RightPlantIcon,
} from '@/components/icons';
import ScrollbarComponent from '@/components/scrollbar/Scrollbar';
import { Button, CssBaseline, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Root = styled('main')`
  position: relative;
  height: 100vh;
  max-height: 100vh;
  background-color: #031162;
`;

const PageContent = styled(Grid)`
  position: absolute;
  max-width: 300px;
  left: 0px;
  right: 0px;
  margin: ${({ theme }) => theme.spacing('15%', 'auto', 0)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageTitle = styled(Typography)`
  font-size: 32px;
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  color: ${({ theme }) => theme.palette.common.white};
`;

const ButtonBack = styled(Button)`
  min-width: 160px;
`;

const StyledNotFoundPageIcon = styled(NotFoundPageIcon)`
  position: absolute;
  top: 0;
  left: 0px;
  right: 0px;
  margin: 0 auto;
`;

const StyledLeftPlantIcon = styled(LeftPlantIcon)`
  position: absolute;
  bottom: 0;
  left: 0px;
`;

const StyledRightPlantIcon = styled(RightPlantIcon)`
  position: absolute;
  bottom: 0;
  right: 0px;
`;

const StyledCenterPlantIcon = styled(CenterPlantIcon)`
  position: absolute;
  bottom: 0;
  left: 20%;
`;

const NotFound = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1 || '/');
  };

  return (
    <ScrollbarComponent>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <StyledNotFoundPageIcon />
        <PageContent>
          <PageTitle>Page Not Found</PageTitle>
          <ButtonBack onClick={handleClick}>Go back</ButtonBack>
        </PageContent>
        <StyledLeftPlantIcon />
        <StyledRightPlantIcon />
        <StyledCenterPlantIcon />
      </Root>
    </ScrollbarComponent>
  );
};

export default NotFound;

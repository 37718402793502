import GlobalStyle from '@/components/GlobalStyle';
import GuestGuard from '@/components/guards/GuestGuard';
import { ReactComponent as AuthPic } from '@/components/icons/AuthPicture.svg';
import { ReactComponent as Logo } from '@/components/icons/BigLogo.svg';
import ScrollbarComponent from '@/components/scrollbar/Scrollbar';
import { Grid, Paper as MuiPaper, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { spacing } from '@mui/system';
import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.neutral.main};
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  background-color: transparent;
  color: ${(props) => props.theme.palette.neutral.dark};
  box-shadow: none;
  max-width: 360px;
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
`;

const WelcomeContainer = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 899.9px) {
    justify-content: center;
  }
  @media screen and (min-width: 900px) {
    justify-content: end;
  } ;
`;
const BrandContainer = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 899.9px) {
    justify-content: center;
  }
  @media screen and (min-width: 900px) {
    justify-content: start;
  } ;
`;

const BrandIcon = styled(Logo)`
  margin-left: ${(props) => props.theme.spacing(2)};
  width: 260px;
  height: 56px;

  & path:nth-of-type(1) {
    fill: ${({ theme }) => theme.palette.custom.dark};
  }

  & path:nth-of-type(3) {
    fill: ${({ theme }) => theme.palette.custom.dark};
  }
`;

const AuthImage = styled(AuthPic)`
  margin-top: 36px;
`;

const Title = styled(Typography)`
  font-size: 41px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  color: ${({ theme }) => theme.palette.custom.dark};
`;

const AuthLayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <GuestGuard>
      <ScrollbarComponent>
        <Root>
          <CssBaseline />
          <GlobalStyle />
          <Grid container>
            <WelcomeContainer item xs={12} md={6} mt="10px">
              <Title>Welcome to</Title>
            </WelcomeContainer>
            <BrandContainer item xs={12} md={6} mt="10px">
              <BrandIcon />
            </BrandContainer>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <MainContent>{children}</MainContent>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <AuthImage />
            </Grid>
          </Grid>
        </Root>
      </ScrollbarComponent>
    </GuestGuard>
  );
};

export default AuthLayout;

import { EUserRole } from '@/types/consts';

export const getUsersUrlByRoleName = (roleName?: string, id?: number) => {
  if (roleName === EUserRole.TEAMLEAD) {
    return `team-admin/users/${id || ''}`;
  }

  if (roleName === EUserRole.SUPER_ADMIN) {
    return `super-admin/users/${id || ''}`;
  }

  if (roleName === EUserRole.RW_ADMIN) {
    return `rw-admin/users/${id || ''}`;
  }

  return `users`;
};

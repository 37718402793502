import { Box, Tabs, Typography } from '@mui/material';
import styled from 'styled-components';

export const MyWorkspaceHeaderMenuBlock = styled.div`
  padding: 15px 23px 15px 23px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 5;
  background-color: white;
  height: auto;
  border-radius: 12px;

  .third-line {
    margin-top: 5px;
    display: flex;
  }

  .progress {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: ${({ theme }) => theme.palette.border.main};
  }
`;

export const WorkspaceTabsWrapper = styled(Box)`
  position: relative;
  z-index: 5;
  top: -25px;
  display: flex;
  justify-content: center;
`;

export const SmallText = styled(Typography)`
  width: auto;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.neutral.light};
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  margin-bottom: 10px;
`;

export const SmallTextWrapper = styled(Typography)`
  display: flex;
  align-items: start;
`;

export const WorkspaceTabs = styled(Tabs)`
  border-radius: 60px;
  width: auto;
  padding: 0;
  background: white;
`;

export const BoxWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  height: 100%;
`;

export const StatisticWrapper = styled.div`
  position: relative;
  top: -20px;
  width: 40%;
  display: flex;
  align-items: center;
  height: auto;
`;
export const ProggresHeaderWorkspace = styled(Box)`
  position: relative;
  top: -18px;
  left: 5px;
  justify-content: end;
  margin-right: auto;
  height: 100%;
  display: flex;
  width: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.border.main};
`;

import {
  breakTimersData,
  EActionsBreakTimerStatus,
  EBrakeTimerState,
  EBrakeTimerTypes,
  EExtraBrakeTimerTypes,
  finishedActionStatuses,
} from '@/components/breaktimer/constants';
import { BreakTimerState } from '@/redux/reducers/breaktimer';
import { IBreakTimerActionGetter, IBreakTimerGetter } from '@/types/models';
import {
  minutesFromUTCMidnightToLocalTime,
  timeToMinutesFromMidnight,
} from '@/utils/secondsToReadableTime';

interface IBreakTimersGetter {
  breakTimers: Array<IBreakTimerGetter>;
  actionsBreakTimers: Array<IBreakTimerActionGetter>;
}

export const bonusBreakTimerAdapterFunction = (
  payload: IBreakTimersGetter
): EActionsBreakTimerStatus | null => {
  const actionsBonusBreakTimer = payload.actionsBreakTimers.filter(
    (action) => action.type.name === EExtraBrakeTimerTypes.BONUS
  );
  if (actionsBonusBreakTimer.length) {
    return actionsBonusBreakTimer[0].status;
  }

  return null;
};

export const breakTimerAdapterFunction = (
  payload: IBreakTimersGetter,
  type: EBrakeTimerTypes
) => {
  const currentBreakTimer = payload.breakTimers.find(
    (timer) => timer.type?.name === type
  ) as IBreakTimerGetter;
  const currentTime = timeToMinutesFromMidnight(new Date());
  const actionsForThisBreakTimer = payload.actionsBreakTimers.filter(
    (action) => action.type?.name === type
  );
  const timePoints = actionsForThisBreakTimer
    .map((item) => item.timePoint)
    .sort((a, b) => a - b);
  const lastTimePointForToday = Math.max(...timePoints);
  const lastActionForToday = actionsForThisBreakTimer.find(
    (action) => action.timePoint === lastTimePointForToday
  ) as IBreakTimerActionGetter;
  let breakTimerState = EBrakeTimerState.INACTIVE;
  let breakTimerCompleted = false;
  let nextTimePoint = Math.min(...timePoints);

  if (finishedActionStatuses.includes(lastActionForToday.status)) {
    breakTimerCompleted = true;
  }

  if (
    actionsForThisBreakTimer.filter(
      (action) =>
        action.status === EActionsBreakTimerStatus.STARTING ||
        action.status === EActionsBreakTimerStatus.PROCESSING
    ).length !== 0
  ) {
    breakTimerState = EBrakeTimerState.WAITING_FOR_INTERACTION;
  }

  if (
    actionsForThisBreakTimer.filter(
      (action) => action.status === EActionsBreakTimerStatus.PRE_COMPLETED
    ).length !== 0
  ) {
    breakTimerState = EBrakeTimerState.COMPLETED;
  }

  if (
    actionsForThisBreakTimer.filter(
      (action) =>
        action.status !== EActionsBreakTimerStatus.STARTING &&
        action.status !== EActionsBreakTimerStatus.PROCESSING
    ).length === 0 &&
    !breakTimerCompleted
  ) {
    nextTimePoint = timePoints.filter((point) => point > currentTime)[0];
  }

  if (nextTimePoint < currentTime && currentTime > Math.max(...timePoints)) {
    breakTimerCompleted = true;
  }

  if (nextTimePoint < currentTime && currentTime < Math.max(...timePoints)) {
    nextTimePoint = timePoints.filter((point) => point > currentTime)[0];
  }

  const actionsStatuses = actionsForThisBreakTimer
    .sort((action1, action2) => action1.timePoint - action2.timePoint)
    .map((action) => action.status);

  const timePointsCount = currentBreakTimer?.timePoints.length;

  return {
    state: breakTimerState,
    done: breakTimerCompleted,
    nextActivation: minutesFromUTCMidnightToLocalTime(nextTimePoint as number),
    todaySuccessCount: actionsForThisBreakTimer.filter(
      (action) => action.status === EActionsBreakTimerStatus.COMPLETED
    ).length,
    timePointsCount,
    actionsCount: actionsForThisBreakTimer.length,
    timePoints,
    actionsStatuses,
    countdown: breakTimersData[type].durationSeconds,
    allFailed:
      actionsStatuses.filter(
        (status) => status === EActionsBreakTimerStatus.FAILED
      ).length === timePointsCount,
  } as BreakTimerState;
};

export const notificationsAdapterFunction = (notifications: any[]) => {
  return notifications.map((notification) => ({
    ...notification,
    title: JSON.parse(notification.notification).title,
    description: JSON.parse(notification.notification).message,
  }));
};

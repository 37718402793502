import {
  CompanyNameProp,
  CompanyNameValue,
  UpdateButton,
} from '@/components/admin/user/components';
import ResendLinkButton from '@/components/admin/user/ResendLinkUserButton';
import UserDeleteButton from '@/components/admin/user/UserDeleteButton';
import {
  CalendarIconWrapper,
  IconButtonWrapper,
} from '@/components/form-elements/components';
import { PhoneNumber } from '@/components/form-elements/PhoneNumber';
import { InfoIcon } from '@/components/icons';
import { AvatarType, CustomAvatar } from '@/components/UI/CustomAvatar';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { FormControlWrapper } from '@/pages/auth/components';
import {
  getUsersByParams,
  restoreUser,
  updateUser,
} from '@/redux/actions/users';
import { resetUser } from '@/redux/reducers/users';
import { adminSelectedUser, getOneUserLoaded } from '@/redux/selectors/users';
import { DEFAULT_TOAST_TIME, EUserRole } from '@/types/consts';

import { IUser } from '@/types/models';

import { inputDateFormater, parseBackDate } from '@/utils/dateHelpers';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';

import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Tooltip,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { isAfter, isBefore } from 'date-fns';

import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const getUserCompanyNames = (user: IUser | null) => {
  if (user?.role.name === EUserRole.RW_ADMIN) {
    return user?.rwCompanies?.map(({ name }) => name) || null;
  }

  return user?.companies?.map(({ name }) => name) || null;
};

interface IUserProfile {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  jobTitle: string;
  company: string; // TODO change
  birthday: string | null;
  startWorkDate: string | null;
}

const initialValues = (user: IUser | null) => {
  return {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    phone: user?.phone || '',
    email: user?.email || '',
    jobTitle: user?.jobTitle || '',
    company: getUserCompanyNames(user)?.join(', ') || '',
    birthday: user?.birthday || null,
    startWorkDate: user?.startWorkDate || null,
  };
};

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  phone: Yup.string()
    .min(10, 'Incomplete phone number')
    .max(10, 'Incorrect phone number')
    .required('Required'),
  email: Yup.string()
    .min(5, 'Too Short!')
    .max(50, 'Too Long!')
    .email('Incorrect email')
    .required('Required'),
  jobTitle: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
  birthday: Yup.string()
    .optional()
    .nullable()
    .test(
      'maxDate',
      'Incorrect date',
      (value) => !value || isBefore(parseBackDate(value) as Date, new Date())
    )
    .test(
      'minDate',
      'Incorrect date',
      (value) =>
        !value || isAfter(parseBackDate(value) as Date, new Date(1900, 1, 1))
    ),
  startWorkDate: Yup.string()
    .optional()
    .nullable()
    .test(
      'maxDate',
      'Incorrect date',
      (value) => !value || isBefore(parseBackDate(value) as Date, new Date())
    )
    .test(
      'minDate',
      'Incorrect date',
      (value) =>
        !value || isAfter(parseBackDate(value) as Date, new Date(1900, 1, 1))
    ),
});

const UpdateUserForm = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(adminSelectedUser);
  const loading = useAppSelector(getOneUserLoaded);
  const [submitError, setSubmitError] = React.useState<string>('');
  const [isFormSubmited, setFormSubmited] = React.useState<boolean>(false);
  const [teamLeaders, setTeamLeaders] = React.useState<IUser[] | null>(null);

  const handleClickRestore = async (id: number) => {
    try {
      await dispatch(restoreUser(id)).unwrap();
      toast.success('User was successfully restored!', {
        position: 'top-right',
        autoClose: DEFAULT_TOAST_TIME,
        hideProgressBar: false,
      });
      window.location.reload();
    } catch (e: any) {
      toast.error(e.error, {
        position: 'top-right',
      });
    }
  };

  const companyNames = useMemo(() => {
    return getUserCompanyNames(user);
  }, [user]);

  const handleCatchError = ({ error }: any) => {
    setSubmitError(error);
  };

  const handleOnSubmit = (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<IUserProfile>
  ) => {
    if (user) {
      setFormSubmited(false);
      setSubmitError('');
      dispatch(
        updateUser({
          id: user?.id,
          ...values,
          stateId: user?.stateId,
        })
      )
        .unwrap()
        .then(() =>
          toast.success('User was successfully updated', {
            position: 'top-right',
            autoClose: DEFAULT_TOAST_TIME,
            hideProgressBar: false,
          })
        )
        .catch((err) => {
          toast.error(err, {
            position: 'top-right',
          });
        })
        .finally(() => {
          setSubmitting(false);
          resetForm({ values });
        });
    }
  };

  useEffect(() => {
    dispatch(
      getUsersByParams({
        company: (companyNames && companyNames[0]) || '',
        roles: [EUserRole.TEAMLEAD],
      })
    )
      .unwrap()
      .then(({ users }) => {
        if (users) {
          setTeamLeaders(users);
        }
      });
  }, [dispatch, user, companyNames]);

  useEffect(() => {
    return () => {
      dispatch(resetUser());
    };
  }, []);

  if (loading || !teamLeaders) {
    return (
      <Box height="calc(100vh - 155px)">
        <Box
          minHeight="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <Formik
      initialValues={initialValues(user)}
      validationSchema={ProfileSchema}
      onSubmit={handleOnSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
        setFieldValue,
        isSubmitting,
        dirty,
        isValid,
      }) => {
        return (
          <Form>
            {user?.role.name !== EUserRole.RW_ADMIN && (
              <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                flexWrap={'nowrap'}
                columnGap={4}
                mb={4}
              >
                <Grid
                  item
                  md={6}
                  container
                  alignItems={'center'}
                  flexWrap={'nowrap'}
                >
                  <CompanyNameProp>
                    {companyNames ? 'Company: ' : 'User email: '}
                  </CompanyNameProp>
                  <CompanyNameValue>
                    {(companyNames && companyNames[0]) || user?.email || 'N/A'}
                  </CompanyNameValue>
                </Grid>
                <Grid
                  item
                  md={6}
                  container
                  alignItems={'center'}
                  flexWrap={'nowrap'}
                >
                  <CompanyNameProp>Team Admin:</CompanyNameProp>
                  {teamLeaders?.length ? (
                    <>
                      <CustomAvatar
                        diameter={46}
                        avatartype={AvatarType.USER}
                        sx={{
                          marginX: '10px',
                        }}
                        alt="Remy Sharp"
                        src={teamLeaders[0]?.profileImage || undefined}
                      >
                        {!teamLeaders[0]
                          ? ''
                          : getUserAvatarLetter({
                              firstName: teamLeaders[0].firstName,
                              lastName: teamLeaders[0].lastName,
                              email: teamLeaders[0].email,
                            })}
                      </CustomAvatar>
                      <CompanyNameValue>
                        {teamLeaders[0].firstName && teamLeaders[0].lastName
                          ? teamLeaders[0].firstName +
                            ' ' +
                            teamLeaders[0].lastName
                          : teamLeaders[0].firstName
                          ? teamLeaders[0].firstName
                          : teamLeaders[0].lastName
                          ? teamLeaders[0].lastName
                          : teamLeaders[0].email}
                      </CompanyNameValue>
                    </>
                  ) : (
                    <CompanyNameValue ml={2}>N/A</CompanyNameValue>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid
              display={'grid'}
              gridTemplateColumns={'repeat(2, 1fr)'}
              columnGap={4}
            >
              <Grid item md={6}>
                <FormControlWrapper
                  hiddenLabel={!values.firstName}
                  error={!!errors.firstName && !!touched.firstName}
                  fullWidth
                >
                  {!!values.firstName && <InputLabel>First Name*</InputLabel>}
                  <OutlinedInput
                    name="firstName"
                    placeholder="First Name*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    error={!!errors.firstName && !!touched.firstName}
                  />
                  {errors.firstName && touched.firstName && (
                    <FormHelperText id="firstName" error>
                      {errors.firstName}
                    </FormHelperText>
                  )}
                </FormControlWrapper>
              </Grid>
              <Grid item md={6}>
                <FormControlWrapper
                  hiddenLabel={!values.lastName}
                  error={!!errors.lastName && !!touched.lastName}
                  fullWidth
                >
                  {!!values.lastName && <InputLabel>Last Name*</InputLabel>}
                  <OutlinedInput
                    name="lastName"
                    placeholder="Last Name*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    error={!!errors.lastName && !!touched.lastName}
                  />
                  {errors.lastName && touched.lastName && (
                    <FormHelperText id="lastName" error>
                      {errors.lastName}
                    </FormHelperText>
                  )}
                </FormControlWrapper>
              </Grid>

              <Grid item md={6}>
                <FormControlWrapper
                  error={!!errors.phone && !!touched.phone}
                  fullWidth
                >
                  <Box
                    display="flex"
                    justifyContent={
                      !!values.phone ? 'space-between' : 'flex-end'
                    }
                    alignItems="flex-end"
                  >
                    {!!values.phone && <InputLabel>Phone Number*</InputLabel>}
                    <Tooltip title="Example: (239) 555-0108">
                      <IconButtonWrapper>
                        <InfoIcon />
                      </IconButtonWrapper>
                    </Tooltip>
                  </Box>
                  <OutlinedInput
                    name="phone"
                    placeholder="Phone Number*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    error={!!errors.phone && !!touched.phone}
                    inputComponent={PhoneNumber as any}
                  />
                  {errors.phone && touched.phone && (
                    <FormHelperText id="phone" error>
                      {errors.phone}
                    </FormHelperText>
                  )}
                </FormControlWrapper>
              </Grid>
              <Grid item md={6}>
                <FormControlWrapper fullWidth>
                  {!!values.email && (
                    <InputLabel sx={{ marginBottom: '12px' }}>Email</InputLabel>
                  )}
                  <OutlinedInput
                    type="email"
                    name="email"
                    placeholder="Email*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    disabled
                  />
                </FormControlWrapper>
              </Grid>

              <Grid item md={6}>
                <FormControlWrapper
                  hiddenLabel={!values.jobTitle}
                  error={!!errors.jobTitle && !!touched.jobTitle}
                  fullWidth
                >
                  {!!values.jobTitle && (
                    <InputLabel>Role or Job Title</InputLabel>
                  )}
                  <OutlinedInput
                    name="jobTitle"
                    placeholder="Job Title*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.jobTitle}
                    error={!!errors.jobTitle && !!touched.jobTitle}
                  />
                  {errors.jobTitle && touched.jobTitle && (
                    <FormHelperText id="jobTitle" error>
                      {errors.jobTitle}
                    </FormHelperText>
                  )}
                </FormControlWrapper>
              </Grid>
              <Grid item md={6}>
                <FormControlWrapper
                  hiddenLabel={!values.company}
                  error={!!errors.company && !!touched.company}
                  fullWidth
                >
                  {!!values.company && (
                    <InputLabel>
                      {user?.role.name === 'rw_admin'
                        ? 'My Accounts'
                        : 'Company'}
                    </InputLabel>
                  )}
                  <OutlinedInput
                    name="company"
                    placeholder="Company"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company}
                    disabled={true}
                  />
                </FormControlWrapper>
              </Grid>

              <Grid item md={6}>
                <FormControlWrapper
                  error={!!errors.birthday && !!touched.birthday}
                  fullWidth
                >
                  <Box
                    display="flex"
                    justifyContent={
                      !!values.birthday ? 'space-between' : 'flex-end'
                    }
                    alignItems="flex-end"
                  >
                    {!!values.birthday && <InputLabel>Birthday</InputLabel>}
                    <Tooltip
                      title={
                        "Add your birthday and we'll send you a birthday gift!"
                      }
                    >
                      <IconButtonWrapper>
                        <InfoIcon />
                      </IconButtonWrapper>
                    </Tooltip>
                  </Box>
                  <DatePicker
                    value={values.birthday}
                    onChange={(value: Date | null, keyboardInputValue) => {
                      if (
                        !keyboardInputValue ||
                        keyboardInputValue.length === 10
                      ) {
                        setFieldValue(
                          'birthday',
                          inputDateFormater(value),
                          true
                        );
                      }
                    }}
                    InputProps={{
                      placeholder: 'Birthday (Optional)',
                    }}
                    components={{
                      OpenPickerIcon: () => <CalendarIconWrapper />,
                    }}
                    OpenPickerButtonProps={{ disableRipple: true }}
                    maxDate={new Date()}
                    minDate={new Date('01/01/1950')}
                    renderInput={(params) => (
                      <OutlinedInput
                        placeholder={params.InputProps?.placeholder}
                        onChange={params.inputProps?.onChange}
                        value={params.inputProps?.value}
                        endAdornment={params.InputProps?.endAdornment}
                        inputRef={params.inputRef}
                        error={!!errors.birthday && touched.birthday}
                      />
                    )}
                  />
                  {errors.birthday && touched.birthday && (
                    <FormHelperText id="birthday" error>
                      {errors.birthday}
                    </FormHelperText>
                  )}
                </FormControlWrapper>
              </Grid>
              <Grid item md={6}>
                <FormControlWrapper
                  error={!!errors.startWorkDate && !!touched.startWorkDate}
                  fullWidth
                >
                  <Box
                    display="flex"
                    justifyContent={
                      !!values.startWorkDate ? 'space-between' : 'flex-end'
                    }
                    alignItems="flex-end"
                  >
                    {!!values.startWorkDate && (
                      <InputLabel>Start Date</InputLabel>
                    )}
                    <Tooltip title="Add your date of work start and we'll send you an anniversary gift.">
                      <IconButtonWrapper>
                        <InfoIcon />
                      </IconButtonWrapper>
                    </Tooltip>
                  </Box>
                  <DatePicker
                    value={values.startWorkDate}
                    onChange={(value: Date | null, keyboardInputValue) => {
                      if (
                        !keyboardInputValue ||
                        keyboardInputValue.length === 10
                      ) {
                        setFieldValue(
                          'startWorkDate',
                          inputDateFormater(value),
                          true
                        );
                      }
                    }}
                    InputProps={{
                      placeholder: 'Start Date (Optional)',
                    }}
                    components={{
                      OpenPickerIcon: () => <CalendarIconWrapper />,
                    }}
                    OpenPickerButtonProps={{ disableRipple: true }}
                    maxDate={new Date()}
                    minDate={new Date('01/01/2000')}
                    renderInput={(params) => (
                      <OutlinedInput
                        placeholder={params.InputProps?.placeholder}
                        onChange={params.inputProps?.onChange}
                        value={params.inputProps?.value}
                        endAdornment={params.InputProps?.endAdornment}
                        inputRef={params.inputRef}
                        error={!!errors.startWorkDate && touched.startWorkDate}
                      />
                    )}
                  />
                  {errors.startWorkDate && touched.startWorkDate && (
                    <FormHelperText id="startWorkDate" error>
                      {errors.startWorkDate}
                    </FormHelperText>
                  )}
                </FormControlWrapper>
              </Grid>
            </Grid>
            {submitError && (
              <FormHelperText error>{submitError}</FormHelperText>
            )}

            {isFormSubmited && (
              <FormHelperText error>
                Invintation was sent to user email
              </FormHelperText>
            )}
            <Grid item container justifyContent={'center'}>
              {!user?.deletedAt ? (
                <>
                  <UpdateButton
                    disabled={
                      !(
                        isValid &&
                        dirty &&
                        initialValues(user) !== values &&
                        !isSubmitting
                      )
                    }
                    type={'submit'}
                    variant="outlined"
                    color={'primary'}
                  >
                    {isSubmitting ? <CircularProgress /> : 'Update'}
                  </UpdateButton>
                  {!user?.isConfirm && (
                    <ResendLinkButton userId={user?.id as number} />
                  )}
                  <UserDeleteButton />
                </>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    color={'primary'}
                    onClick={() => handleClickRestore(user?.id as number)}
                    sx={{ marginRight: 2 }}
                  >
                    Restore
                  </Button>
                  <UserDeleteButton
                    isSoft={false}
                    text={'Delete permanently'}
                  />
                </>
              )}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UpdateUserForm;

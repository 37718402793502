import { EUser } from '@/redux/constants';
import instance from '@/redux/middlewares/api';
import { resetUserState } from '@/redux/reducers/user';
import { resetUserListState } from '@/redux/reducers/users';
import { DEFAULT_TOAST_TIME, EUserRole } from '@/types/consts';
import {
  IChangePassword,
  ICompany,
  IForgotPassword,
  ILogin,
  IResetPassword,
  ISuccessResponce,
  IUpdateNotificationSettings,
  IUser,
  IUserLogin,
  IUserUpdate,
} from '@/types/models';
import { removeAuthTokens } from '@/utils/apiCallHelpers';
import { idToUid } from '@/utils/chatHelpers';
import { getUsersUrlByRoleName } from '@/utils/userRoleHelpers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetActivityState } from '../reducers/activity';
import { resetBreakTimersState } from '../reducers/breaktimer';
import { resetCompanyState } from '../reducers/company';
import { resetNotificationsState } from '../reducers/notifications';
import { resetWorkSpace } from '../reducers/workspace';
import { RootState } from '../store';
import { loginChatUser, logoutChatUser } from './chat';
import { toast } from 'react-toastify';

const login = createAsyncThunk(EUser.login, async (login: ILogin, thunkAPI) => {
  try {
    const loginResult = await instance.post<IUserLogin>('auth/login', login);
    if (loginResult.data.role.name !== EUserRole.SUPER_ADMIN) {
      thunkAPI.dispatch(loginChatUser(idToUid(loginResult.data.id)));
    }
    return loginResult.data;
  } catch (error: any) {
    if (error.status !== 401) {
      toast.error(`${error.status} - ${error.message}`, {
        position: 'top-right',
        autoClose: DEFAULT_TOAST_TIME,
        hideProgressBar: false,
      });
    }
    return thunkAPI.rejectWithValue({ error });
  }
});

const logout = createAsyncThunk(
  EUser.logout,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await instance.get('auth/logout');
      return dispatch(resetUserState());
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      await Promise.all([
        dispatch(resetActivityState()),
        removeAuthTokens(),
        dispatch(resetCompanyState()),
        dispatch(resetUserListState()),
        dispatch(resetBreakTimersState()),
        dispatch(logoutChatUser()),
        dispatch(resetNotificationsState()),
        dispatch(resetWorkSpace()),
      ]);
    }
  }
);

const forgotPassword = createAsyncThunk(
  EUser.forgotPassword,
  async (forgotData: IForgotPassword, thunkAPI) => {
    try {
      const response = await instance.post<ISuccessResponce>(
        'auth/forgot-password',
        forgotData
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const resetPassword = createAsyncThunk(
  EUser.resetPassword,
  async ({ emailAuth, password }: IResetPassword, thunkAPI) => {
    try {
      const response = await instance.post<ISuccessResponce>(
        'auth/password-reset',
        {
          password,
        },
        {
          headers: {
            'Email-Auth': emailAuth as string,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const confirmWorkLifeShopEmail = createAsyncThunk(
  EUser.confirmWorkLifeShopEmail,
  async ({ emailAuth }: { emailAuth: string }, thunkAPI) => {
    try {
      const response = await instance.post<ISuccessResponce>(
        'auth/confirm-work-life-shop-email',
        {},
        {
          headers: {
            'Email-Auth': emailAuth as string,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const me = createAsyncThunk(EUser.getUser, async (_, thunkAPI) => {
  try {
    const userResult = await instance.get<IUser>('auth/me');
    if (userResult.data.role.name !== EUserRole.SUPER_ADMIN) {
      thunkAPI.dispatch(loginChatUser(idToUid(userResult.data.id)));
    }
    return userResult.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

const changePassword = createAsyncThunk(
  EUser.changePassword,
  async (changePassData: IChangePassword, thunkAPI) => {
    try {
      const response = await instance.patch<ISuccessResponce>(
        'auth/password-reset',
        changePassData
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const updateMe = createAsyncThunk(
  EUser.changeUserData,
  async (userData: IUserUpdate, thunkAPI) => {
    try {
      const { user } = thunkAPI.getState() as RootState;
      const { role, id } = user.user as IUser;
      const userResult = await instance.patch<IUser>(
        getUsersUrlByRoleName(role.name, id),
        userData
      );
      return userResult.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const updateNotification = createAsyncThunk(
  EUser.updateUserNotification,
  async (userData: IUpdateNotificationSettings, thunkAPI) => {
    try {
      const { user } = thunkAPI.getState() as RootState;
      const { role } = user.user as IUser;
      const userResult = await instance.patch<IUser>(
        getUsersUrlByRoleName(role.name, userData.id),
        userData
      );
      return userResult.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const uploadAvatar = createAsyncThunk(
  EUser.uploadAvatar,
  async (blob: Blob, thunkAPI) => {
    try {
      const { user } = thunkAPI.getState() as RootState;
      const { role, id } = user.user as IUser;
      const formData = new FormData();
      formData.append('profile_image', blob as Blob, 'avatar_image.jpeg');
      const response = await instance.post<IUser>(
        `${getUsersUrlByRoleName(role.name, id)}/image`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const deleteAvatar = createAsyncThunk(
  EUser.deleteAvatar,
  async (_, thunkAPI) => {
    try {
      const { user } = thunkAPI.getState() as RootState;
      const { role, id } = user.user as IUser;
      const response = await instance.delete<IUser>(
        `${getUsersUrlByRoleName(role.name, id)}/image`
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getMyCompany = createAsyncThunk(
  EUser.getMyCompany,
  async (companyId: number, thunkAPI) => {
    try {
      const { user } = thunkAPI.getState() as RootState;
      const { role } = user.user as IUser;
      const companyUrl =
        role.name === EUserRole.TEAMLEAD ? 'team-admin' : 'users';
      const response = await instance.get<ICompany>(
        `${companyUrl}/companies/${companyId}`
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export {
  login,
  me,
  logout,
  forgotPassword,
  resetPassword,
  confirmWorkLifeShopEmail,
  changePassword,
  updateMe,
  updateNotification,
  uploadAvatar,
  deleteAvatar,
  getMyCompany,
};

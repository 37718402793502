import React from 'react';

function DesignTeam() {
  return (
    <div>
      <h1>DesignTeam</h1>
    </div>
  );
}

export default DesignTeam;

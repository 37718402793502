import { Box, Button, OutlinedInput, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';

export const CompanyPointSection = styled('section')`
  padding: ${({ theme }) => theme.spacing(12, 2)};
`;

export const CompanyPointsTitle = styled(Typography)`
  color: #5d6081;
  text-align: center;
  font-weight: 500;
`;

export const NameWrapper = styled(Box)`
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  max-width: 200px;
`;

export const CompanyPointValue = styled(Box)`
  background: #dfeafa;
  border-radius: 8px;
  font-size: 36px;
  font-weight: bold;
  color: #5d6081;
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const CompanyPointManagmentSection = styled('section')`
  margin: 0 auto 150px;
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: 16px;
  padding: ${({ theme }) => theme.spacing(2, 8)};
`;

export const CompanyPointManagmentContainer = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 24px 64px rgba(0, 5, 62, 0.15);
  border-radius: 16px;
  padding: ${({ theme }) => theme.spacing(7, 9, 12)};
  transform: translateY(${({ theme }) => theme.spacing(15)});
`;

export const CompanyPointManagmentTitleContainer = styled(Box)`
  display: flex;
`;
export const CompanyPointManagmentTitleBlock = styled(Box)`
  display: flex;
  flex-grow: 3;
  padding-right: 116px;
`;

export const CompanyPointManagmentTitle = styled(Typography)`
  font-size: 16px;
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  text-align: center;
  width: 100%;
`;

export const CompanyPointManagmentTable = styled(Box)``;

export const CompanyPointOutlinedField = styled(OutlinedInput)`
  & .MuiOutlinedInput-input {
    background-color: ${({ theme }) => theme.palette.background.default};
  }
`;

export const UpdateAvailableButton = styled(Button)`
  background-color: #e0f3f6;
  color: #0099ff;
  border-radius: 8px;
  padding: 0 15px;

  &:hover {
    background-color: #c2d2e1;
  }

  & svg {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
`;

export const PointsManagementTable = styled(DataGrid)`
  &.MuiDataGrid-root {
    border: none;

    & .MuiDataGrid {
      &-columnHeader {
        color: #7e84a6;
        font-weight: 500;

        &.total {
          background-color: #e4f4fe;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
        }

        &:focus,
        &:focus-within {
          outline: none;
        }
      }

      &-columnSeparator {
        display: none;
      }

      &-virtualScrollerRenderZone {
        // row-gap: 20px;
      }

      &-row {
        &:last-child {
          .total {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
          }
        }

        &.Mui-selected,
        &:hover {
          background-color: unset;
        }
      }

      &-cell {
        border-bottom: none;
        padding: 0;

        &.total {
          background-color: #e4f4fe;
          padding-left: 12px;
        }

        &.MuiDataGrid-cell--editable {
          .MuiDataGrid-cellContent {
            border: 1px solid #2fd60a;
            background: white;
          }

          &.MuiDataGrid-cell--editing {
            & .MuiDataGrid-editInputCell {
              border: 1px solid #2fd60a;
              background: white;
            }
          }
        }

        &:focus {
          outline: none;
        }

        & .MuiDataGrid-cellContent {
          width: 130px;
          text-align: center;
          padding: 12px 0;
          background-color: ${({ theme }) => theme.palette.background.paper};
          border-radius: 4px;
        }

        &.MuiDataGrid-cell--editing {
          box-shadow: none;
          background-color: unset;

          &:focus-within {
            outline: none;
          }

          & .MuiDataGrid-editInputCell {
            width: 130px;
            text-align: center;
            padding: 12px 0;
            background-color: ${({ theme }) => theme.palette.background.paper};
            border-radius: 4px;
          }
        }
      }
    }
  }
`;

import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { me } from '@/redux/actions/user';
import { setLoading } from '@/redux/reducers/user';
import { getUserLoaded } from '@/redux/selectors/userSelectors';
import { ELocalStoreKeys } from '@/types/consts';
import { messageCenterInit } from '@/utils/messagesInit';
import { CircularProgress } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const userLoaded = useAppSelector(getUserLoaded);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  useEffect(() => {
    const token = localStorage.getItem(ELocalStoreKeys.ACCESS_TOKEN);
    if (token) {
      messageCenterInit(() => dispatch(me()));
    } else {
      messageCenterInit(() => dispatch(setLoading(false)));
    }
  }, [dispatch]);

  useEffect(() => {
    if (userLoaded && initialLoading) {
      setInitialLoading(false);
    }
  }, [userLoaded, initialLoading]);

  if (!userLoaded && initialLoading) {
    return <CircularProgress />;
  }

  return <>{children}</>;
};

export default AuthProvider;

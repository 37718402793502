import SearchToolsTabs from '@/components/admin/SearchToolsTabs';
import {
  Main,
  UsersGrid,
  UsersTableSection,
} from '@/components/admin/user/components';
import CreateUserButtonComponent from '@/components/admin/user/CreateUserButton';
import SearchBar from '@/components/admin/user/SearchBar';
import UsersTable from '@/components/admin/user/UsersTable';
import AddTeammembersModal from '@/components/team-admin/AddTeammembersModal';
import { Container } from '@mui/material';
import React from 'react';

const Users = () => {
  return (
    <Main>
      <UsersGrid>
        <CreateUserButtonComponent />
        <AddTeammembersModal />
        <SearchToolsTabs />
      </UsersGrid>
      <SearchBar />

      <UsersTableSection>
        <Container maxWidth="xl" sx={{ width: '95%' }}>
          <UsersTable />
        </Container>
      </UsersTableSection>
    </Main>
  );
};

export default Users;

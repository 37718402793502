import { ProgressiveImage } from '@/components/icons';
import useAppSelector from '@/hooks/useAppSelector';
import { getUserSelector } from '@/redux/selectors/userSelectors';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const ProgressiveInfo = styled(Grid)``;

const CompanyNameContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  miw-width: 100px;
  padding: ${({ theme }) => theme.spacing(3, 2)};
  border-radius: ${({ theme }) => theme.spacing(2, 2, 0, 0)};
  background-color: #5596fc;
`;

const CompanyName = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  text-transform: uppercase;
`;

const Greetings = styled(Typography)`
  align-self: center;
  margin-left: ${({ theme }) => theme.spacing(2)};
  margin-right: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.header.info.greetings.color};
`;

const UserName = styled(Typography)`
  align-self: center;
  color: ${({ theme }) => theme.palette.common.white};
`;

const HeaderUserInfo = () => {
  const user = useAppSelector(getUserSelector);

  return (
    <ProgressiveInfo container alignItems={'end'} wrap={'nowrap'}>
      {user?.companies[0]?.name ? (
        <CompanyNameContainer>
          <CompanyName variant={'body1'}>{user.companies[0].name}</CompanyName>
        </CompanyNameContainer>
      ) : (
        <ProgressiveImage />
      )}
      <Greetings>Welcome</Greetings>
      <UserName>{user?.firstName}</UserName>
    </ProgressiveInfo>
  );
};

export default HeaderUserInfo;

import {
  IUpdateUsersAvailablePoints,
  IUpdateUsersGiftedPoints,
} from '@/types/models';
import { Button, Grid } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { FC } from 'react';
import { UpdateAvailableButton } from '../team-admin/points-managment/components';

interface ICustomFooter {
  rows: Array<any>;
  editedAvailablePointsRows: any;
  editedGiftedPointsRows: any;
  needReset?: boolean;
  onUpdatedAvailablePoints: (
    preparedRows: Array<IUpdateUsersAvailablePoints>
  ) => void;
  onGiftedPoints: (preparedRows: Array<IUpdateUsersGiftedPoints>) => void;
}

const CompanyPointsFooter: FC<ICustomFooter> = ({
  rows,
  editedAvailablePointsRows,
  editedGiftedPointsRows,
  onUpdatedAvailablePoints,
  onGiftedPoints,
}) => {
  const apiRef = useGridApiContext();

  const editedRowsMapper = (editedRows: any) =>
    Object.entries(editedRows).map(mapCallback);

  const mapCallback = ([key, value]: any) => {
    const columnUserIdState = apiRef.current.getCellValue(key, 'id');

    return {
      userId: columnUserIdState as number,
      ...(value as object),
    };
  };

  const handleAvailableClick = () => {
    const finalEditedData = {
      ...editedAvailablePointsRows,
    };

    rows.forEach((row: any) => {
      if (apiRef.current.getCellMode(row.id, 'availablePoints') !== 'edit') {
        return;
      }

      apiRef.current.commitCellChange({
        id: row.id,
        field: 'availablePoints',
      });

      finalEditedData[row.id] = {
        availablePoints: apiRef.current.getCellValue(row.id, 'availablePoints'),
      };
    });

    const mappedEditedRows = editedRowsMapper(
      finalEditedData
    ) as Array<IUpdateUsersAvailablePoints>;
    onUpdatedAvailablePoints(mappedEditedRows);
  };

  const handleGiftedClick = () => {
    const finalEditedData = {
      ...editedGiftedPointsRows,
    };

    rows.forEach((row: any) => {
      if (apiRef.current.getCellMode(row.id, 'points') !== 'edit') {
        return;
      }
      apiRef.current.commitCellChange({
        id: row.id,
        field: 'points',
      });

      finalEditedData[row.id] = {
        points: apiRef.current.getCellValue(row.id, 'points'),
      };
    });

    onGiftedPoints(
      editedRowsMapper(finalEditedData) as Array<IUpdateUsersGiftedPoints>
    );
  };

  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent={'center'}
      flexWrap={'nowrap'}
      mt={4}
    >
      <Grid item flex={1.5}></Grid>
      <Grid item flex={1} container justifyContent={'center'}>
        <UpdateAvailableButton
          sx={{ width: 130, fontSize: 12 }}
          onClick={handleAvailableClick}
        >
          Confirm Available
        </UpdateAvailableButton>
      </Grid>

      <Grid item flex={1}></Grid>
      <Grid item flex={1} container justifyContent={'center'}>
        <Button
          color={'secondary'}
          onClick={handleGiftedClick}
          sx={{ width: 130, fontSize: 12 }}
        >
          Confirm Gifted
        </Button>
      </Grid>
      <Grid item flex={2}></Grid>
    </Grid>
  );
};

export default CompanyPointsFooter;

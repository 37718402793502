import React, { FC, useCallback } from 'react';
import {
  ImagePreviewCover,
  PinnedMessageContainer,
  Spliter,
} from '../components';
import { IImageData, IPinnedMessage, IUser } from '@/types/models';
import { EMessageType, EUserRole } from '@/types/consts';
import { Box } from '@mui/material';
import useAppSelector from '@/hooks/useAppSelector';
import { pinnedMessageSelector } from '@/redux/selectors/chatSelector';
import { CloseIcon } from '@/components/icons';
import useAuth from '@/hooks/useAuth';
import useAppDispatch from '@/hooks/useAppDispatch';
import { unpinMessage } from '@/redux/actions/chat';

interface IPinnedMessageProps {
  onPinnedMessageClick: () => void;
}

const PinnedMessage: FC<IPinnedMessageProps> = ({ onPinnedMessageClick }) => {
  const { data, type, id } = useAppSelector(
    pinnedMessageSelector
  ) as IPinnedMessage;
  const user = useAuth().user as IUser;
  const dispatch = useAppDispatch();

  const unpinMsg = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      dispatch(unpinMessage({ messageId: +id }));
    },
    []
  );

  return (
    <PinnedMessageContainer
      display="flex"
      alignItems="center"
      onClick={() => onPinnedMessageClick()}
    >
      <Spliter />
      {type === EMessageType.IMAGE && (
        <Box height="32px" width="32px" mr="8px">
          <ImagePreviewCover src={(data as IImageData).url} />
        </Box>
      )}
      <Box>Click here for Coach’s latest message</Box>
      {user.role.name === EUserRole.RW_ADMIN && (
        <Box ml="auto" onClick={(e) => unpinMsg(e)}>
          <CloseIcon />
        </Box>
      )}
    </PinnedMessageContainer>
  );
};

export default PinnedMessage;

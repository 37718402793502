import SidebarNavList from '@/components/sidebar/SidebarNavList';
import useAppSelector from '@/hooks/useAppSelector';
import { selectState } from '@/redux/selectors/componentsManage';
import { SidebarPageType } from '@/types/sidebar';

import { Box, BoxProps, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import WorkLifeNavItem from './WorkLifeNavItem';

const NavSection = styled(Box)<BoxProps>`
  margin: ${(props) => props.theme.spacing(4, 0)};
`;

const Title = styled(Typography)<{ open: boolean }>`
  display: block;
  padding: ${({ theme }) => theme.spacing(0, 5)};
  color: ${({ theme }) => theme.sidebar.color};
  font-size: ${({ theme }) => theme.typography.fontSize};
  text-align: ${({ open }) => (open ? 'left' : 'center')};
  transition: all 0.3s ease;
`;

type SidebarNavSectionProps = {
  pages: SidebarPageType[];
  title: string;
  withWorkShop?: boolean;
};

const SidebarNavSection: React.FC<SidebarNavSectionProps> = ({
  title,
  pages,
  withWorkShop,
}) => {
  const open = useAppSelector(selectState);

  return (
    <NavSection component={'section'}>
      <Title variant="subtitle2" open={open}>
        {title}
      </Title>
      <SidebarNavList pages={pages} />
      {withWorkShop && <WorkLifeNavItem />}
    </NavSection>
  );
};

export default SidebarNavSection;

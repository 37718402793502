import {
  CalendarIconWrapper,
  FormControlWrapper,
  FormTitle,
} from '@/components/form-elements/components';
import { PhoneNumber } from '@/components/form-elements/PhoneNumber';
import { AvatarType, CustomAvatar } from '@/components/UI/CustomAvatar';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import { updateMe } from '@/redux/actions/user';
import { updateUser } from '@/redux/actions/users';
import { getUserSelector } from '@/redux/selectors/userSelectors';
import { DEFAULT_TOAST_TIME } from '@/types/consts';
import { IUser, IUserUpdate } from '@/types/models';
import { inputDateFormater, parseBackDate } from '@/utils/dateHelpers';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { isAfter, isBefore } from 'date-fns';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
  ErrorWrapper,
  OptBlockContainer,
  OptContextContainer,
  ProfileFormContainer,
  SwitchContainer,
} from './components';
import { numberOfDaysInMonth } from '@/utils/birthdayHelpers';
import Switch from '@/components/UI/Switch';
import { SwitchLabel } from '@/components/user-profile/notification-settings/components';
import moment from 'moment';

export interface IUserProfile extends IUserUpdate {
  email: string;
  company: string;
}

interface IUserProfileForm extends IUserProfile {
  birthdayDay: number | null;
  birthdayMonth: number | null;
}

const ProfileSchema = Yup.object().shape({
  title: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
  jobTitle: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
  phone: Yup.string()
    .min(10, 'Incomplete phone number')
    .max(10, 'Incorrect phone number'),
  birthdayDay: Yup.number()
    .optional()
    .nullable()
    .positive('Date must be a positive number')
    .integer()
    .max(31, 'Invalid date!'),
  birthdayMonth: Yup.number()
    .optional()
    .nullable()
    .positive('Month must be a positive number')
    .integer()
    .max(12, 'Month cannot be more than 12'),
  startWorkDate: Yup.string()
    .optional()
    .nullable()
    .test(
      'maxDate',
      'Incorrect date',
      (value) => !value || isBefore(parseBackDate(value) as Date, new Date())
    )
    .test(
      'minDate',
      'Incorrect date',
      (value) =>
        !value || isAfter(parseBackDate(value) as Date, new Date(1900, 1, 1))
    ),
  // WILL BE LATER
  // streetAddress: Yup.string().required('Required'),
  // streetAddress2: Yup.string().optional(),
  // city: Yup.string().required('Required'),
  // stateId: Yup.number().optional(),
  // countryId: Yup.number()
  //   .positive()
  //   .min(1, 'Select country')
  //   .required('Required'),
  // zipCode: Yup.string().optional(),
});

const ProfileForm: React.FC = () => {
  const {
    id,
    firstName,
    lastName,
    title,
    email,
    phone,
    birthday,
    startWorkDate,
    companies,
    rwCompanies,
    optPhone,
    optBirthdayHireday,
    jobTitle,
    registrationAt,
    // streetAddress,
    // streetAddress2,
    // city,
    // countryId,
    // stateId,
    // zipCode,
    // workLifeShopEmail,
    // workLifeShopEmailConfirm,
    role,
  } = useAppSelector(getUserSelector) as IUser;
  const users = useAppSelector((state) => state.user.myTeam);
  const teamAdmin = users?.find(
    (one) =>
      one.UsersCompanies?.companyRoleId === 3 &&
      one.UsersCompanies?.companyId === companies[0].id
  );

  const { isUser } = useAuth();
  const dispatch = useAppDispatch();

  const companiesNames = rwCompanies[0]
    ? rwCompanies.map((item) => item.name).join(', ')
    : companies.length > 1
    ? companies.map((item) => item.name).join(', ')
    : companies[0]?.name;

  const isFirstDay = moment(registrationAt).isSame(moment(), 'day');

  const initialValues = {
    id,
    firstName: firstName || '',
    lastName: lastName || '',
    title: title || '',
    jobTitle: jobTitle || '',
    email,
    phone: phone || '',
    optPhone: isFirstDay ? true : optPhone,
    birthday: birthday || null,
    birthdayDay: birthday ? new Date(birthday).getDate() : null,
    birthdayMonth: birthday ? new Date(birthday).getMonth() + 1 : null,
    optBirthdayHireday: isFirstDay ? true : optBirthdayHireday,
    startWorkDate: startWorkDate || null,
    company: companiesNames || 'UNKNOWN COMPANY',
    // streetAddress: streetAddress || '',
    // streetAddress2: streetAddress2 || '',
    // city: city || '',
    // countryId: countryId || 0,
    // stateId: stateId || 0,
    // zipCode: zipCode || '',
    // workLifeShopEmail: workLifeShopEmail || '',
  } as IUserProfileForm;

  const onProfileSave = useCallback(
    (values: IUserProfileForm, actions: FormikHelpers<IUserProfileForm>) => {
      if (isUser) {
        dispatch(
          updateMe({
            ...values,
            stateId: values.stateId || null,
            birthday: values.birthday || null,
            startWorkDate: values.startWorkDate || null,
          })
        )
          .unwrap()
          .then(() => {
            toast.success('Your profile has been updated', {
              position: 'top-right',
              autoClose: DEFAULT_TOAST_TIME,
              hideProgressBar: false,
            });
          })
          .catch(({ error }: any) => {
            console.error(error);
            toast.error(`${error}`, { position: 'top-right' });
          })
          .finally(() => {
            actions.setSubmitting(false);
            actions.resetForm({ values });
          });
      } else {
        dispatch(
          updateUser({
            ...values,
            stateId: values.stateId || null,
            birthday: values.birthday || null,
            startWorkDate: values.startWorkDate || null,
          })
        )
          .unwrap()
          .then(() =>
            toast.success('Profile has been updated', {
              position: 'top-right',
              autoClose: DEFAULT_TOAST_TIME,
              hideProgressBar: false,
            })
          )
          .catch(({ error }: any) => {
            console.error(error);
            toast.error(`${error}`, { position: 'top-right' });
          })
          .finally(() => {
            actions.setSubmitting(false);
            actions.resetForm({ values });
          });
      }
    },
    [dispatch, isUser]
  );

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={ProfileSchema}
        validate={({
          birthdayDay,
          birthdayMonth,
          phone,
          optPhone,
          optBirthdayHireday,
          startWorkDate,
        }) => {
          const errors: FormikErrors<IUserProfileForm> = {};
          if (
            !!birthdayDay &&
            !!birthdayMonth &&
            birthdayDay > numberOfDaysInMonth(birthdayMonth)
          ) {
            errors.birthdayDay = 'Date does not exist!';
          }

          if (
            (!!birthdayDay && !birthdayMonth) ||
            (!!birthdayMonth && !birthdayDay)
          ) {
            errors.birthdayDay = 'Invalid date!';
          }

          if (optPhone && !phone) {
            errors.optPhone = 'Please fill in a phone number to opt in.';
          }

          if (
            optBirthdayHireday &&
            !birthdayDay &&
            !birthdayMonth &&
            !startWorkDate
          ) {
            errors.optBirthdayHireday =
              'Please fill in birthday and hire date to opt in.';
          }

          return errors;
        }}
        onSubmit={(values, actions) => {
          const { birthdayDay, birthdayMonth } = values;
          const birthday =
            !!birthdayDay && !!birthdayMonth
              ? (inputDateFormater(
                  new Date(1900, birthdayMonth - 1, birthdayDay)
                ) as string)
              : null;
          onProfileSave({ ...values, birthday }, actions);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue,
          dirty,
        }) => (
          <ProfileFormContainer onSubmit={handleSubmit}>
            <FormTitle>My Information</FormTitle>
            <FormControlWrapper>
              <InputLabel>First Name</InputLabel>
              <OutlinedInput
                name="firstName"
                placeholder="First Name*"
                value={values.firstName}
                disabled
              />
            </FormControlWrapper>
            <FormControlWrapper>
              <InputLabel>Last Name</InputLabel>
              <OutlinedInput
                name="lastName"
                placeholder="Last Name*"
                value={values.lastName}
                disabled
              />
            </FormControlWrapper>
            <FormControlWrapper>
              <InputLabel>
                {role.name === 'rw_admin' ? 'My Accounts' : 'Company'}
              </InputLabel>
              <OutlinedInput
                name="company"
                placeholder="Company"
                value={values.company}
                disabled
              />
            </FormControlWrapper>
            <FormControlWrapper>
              <InputLabel>Company Admin</InputLabel>
              <OutlinedInput
                name="teamAdmin"
                placeholder="Company Admin"
                value={
                  teamAdmin
                    ? `${teamAdmin.firstName + ' ' + teamAdmin.lastName}`
                    : ''
                }
                disabled
                startAdornment={
                  <InputAdornment position="start" sx={{ paddingRight: '5px' }}>
                    <CustomAvatar
                      diameter={24}
                      avatartype={AvatarType.USER}
                      alt="Remy Sharp"
                      src={teamAdmin?.profileImage || undefined}
                    >
                      {getUserAvatarLetter({
                        firstName: teamAdmin?.firstName,
                        lastName: teamAdmin?.lastName,
                        email: teamAdmin?.email || '',
                      })}
                    </CustomAvatar>
                  </InputAdornment>
                }
              />
            </FormControlWrapper>
            <FormControlWrapper>
              <InputLabel>Platform Email</InputLabel>
              <OutlinedInput
                type="email"
                name="email"
                placeholder="Platform Email"
                value={values.email}
                disabled
              />
            </FormControlWrapper>
            <FormControlWrapper>
              <InputLabel>Work+Life Shop Email</InputLabel>
              <OutlinedInput
                name="workLifeShopEmail"
                placeholder="Work+Life Shop Email"
                value={values.email}
                disabled
              />
            </FormControlWrapper>

            <FormControlWrapper
              hiddenLabel={!values.jobTitle}
              error={!!errors.jobTitle && !!touched.jobTitle}
            >
              {!!values.jobTitle && <InputLabel>Role or Job Title</InputLabel>}
              <OutlinedInput
                name="jobTitle"
                placeholder="Role or Job Title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.jobTitle}
                error={!!errors.jobTitle && !!touched.jobTitle}
              />
              {errors.jobTitle && touched.jobTitle && (
                <FormHelperText id="jobTitle" error>
                  {errors.jobTitle}
                </FormHelperText>
              )}
            </FormControlWrapper>

            <FormTitle>
              <OptBlockContainer>
                <Typography variant={'h1'}>Phone Number</Typography>
                <SwitchContainer>
                  <SwitchLabel>Opt-Out / Opt-In</SwitchLabel>
                  <Switch
                    name="optPhone"
                    checked={values.optPhone}
                    onChange={handleChange}
                  />
                </SwitchContainer>
              </OptBlockContainer>
            </FormTitle>
            <ErrorWrapper>
              {errors.optPhone && (
                <FormHelperText id="optPhone" error>
                  {errors.optPhone}
                </FormHelperText>
              )}
            </ErrorWrapper>
            <Typography variant="h6" my={5}>
              By providing your phone you are opting in to receive periodic
              reminders via text or phone.
            </Typography>
            <FormControlWrapper>
              <OptContextContainer>
                <Box width={150}>
                  <Typography variant="h6">My Phone Number</Typography>
                </Box>
                <OutlinedInput
                  name="phone"
                  placeholder="Phone Number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  error={!!errors.phone && !!touched.phone}
                  inputComponent={PhoneNumber as any}
                  sx={{ width: '70%' }}
                />
              </OptContextContainer>
              <ErrorWrapper>
                {errors.phone && touched.phone && (
                  <FormHelperText id="phone" error>
                    {errors.phone}
                  </FormHelperText>
                )}
              </ErrorWrapper>
            </FormControlWrapper>

            <FormTitle>
              <OptBlockContainer>
                <Typography variant={'h1'}>
                  Celebrate Your Milestones
                </Typography>
                <SwitchContainer>
                  <SwitchLabel>Opt-Out / Opt-In</SwitchLabel>
                  <Switch
                    name="optBirthdayHireday"
                    checked={values.optBirthdayHireday}
                    onChange={handleChange}
                  />
                </SwitchContainer>
              </OptBlockContainer>
            </FormTitle>
            <ErrorWrapper>
              {errors.optBirthdayHireday && (
                <FormHelperText id="optBirthdayHireday" error>
                  {errors.optBirthdayHireday}
                </FormHelperText>
              )}
            </ErrorWrapper>
            <Typography variant="h6" my={5}>
              By providing your birthday and hire date you are eligible to
              receive gifts and providing permission for RemoteWorx to announce
              and celebrate your birthday.
            </Typography>
            <FormControlWrapper>
              <OptContextContainer>
                <Box width={150}>
                  <Typography variant="h6">My Birthday</Typography>
                </Box>
                <Box display="flex" minWidth={'70%'} justifyContent={'center'}>
                  <Box
                    display="flex"
                    flexDirection={'column'}
                    minWidth={80}
                    alignItems={'center'}
                  >
                    <InputLabel>Month</InputLabel>
                    <OutlinedInput
                      name="birthdayMonth"
                      type="number"
                      sx={{
                        maxWidth: 64,
                        '.MuiOutlinedInput-input': { textAlign: 'center' },
                      }}
                      placeholder={'MM'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.birthdayMonth}
                    />
                  </Box>

                  <Box
                    display="flex"
                    flexDirection={'column'}
                    minWidth={80}
                    alignItems={'center'}
                  >
                    <InputLabel>Day</InputLabel>
                    <OutlinedInput
                      name="birthdayDay"
                      type="number"
                      sx={{
                        maxWidth: 64,
                        '.MuiOutlinedInput-input': { textAlign: 'center' },
                      }}
                      placeholder={'DD'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.birthdayDay}
                    />
                  </Box>
                </Box>
              </OptContextContainer>
              <ErrorWrapper>
                {(!!errors.birthdayDay || !!errors.birthdayMonth) &&
                  !!touched.birthdayDay &&
                  !!touched.birthdayMonth && (
                    <FormHelperText id="birthdayDay" error>
                      {errors.birthdayDay
                        ? errors.birthdayDay
                        : errors.birthdayMonth}
                    </FormHelperText>
                  )}
              </ErrorWrapper>
            </FormControlWrapper>
            <FormControlWrapper
              error={!!errors.startWorkDate && !!touched.startWorkDate}
            >
              <OptContextContainer>
                <Box width={150}>
                  <Typography variant="h6">My Hire Date</Typography>
                </Box>
                <DatePicker
                  value={values.startWorkDate}
                  onChange={(value: Date | null, keyboardInputValue) => {
                    if (
                      !keyboardInputValue ||
                      keyboardInputValue.length === 10
                    ) {
                      setFieldValue(
                        'startWorkDate',
                        inputDateFormater(value),
                        true
                      );
                    }
                  }}
                  InputProps={{
                    placeholder: 'Company Hire Date (Optional)',
                  }}
                  components={{
                    OpenPickerIcon: () => <CalendarIconWrapper />,
                  }}
                  OpenPickerButtonProps={{ disableRipple: true }}
                  maxDate={new Date()}
                  minDate={new Date('01/01/2000')}
                  renderInput={(params) => (
                    <OutlinedInput
                      placeholder={params.InputProps?.placeholder}
                      onChange={params.inputProps?.onChange}
                      value={params.inputProps?.value}
                      endAdornment={params.InputProps?.endAdornment}
                      inputRef={params.inputRef}
                      error={!!errors.startWorkDate && touched.startWorkDate}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
                {errors.startWorkDate && touched.startWorkDate && (
                  <FormHelperText id="startWorkDate" error>
                    {errors.startWorkDate}
                  </FormHelperText>
                )}
              </OptContextContainer>
            </FormControlWrapper>

            <Grid container justifyContent={'center'}>
              <Button
                variant="outlined"
                type="submit"
                disabled={!(dirty && initialValues !== values && !isSubmitting)}
                color="secondary"
              >
                Save
              </Button>
            </Grid>
          </ProfileFormContainer>
        )}
      </Formik>
    </div>
  );
};

export default ProfileForm;

import { ProgressContainer } from '@/components/admin/user/components';
import KnolwledgeCenterProggress from '@/components/admin/user/KnolwledgeCenterProggress';
import ActivityBreakdownWrapper from '@/components/progressMeter/ActivityBreakdown/ActivityBreakDownWrapper';
import {
  GeneralInfoBlock,
  MessageStatTitle,
  ProgressMeterContainer,
  ProgressMeterInfo,
  StatLabel,
  StatValue,
} from '@/components/progressMeter/components';
import PersonalActivity from '@/components/progressMeter/PersonalActivity/PersonalActivity';
import ProgressMeter from '@/components/progressMeter/ProgressMeter/ProgressMeter';
import {
  Days,
  StreakDays,
} from '@/components/progressMeter/RewardPoints/components';
import WorkSpaceProgress from '@/components/progressMeter/WorkSpaceProgress';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useGetWorkspaceProgress from '@/hooks/useGetWorkspaceProgress';
import { TabsContainer } from '@/pages/dashboard/ProgressMeterWidget/components';
import {
  getUserById,
  getUserDailyStreak,
  getUserMessagesStat,
  getUserProgress,
} from '@/redux/actions/users';
import {
  adminSelectedUser,
  adminUserDailyStreak,
  adminUserMessagingStats,
  adminUserProgressSelector,
} from '@/redux/selectors/users';
import { EActivityLevel, EPeriod } from '@/types/consts';
import { IUser } from '@/types/models';
import { getEndOfWeek, getStartOfWeek } from '@/utils/dateHelpers';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Tab,
  Tabs,
} from '@mui/material';
import { format, formatDuration } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { numberWithCommas } from '../company/helpers';

const UserActivity = () => {
  const { userId } = useParams<{ userId: string }>();
  const user = useAppSelector((state) => state.users.user);
  const userProgress = useAppSelector(adminUserProgressSelector);
  const dailyStreak = useAppSelector(adminUserDailyStreak);
  const messageStat = useAppSelector(adminUserMessagingStats);
  const { totalLength, currentLength, countCategories } =
    useGetWorkspaceProgress(+userId!);
  const dispatch = useAppDispatch();
  const [period, setPeriod] = useState<EPeriod>(EPeriod.DAY);

  useEffect(() => {
    if (!userId) {
      return;
    }
    if (!user) {
      dispatch(getUserById(+userId));
    }
  }, [user, userId]);

  useEffect(() => {
    if (user) {
      dispatch(
        getUserProgress({
          from: format(new Date(), 'yyyy-MM-dd'),
          to: format(new Date(), 'yyyy-MM-dd'),
          dataLevel: EActivityLevel.DYNAMIC,
          userId: user?.id,
        })
      );
      dispatch(
        getUserDailyStreak({
          from: format(new Date(), 'yyyy-MM-dd'),
          to: format(new Date(), 'yyyy-MM-dd'),
          userId: user?.id,
        })
      );
      dispatch(getUserMessagesStat(user?.id));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (user) {
      const start =
        period === EPeriod.WEEK ? getStartOfWeek(new Date()) : new Date();
      const end =
        period === EPeriod.WEEK ? getEndOfWeek(new Date()) : new Date();
      const progressParams = {
        from: format(start, 'yyyy-MM-dd'),
        to: format(end, 'yyyy-MM-dd'),
        dataLevel: EActivityLevel.DYNAMIC,
      };
      if (userId) {
        dispatch(
          getUserProgress({
            ...progressParams,
            userId: +userId,
          })
        );
      }
    }
  }, [period, user]);

  if (!user || !userId) {
    return (
      <Box height="calc(100vh - 155px)">
        <Box
          minHeight="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <Container sx={{ py: 6 }}>
      <GeneralInfoBlock display="flex" alignItems="stretch">
        <ActivityBreakdownWrapper userId={user?.id} />
        <ProgressMeterInfo display="flex" flexDirection="column">
          <TabsContainer component={'section'}>
            <Tabs
              value={period}
              onChange={(e, value) => setPeriod(value)}
              centered
              textColor={'secondary'}
            >
              <Tab value={EPeriod.DAY} label="Day" />
              <Tab value={EPeriod.WEEK} label="Week" />
            </Tabs>
          </TabsContainer>
          <Box mt={2}>
            <ProgressMeterContainer alignSelf="center">
              <ProgressMeter
                social={userProgress.social}
                mental={userProgress.mental}
                physical={userProgress.physical}
              />
            </ProgressMeterContainer>
            <Box mt={4} alignSelf="flex-end" justifySelf="flex-end">
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <StreakDays>
                  100% Goal Reached
                  <Days>
                    {formatDuration({
                      days: dailyStreak?.totalDaysCompleted,
                    }) || '0 days'}
                  </Days>
                </StreakDays>
                <StreakDays>
                  Longest Consecutive Streak
                  <Days>
                    {formatDuration({
                      days: dailyStreak?.maxDaysInARowCompleted,
                    }) || '0 days'}
                  </Days>
                </StreakDays>
              </Box>
            </Box>
          </Box>
        </ProgressMeterInfo>
      </GeneralInfoBlock>
      <PersonalActivity userId={user?.id} />
      <ProgressContainer container flexWrap={'nowrap'} columnGap={12}>
        <Grid item md={6}>
          <KnolwledgeCenterProggress />
        </Grid>
        <Grid item md={6}>
          <WorkSpaceProgress from={20} completed={countCategories} />
        </Grid>
      </ProgressContainer>
      {messageStat && (
        <Box paddingX="50px">
          <MessageStatTitle>Messaging</MessageStatTitle>
          <Box display="flex">
            <Box display="flex">
              <StatLabel>Messages Sent This Week</StatLabel>
              <StatValue>{numberWithCommas(messageStat.weekCount)}</StatValue>
            </Box>
            <Box display="flex" marginLeft="30px">
              <StatLabel>Messages Sent This Month</StatLabel>
              <StatValue>{numberWithCommas(messageStat.monthCount)}</StatValue>
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default UserActivity;

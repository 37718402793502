import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from '@mui/material';

const CircularProgressWithLabel = (
  props: CircularProgressProps & { value?: number }
) => {
  const { value = 2 } = props;
  return (
    <Box className="spinner">
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {value && (
          <Typography
            variant="h2"
            component="div"
            color="text.secondary"
          >{`${Math.round(value)}%`}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;

import {
  EActionsBreakTimerStatus,
  EBrakeTimerState,
  EBrakeTimerTypes,
  EExtraBrakeTimerTypes,
  TBreaktimerSettings,
} from '@/components/breaktimer/constants';
import {
  changeBreaktimerSettings,
  completeBreakTimer,
  completeExtraBreakTimer,
  getBrakeTimersData,
  preCompleteBreakTimer,
  setBreakTimerStateAction,
  setCountdown,
  updateBreakTimer,
} from '@/redux/actions/breaktimers';
import { adaptBreakTimerSettings } from '@/redux/reducers/functions/breakTimerSettingsFunc';
import {
  bonusBreakTimerAdapterFunction,
  breakTimerAdapterFunction,
} from '@/redux/reducers/functions/functions';
import { createSlice } from '@reduxjs/toolkit';

export interface BreakTimerState {
  state: EBrakeTimerState;
  done: boolean;
  nextActivation: string;
  todaySuccessCount: number;
  timePointsCount: number;
  actionsCount: number;
  timePoints: number[];
  actionsStatuses: EActionsBreakTimerStatus[];
  countdown: number;
  allFailed: boolean;
}

const BreakTimerInitialState = {
  state: EBrakeTimerState.INACTIVE,
  done: false,
  nextActivation: '00:00 am',
  todaySuccessCount: 0,
  timePointsCount: 2,
  actionsCount: 0,
  timePoints: [],
  actionsStatuses: [],
  countdown: 0,
  allFailed: false,
};

export interface BreakTimersState {
  [EBrakeTimerTypes.HYDRATE]: BreakTimerState;
  [EBrakeTimerTypes.MOVE]: BreakTimerState;
  [EBrakeTimerTypes.STRETCH]: BreakTimerState;
  [EBrakeTimerTypes.RELAX]: BreakTimerState;
  [EExtraBrakeTimerTypes.BONUS]: EActionsBreakTimerStatus | null;
  breaktimersSettings: TBreaktimerSettings | null;
  loading: boolean;
}

const initialState: BreakTimersState = {
  [EBrakeTimerTypes.HYDRATE]: BreakTimerInitialState,
  [EBrakeTimerTypes.MOVE]: BreakTimerInitialState,
  [EBrakeTimerTypes.STRETCH]: BreakTimerInitialState,
  [EBrakeTimerTypes.RELAX]: BreakTimerInitialState,
  [EExtraBrakeTimerTypes.BONUS]: null,
  breaktimersSettings: null,
  loading: false,
};

export const { actions, reducer } = createSlice({
  name: 'breakTimer',
  initialState,
  reducers: {
    resetBreakTimersState: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrakeTimersData.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }

        state[EBrakeTimerTypes.RELAX] = breakTimerAdapterFunction(
          payload,
          EBrakeTimerTypes.RELAX
        );
        state[EBrakeTimerTypes.MOVE] = breakTimerAdapterFunction(
          payload,
          EBrakeTimerTypes.MOVE
        );
        state[EBrakeTimerTypes.STRETCH] = breakTimerAdapterFunction(
          payload,
          EBrakeTimerTypes.STRETCH
        );
        state[EBrakeTimerTypes.HYDRATE] = breakTimerAdapterFunction(
          payload,
          EBrakeTimerTypes.HYDRATE
        );
        state[EExtraBrakeTimerTypes.BONUS] =
          bonusBreakTimerAdapterFunction(payload);

        state.breaktimersSettings = adaptBreakTimerSettings(
          payload.breakTimers
        );
      })
      .addCase(completeBreakTimer.fulfilled, (state, { payload, meta }) => {
        if (!payload) {
          return;
        }

        state[meta.arg] = breakTimerAdapterFunction(payload, meta.arg);
        state.loading = false;
      })
      .addCase(completeBreakTimer.pending, (state) => {
        state.loading = true;
      })
      .addCase(completeBreakTimer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(preCompleteBreakTimer.fulfilled, (state, { payload, meta }) => {
        if (!payload) {
          return;
        }

        state[meta.arg] = breakTimerAdapterFunction(payload, meta.arg);
        state.loading = false;
      })
      .addCase(preCompleteBreakTimer.pending, (state) => {
        state.loading = true;
      })
      .addCase(preCompleteBreakTimer.rejected, (state) => {
        state.loading = false;
      })
      .addCase(
        completeExtraBreakTimer.fulfilled,
        (state, { payload, meta }) => {
          state[meta.arg] = bonusBreakTimerAdapterFunction(payload);
        }
      )
      .addCase(updateBreakTimer.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }

        const { breakTimer, actions } = payload;

        state[breakTimer.type.name] = breakTimerAdapterFunction(
          {
            breakTimers: [breakTimer],
            actionsBreakTimers: actions,
          },
          breakTimer.type.name
        );
      })
      .addCase(setCountdown.fulfilled, (state, { payload }) => {
        if (!payload || !state[payload.type]) {
          return;
        }

        state[payload.type] = {
          ...state[payload.type],
          countdown: payload.countdown,
        };
      })
      .addCase(setBreakTimerStateAction.fulfilled, (state, { payload }) => {
        if (!payload || !state[payload.type]) {
          return;
        }

        state[payload.type] = {
          ...state[payload.type],
          state: payload.state,
          countdown: payload.countdown || state[payload.type].countdown,
        };
      })
      .addCase(changeBreaktimerSettings.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }

        state.breaktimersSettings = adaptBreakTimerSettings(payload);
      });
  },
});

export const { resetBreakTimersState } = actions;

export default reducer;

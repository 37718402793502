import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { BuildMyWorkspaceBlock } from '@/pages/dashboard/MyWorkspace/BuildMyWorkspace/component';
import OneCategory from '@/pages/dashboard/MyWorkspace/BuildMyWorkspace/OneCategory';
import { WorkspaceItem } from '@/pages/dashboard/MyWorkspace/types';
import { getDailyCheck } from '@/redux/actions/workSpace';
import { getUserSelector } from '@/redux/selectors/userSelectors';
import { allCategoriesSelector } from '@/redux/selectors/workspaceSelector';
import React, { useEffect, useState } from 'react';

interface BuildMyWorkspaceProps {
  state: WorkspaceItem[];
  setState: React.Dispatch<React.SetStateAction<WorkspaceItem[]>>;
  canEdit: boolean;
}

const BuildMyWorkspace: React.FC<BuildMyWorkspaceProps> = ({
  state,
  setState,
  canEdit,
}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUserSelector);
  const allCategories = useAppSelector(allCategoriesSelector);
  const [sorted, setSorted] = useState<
    { id: number; items: WorkspaceItem[] }[]
  >([]);
  useEffect(() => {
    const sortedByCategories = allCategories.map((category) => {
      return {
        id: category.id,
        items: state.filter((item) => item.category?.id === category.id),
      };
    });
    setSorted(sortedByCategories);
    canEdit && dispatch(getDailyCheck({ userId: user?.id }));
  }, [state]);

  return (
    <BuildMyWorkspaceBlock container>
      {sorted.map((oneCategory) => (
        <OneCategory
          key={oneCategory.id}
          oneCategory={oneCategory}
          setState={setState}
          canEdit={canEdit}
        />
      ))}
      <div className="item-empty"></div>
    </BuildMyWorkspaceBlock>
  );
};

export default BuildMyWorkspace;

import styled from 'styled-components';

interface MyWorkspaceBlockProps {
  overflow?: 'clip' | 'auto';
}

export const MyWorkspaceBlock = styled.div<MyWorkspaceBlockProps>`
  overflow-x: ${(props) => props.overflow};
  width: auto;
  background-color: ${({ theme }) => theme.palette.background.paper};
  overflow-y: hidden;

  .wrapper {
    position: relative;
    display: flex;
    align-items: start;
    justify-content: center;
  }

  .wrapper > div {
    position: absolute;
  }

  .wrapper > div > svg {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .wrapper > div:hover {
    filter: grayscale(0);
  }

  @media screen and (min-width: 320px) and (max-width: 480px) {
    .my-work-space {
      margin-top: -80px;
      min-height: 670px;
    }
  }
  @media screen and (min-width: 481px) and (max-width: 640px) {
    .my-work-space {
      margin-top: -90px;
      min-height: 680px;
    }
  }
  @media screen and (min-width: 641px) and (max-width: 754px) {
    .my-work-space {
      margin-top: -100px;
      min-height: 650px;
    }
  }
  @media screen and (min-width: 755px) and (max-width: 889px) {
    .my-work-space {
      margin-top: -100px;
      min-height: 620px;
    }
  }
  @media screen and (min-width: 890px) and (max-width: 960px) {
    .my-work-space {
      margin-top: -100px;
      min-height: 645px;
    }
    .work-space-dashboard {
      margin-top: -10px;
    }
  }
  @media screen and (min-width: 961px) and (max-width: 1054px) {
    .my-work-space {
      margin-top: -135px;
      min-height: 510px;
    }
    .work-space-dashboard {
      margin-top: -35px;
    }
  }
  @media screen and (min-width: 1055px) and (max-width: 1100px) {
    .my-work-space {
      margin-top: -145px;
      min-height: 580px;
    }
    .work-space-dashboard {
      margin-top: -45px;
    }
  }
  @media screen and (min-width: 1101px) and (max-width: 1190px) {
    .my-work-space {
      margin-top: -145px;
      min-height: 620px;
    }
    .work-space-dashboard {
      margin-top: -45px;
    }
  }
  @media screen and (min-width: 1191px) and (max-width: 1280px) {
    .my-work-space {
      margin-top: -145px;
      min-height: 680px;
    }
    .work-space-dashboard {
      margin-top: -45px;
    }
  }
  @media screen and (min-width: 1281px) and (max-width: 1370px) {
    .my-work-space {
      margin-top: -170px;
      min-height: 735px;
    }
    .work-space-dashboard {
      margin-top: -40px;
    }
  }
  @media screen and (min-width: 1371px) and (max-width: 1440px) {
    .my-work-space {
      margin-top: -170px;
      min-height: 800px;
    }
    .work-space-dashboard {
      margin-top: -50px;
    }
  }
  @media screen and (min-width: 1441px) and (max-width: 1534px) {
    .my-work-space {
      margin-top: -175px;
      min-height: 855px;
    }
    .work-space-dashboard {
      margin-top: -60px;
    }
  }
  @media screen and (min-width: 1535px) and (max-width: 1730px) {
    .my-work-space {
      margin-top: -180px;
      min-height: 930px;
    }
    .work-space-dashboard {
      margin-top: -80px;
    }
  }
  @media screen and (min-width: 1731px) and (max-width: 2699px) {
    .my-work-space {
      margin-top: -180px;
      min-height: 1040px;
    }
    .work-space-dashboard {
      margin-top: -90px;
    }
  }
  @media screen and (min-width: 2700px) {
    .my-work-space {
      margin-top: -180px;
      min-height: 1048px;
    }
    .work-space-dashboard {
      margin-top: -100px;
    }
  }
`;

import KnowledgeBasePreviewItem from '@/components/knowledgeBase/KnowledgeBasePreviewItem';
import { EKnowledgeBaseItemTypes } from '@/components/knowledgeBase/models';
import ColorfulHorizontalProgress from '@/components/progressMeter/ColorfulHorizontalProgress/ColorfulHorizontalProgress';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import {
  BarWrapper,
  KnowledgeBaseList,
  KnowledgeBaseRoot,
  KnowledgeBoldGreenText,
  KnowledgeText,
} from '@/pages/knowledge-base/components';
import { getKnowledgeBaseData } from '@/redux/actions/knowledge-base';
import { getKnowledgeBase } from '@/redux/selectors/knowledgeBaseSelector';
import { Box, Divider, Typography } from '@mui/material';
import React, { useEffect } from 'react';

function KnowledgeBase() {
  const items = useAppSelector(getKnowledgeBase);
  const dispatch = useAppDispatch();

  const videoItems = items?.filter(
    (item) => item.type === EKnowledgeBaseItemTypes.VIDEO
  );

  const guidesItems = items?.filter(
    (item) => item.type === EKnowledgeBaseItemTypes.GUIDE
  );

  useEffect(() => {
    dispatch(getKnowledgeBaseData());
  }, []);

  return (
    <KnowledgeBaseRoot>
      <Box>
        <Box display="flex">
          <Typography variant="h2">Knowledge Base</Typography>
          <BarWrapper>
            <ColorfulHorizontalProgress
              display="flex"
              current={items?.filter((item) => item.alreadyCompleted).length}
              max={items?.length}
            />
          </BarWrapper>
        </Box>
        <KnowledgeText>
          Welcome to the Knowledge Base. Here you’ll find the latest information
          to support your wellbeing.
          <Typography fontWeight={'bold'}>
            Earn Reward Points for watching Videos and reading Guides, up to 1
            per day, 5 per month.
          </Typography>
          <KnowledgeBoldGreenText>
            At the end of each Video or Guide, press "Click for Points" to
            receive your Points!
          </KnowledgeBoldGreenText>
        </KnowledgeText>
      </Box>
      <Divider className="divider" />
      <Box display="flex" className="knowledge-base-content">
        <KnowledgeBaseList>
          {videoItems && (
            <>
              <Typography variant="h2">{`Videos (${videoItems.length})`}</Typography>
              {videoItems.map((item, id) => (
                <KnowledgeBasePreviewItem key={'video-' + id} {...item} />
              ))}
            </>
          )}
        </KnowledgeBaseList>
        <KnowledgeBaseList>
          {guidesItems && (
            <>
              <Typography variant="h2">{`Guides (${guidesItems.length})`}</Typography>
              {guidesItems.map((item, id) => (
                <KnowledgeBasePreviewItem key={'guide-' + id} {...item} />
              ))}
            </>
          )}
        </KnowledgeBaseList>
      </Box>
    </KnowledgeBaseRoot>
  );
}

export default KnowledgeBase;

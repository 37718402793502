import { EBrakeTimerTypes } from '@/components/breaktimer/constants';
import { Box, FilledInput } from '@mui/material';
import styled from 'styled-components';

interface BreaktimerLineProps {
  length: number;
}

export const BreaktimerForm = styled.form`
  padding-top: 19px;
`;

export const IconWrapper = styled.div<{ type: EBrakeTimerTypes }>`
  width: 46px;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
  color: ${(props) => {
    return props.theme.palette.breakTimer[props.type].main;
  }};
`;

export const BreaktimerIcon = styled.img`
  width: 46px;
  height: 45px;
  object-fit: contain;
  margin-right: 12px;
`;

export const BreaktimerLine = styled.div<BreaktimerLineProps>`
  min-width: 304px;
  width: 100%;
  display: flex;

  & > * {
    margin-right: 4px;
    flex-basis: calc(${({ length }) => 100 / length}% - 4px);
  }
`;

export const BreaktimerWrapper = styled(Box)`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 25px;
  }
`;

export const ErrorWrapper = styled(Box)`
  width: 100%;
`;

export const BreaktimerInput = styled(FilledInput)`
  border-radius: 4px;
  border: none;
  background: ${({ theme }) => theme.palette.background.paper};

  &.MuiFilledInput-underline:before {
    display: none;
  }

  & .MuiFilledInput-notchedOutline {
    border: none;
  }

  & .MuiFilledInput-input {
    background: none;
    padding-top: 15px;
    padding-left: 15px;
  }

  & .MuiInputAdornment-positionEnd {
    padding-right: 5px;
  }
`;

import { indigo } from '@mui/material/colors';

const sidebar = {
  color: '#7E84A6',
  background: '#293951',
  header: {
    color: '#FFF',
    background: '#1E1E1E',
    brand: {
      color: '#FFFFFF',
    },
  },
  footer: {
    color: '#FFF',
    background: indigo[800],
    online: {
      background: '#FFF',
    },
  },
  item: {
    color: {
      main: '#C9C7E4',
    },
    active: {
      border: '4px solid #FFFFFF',
      background: '#2C294C',
    },
  },
  badge: {
    color: '#000',
    background: '#FFF',
  },
};

export default sidebar;

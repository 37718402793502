import {
  BreakTimerBlock,
  BreakTimerTextBlock,
} from '@/components/breaktimer/components';
import {
  breakTimersData,
  breakTimersStatesIcons,
  EActionsBreakTimerStatus,
  EBrakeTimerState,
  EBrakeTimerTypes,
} from '@/components/breaktimer/constants';
import { ClassNameWithType } from '@/components/icons/breaktimers/CustomComponents/constants';
import IconDone from '@/components/icons/breaktimers/CustomComponents/IconDone';
import IconFailed from '@/components/icons/breaktimers/CustomComponents/IconFailed';
import IconUndone from '@/components/icons/breaktimers/CustomComponents/IconUndone';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import {
  completeBreakTimer,
  setBreakTimerStateAction,
  startCountdown,
} from '@/redux/actions/breaktimers';
import {
  getBreakTimerData,
  getBreakTimerLoadings,
} from '@/redux/selectors/breakTimersSelectors';
import { secondsToReadableTime } from '@/utils/secondsToReadableTime';
import { IconButton } from '@mui/material';
import React, { useCallback } from 'react';
import { getMyProgress } from '@/redux/actions/activity';
import { format } from 'date-fns';
import { EActivityLevel } from '@/types/consts';

interface IBreakTimerProps {
  type: EBrakeTimerTypes;
}

const BreakTimer: React.FC<IBreakTimerProps> = ({ type }: IBreakTimerProps) => {
  const breakTimerData = useAppSelector(getBreakTimerData(type));
  const loading = useAppSelector(getBreakTimerLoadings);
  const checkmarksCount = breakTimerData.todaySuccessCount;
  const breakTimerState = breakTimerData.state;
  const Icon = breakTimersStatesIcons[breakTimerState]
    .Icon as React.FC<ClassNameWithType>;
  const dispatch = useAppDispatch();

  const handleAction = useCallback(async () => {
    switch (breakTimerState) {
      case EBrakeTimerState.INACTIVE:
        return;
      case EBrakeTimerState.WAITING_FOR_INTERACTION:
        dispatch(
          setBreakTimerStateAction({
            type: type,
            state: EBrakeTimerState.PROCESSING,
          })
        );
        dispatch(
          startCountdown({
            type: type,
            countdown: breakTimersData[type].durationSeconds,
          })
        );
        break;
      case EBrakeTimerState.COMPLETED:
        if (!loading) {
          await dispatch(completeBreakTimer(type));
        }
        setBreakTimerStateAction({
          type: type,
          state: EBrakeTimerState.INACTIVE,
        });
        break;
      default:
        break;
    }
  }, [breakTimerState, loading]);

  const getCheckmarks = useCallback(() => {
    return breakTimerData.actionsStatuses.map((status, i) => {
      if (status === EActionsBreakTimerStatus.COMPLETED) {
        return (
          <IconDone key={`checkmark-${type}-${i}`} className="checkmark" />
        );
      }
      if (status === EActionsBreakTimerStatus.FAILED) {
        return (
          <IconFailed key={`checkmark-${type}-${i}`} className="checkmark" />
        );
      }
      return (
        <IconUndone key={`checkmark-${type}-${i}`} className="checkmark" />
      );
    });
  }, [checkmarksCount, breakTimerData, loading]);

  return (
    <BreakTimerBlock type={type} state={breakTimerState}>
      <img className="breaktimer-image" src={breakTimersData[type].image} />
      <BreakTimerTextBlock type={type} state={breakTimerState}>
        {breakTimerData.done &&
        breakTimerState === EBrakeTimerState.INACTIVE ? (
          <div className="done">
            <b>
              {breakTimerData.allFailed
                ? breakTimersData[type].failedText
                : 'Done!'}
            </b>
          </div>
        ) : (
          <div className="countdown">
            <b>
              {breakTimerState === EBrakeTimerState.INACTIVE
                ? breakTimerData.nextActivation
                : breakTimerState === EBrakeTimerState.WAITING_FOR_INTERACTION
                ? 'Start timer!'
                : secondsToReadableTime(
                    breakTimerState === EBrakeTimerState.COMPLETED
                      ? 0
                      : breakTimerData.countdown || 0
                  )}
            </b>
          </div>
        )}
        <b className="name">{breakTimersData[type].text}</b>
        <div className="checkmarks">{getCheckmarks()}</div>
      </BreakTimerTextBlock>
      <IconButton
        className="action-button"
        disabled={breakTimerState === EBrakeTimerState.INACTIVE || loading}
        onClick={handleAction}
      >
        <Icon type={type} />
      </IconButton>
    </BreakTimerBlock>
  );
};

export default BreakTimer;

import ConfirmModal, { ModalStatuses } from '@/components/dialogs/ConfirmModal';
import {
  BillingContactIcon,
  DeleteCompanyIcon,
  InfoRedIcon,
  StarIcon,
} from '@/components/icons';
import {
  IconButtonBox,
  Popover,
  StyledScrollbarComponent,
  TeamList,
  TeamListItem,
  UserContextMenu,
} from '@/components/team-admin/components';
import UserPopoverContent from '@/components/team-admin/UserPopoverContent';
import useAppDispatch from '@/hooks/useAppDispatch';
import useCompany from '@/hooks/useCompany';
import { deleteUser, getUsersByParams } from '@/redux/actions/users';

import { DEFAULT_TOAST_TIME, EUserRole, rolesListName } from '@/types/consts';
import { IUser } from '@/types/models';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';

import { Box, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { AvatarType, CustomAvatar } from '../UI/CustomAvatar';

const CompanyTeamList = () => {
  const dispatch = useAppDispatch();
  const { company } = useCompany();

  const [companyTeam, setCompanyTeam] = useState<IUser[] | null>(null);

  const anchorEl = useRef(null);

  const [isOpen, setOpen] = useState<boolean>(false);
  const [userId, setUserId] = useState<number | null>(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleOpenConfirm = () => {
    setConfirmationOpen(true);
  };

  const handleOpen = (event: any, id: number) => {
    anchorEl.current = event.target;
    setUserId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUserId(null);
  };

  const companyName = useMemo(() => company?.name, [company?.name]);

  useEffect(() => {
    dispatch(getUsersByParams({ company: companyName }))
      .unwrap()
      .then(({ users }) => {
        if (users) {
          setCompanyTeam(users);
        }
      });
  }, [dispatch, companyName]);

  const handleDelete = useCallback(() => {
    dispatch(deleteUser({ id: Number(userId), isSoft: true }))
      .unwrap()
      .then(() => {
        setConfirmationOpen(false);
        toast.success('User was successfully deleted!', {
          position: 'top-right',
          autoClose: DEFAULT_TOAST_TIME,
          hideProgressBar: false,
        });
        handleClose();
        if (companyTeam) {
          setCompanyTeam(companyTeam.filter((user) => user.id !== userId));
        }
      });
  }, [userId]);

  return (
    <Box>
      <StyledScrollbarComponent>
        {!companyTeam && (
          <Skeleton animation="wave" width={'100%'} height={40} />
        )}
        <TeamList disablePadding>
          {companyTeam &&
            companyTeam.map(
              ({
                id,
                firstName,
                lastName,
                role,
                profileImage,
                email,
                isConfirm,
              }) => {
                const name = `${firstName} ${lastName}`;

                return (
                  <TeamListItem key={id} disablePadding>
                    <CustomAvatar
                      diameter={24}
                      avatartype={AvatarType.USER}
                      alt="Remy Sharp"
                      src={profileImage || undefined}
                    >
                      {getUserAvatarLetter({
                        firstName,
                        lastName,
                        email,
                      })}
                    </CustomAvatar>
                    <Typography
                      variant={'body1'}
                      component={'span'}
                      sx={{
                        maxWidth: 200,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {firstName && lastName ? name : email}
                    </Typography>
                    {role ? (
                      <Typography
                        variant={'body1'}
                        component={'span'}
                        textAlign={'center'}
                      >
                        {!isConfirm && (
                          <Tooltip title="This user has not accepted their invitation">
                            <InfoRedIcon />
                          </Tooltip>
                        )}
                        {id === company?.billingContactId && (
                          <BillingContactIcon />
                        )}
                        {role.name === EUserRole.TEAMLEAD && <StarIcon />}{' '}
                        {
                          rolesListName.find(
                            ({ name: roleName }) => roleName === role.name
                          )?.view_name
                        }
                      </Typography>
                    ) : (
                      <Typography
                        variant={'body1'}
                        component={'span'}
                        textAlign={'center'}
                      >
                        User
                      </Typography>
                    )}
                    <IconButtonBox
                      ref={anchorEl}
                      onClick={(event) => handleOpen(event, id)}
                    >
                      <IconButton aria-label={'user-context-menu'}>
                        <UserContextMenu />
                      </IconButton>
                    </IconButtonBox>
                  </TeamListItem>
                );
              }
            )}
        </TeamList>
      </StyledScrollbarComponent>
      <Popover
        open={isOpen && !!userId}
        anchorEl={anchorEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ left: '-280px', top: '12px' }}
      >
        <UserPopoverContent userId={userId} handleDelete={handleOpenConfirm} />
      </Popover>
      <ConfirmModal
        textConfirm="Delete user"
        confirmStatus={ModalStatuses.WARNING}
        isOpen={confirmationOpen}
        title="Warning!"
        text="Deleted user can be restored only by RemoteWorx super admin. To confirm user deletion click Delete User."
        confirmIcon={<DeleteCompanyIcon />}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleDelete}
      />
    </Box>
  );
};

export default CompanyTeamList;

import {
  breakTimersData,
  EBrakeTimerTypes,
  TBreaktimerSettings,
} from '@/components/breaktimer/constants';
import { Placeholder } from '@/components/form-elements/components';
import { minutesFromUTCMidnightToLocalTime } from '@/utils/secondsToReadableTime';
import { Box, FormHelperText, MenuItem, Select } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import {
  BreaktimerIcon,
  BreaktimerInput,
  BreaktimerLine,
  BreaktimerWrapper,
  ErrorWrapper,
  IconWrapper,
} from './components';

interface IBreakTimerItemProps {
  type: EBrakeTimerTypes;
}

const AvailableTime = Array.from({ length: 48 }).map((_, i) => ({
  value: i * 30,
  text: minutesFromUTCMidnightToLocalTime(i * 30).toString(),
}));

const BreaktimerItem: React.FC<IBreakTimerItemProps> = ({ type }) => {
  const { values, errors, handleChange, handleBlur } =
    useFormikContext<Required<TBreaktimerSettings>>();
  const sortedArray = [...values[type]].sort((a: number, b: number) => a - b);

  return (
    <BreaktimerWrapper>
      <Box display="flex" flex={1}>
        <IconWrapper type={type}>
          <BreaktimerIcon
            className="breaktimer-image"
            src={breakTimersData[type].image}
          />
          {type}
        </IconWrapper>
        {sortedArray?.length > 0 && (
          <FieldArray name={type}>
            {() => (
              <Box display="flex" flexDirection="column" flex={1}>
                <BreaktimerLine length={sortedArray.length}>
                  {sortedArray.map((_, index) => (
                    <Select
                      displayEmpty
                      key={`${type}-${index}`}
                      name={`${type}.${index}`}
                      value={sortedArray[index]}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!errors[type] && !!(errors[type] as any)[index]}
                      renderValue={(selected) => {
                        const time = AvailableTime.find(
                          ({ value }) => value === selected
                        );
                        return time?.text || <Placeholder>__:__</Placeholder>;
                      }}
                      input={<BreaktimerInput />}
                    >
                      {AvailableTime.map((time) => (
                        <MenuItem
                          key={`${type}-${index}-${time.value}`}
                          value={time.value}
                        >
                          {time.text}
                        </MenuItem>
                      ))}
                    </Select>
                  ))}
                </BreaktimerLine>
                <ErrorWrapper>
                  {errors[type] && (
                    <FormHelperText error>{errors[type]}</FormHelperText>
                  )}
                </ErrorWrapper>
              </Box>
            )}
          </FieldArray>
        )}
      </Box>
    </BreaktimerWrapper>
  );
};

export default BreaktimerItem;

import { navigationLinks } from '@/components/admin/adminItems';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useUnreadMessagesCount from '@/hooks/useUnreadMessagesCount';
import { getUserConversation } from '@/redux/actions/chat';
import { chatUserSelector } from '@/redux/selectors/chatSelector';
import { getUserSelector } from '@/redux/selectors/userSelectors';
import palette from '@/theme/palette';
import { EAdminHeaderKeys, EUserRole } from '@/types/consts';
import { IUser } from '@/types/models';
import { idToUid } from '@/utils/chatHelpers';
import { Grid, Link, LinkProps, List, ListItem } from '@mui/material';
import { useEffect } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';
import HeaderUserDropdown from '../header/HeaderUserDropdown';
import { BrandLogo, BrandName } from '../icons';
import { UnreadBadge } from '../message-center/components';

const Logo = styled(Grid)``;
const NavigationList = styled(List)`
  display: flex;
  column-gap: 25px;
`;

const ListItemLink = styled(Link)<
  NavLinkProps & LinkProps & { component: typeof NavLink }
>`
  padding: ${({ theme }) => theme.spacing(1, 0)};
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  position: relative;
`;

const HeaderNavigation = () => {
  const dispatch = useAppDispatch();
  const chatUser = useAppSelector(chatUserSelector);
  const { role, id } = useAppSelector(getUserSelector) as IUser;
  const { onlyTeamMessagesCount: unreadCount } = useUnreadMessagesCount();

  useEffect(() => {
    if (id && chatUser) {
      dispatch(getUserConversation(idToUid(id)));
    }
  }, [id, chatUser]);

  return (
    <NavigationList disablePadding>
      {navigationLinks.map((link) =>
        !link.availableForRole ||
        link.availableForRole?.includes(role.name as EUserRole) ? (
          <ListItem key={link.title} disablePadding>
            <ListItemLink
              to={link.href}
              component={NavLink}
              underline={'hover'}
            >
              {link.title}
              {link.key === EAdminHeaderKeys.MESSAGE && !!unreadCount && (
                <UnreadBadge position="absolute" right="-23px">
                  {unreadCount}
                </UnreadBadge>
              )}
            </ListItemLink>
          </ListItem>
        ) : null
      )}
    </NavigationList>
  );
};

const Header = () => {
  return (
    <Grid
      component={'header'}
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      py={4}
      px={3}
    >
      <Grid item width={'auto'} md={2}>
        <NavLink to={'/'}>
          <Logo item container justifyContent={'start'} alignItems={'center'}>
            <BrandLogo fill={palette.selector.dark} />
            <BrandName fill={palette.selector.dark} />
          </Logo>
        </NavLink>
      </Grid>

      <Grid item container justifyContent={'center'} md={8}>
        <HeaderNavigation />
      </Grid>
      <Grid item container md={2} justifyContent={'end'}>
        <HeaderUserDropdown />
      </Grid>
    </Grid>
  );
};

export default Header;

import styled from 'styled-components';
import { Box, BoxProps, Button, ButtonProps } from '@mui/material';

interface IBonusBreakTimerBlock extends BoxProps {
  show: boolean;
}

export const BonusBreakTimerBlock = styled(Box)<IBonusBreakTimerBlock>`
  height: 48px;
  width: 380px;
  background-color: ${({ show }) => (show ? '#2b2c4d' : 'transparent')};
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

export const BonusBreakTimerButton = styled(Button)<ButtonProps>`
  width: 140px;
  height: 34px;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.custom.light};

  & .icon-hover {
    transition: 250ms;
  }
  & .icon-hover.icon-one {
    opacity: 1;
  }
  &:hover .icon-hover.icon-one {
    opacity: 0;
  }
  & .icon-hover.icon-two {
    position: absolute;
    right: 10px;
    opacity: 0;
  }
  &:hover .icon-hover.icon-two {
    position: absolute;
    right: 10px;
    opacity: 1;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.custom.light};
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const BonusBreakerText = styled(Box)`
  display: flex;
  align-items: center;
  padding-left: 8px;
  color: ${({ theme }) => theme.palette.custom.light};
`;

import {
  nameColumnSortByLastName,
  numberColumnSortingComparator,
} from '@/utils/tableHelpers';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import CustomGridCell from '../CustomGridCell';

export const teamDashboardColumns: GridColDef[] = [
  { field: 'id', headerName: 'Id', hide: true },
  {
    field: 'user',
    headerName: 'Name',
    flex: 2,
    renderCell: (params: GridRenderCellParams) => {
      return <CustomGridCell {...params} />;
    },
    sortComparator: nameColumnSortByLastName,
  },
  {
    field: 'mental',
    headerName: 'Mental',
    flex: 1,
    align: 'center',
    sortComparator: numberColumnSortingComparator,
    valueFormatter: (params) => Number(params.value).toLocaleString('en'),
  },
  {
    field: 'phycical',
    headerName: 'Physical',
    flex: 1,
    align: 'center',
    sortComparator: numberColumnSortingComparator,
    valueFormatter: (params) => Number(params.value).toLocaleString('en'),
  },
  {
    field: 'social',
    headerName: 'Social',
    flex: 1,
    align: 'center',
    sortComparator: numberColumnSortingComparator,
    valueFormatter: (params) => Number(params.value).toLocaleString('en'),
  },
  {
    field: 'menterTotals',
    headerName: 'Meters Total',
    flex: 1,
    align: 'center',
    sortComparator: numberColumnSortingComparator,
    valueFormatter: (params) => Number(params.value).toLocaleString('en'),
  },
  {
    field: 'kbProgress',
    headerName: 'KB Progress',
    flex: 1,
    align: 'center',
    sortComparator: numberColumnSortingComparator,
    valueFormatter: (params) => Number(params.value).toLocaleString('en'),
  },
  {
    field: 'workspaceProgress',
    headerName: 'Workspace Progress',
    flex: 1,
    align: 'center',
    sortComparator: numberColumnSortingComparator,
    valueFormatter: (params) => Number(params.value).toLocaleString('en'),
  },
  {
    field: 'messagesSent',
    headerName: 'Messages Sent',
    flex: 1,
    align: 'center',
    sortComparator: numberColumnSortingComparator,
    valueFormatter: (params) => Number(params.value).toLocaleString('en'),
  },
  {
    field: 'availablePoints',
    headerName: 'Available Points',
    flex: 1,
    align: 'center',
    sortComparator: numberColumnSortingComparator,
    valueFormatter: (params) => Number(params.value).toLocaleString('en'),
  },
  {
    field: 'earnedPoints',
    headerName: 'Earned Points',
    flex: 1,
    align: 'center',
    sortComparator: numberColumnSortingComparator,
    valueFormatter: (params) => Number(params.value).toLocaleString('en'),
  },
  {
    field: 'rewardPoints',
    headerName: 'Reward Points',
    flex: 1,
    align: 'center',
    sortComparator: numberColumnSortingComparator,
    valueFormatter: (params) => Number(params.value).toLocaleString('en'),
  },
  {
    field: 'jobTitle',
    headerName: 'Job Title',
    flex: 1,
    align: 'left',
  },
  { field: 'optPhone', headerName: 'Phone OPT', flex: 1, align: 'center' },
  {
    field: 'optBirthdayHireday',
    headerName: 'Celebrate OPT',
    flex: 1,
    align: 'center',
  },
  { field: 'birthDate', headerName: 'Birth Date', flex: 1, align: 'center' },
  { field: 'startWorkDate', headerName: 'Hire Date', flex: 1, align: 'center' },
  {
    field: 'registrationAt',
    headerName: 'First Login',
    flex: 1,
    align: 'center',
  },
];

import Chat from '@/components/message-center/Chat/Chat';
import {
  AdminContainer,
  AdminMessageCenterContainer,
} from '@/components/message-center/components';
import ConversationList from '@/components/message-center/ConversationList/ConversationList';
import useAppSelector from '@/hooks/useAppSelector';
import { getUserSelector } from '@/redux/selectors/userSelectors';
import { IUser } from '@/types/models';
import { Box } from '@mui/material';
import { FC } from 'react';

const AdminMessageCenter: FC = () => {
  const user = useAppSelector(getUserSelector) as IUser;
  return (
    <AdminContainer>
      <AdminMessageCenterContainer>
        {!!user.rwCompanies.length ? (
          <Box display="flex" height="100%">
            <ConversationList isAdmin={true} />
            <Chat isScrolled={true} />
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="30vh"
          >
            You don't have connected company
          </Box>
        )}
      </AdminMessageCenterContainer>
    </AdminContainer>
  );
};

export default AdminMessageCenter;

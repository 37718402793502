import { FormTitle } from '@/components/form-elements/components';
import Switch from '@/components/UI/Switch';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useNotifications from '@/hooks/useNotification';
import { updateNotification } from '@/redux/actions/user';
import { getUserSelector } from '@/redux/selectors/userSelectors';
import { IUpdateNotificationSettings, IUser, IWithUser } from '@/types/models';
import { Box, FormControl } from '@mui/material';
import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import {
  NotificationContainer,
  NotificationRow,
  SwitchLabel,
} from './components';

const NotificationSettings: React.FC<IWithUser> = ({ user }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(getUserSelector) as IUser;
  const { askNotificationPermission } = useNotifications();
  const { id, newMessagesSounds, breakTimesSounds, progressMetersSounds } =
    user || currentUser;
  const saveSettings = useCallback(
    (values: IUpdateNotificationSettings) => {
      if (
        values.breakTimesSounds ||
        values.progressMetersSounds ||
        values.newMessagesSounds
      ) {
        askNotificationPermission();
      }
      dispatch(updateNotification(values));
    },
    [dispatch]
  );

  const { values, handleChange, handleSubmit, dirty } = useFormik({
    initialValues: {
      id,
      newMessagesSounds,
      breakTimesSounds,
      progressMetersSounds,
    },
    onSubmit: saveSettings,
  });

  useEffect(() => {
    if (dirty) {
      handleSubmit();
    }
  }, [values, dirty, handleSubmit]);

  return (
    <Box marginBottom="20px">
      <FormTitle>Notification Sounds</FormTitle>
      <NotificationContainer display="flex" flexDirection="column">
        <FormControl>
          <NotificationRow display="flex" alignItems="center">
            <SwitchLabel>New Messages</SwitchLabel>
            <Switch
              name="newMessagesSounds"
              checked={values.newMessagesSounds}
              onChange={handleChange}
            />
          </NotificationRow>
        </FormControl>
        <FormControl>
          <NotificationRow display="flex" alignItems="center">
            <SwitchLabel>Break Timers</SwitchLabel>
            <Switch
              name="breakTimesSounds"
              checked={values.breakTimesSounds}
              onChange={handleChange}
            />
          </NotificationRow>
        </FormControl>
        <FormControl>
          <NotificationRow display="flex" alignItems="center">
            <SwitchLabel>Progress Meters</SwitchLabel>
            <Switch
              name="progressMetersSounds"
              checked={values.progressMetersSounds}
              onChange={handleChange}
            />
          </NotificationRow>
        </FormControl>
      </NotificationContainer>
    </Box>
  );
};

export default NotificationSettings;

import {
  FormControlWrapper,
  FormTitle,
  IconWrapper,
} from '@/components/form-elements/components';
import { ReactComponent as InvisibleIcon } from '@/components/icons/Eye-closed.svg';
import { ReactComponent as VisibleIcon } from '@/components/icons/Eye.svg';
import useAppDispatch from '@/hooks/useAppDispatch';
import { changePassword } from '@/redux/actions/user';
import { DEFAULT_TOAST_TIME, PASSWORD_REGEX } from '@/types/consts';
import { IChangePassword, ISuccessResponce } from '@/types/models';
import {
  Box,
  Button,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const NewPasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Required'),
  newPassword: Yup.string()
    .min(8, 'Too Short!')
    .max(50, 'Too Long!')
    .matches(
      PASSWORD_REGEX,
      'Password must be at least 8 characters long, include 1 uppercase letter, 1 lowercase letter and 1 number'
    )
    .notOneOf(
      [Yup.ref('oldPassword')],
      'New password cannot be the same as your old password.'
    )
    .required('Required'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

const ChangePassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((val) => !val);
  }, [setShowPassword]);

  const handleClickShowOldPassword = useCallback(() => {
    setShowOldPassword((val) => !val);
  }, [setShowOldPassword]);

  const handleClickShowConfirmPassword = useCallback(() => {
    setShowConfirmPassword((val) => !val);
  }, [setShowConfirmPassword]);

  const onCreatePassword = useCallback(
    (
      { oldPassword, newPassword }: IChangePassword,
      actions: FormikHelpers<IChangePassword>
    ) => {
      dispatch(
        changePassword({
          oldPassword,
          newPassword,
        })
      )
        .unwrap()
        .then(({ success }: ISuccessResponce) => {
          if (success) {
            actions.resetForm();
            toast.success('Password was successfully updated', {
              position: 'top-right',
              autoClose: DEFAULT_TOAST_TIME,
              hideProgressBar: false,
            });
          } else {
            console.error('Error on password update');
            toast.error('Error on password update', {
              position: 'top-right',
            });
          }
        })
        .catch(({ error }: any) => {
          console.error(error);
          toast.error(error, {
            position: 'top-right',
          });
        })
        .finally(() => {
          actions.setSubmitting(false);
          actions.resetForm();
        });
    },
    [dispatch]
  );

  return (
    <div>
      <FormTitle>Change Password</FormTitle>
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        }}
        validationSchema={NewPasswordSchema}
        onSubmit={(values, actions: any) => onCreatePassword(values, actions)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormControlWrapper
              hiddenLabel={!values.oldPassword.toString()}
              error={!!errors.oldPassword && !!touched.oldPassword}
              fullWidth
            >
              {!!values.oldPassword && (
                <InputLabel>Current Password</InputLabel>
              )}
              <OutlinedInput
                type={showOldPassword ? 'text' : 'password'}
                name="oldPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.oldPassword}
                placeholder="Current Password"
                error={!!errors.oldPassword && !!touched.oldPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconWrapper
                      aria-label="toggle password visibility"
                      onClick={handleClickShowOldPassword}
                      edge="end"
                      disableRipple
                    >
                      {showOldPassword ? <InvisibleIcon /> : <VisibleIcon />}
                    </IconWrapper>
                  </InputAdornment>
                }
              />
              {errors.oldPassword && touched.oldPassword && (
                <FormHelperText id="oldPassword" error>
                  {errors.oldPassword}
                </FormHelperText>
              )}
            </FormControlWrapper>
            <FormControlWrapper
              hiddenLabel={!values.newPassword.toString()}
              error={!!errors.newPassword && !!touched.newPassword}
              fullWidth
            >
              {!!values.newPassword && (
                <InputLabel>Create New Password</InputLabel>
              )}
              <OutlinedInput
                type={showPassword ? 'text' : 'password'}
                name="newPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPassword}
                placeholder="Create New Password"
                error={!!errors.newPassword && !!touched.newPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconWrapper
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      disableRipple
                    >
                      {showPassword ? <InvisibleIcon /> : <VisibleIcon />}
                    </IconWrapper>
                  </InputAdornment>
                }
              />
              {errors.newPassword && touched.newPassword && (
                <FormHelperText id="newPassword" error>
                  {errors.newPassword}
                </FormHelperText>
              )}
            </FormControlWrapper>
            <FormControlWrapper
              hiddenLabel={!values.confirmPassword.toString()}
              error={!!errors.confirmPassword && !!touched.confirmPassword}
              fullWidth
            >
              {!!values.confirmPassword && (
                <InputLabel htmlFor="confirmPassword">
                  Re-Enter Password
                </InputLabel>
              )}
              <OutlinedInput
                type={showConfirmPassword ? 'text' : 'password'}
                name="confirmPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword}
                placeholder="Re-Enter Password"
                error={!!errors.confirmPassword && !!touched.confirmPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconWrapper
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      edge="end"
                      disableRipple
                    >
                      {showConfirmPassword ? (
                        <InvisibleIcon />
                      ) : (
                        <VisibleIcon />
                      )}
                    </IconWrapper>
                  </InputAdornment>
                }
              />
              {errors.confirmPassword && touched.confirmPassword && (
                <FormHelperText id="confirmPassword" error>
                  {errors.confirmPassword}
                </FormHelperText>
              )}
            </FormControlWrapper>
            <Box display="flex" justifyContent="center">
              <Button
                variant="outlined"
                color="secondary"
                type="submit"
                disabled={!isValid || isSubmitting || !dirty}
              >
                Reset Password
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;

import { FormControlWrapper } from '@/components/form-elements/components';
import { NumberFormatCustom } from '@/components/form-elements/NumberInput';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAuth from '@/hooks/useAuth';
import { numberWithCommas } from '@/pages/super-admin/company/helpers';
import {
  getMyRewardPoints,
  getMyRewardPointsLog,
} from '@/redux/actions/activity';
import { redeemPoints } from '@/redux/actions/users';
import { toggleModal } from '@/redux/reducers/componentsManage';
import { ELimits } from '@/types/consts';
import { getStartOfMonth } from '@/utils/dateHelpers';
import { FormHelperText, Grid, OutlinedInput } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import useAppSelector from '@/hooks/useAppSelector';
import {
  adminUserRewardPointsSelector,
  getRewardPointsLoaded,
} from '@/redux/selectors/users';
import { CancelButton, ConfirmButton } from '@/components/buttons/components';

interface ITransferPoints {
  amountPoints: number;
  shopEmail: string;
}

const validationSchema = Yup.object().shape({
  amountPoints: Yup.number()
    .moreThan(0, 'Should be greater than 0')
    .required('Required'),
  shopEmail: Yup.string()
    .min(5, 'Too Short!')
    .max(50, 'Too Long!')
    .email('Incorrect email')
    .required('Required'),
});

const TransferPointsForm = () => {
  const dispatch = useAppDispatch();
  let { user } = useAuth();
  const adminUserRewardPoints = useAppSelector(adminUserRewardPointsSelector);
  if (adminUserRewardPoints) {
    user = adminUserRewardPoints.user;
  }
  const loading = useAppSelector(getRewardPointsLoaded);
  const handleOnSubmit = (values: ITransferPoints) => {
    // if (user && user?.workLifeShopEmail !== values.shopEmail) {
    //   return dispatch(
    //     updateMe({
    //       id: user.id,
    //       workLifeShopEmail: values.shopEmail,
    //     })
    //   )
    //     .unwrap()
    //     .then(() => {
    //       toast.success(
    //         'Your Work+Life shop email has been updated. To confirm it, please follow instruction sent on your email. After confirmation, hit Redeem Points one more time',
    //         {
    //           position: 'top-right',
    //           hideProgressBar: false,
    //         }
    //       );
    //     })
    //     .catch(({ error }: any) => {
    //       console.error(error);
    //       toast.error(`${error}`, { position: 'top-right' });
    //     });
    // }

    dispatch(
      redeemPoints({
        email: values.shopEmail,
        points: values.amountPoints,
        userId: user?.id,
      })
    )
      .unwrap()
      .then(() => {
        toast.success(
          `Nice! You have transferred ${numberWithCommas(
            values.amountPoints
          )} points. ${
            adminUserRewardPoints
              ? ''
              : 'They can now be used for purchases in the Work-Life Shop. Enjoy!'
          }`,
          {
            position: 'top-right',
            hideProgressBar: false,
          }
        );
        dispatch(getMyRewardPoints());

        const activityDate = {
          startDate: getStartOfMonth(new Date()),
          endDate: new Date(),
        };

        const logPagination = {
          limit: ELimits.ACTIVITY,
          from: activityDate.startDate,
          to: activityDate.endDate,
          offset: 0,
        };
        dispatch(getMyRewardPointsLog({ logPagination, earned: false }));
        handleClose();
      })
      .catch(({ error }: any) => {
        console.error(error);
        toast.error(`${error}`, { position: 'top-right' });
      });
  };

  const handleClose = () => {
    dispatch(toggleModal(false));
  };

  return (
    <Formik
      initialValues={{
        amountPoints: 0,
        shopEmail: user?.email || '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ errors, handleBlur, handleChange, touched, values }) => {
        return (
          <Form>
            <Grid
              container
              alignItems={'center'}
              flexWrap={'nowrap'}
              columnGap={4}
            >
              <Grid item md={6}>
                <FormControlWrapper
                  hiddenLabel={true}
                  error={!!errors.amountPoints && !!touched.amountPoints}
                >
                  <OutlinedInput
                    name={'amountPoints'}
                    placeholder={'Amount of Points to Transfer'}
                    value={values.amountPoints}
                    error={Boolean(touched.amountPoints && errors.amountPoints)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={'number'}
                    inputComponent={NumberFormatCustom as any}
                  />
                  {touched.amountPoints && errors.amountPoints && (
                    <FormHelperText id={'amountPoints'} error>
                      {errors.amountPoints}
                    </FormHelperText>
                  )}
                </FormControlWrapper>
              </Grid>
              <Grid item md={6}>
                <FormControlWrapper
                  hiddenLabel={true}
                  // error={!!errors.shopEmail && !!touched.shopEmail}
                >
                  <OutlinedInput
                    name={'shopEmail'}
                    placeholder={'Enter Work+Life Shop Email'}
                    value={values.shopEmail}
                    // error={Boolean(touched.shopEmail && errors.shopEmail)}
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                    disabled
                    type={'text'}
                  />
                  {touched.shopEmail && errors.shopEmail && (
                    <FormHelperText id={'shopEmail'} error>
                      {errors.shopEmail}
                    </FormHelperText>
                  )}
                </FormControlWrapper>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={'center'}
              alignItems={'center'}
              flexWrap={'nowrap'}
              columnGap={4}
              mt={8}
            >
              <Grid item md={6} container justifyContent={'flex-end'}>
                <ConfirmButton disabled={loading} type={'submit'}>
                  Transfer Points
                </ConfirmButton>
              </Grid>
              <Grid item md={6}>
                <CancelButton onClick={handleClose}>Cancel</CancelButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TransferPointsForm;

import HeaderNotificationsDropdown from '@/components/header/HeaderNotificationsDropdown';
import HeaderUserDropdown from '@/components/header/HeaderUserDropdown';
import HeaderUserInfo from '@/components/header/HeaderUserInfo';

import {
  AppBar as MuiAppBar,
  Grid,
  Toolbar as MuiToolbar,
} from '@mui/material';
import styled from 'styled-components';

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
`;

const Toolbar = styled(MuiToolbar)`
  min-height: ${({ theme }) => theme.spacing(17)};
`;

const Header: React.FC = () => {
  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar disableGutters>
        <Grid container alignItems={'center'} height={'100%'}>
          <Grid item xs={7} />
          <Grid item xs={3} marginLeft="2.1px" alignSelf={'end'}>
            <HeaderUserInfo />
          </Grid>
          <Grid
            item
            container
            xs={1.9}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <Grid item>
              <HeaderNotificationsDropdown />
            </Grid>
            <Grid item>
              <HeaderUserDropdown />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

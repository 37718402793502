import useAppSelector from '@/hooks/useAppSelector';
import useGetWorkspaceProgress from '@/hooks/useGetWorkspaceProgress';
import {
  BoxWrapper,
  MyWorkspaceHeaderMenuBlock,
  ProggresHeaderWorkspace,
  SmallText,
  SmallTextWrapper,
  StatisticWrapper,
  WorkspaceTabs,
  WorkspaceTabsWrapper,
} from '@/pages/dashboard/MyWorkspace/MyWorkspaceHeaderMenu/component';
import { createProgressArray } from '@/pages/dashboard/MyWorkspace/MyWorkspaceHeaderMenu/helpers';
import {
  MyWorkspaceHeaderMenuProps,
  ProgressItem,
} from '@/pages/dashboard/MyWorkspace/MyWorkspaceHeaderMenu/types';
import { WorkSpaceProgressSlider } from '@/pages/dashboard/MyWorkspace/MyWorkspaceHeaderMenu/WorkSpaceProgressSlider';
import { WorkspaceMenuStatus } from '@/pages/dashboard/MyWorkspace/types';
import theme from '@/theme/theme';
import { Box, Tab, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const MyWorkspaceHeaderMenu: React.FC<MyWorkspaceHeaderMenuProps> = ({
  status,
  setStatus,
  userId,
}) => {
  const [progressComplete, setProgressComplete] = useState<ProgressItem[]>([]);
  const { totalLength, currentLength, countCategories } =
    useGetWorkspaceProgress(userId);

  const changeStatus = (newStatus: WorkspaceMenuStatus) => {
    if (setStatus) {
      setStatus(newStatus);
    }
  };

  useEffect(() => {
    setProgressComplete(createProgressArray(currentLength));
  }, [currentLength, countCategories]);

  return (
    <Box width="100%">
      <MyWorkspaceHeaderMenuBlock>
        <Box display="flex" alignItems="center" width="35%">
          <Typography
            variant="h3"
            lineHeight="45px"
            fontSize="20px"
            color={theme.palette.secondary.main}
          >
            My Workspace
          </Typography>
        </Box>
        <Box display="flex" width="100%" justifyContent="space-between">
          {status === WorkspaceMenuStatus.view ? (
            <Box display="flex" minWidth="10%" maxWidth="40%">
              <Typography color={theme.knowledgeBase.color}>
                Visualize and build your ideal work environment! Start with this
                grayscale Workspace View illustration and colorize the items you
                own by checking them off in the Build My Workspace checklist.
                Click on items in the illustration to learn more. Acquiring
                items in recommended categories will improve your comfort,
                productivity, and wellbeing.
              </Typography>
            </Box>
          ) : (
            status === WorkspaceMenuStatus.build && (
              <Box display="flex" minWidth="10%" maxWidth="40%">
                <Typography color={theme.knowledgeBase.color}>
                  Check the box next to an item you have in your workspace. The
                  item will highlight in color in the <b>Workspace View</b>{' '}
                  illustration. Continue colorizing the illustration by adding
                  all the items that you have, up to 1 item per day, 5 items per
                  month. Acquiring and using the non-highlighted items will
                  improve your comfort, productivity, and wellbeing.
                </Typography>
              </Box>
            )
          )}

          <StatisticWrapper>
            <Box display="flex" flexDirection="column" width="100%">
              <BoxWrapper>
                {progressComplete.map((progress) => (
                  <WorkSpaceProgressSlider
                    key={progress.id}
                    progress={progress}
                    total={5}
                  />
                ))}
              </BoxWrapper>
              <ProggresHeaderWorkspace>
                {currentLength} / 32 Wellness Items Acquired
              </ProggresHeaderWorkspace>
            </Box>
          </StatisticWrapper>
        </Box>
        <SmallTextWrapper justifyContent="start">
          {status === WorkspaceMenuStatus.build ? (
            <SmallText>
              * Multiple items required to complete this category
            </SmallText>
          ) : (
            <SmallText color="#ffff">.</SmallText>
          )}
        </SmallTextWrapper>
      </MyWorkspaceHeaderMenuBlock>
      <WorkspaceTabsWrapper>
        <WorkspaceTabs
          value={status}
          onChange={(e, value) => changeStatus(value)}
          centered
          textColor={'secondary'}
        >
          <Tab
            value={WorkspaceMenuStatus.view}
            label={WorkspaceMenuStatus.view}
          />
          <Tab
            value={WorkspaceMenuStatus.build}
            label={WorkspaceMenuStatus.build}
          />
        </WorkspaceTabs>
      </WorkspaceTabsWrapper>
    </Box>
  );
};

export default MyWorkspaceHeaderMenu;

import { ECompany } from '@/redux/constants';
import instance from '@/redux/middlewares/api';
import {
  ITeamDashboardOneUser,
  resetCompanyState,
  setCompaniesLoading,
} from '@/redux/reducers/company';
import { RootState } from '@/redux/store';
import { EUserRole } from '@/types/consts';
import {
  IChangeAutoCalculatorCompany,
  ICompaniesPaginated,
  ICompany,
  ICompanyActivityLogPagination,
  ICompanyActivityRange,
  ICompanyParams,
  ICompanyPerioProgress,
  ICompanyProgressParams,
  ICompanyReportParams,
  ICompanySearchParams,
  ICreateCompany,
  IMessagesStat,
  IPointManageHistoryParams,
  IPointsHistory,
  IUpdateCompany,
  IUpdateCompanyUsersAvailablePoints,
  IUpdateCompanyUsersGiftedPoints,
  IUser,
} from '@/types/models';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getCompaniesUrlByRoleName = (roleName?: string) => {
  if (roleName === EUserRole.TEAMLEAD) {
    return 'team-admin/companies';
  }

  if (roleName === EUserRole.SUPER_ADMIN) {
    return 'super-admin/companies';
  }

  if (roleName === EUserRole.RW_ADMIN) {
    return 'rw-admin/companies';
  }

  return 'companies';
};

const getSimpleCompanies = createAsyncThunk(
  ECompany.getSimpleCompanies,
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user } = getState() as RootState;
      const role = user.user?.role.name;

      const getCompaniesUrl = getCompaniesUrlByRoleName(role);

      const companies = await instance.get<{
        companies: ICompany[];
        countAll: number;
      }>(`${getCompaniesUrl}/get-all-for-selects`);
      return companies.data.companies;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const getCompanies = createAsyncThunk(
  ECompany.getCompanies,
  async (params: ICompanyParams, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user } = getState() as RootState;
      const role = user.user?.role.name;

      const getCompaniesUrl = getCompaniesUrlByRoleName(role);

      const companies = await instance.get<{
        //
        companies: ICompany[];
        countAll: number;
      }>(getCompaniesUrl, {
        params: {
          limit: params.limit,
          offset: params.offset || 0,
        },
      });
      return companies.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const searchCompanies = createAsyncThunk(
  ECompany.searchCompanies,
  async (
    params: ICompanySearchParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user } = getState() as RootState;
      const role = user.user?.role.name;

      const getCompaniesUrl = getCompaniesUrlByRoleName(role);

      const companies = await instance.get<ICompaniesPaginated>(
        getCompaniesUrl,
        {
          params: {
            company: params.company,
            limit: params.limit,
            offset: params.offset || 0,
          },
        }
      );
      if (companies.data.countAll > companies.data.companies.length) {
        return (
          await instance.get<ICompaniesPaginated>(getCompaniesUrl, {
            params: {
              company: params.company,
              limit: companies.data.countAll,
              offset: 0,
            },
          })
        ).data;
      }
      return companies.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const createCompany = createAsyncThunk(
  ECompany.createCompany,
  async (
    companyData: ICreateCompany,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user } = getState() as RootState;
      const role = user.user?.role.name;

      const getCompaniesUrl = getCompaniesUrlByRoleName(role);

      const company = await instance.post<ICompany>(
        `${getCompaniesUrl}/new`,
        companyData
      );
      return company.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const getCompany = createAsyncThunk(
  ECompany.getCompany,
  async (companyId: number, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user } = getState() as RootState;
      const role = user.user?.role.name;

      const getCompaniesUrl = getCompaniesUrlByRoleName(role);

      const company = await instance.get(`${getCompaniesUrl}/${companyId}`);
      return company.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const getCompanyProgress = createAsyncThunk(
  ECompany.getUserProgress,
  async (
    range: ICompanyActivityRange,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user: userState } = getState() as RootState;
      const role = userState.user?.role.name;

      const getCompanyUrl = getCompaniesUrlByRoleName(role);
      const user = await instance.get(
        `${getCompanyUrl}/${range.companyId}/progress-meters?from=${range.from}&to=${range.to}`
      );
      return {
        data: user.data,
        dataLevel: range.dataLevel,
      };
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const getCompanyActivityLog = createAsyncThunk(
  ECompany.getCompanyActivity,
  async (
    logPagination: ICompanyActivityLogPagination,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user: userState } = getState() as RootState;
      const role = userState.user?.role.name;

      const getCompanyUrl = getCompaniesUrlByRoleName(role);
      const user = await instance.get(
        `${getCompanyUrl}/${logPagination.companyId}/activities`,
        {
          params: {
            offset: logPagination.offset,
            limit: logPagination.limit,
            from: logPagination.from?.toDateString(),
            to: logPagination.to?.toDateString(),
          },
        }
      );
      return {
        data: user.data,
        offset: logPagination.offset,
      };
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const getCompanyMessagingStat = createAsyncThunk(
  ECompany.getCompanyMessagingStat,
  async (companyId: number, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user: userState } = getState() as RootState;
      const role = userState.user?.role.name;

      const getCompanyUrl = getCompaniesUrlByRoleName(role);
      const user = await instance.get<IMessagesStat>(
        `${getCompanyUrl}/${companyId}/messages-stats`
      );
      return user.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const getCompanyUsersRewardPoints = createAsyncThunk(
  ECompany.getCompanyUsersRewardPoints,
  async (companyId: number, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user: userState } = getState() as RootState;
      const role = userState.user?.role.name;
      const getCompanyUrl = getCompaniesUrlByRoleName(role);
      const response = await instance.get(
        `${getCompanyUrl}/${companyId}/users/reward-points`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const updateCompanyUsersRewardPoints = createAsyncThunk(
  ECompany.updateCompanyUsersRewardPoints,
  async (
    { companyId, data }: IUpdateCompanyUsersAvailablePoints,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user: userState } = getState() as RootState;
      const role = userState.user?.role.name;

      const getCompanyUrl = getCompaniesUrlByRoleName(role);
      const response = await instance.post(
        `${getCompanyUrl}/${companyId}/users/available-reward-points`,
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const giftCompanyUsersRewardPoints = createAsyncThunk(
  ECompany.giftCompanyUsersRewardPoints,
  async (
    { companyId, data }: IUpdateCompanyUsersGiftedPoints,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user: userState } = getState() as RootState;
      const role = userState.user?.role.name;

      const getCompanyUrl = getCompaniesUrlByRoleName(role);
      const response = await instance.post(
        `${getCompanyUrl}/${companyId}/users/gifted-reward-points`,
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const updateCompany = createAsyncThunk(
  ECompany.updateCompany,
  async (
    updatedCompanyData: IUpdateCompany,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user } = getState() as RootState;
      const role = user.user?.role.name;

      const getCompaniesUrl = getCompaniesUrlByRoleName(role);

      const company = await instance.patch<ICompany>(
        `${getCompaniesUrl}/${updatedCompanyData.id}`,
        updatedCompanyData
      );
      return company.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const changeAutoCalculatorCompany = createAsyncThunk(
  ECompany.changeAutoCalculatorCompany,
  async (
    changeAutoCalculatorData: IChangeAutoCalculatorCompany,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user } = getState() as RootState;
      const role = user.user?.role.name;

      const getCompaniesUrl = getCompaniesUrlByRoleName(role);

      const company = await instance.patch<ICompany>(
        `${getCompaniesUrl}/${changeAutoCalculatorData.id}`,
        changeAutoCalculatorData
      );
      return company.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const deleteCompany = createAsyncThunk(
  ECompany.deleteCompany,
  async (companyId: number, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setCompaniesLoading(true));
      await instance.delete(`super-admin/companies/${companyId}`);
      return dispatch(resetCompanyState());
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const loadReport = createAsyncThunk(
  ECompany.getCompanyReport,
  async (
    { companyId, from, to }: ICompanyReportParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setCompaniesLoading(true));
      const report = await instance.get(
        `super-admin/companies/${companyId}/report`,
        {
          params: {
            from,
            to,
          },
        }
      );
      return report.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);
const loadTeamDashboardReport = createAsyncThunk(
  ECompany.getCompanyTeamDashboardReport,
  async (companyId: number, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setCompaniesLoading(true));
      const report = await instance.get(
        `super-admin/companies/${companyId}/team-activities/download`
      );
      return report.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const loadRewardPointsReport = createAsyncThunk(
  ECompany.getCompanyRewardPointsReport,
  async (companyId: number, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setCompaniesLoading(true));
      const report = await instance.get(
        `super-admin/companies/${companyId}/users/reward-points/download`
      );
      return report.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

interface IUpdatePoints {
  amount: number;
  companyId: number;
}

const updateCompanyRewardPoints = createAsyncThunk(
  ECompany.updateCompanyRewardPoints,
  async (
    { amount, companyId }: IUpdatePoints,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user } = getState() as RootState;
      const role = user.user?.role.name;

      const getCompaniesUrl = getCompaniesUrlByRoleName(role);

      const company = await instance.post(
        `${getCompaniesUrl}/${companyId}/reward-points`,
        {
          availablePoints: amount,
        }
      );
      return company.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const refundRewardPoints = createAsyncThunk(
  ECompany.refundCompanyRewardPoints,
  async (companyId: number, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user } = getState() as RootState;
      const role = user.user?.role.name;

      const getCompaniesUrl = getCompaniesUrlByRoleName(role);

      const company = await instance.post(
        `${getCompaniesUrl}/${companyId}/reward-points/refund`
      );
      return company.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const updateCompanyFlexPoints = createAsyncThunk(
  ECompany.updateCompanyFlexPoints,
  async (
    { amount, companyId }: IUpdatePoints,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user } = getState() as RootState;
      const role = user.user?.role.name;

      const getCompaniesUrl = getCompaniesUrlByRoleName(role);

      const company = await instance.post(
        `${getCompaniesUrl}/${companyId}/reward-points`,
        {
          flexPoints: amount,
        }
      );
      return company.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const getCompanyPointManagementHistory = createAsyncThunk(
  ECompany.companyPointsManagmentHistory,
  async (
    historyParams: IPointManageHistoryParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user } = getState() as RootState;
      const role = user.user?.role.name;

      const getCompaniesUrl = getCompaniesUrlByRoleName(role);

      const company = await instance.get<{
        logs: IPointsHistory[];
        countAll: number;
      }>(
        `${getCompaniesUrl}/${historyParams.id}/points-management-log-for-company`,
        {
          params: {
            offset: historyParams.offset,
            limit: historyParams.limit,
            from: historyParams.from,
            to: historyParams.to,
          },
        }
      );
      return company.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const getUsersPointManagementHistory = createAsyncThunk(
  ECompany.usersPointsManagmentHistory,
  async (
    historyParams: IPointManageHistoryParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user } = getState() as RootState;
      const role = user.user?.role.name;

      const getCompaniesUrl = getCompaniesUrlByRoleName(role);

      const company = await instance.get<{
        logs: IPointsHistory[];
        countAll: number;
      }>(
        `${getCompaniesUrl}/${historyParams.id}/points-management-log-for-users`,
        {
          params: {
            offset: historyParams.offset,
            limit: historyParams.limit,
            from: historyParams.from,
            to: historyParams.to,
            userIds: historyParams.userIds,
          },
        }
      );
      return company.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const getTeamActivitiesById = createAsyncThunk(
  ECompany.getTeamActivitiesById,
  async (companyId: number, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user } = getState() as RootState;
      const role = user.user?.role.name;
      let url = '';
      if (role == 'rw_admin') {
        url = '/rw-admin';
      } else if (role == 'super_admin') {
        url = '/super-admin';
      }
      const company = await instance.get<ITeamDashboardOneUser[]>(
        `${url}/companies/${companyId}/team-activities`
      );
      return company.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    } finally {
      dispatch(setCompaniesLoading(false));
    }
  }
);

const getChatSupportsByCompanyId = createAsyncThunk(
  ECompany.getChatSupportsByCompanyId,
  async (companyId: number, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setCompaniesLoading(true));
      const { user } = getState() as RootState;
      const { role } = user.user as IUser;
      const userUrl =
        role.name === EUserRole.TEAMLEAD ? `/team-admin` : `/users`;

      const { data } = await instance.get<IUser[]>(
        `${userUrl}/companies/${companyId}/support-users`
      );

      return data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    }
  }
);

const getCompanyPeriodProgress = createAsyncThunk(
  ECompany.getCompanyPeriodProgress,
  async (
    { companyId, from, to, divide }: ICompanyProgressParams,
    { getState, rejectWithValue }
  ) => {
    try {
      const { user } = getState() as RootState;
      const role = user.user?.role.name;

      const companyUrl = getCompaniesUrlByRoleName(role);
      const progressData = await instance.get<ICompanyPerioProgress[]>(
        `${companyUrl}/${companyId}/team-average-progres-meters`,
        {
          params: {
            from,
            to,
            divide,
          },
        }
      );
      return progressData.data;
    } catch (error: any) {
      rejectWithValue({ error: error.message });
    }
  }
);

export {
  getSimpleCompanies,
  getCompanies,
  searchCompanies,
  createCompany,
  getCompany,
  getCompanyProgress,
  getCompanyActivityLog,
  updateCompany,
  changeAutoCalculatorCompany,
  deleteCompany,
  getChatSupportsByCompanyId,
  getCompanyUsersRewardPoints,
  updateCompanyRewardPoints,
  updateCompanyFlexPoints,
  updateCompanyUsersRewardPoints,
  giftCompanyUsersRewardPoints,
  getCompanyMessagingStat,
  getCompanyPointManagementHistory,
  getUsersPointManagementHistory,
  loadReport,
  loadTeamDashboardReport,
  loadRewardPointsReport,
  getTeamActivitiesById,
  getCompanyPeriodProgress,
  refundRewardPoints,
};

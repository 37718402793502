import { createSlice } from '@reduxjs/toolkit';

export interface ComponentsManageState {
  open: boolean;
  drawerOpen: boolean;
  modalOpen: boolean;
  modalFlexOpen: boolean;
}

const initialState: ComponentsManageState = {
  open: true,
  drawerOpen: false,
  modalOpen: false,
  modalFlexOpen: false,
};

export const componentsManageSlice = createSlice({
  name: 'componentsManage',
  initialState,
  reducers: {
    toggle: (state) => {
      state.open = !state.open;
    },
    toggleDrawer: (state, { payload }) => {
      state.drawerOpen = payload;
    },
    toggleModal: (state, { payload }) => {
      state.modalOpen = payload;
    },
    toggleModalFlex: (state, { payload }) => {
      state.modalFlexOpen = payload;
    },
  },
});

export const { toggle, toggleDrawer, toggleModal, toggleModalFlex } =
  componentsManageSlice.actions;

export default componentsManageSlice.reducer;

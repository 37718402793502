import useAppDispatch from '@/hooks/useAppDispatch';
import { resendLink } from '@/redux/actions/users';
import { DEFAULT_TOAST_TIME } from '@/types/consts';
import { Button, Typography } from '@mui/material';
import React from 'react';
import { toast } from 'react-toastify';

const ResendLinkButton = ({ userId }: { userId: number }) => {
  const dispatch = useAppDispatch();

  const handleClick = async () => {
    try {
      await dispatch(resendLink(userId)).unwrap();
      toast.success('Invitation link was successfully resent', {
        position: 'top-right',
        autoClose: DEFAULT_TOAST_TIME,
        hideProgressBar: false,
      });
    } catch (e: any) {
      toast.error(e.error, {
        position: 'top-right',
      });
    }
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        color="secondary"
        sx={{
          marginRight: 2,
        }}
      >
        <Typography variant={'body1'} mr={1}>
          Resend Invite
        </Typography>
      </Button>
    </>
  );
};
export default ResendLinkButton;

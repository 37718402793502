import { ENotification } from '@/redux/constants/notifications';
import instance from '@/redux/middlewares/api';
import { IActivityLogPagination, INotification } from '@/types/models';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getNotificationsData = createAsyncThunk(
  ENotification.getData,
  async (logPagination: IActivityLogPagination, thunkAPI) => {
    try {
      const response = await instance.get('notification', {
        params: {
          offset: logPagination.offset,
          limit: logPagination.limit,
        },
      });

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const readNotifications = createAsyncThunk(
  ENotification.readNotifications,
  async (ids: number[], thunkAPI) => {
    try {
      const response = await instance.post('notification/show', { ids: ids });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const addNotification = createAsyncThunk(
  ENotification.addNotification,
  async (notification: INotification, thunkAPI) => {
    return notification;
  }
);

export { getNotificationsData, readNotifications, addNotification };

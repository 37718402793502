import {
  addNotification,
  getNotificationsData,
  readNotifications,
} from '@/redux/actions/notifications';
import { notificationsAdapterFunction } from '@/redux/reducers/functions/functions';
import { INotification } from '@/types/models';
import { createSlice } from '@reduxjs/toolkit';

export interface NotificationState {
  notifications: INotification[];
  isLoading: boolean;
}

const initialState = {
  notifications: [],
  isLoading: true,
};

export const { actions, reducer } = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    resetNotificationsState: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsData.fulfilled, (state, { payload, meta }) => {
        if (!payload) {
          return;
        }
        const adaptedPayload = notificationsAdapterFunction(payload) as never[];

        state.isLoading = false;
        state.notifications =
          meta.arg.offset && state.notifications?.length
            ? [...state.notifications, ...adaptedPayload]
            : adaptedPayload;
      })
      .addCase(addNotification.fulfilled, (state, { payload, meta }) => {
        if (!payload) {
          return;
        }
        const adaptedPayload = notificationsAdapterFunction([
          payload,
        ]) as never[];

        state.notifications = [...adaptedPayload, ...state.notifications];
      })
      .addCase(readNotifications.fulfilled, (state, { payload, meta }) => {
        if (!payload || !state.notifications) {
          return;
        }
        state.notifications = state.notifications.map(
          (notification: INotification) => ({
            ...notification,
            isShow: meta.arg.includes(notification.id)
              ? true
              : notification.isShow,
          })
        ) as [];
      });
  },
});

export const { resetNotificationsState } = actions;

export default reducer;

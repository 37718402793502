import {
  BackToSearchUsersButton,
  CompanyNameProp,
  CompanyNameValue,
  InforfationWrapper,
  ToolsGrid,
} from '@/components/admin/user/components';
import { ChewronLeftIcon } from '@/components/icons';
import { AvatarType, CustomAvatar } from '@/components/UI/CustomAvatar';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { resetAdminUserState } from '@/redux/reducers/users';
import { adminSelectedUser } from '@/redux/selectors/users';
import { EUserRole } from '@/types/consts';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Tools = () => {
  const user = useAppSelector(adminSelectedUser);
  const isDeleted = user ? user.deletedAt : false;
  const isRwAdmin = user ? user?.role.name === EUserRole.RW_ADMIN : false;
  const dispatch = useAppDispatch();
  return (
    <ToolsGrid
      container
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection={'row'}
      py={3}
      mb={8}
    >
      <Grid item container justifyContent={'center'} md={3}>
        <BackToSearchUsersButton
          LinkComponent={Link}
          to={
            isRwAdmin
              ? '/admin/rw-admins'
              : isDeleted
              ? '/admin/deleted-users'
              : '/admin/users'
          }
          startIcon={<ChewronLeftIcon />}
          color={'secondary'}
          onClick={() => dispatch(resetAdminUserState())}
        >
          Back to Search {isRwAdmin ? 'RW Admins' : 'Users'}
        </BackToSearchUsersButton>
      </Grid>
      <Grid
        item
        container
        justifyContent={'center'}
        alignItems={'center'}
        md={6}
      >
        <InforfationWrapper
          container
          justifyContent={'center'}
          alignItems={'center'}
          flexWrap={'nowrap'}
          gap={4}
          width={'auto'}
        >
          <Grid container alignItems={'center'} flexWrap={'nowrap'} gap={2}>
            <CustomAvatar
              diameter={46}
              avatartype={AvatarType.USER}
              alt="Remy Sharp"
              src={user?.profileImage || undefined}
            >
              {!user
                ? ''
                : getUserAvatarLetter({
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                  })}
            </CustomAvatar>
            <Typography variant={'body1'} whiteSpace={'nowrap'}>
              {user?.firstName
                ? `${user?.firstName} ${user?.lastName}`
                : user?.email}
            </Typography>
          </Grid>
          <Grid container alignItems={'center'} flexWrap={'nowrap'}>
            <CompanyNameProp>
              {user?.companies.length && user?.companies[0]?.name
                ? 'Company: '
                : 'User email: '}
            </CompanyNameProp>
            <CompanyNameValue>
              {user?.companies[0]?.name || user?.email || 'N/A'}
            </CompanyNameValue>
          </Grid>
        </InforfationWrapper>
      </Grid>
      <Grid item md={3}></Grid>
    </ToolsGrid>
  );
};

export default Tools;

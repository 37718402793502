import { KnowledgeBaseState } from '@/redux/reducers/knowledgeBase';
import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

export const getKnowledgeBase = createSelector(
  (state: DefaultRootState) => state.knowledgeBase,
  (state: KnowledgeBaseState) => state.items
);

export const getKnowledgeBaseItem = (id: number) =>
  createSelector(
    (state: DefaultRootState) => state.knowledgeBase,
    (state: KnowledgeBaseState) => state.items?.find((item) => item.id === id)
  );

export const getKnowledgeStat = createSelector(
  (state: DefaultRootState) => state.knowledgeBase,
  ({ statistic }: KnowledgeBaseState) => statistic
);

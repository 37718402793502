export enum EBreakTimer {
  getData = 'breakTimer/getData',
  updateData = 'breakTimer/updateData',
  complete = 'breakTimer/complete',
  preComplete = 'breakTimer/pre-complete',
  completeExtra = 'breakTimer/completeExtra',
  updateSettings = 'breakTimer/updateSettings',
  setCountdown = 'breakTimer/setCountdown',
  startCountdown = 'breakTimer/startCountdown',
  setState = 'breakTimer/setState',
}

import { UserContextMenuIcon } from '@/components/icons';

import ScrollbarComponent from '@/components/scrollbar/Scrollbar';

import {
  Avatar,
  Box,
  Button,
  Button as MuiButton,
  List as MuiList,
  ListItem as MuiListItem,
  Popover as MuiPopover,
  PopoverProps,
  Typography,
} from '@mui/material';

import { Form as FormikForm } from 'formik';
import styled from 'styled-components';

export const Header = styled('header')`
  padding: ${({ theme }) => theme.spacing(7, 0, 3)};
`;

export const HeaderTitle = styled(Typography)`
  text-align: center;
  color: #5d6081;
`;

export const MyCompanyContainer = styled(Box)`
  padding: ${({ theme }) => theme.spacing(7, 0, 3)};
`;

export const StyledScrollbarComponent = styled(ScrollbarComponent)`
  border-right: 1px solid #e2e8ed;
`;

export const TeamList = styled(MuiList)`
  max-height: 270px;
  padding-right: 20px;
`;

export const TeamListItem = styled(MuiListItem)`
  display: grid;
  grid-template-columns: 1fr 8fr 3fr 1fr;
  align-items: center;
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1, 0, 1, 2)};
  white-space: nowrap;

  &:not(&:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  &:hover {
    background: #e3f3fe;
  }
`;

export const IconButtonBox = styled(Box)`
  display: flex;
  justify-content: center;
`;

export const UserContextMenu = styled(UserContextMenuIcon)`
  &:hover {
    & > g {
      opacity: 1;
    }
  }
`;

export const Popover = styled(MuiPopover)<PopoverProps>`
  & .MuiPopover-paper {
    box-shadow: 0px 24px 64px rgba(0, 5, 62, 0.15);
    border-radius: ${({ theme }) => theme.spacing(3)};
    padding: ${({ theme }) => theme.spacing(4)};
  }
`;

export const DeleteButton = styled(Button)`
  max-width: 60px;
  min-width: 60px;
  height: 100%;
  background-color: #feebef;
  color: ${({ theme }) => theme.palette.redColor.main};

  &:hover {
    background-color: #c2d2e1;
  }
`;

export const UserAvatar = styled(Avatar)`
  background-color: orange;
  margin: ${({ theme }) => theme.spacing(0, 'auto')};
  width: 46px;
  height: 46px;
`;

export const UserEmail = styled(Typography)`
  color: #0099ff;
  text-decoration: underline;
  text-align: center;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

export const Placeholder = styled('em')`
  color: ${({ theme }) => theme.palette.selector.light};
  font-style: italic;
  opacity: 0.42;
`;

export const UpdateButton = styled(MuiButton)``;

export const Form = styled(FormikForm)`
  height: 100%;
`;

import { StateState } from '@/redux/reducers/state';
import { RootState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';

export const getStatesSelector = createSelector(
  (state: RootState) => state.state,
  ({ states }: StateState) => states
);

export const getStateLoading = createSelector(
  (state: RootState) => state.state,
  ({ loading }: StateState) => loading
);

export const getState = createSelector(
  (state: RootState) => state.state,
  ({ state }: StateState) => state
);

export const getStateSearchText = createSelector(
  (state: RootState) => state.state,
  ({ searchText }: StateState) => searchText
);

import { IconWrapper } from '@/components/form-elements/components';
import { ReactComponent as CheckboxCheckedIcon } from '@/components/icons/CheckboxChecked.svg';
import { ReactComponent as InvisibleIcon } from '@/components/icons/Eye-closed.svg';
import { ReactComponent as VisibleIcon } from '@/components/icons/Eye.svg';
import useAppDispatch from '@/hooks/useAppDispatch';
import {
  AuthButton,
  AuthError,
  CenteredButtonContainer,
  ClickHereBlock,
  FormContainer,
  FormControlWrapper,
  SubTitle,
} from '@/pages/auth/components';
import { resetPassword } from '@/redux/actions/user';
import { PASSWORD_REGEX } from '@/types/consts';
import { IResetPasswordForm } from '@/types/models';
import {
  Box,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { isMobile, isTablet } from 'react-device-detect';
import AgreementModal from '@/pages/auth/AgreementModal/AgreementModal';
import { toggleModal } from '@/redux/reducers/componentsManage';

const NewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Too Short!')
    .max(50, 'Too Long!')
    .matches(
      PASSWORD_REGEX,
      'Password must be at least 8 characters long, include 1 uppercase letter, 1 lowercase letter and 1 number'
    )
    .required('Required'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  // agreement: Yup.boolean().isTrue(),
});

const UserSetPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const match = useMatch('invitation-register');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [resetPassError, setResetPassError] = useState<string>('');
  const [search] = useSearchParams();
  const token = search.get('token');
  const [withMobileWarning, setWithMobileWarning] = useState<boolean>(false);
  const [agreemented, setAgreement] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((val) => !val);
  }, [setShowPassword]);

  const handleClickShowConfirmPassword = useCallback(() => {
    setShowConfirmPassword((val) => !val);
  }, [setShowConfirmPassword]);

  const onCreatePassword = useCallback(
    (
      values: IResetPasswordForm,
      actions: FormikHelpers<IResetPasswordForm>
    ) => {
      dispatch(resetPassword(values))
        .unwrap()
        .then(() => {
          if (isMobile || isTablet) {
            setWithMobileWarning(true);
          } else {
            navigate('/login');
          }
        })
        .catch(({ error }) => {
          console.error(error);
          setResetPassError(error);
        })
        .finally(() => actions.setSubmitting(false));
    },
    [dispatch, navigate, isMobile, isTablet]
  );

  const openModal = () => dispatch(toggleModal(true));

  return (
    <div>
      {!withMobileWarning ? (
        <>
          <SubTitle error={!!resetPassError}>
            {match ? 'Please create your password' : 'Reset password'}
          </SubTitle>
          {!!resetPassError && <AuthError> {resetPassError} </AuthError>}
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
              emailAuth: token || '',
              agreement: !match,
            }}
            validationSchema={NewPasswordSchema}
            onSubmit={(values, actions) => onCreatePassword(values, actions)}
          >
            {({
              values: { password, confirmPassword },
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
            }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FormControlWrapper
                  width="100%"
                  hiddenLabel={!password.toString()}
                  error={!!errors.password && !!touched.password}
                >
                  {!!password && (
                    <InputLabel>
                      {match ? 'Password' : 'Create New Password'}
                    </InputLabel>
                  )}
                  <OutlinedInput
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={password}
                    placeholder={match ? 'Password' : 'Create New Password'}
                    error={!!errors.password && !!touched.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconWrapper
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <InvisibleIcon /> : <VisibleIcon />}
                        </IconWrapper>
                      </InputAdornment>
                    }
                  />
                  {errors.password && touched.password && (
                    <FormHelperText id="password" error>
                      {errors.password}
                    </FormHelperText>
                  )}
                </FormControlWrapper>
                <FormControlWrapper
                  width="100%"
                  hiddenLabel={!confirmPassword.toString()}
                  error={!!errors.confirmPassword && !!touched.confirmPassword}
                >
                  {!!confirmPassword && (
                    <InputLabel htmlFor="confirmPassword">
                      Re-Enter Password
                    </InputLabel>
                  )}
                  <OutlinedInput
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={confirmPassword}
                    placeholder="Re-Enter Password"
                    error={
                      !!errors.confirmPassword && !!touched.confirmPassword
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconWrapper
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <InvisibleIcon />
                          ) : (
                            <VisibleIcon />
                          )}
                        </IconWrapper>
                      </InputAdornment>
                    }
                  />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <FormHelperText id="confirmPassword" error>
                      {errors.confirmPassword}
                    </FormHelperText>
                  )}
                </FormControlWrapper>
                <CenteredButtonContainer>
                  {agreemented ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CheckboxCheckedIcon />
                      <Typography marginLeft={3} variant={'h3'}>
                        Agreements Accepted
                      </Typography>
                    </Box>
                  ) : (
                    <ClickHereBlock onClick={openModal}>
                      Click here to review and accept Terms Of Use
                    </ClickHereBlock>
                  )}
                  <AgreementModal setAgreement={setAgreement} />
                </CenteredButtonContainer>
                <CenteredButtonContainer>
                  <AuthButton
                    variant="outlined"
                    color="secondary"
                    type="submit"
                    disabled={
                      !isValid ||
                      isSubmitting ||
                      !agreemented ||
                      !password ||
                      !confirmPassword
                    }
                  >
                    {match ? 'Go To Login' : 'Save'}
                  </AuthButton>
                </CenteredButtonContainer>
              </FormContainer>
            )}
          </Formik>
        </>
      ) : (
        <Box textAlign="center">
          Your password was successfully reset. Please return to the RemoteWorx
          mobile app to use your new password.
        </Box>
      )}
    </div>
  );
};

export default UserSetPassword;

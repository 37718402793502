import { Paper as MuiPaper } from '@mui/material';
import { spacing } from '@mui/system';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const AppContent = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-right: ${(props) => props.theme.spacing(3)};
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Paper = styled(MuiPaper)(spacing);

export const MainContent = styled(Paper)`
  flex: 1;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(3)};
  overflow: hidden;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;

import { Box, Button, OutlinedInput, Slider, Typography } from '@mui/material';
import styled from 'styled-components';

export const PointsContainer = styled(Box)`
  height: 100%;
  background: #f5fcf4;
  border-radius: ${({ theme }) => theme.spacing(3)};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  padding: ${({ theme }) => theme.spacing(6, 7, 6, 9)};
`;

export const TotalContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  height: 100%;
  background: #f5fcf4;
  border-radius: ${({ theme }) => theme.spacing(3)};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
`;

export const LabelsColumn = styled(Box)`
  width: 50%;
  padding: 30px 15px 30px 45px;
`;

export const PointsLabel = styled(Typography)``;

export const PointsValue = styled.div`
  font-size: 36px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const TransferPointsButton = styled(Button)`
  background-color: #e0f3f6;
  color: #0099ff;
  border-radius: 8px;
  padding: 0 15px;

  &:hover {
    background-color: #c2d2e1;
  }

  & svg {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
`;

export const RewardPointsProgressBar = styled(Slider)`
  height: 6px;
  &.Mui-disabled {
    color: #5d6081;
  }
  & .MuiSlider-rail {
    border: ${(props) => `1px solid ${props.theme.knowledgeBase.color}`};
    background-color: transparent;
  }
  & .MuiSlider-thumb {
    width: 0;
  }
  & .MuiSlider-valueLabel {
    font-size: 10px;
    bottom: 0px;
    background-color: unset;
    color: ${(props) => props.theme.palette.text.secondary};
    transform: translateY(20px);
    & * {
      background: transparent;
      color: ${(props) => props.theme.palette.text.secondary};
    }
  }
`;

export const PointsActivityContainer = styled(Box)`
  background-color: #f3f6f8cc;
  border-radius: 16px;
  margin-bottom: 40px;
  padding: ${({ theme }) => theme.spacing(8, 12)};
`;

export const PointsActivityTitle = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const DatePickerInput = styled(OutlinedInput)`
  padding-right: 12px;

  & .MuiOutlinedInput-input {
    padding: 6px 0px 6px 8px;
  }

  & .MuiInputAdornment-root {
    margin-left: 4px;
  }
  & svg {
    margin-right: 4px;
  }
`;

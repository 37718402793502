import Chat from '@/components/message-center/Chat/Chat';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { setCurrentChat } from '@/redux/reducers/chat';
import {
  chatUserSelector,
  conversationsSelector,
  currentChatSelector,
} from '@/redux/selectors/chatSelector';
import { EConversationType } from '@/types/consts';
import { IGroupChat } from '@/types/models';

import { Box } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import {
  ChatDashboardWrapper,
  DashboardTitleWrapper,
} from '../DashboardWorkspace/component';
import { useNavigate } from 'react-router-dom';

const ChatWidget: FC = () => {
  const ref = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const dispatch = useAppDispatch();
  const userChat = useAppSelector(chatUserSelector);
  const chat = useAppSelector(currentChatSelector);
  const groupChats = useAppSelector(
    conversationsSelector(EConversationType.GROUP)
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (
      userChat &&
      !!groupChats?.length &&
      (groupChats[0].conversationWith as IGroupChat).guid !== chat?.chatId
    ) {
      dispatch(
        setCurrentChat({
          isGroup: true,
          chatId: (groupChats[0].conversationWith as IGroupChat).guid,
        })
      );
    }
  }, [groupChats, chat?.chatId, dispatch, userChat]);

  const handlerOutsideClick = (e: any) => {
    if (!e.composedPath().includes(ref.current)) {
      setIsScrolled(false);
    } else {
      setIsScrolled(true);
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', handlerOutsideClick);

    return () => {
      document.body.removeEventListener('click', handlerOutsideClick);
    };
  }, []);

  return (
    <ChatDashboardWrapper ref={ref}>
      <DashboardTitleWrapper onClick={() => navigate('/message-center')}>
        <Box className="title">Team Messages Channel</Box>
      </DashboardTitleWrapper>
      <Chat
        hideHeader
        isWidget
        isScrolled={isScrolled}
        setIsScrolled={setIsScrolled}
      />
    </ChatDashboardWrapper>
  );
};

export default ChatWidget;

import PictureMask from '@/components/icons/PictureMask.svg';
import {
  Box,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import styled from 'styled-components';

export const DialogWrapper = styled(Dialog)`
  & .MuiPaper-root {
    border-radius: 16px;
  }
`;

export const ModalDialogTitle = styled(DialogTitle)`
  position: relative;
`;

export const CloseIconWrapper = styled(IconButton)`
  position: absolute;
  content: '';
  right: 16px;
  top: 16px;
`;

export const Title = styled(Box)`
  width: 100%;
  text-align: center;
`;

export const LogoSelect = styled(Grid)`
  width: 247px;
  margin-top: ${({ theme }) => theme.spacing(4)};
  background-color: #f3f6f8;
  padding: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.spacing(3)};
`;

export const IconBox = styled(Box)`
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

export const Mask = styled.div`
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background-image: url(${PictureMask});
`;

export const DefaultAvatar = styled(Box)`
  background-color: #ffa800;
`;

export const UserInitial = styled(Typography)`
  font-weight: 700;
  font-size: 50px;
  line-height: 45px;
  color: ${({ theme }) => theme.palette.neutral.main};
  opacity: 0.7;
`;

export const FileInput = styled.input`
  display: none;
`;

import CompanyBillingAddressForm from '@/components/admin/company/CompanyBillingAddressForm';
import CreateUserButtonComponent from '@/components/admin/user/CreateUserButton';

import AddTeammembersModal from '@/components/team-admin/AddTeammembersModal';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { toggleDrawer } from '@/redux/reducers/componentsManage';
import { selectStateDrawer } from '@/redux/selectors/componentsManage';
import { Box, Drawer, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const DrawerContainer = styled(Box)`
  max-width: 830px;
  padding: ${({ theme }) => theme.spacing(7, 9)};
`;

const DrawerHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CompanyCreate = () => {
  const dispatch = useAppDispatch();
  const drawerState = useAppSelector(selectStateDrawer);

  const handleCloseDrawer = () => {
    dispatch(toggleDrawer(false));
  };

  return (
    <Drawer anchor={'right'} open={drawerState} onClose={handleCloseDrawer}>
      <DrawerContainer>
        <DrawerHeader>
          <Typography variant={'h3'} mb={5}>
            New Company
          </Typography>

          <CreateUserButtonComponent />
          <AddTeammembersModal />
        </DrawerHeader>

        <CompanyBillingAddressForm />
      </DrawerContainer>
    </Drawer>
  );
};

export default CompanyCreate;

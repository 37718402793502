import DataGrid from '@/components/UI/DataGrid';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import useCompany from '@/hooks/useCompany';
import { getTeamActivitiesById } from '@/redux/actions/company';
import { getTeamDashboardStatisticsSelector } from '@/redux/selectors/companySelectors';
import { getRoutePrefixByRoleName } from '@/utils/routeRoleHelpers';
import { Box } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { teamDashboardColumns } from './constants';

const TeamDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { company } = useCompany();
  const rows = useAppSelector(getTeamDashboardStatisticsSelector);
  const { userRole } = useAuth();
  const handleRowClick = (params: GridRowParams) => {
    navigate(
      getRoutePrefixByRoleName(userRole) + `/user/${params.id}/information`
    );
  };
  useEffect(() => {
    if (company) {
      dispatch(getTeamActivitiesById(company.id));
    }
  }, [dispatch, company]);

  if (!rows) {
    return null;
  }

  return (
    <Box>
      <DataGrid
        columns={teamDashboardColumns}
        loading={false}
        rows={rows}
        pageSize={50}
        rowsPerPageOptions={[50]}
        onRowClick={handleRowClick}
      />
    </Box>
  );
};

export default TeamDashboard;

import ColorfulHorizontalProgress from '@/components/progressMeter/ColorfulHorizontalProgress/ColorfulHorizontalProgress';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { getUserKnowledgeBase } from '@/redux/actions/users';
import {
  adminSelectedUser,
  adminUserKnowledgeBaseSelector,
} from '@/redux/selectors/users';
import { IUser } from '@/types/models';
import React, { useEffect } from 'react';
import { PointsProggressTitle } from './components';

interface IKnowledgeCenterProgress {
  userId?: number;
}

const KnolwledgeCenterProggress: React.FC<IKnowledgeCenterProgress> = ({
  userId,
}) => {
  const user = useAppSelector(adminSelectedUser) as IUser;
  const knowledgeBase = useAppSelector(adminUserKnowledgeBaseSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserKnowledgeBase(userId || Number(user.id)));
  }, [dispatch, user?.id, userId]);

  return (
    <>
      <PointsProggressTitle variant={'h2'}>
        Knowledge Base Progress
      </PointsProggressTitle>
      <ColorfulHorizontalProgress
        display="block"
        current={knowledgeBase?.filter((item) => item.alreadyCompleted).length}
        max={knowledgeBase?.length}
      />
    </>
  );
};

export default KnolwledgeCenterProggress;

import { PlayIcon } from '@/components/icons/knowledge-base';
import { KnowledgeBaseSmallPreviewBlock } from '@/components/knowledgeBase/VideoContainer/components';
import useAppSelector from '@/hooks/useAppSelector';
import React, { FC } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useParams } from 'react-router-dom';
import * as crypto from 'crypto-js';

const VideoContainer: FC<{
  fileUrl: string;
  previewUrl: string;
  // setIsCompleted: (agr: boolean) => void;
  finishReading: () => void;
}> = ({ fileUrl, previewUrl, finishReading }) => {
  const user = useAppSelector((state) => state.user.user);
  const { itemId } = useParams();

  const disableFunctions = () => {
    const video = document.getElementsByTagName('video')[0] as HTMLVideoElement;
    let supposedCurrentTime = 0;

    if (!video) {
      return;
    }
    video.addEventListener(
      'loadedmetadata',
      function () {
        if (user) {
          const current = localStorage.getItem(
            `timestampUser=${user.id}Video=${itemId}`
          );
          if (current) {
            const bytes = crypto.AES.decrypt(current, user.email);
            const originalText = bytes.toString(crypto.enc.Utf8);
            this.currentTime = +originalText;
            supposedCurrentTime = +originalText;
          }
        }
      },
      false
    );

    video.addEventListener('timeupdate', function () {
      if (user) {
        const ciphertext = crypto.AES.encrypt(
          String(video.currentTime),
          user.email
        ).toString();
        localStorage.setItem(
          `timestampUser=${user.id}Video=${itemId}`,
          ciphertext
        );
      }
      if (!video.seeking) {
        supposedCurrentTime = video.currentTime;
      }
    });

    video.addEventListener('seeking', function () {
      const delta = video.currentTime - supposedCurrentTime;
      if (delta > 0.01) {
        video.currentTime = supposedCurrentTime;
      }
    });

    video.addEventListener('ended', function () {
      supposedCurrentTime = 0;
    });

    video.addEventListener('ratechange', () => {
      video.playbackRate = 1;
    });

    video.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  };

  return (
    <KnowledgeBaseSmallPreviewBlock>
      <ReactPlayer
        url={fileUrl}
        onEnded={finishReading}
        onReady={() => disableFunctions()}
        onClickPreview={() => setTimeout(() => disableFunctions(), 100)}
        className="player"
        width="100%"
        height="100%"
        volume={0.5}
        config={{
          file: {
            attributes: {
              controls: 'true',
              autoplay: 'true',
              controlsList: 'nodownload noplaybackrate',
              oncontextmenu: 'return false;',
            },
          },
        }}
        light={previewUrl}
        playIcon={<PlayIcon />}
      />
    </KnowledgeBaseSmallPreviewBlock>
  );
};

export default VideoContainer;

import useAppDispatch from '@/hooks/useAppDispatch';
import {
  AuthButton,
  AuthError,
  ButtonBetween,
  CenteredButtonContainer,
  FlexRow,
  ForgotTitle,
  FormContainer,
  FormControlWrapper,
  SubTitle,
} from '@/pages/auth/components';
import { forgotPassword } from '@/redux/actions/user';
import { IForgotPassword } from '@/types/models';
import { FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .min(5, 'Too Short!')
    .max(50, 'Too Long!')
    .email('Incorrect email')
    .required('Required'),
});

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [successAction, setActionSuccess] = useState<boolean>(false);
  const [forgotPassError, setForgotPassError] = useState<string>('');
  const dispatch = useAppDispatch();

  const onForgotPassword = useCallback(
    (values: IForgotPassword, actions: FormikHelpers<IForgotPassword>) => {
      dispatch(forgotPassword(values))
        .unwrap()
        .then(() => {
          setActionSuccess(true);
        })
        .catch(({ error }) => {
          console.error(error);
          setForgotPassError('No account found with that email address');
        })
        .finally(() => actions.setSubmitting(false));
    },
    [dispatch]
  );

  const backNavigate = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  if (successAction) {
    return (
      <div>
        <ForgotTitle>A password reset link has been sent to email</ForgotTitle>
        <CenteredButtonContainer>
          <AuthButton onClick={backNavigate} color="info">
            Back to Sign-In
          </AuthButton>
        </CenteredButtonContainer>
      </div>
    );
  }

  return (
    <div>
      <SubTitle error={!!forgotPassError}>Forgot password</SubTitle>
      {!!forgotPassError && <AuthError> {forgotPassError} </AuthError>}
      <Formik
        initialValues={{ email: '' }}
        validationSchema={ForgotPasswordSchema}
        onSubmit={(values, actions) => onForgotPassword(values, actions)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
        }) => (
          <FormContainer onSubmit={handleSubmit}>
            <FormControlWrapper
              hiddenLabel={!values.email}
              error={!!errors.email && !!touched.email}
            >
              {!!values.email && <InputLabel>Email</InputLabel>}
              <OutlinedInput
                fullWidth
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                placeholder="Email"
                error={!!errors.email && !!touched.email}
              />
              {errors.email && touched.email && (
                <FormHelperText id="email" error>
                  {errors.email}
                </FormHelperText>
              )}
            </FormControlWrapper>
            <FlexRow justifyContent="space-between">
              <ButtonBetween color="info" onClick={backNavigate}>
                &lt; Back
              </ButtonBetween>
              <ButtonBetween
                color="primary"
                type="submit"
                disabled={!isValid && isSubmitting}
              >
                Recover
              </ButtonBetween>
            </FlexRow>
          </FormContainer>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;

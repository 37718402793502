import { Badge, Box, Popover as MuiPopover } from '@mui/material';
import styled from 'styled-components';

export const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 400px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

export const Indicator = styled(Badge)`
  .MuiBadge-badge {
    width: 8px;
    min-width: 8px;
    height: 8px;
    padding: 0;
    background: ${({ theme }) => theme.header.indicator.background};
    color: ${({ theme }) => theme.header.background};
    border: 1px solid ${({ theme }) => theme.palette.common.white};
  }
`;

export const NotificationHeader = styled(Box)`
  text-align: right;
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 12px;
`;

export const NotificationsBox = styled(Box)`
  overflow: auto;
  max-height: 500px;
  padding: 0 10px 10px 10px;
`;

import Header from '@/components/admin/Header';
import GlobalStyle from '@/components/GlobalStyle';
import SuperAdminGuard from '@/components/guards/SuperAdminGuard';
import ScrollbarComponent from '@/components/scrollbar/Scrollbar';
import ChatProvider from '@/sokets/ChatSocket';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${(props) => props.theme.palette.common.white};
`;

const SuperAdminLayout: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <SuperAdminGuard>
      <ChatProvider>
        <ScrollbarComponent>
          <Root>
            <CssBaseline />
            <GlobalStyle />
            <Header />
            {children}
          </Root>
        </ScrollbarComponent>
      </ChatProvider>
    </SuperAdminGuard>
  );
};

export default SuperAdminLayout;

export enum ECompany {
  getCompanies = 'company/getCompanies',
  getCompany = 'company/getCompany',
  createCompany = 'company/createCompany',
  updateCompany = 'company/updateCompany',
  changeAutoCalculatorCompany = 'company/changeAutoCalculatorCompany',
  deleteCompany = 'company/deleteCompany',
  getCompanyActivity = 'company/getCompanyActivity',
  getCompanyMessagingStat = 'company/getCompanyMessagingStat',
  getUserProgress = 'company/getUserProgress',
  searchCompanies = 'company/searchCompany',
  getSimpleCompanies = 'company/getSimpleCompany',
  getCompanyUsersRewardPoints = 'company/getCompanyUsersRewardPoints',
  updateCompanyRewardPoints = 'company/updateCompanyRewardPoints',
  updateCompanyFlexPoints = 'company/updateCompanyFlexPoints',
  updateCompanyUsersRewardPoints = 'company/updateCompanyUsersRewardPoints',
  giftCompanyUsersRewardPoints = 'company/giftCompanyUsersRewardPoints',
  companyPointsManagmentHistory = 'company/companyPointsHistory',
  usersPointsManagmentHistory = 'company/usersPointsHistory',
  getCompanyReport = 'company/loadReport',
  getCompanyTeamDashboardReport = 'company/loadTeamDashboardReport',
  getCompanyRewardPointsReport = 'company/loadRewardPoints',
  getTeamActivitiesById = 'company/getTeamActivitiesById',
  getCompanyPeriodProgress = 'company/getCompanyPeriodProgress',
  refundCompanyRewardPoints = 'company/refundCompanyRewardPoints',
  getChatSupportsByCompanyId = 'company/getChatSupportsByCompanyId',
}

export enum ECompanyShippingAddresses {
  getCompanyShippingAddress = 'company/getCompanyShippingAddress',
  createCompanyShippingAddress = 'company/createCompanyShippingAddress',
  updateCompanyShippingAddress = 'company/updateCompanyShippingAddress',
  deleteCompanyShippingAddress = 'company/deleteCompanyShippingAddress',
}

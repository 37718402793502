import {
  AvatarContainer,
  StatusDot,
} from '@/components/message-center/components';
import { AvatarType, CustomAvatar } from '@/components/UI/CustomAvatar';
import { IUser } from '@/types/models';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import React, { FC } from 'react';
import { Badge } from '../SidebarNavListItem';
import { ChatItemContent, UserChatName } from './components';

interface IUserChatNavItemProps {
  isOnline: boolean;
  user: IUser;
  unreadCount?: number;
  isOpen: boolean;
  active: boolean;
}

const UserChatNavItem: FC<IUserChatNavItemProps> = ({
  isOpen,
  user,
  isOnline,
  unreadCount,
  active,
}) => {
  return (
    <ChatItemContent isOpen={isOpen} active={active}>
      <AvatarContainer>
        <CustomAvatar
          diameter={24}
          avatartype={AvatarType.USER}
          sx={{
            fontSize: 16,
          }}
          alt="Remy Sharp"
          src={user?.profileImage || undefined}
        >
          {!user
            ? ''
            : getUserAvatarLetter({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
              })}
        </CustomAvatar>
        <StatusDot isSmall={true} isOnline={isOnline}></StatusDot>
      </AvatarContainer>
      <UserChatName isOpen={isOpen}>
        {user?.firstName ? `${user?.firstName} ${user.lastName}` : user.email}
      </UserChatName>
      {!!unreadCount && <Badge open={isOpen} label={unreadCount} />}
    </ChatItemContent>
  );
};

export default UserChatNavItem;

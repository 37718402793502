import { FormTitle } from '@/components/form-elements/components';
import { CameraIcon, TrashIcon } from '@/components/icons';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import { deleteAvatar, uploadAvatar } from '@/redux/actions/user';
import { deleteUserAvatar, uploadUserAvatar } from '@/redux/actions/users';
import { getUserSelector } from '@/redux/selectors/userSelectors';
import { DEFAULT_TOAST_TIME, MAX_AVATAR_IMAGE_SIZE } from '@/types/consts';
import { IUser, IWithUser } from '@/types/models';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import { Box, CircularProgress } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  DefaultAvatar,
  FileInput,
  IconBox,
  LogoSelect,
  Mask,
  UserInitial,
} from './components';
import ImageCropperModal from './ImageCropModal';

const UserImage: React.FC<IWithUser> = ({ user }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(getUserSelector) as IUser;
  const { profileImage, id, lastName, firstName, email } = user || currentUser;
  const { isUser, isRwAdmin } = useAuth();
  const fileInput = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<string | null>(null);
  const [isOpenCropper, setCropperState] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const onUploadBtnClick = useCallback(() => {
    if (fileInput?.current) {
      fileInput.current.click();
    }
  }, [fileInput]);

  const onImageUpload = useCallback((e: any) => {
    e.preventDefault();
    const files = e.target.files;
    if (files[0] && files[0].size < MAX_AVATAR_IMAGE_SIZE) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as any);
        setCropperState(true);
        e.target.value = null;
      };
      reader.readAsDataURL(files[0]);
    } else {
      toast.warning('Please upload a photo smaller than 5 Mb');
      console.log('Please upload a photo smaller than 5 Mb');
    }
  }, []);

  const onDeleteAvatar = useCallback(async () => {
    try {
      if (isUser || id === currentUser.id) {
        await dispatch(deleteAvatar()).unwrap();
      } else {
        await dispatch(deleteUserAvatar(id)).unwrap();
      }

      toast.success('Avatar was successfully deleted', {
        position: 'top-right',
        autoClose: DEFAULT_TOAST_TIME,
        hideProgressBar: false,
      });
    } catch (error: any) {
      console.error(error);
      toast.error(error, {
        position: 'top-right',
      });
    }
  }, []);

  const handleCropperClose = useCallback(
    async (image?: string, blob?: Blob) => {
      if (fileInput?.current?.value) {
        fileInput.current.value = '';
      }

      if (blob) {
        setSubmitting(true);
        try {
          if (isUser || id === currentUser.id) {
            await dispatch(uploadAvatar(blob)).unwrap();
          } else {
            await dispatch(uploadUserAvatar({ id, blob })).unwrap();
          }

          toast.success('Avatar was successfully updated', {
            position: 'top-right',
            autoClose: DEFAULT_TOAST_TIME,
            hideProgressBar: false,
          });
        } catch (error: any) {
          console.error(error);
          toast.error(error, {
            position: 'top-right',
          });
        }
        setSubmitting(false);
      }
      setCropperState(false);
    },
    [dispatch, id, isUser]
  );

  return (
    <div>
      <FormTitle>Profile Picture</FormTitle>
      <FileInput
        type="file"
        accept=".png, .jpeg, .jpg, .PNG, .JPEG, .JPG"
        ref={fileInput}
        onChange={onImageUpload}
      />
      <LogoSelect>
        <Box height={212} width={212} position={'relative'}>
          {isSubmitting ? (
            <Box
              height={212}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {!!profileImage ? (
                <img
                  height={212}
                  width={212}
                  src={profileImage as string}
                  alt="user-avatar"
                />
              ) : (
                <DefaultAvatar
                  height={212}
                  width={212}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <UserInitial>
                    {getUserAvatarLetter({
                      firstName,
                      lastName,
                      email,
                    })}
                  </UserInitial>
                </DefaultAvatar>
              )}
            </>
          )}
          <Mask>
            {(!isRwAdmin || currentUser.id === id) && (
              <IconBox left={10} onClick={onUploadBtnClick}>
                <CameraIcon />
              </IconBox>
            )}
            {(!isRwAdmin || currentUser.id === id) && profileImage && (
              <IconBox right={10} onClick={onDeleteAvatar}>
                <TrashIcon />
              </IconBox>
            )}
          </Mask>
        </Box>
      </LogoSelect>
      <ImageCropperModal
        visible={isOpenCropper}
        image={image || ''}
        close={handleCropperClose}
      />
    </div>
  );
};

export default UserImage;

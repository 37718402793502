import useAuth from '@/hooks/useAuth';
import {
  ProfileTitle,
  UserProfileContainer,
} from '@/pages/user-profile/components';
import UserProfileTabs from '@/pages/user-profile/UserProfileTabs';
import React from 'react';

const UserProfile: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isTeamLead, isUser } = useAuth();

  return (
    <UserProfileContainer>
      <ProfileTitle>My Profile</ProfileTitle>
      {(isTeamLead || isUser) && <UserProfileTabs />}
      {children}
    </UserProfileContainer>
  );
};

export default UserProfile;

import {
  EBrakeTimerTypes,
  TBreaktimerSettings,
} from '@/components/breaktimer/constants';
import {
  CountButton,
  FormTitle,
  TitleBox,
} from '@/components/form-elements/components';
import { TimeSettingsIcon } from '@/components/icons';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import { changeBreaktimerSettings } from '@/redux/actions/breaktimers';
import { updateUserBreakTimerSettings } from '@/redux/actions/users';
import { getBreakTimerSettings } from '@/redux/selectors/breakTimersSelectors';
import { DEFAULT_TOAST_TIME } from '@/types/consts';
import { IBreakTimerSetting, IUser, IWithUser } from '@/types/models';
import {
  Box,
  Button,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import BreaktimerItem from './BreaktimerItem';
import { BreaktimerForm } from './components';

const breakTimerValidate = (array: any[] | undefined) =>
  array?.every(
    (item, index) =>
      typeof item === 'number' &&
      item % 30 === 0 &&
      array.filter((_, i) => index !== i).every((el) => item !== el)
  ) || false;

const breakTimerValidateIntersection = (
  array: any[] | undefined,
  { parent, path }: any
) => {
  const breakTimersToCheck = Object.keys(parent).filter(
    (key) => key !== EBrakeTimerTypes.HYDRATE && key !== path
  );

  const allTimesToCheck = breakTimersToCheck.reduce((acc, el) => {
    acc.push(...parent[el]);
    return acc;
  }, [] as number[]);

  return allTimesToCheck.filter((value) => array?.includes(value)).length === 0;
};

const BreaktimerSchema = Yup.object().shape({
  [EBrakeTimerTypes.HYDRATE]: Yup.array()
    .of(
      Yup.number()
        .required('Required')
        .test(
          'thirtyMinutes',
          '',
          (item) => typeof item === 'number' && item % 30 === 0
        )
    )
    .test(
      'hasRange',
      'The time range between breaks should be at least 30 minutes.',
      breakTimerValidate
    ),
  [EBrakeTimerTypes.MOVE]: Yup.array()
    .of(
      Yup.number()
        .required('Required')
        .test(
          'thirtyMinutes',
          '',
          (item) => typeof item === 'number' && item % 30 === 0
        )
    )
    .test(
      'hasRange',
      'The time range between breaks should be at least 30 minutes.',
      breakTimerValidate
    )
    .test(
      'hasIntersection',
      'Break times for Relax, Stretch and Move should be unique.',
      breakTimerValidateIntersection
    ),
  [EBrakeTimerTypes.STRETCH]: Yup.array()
    .of(
      Yup.number()
        .required('Required')
        .test(
          'thirtyMinutes',
          '',
          (item) => typeof item === 'number' && item % 30 === 0
        )
    )
    .test(
      'hasRange',
      'The time range between breaks should be at least 30 minutes.',
      breakTimerValidate
    )
    .test(
      'hasIntersection',
      'Break times for Relax, Stretch and Move should be unique.',
      breakTimerValidateIntersection
    ),
  [EBrakeTimerTypes.RELAX]: Yup.array()
    .of(
      Yup.number()
        .required('Required')
        .test(
          'thirtyMinutes',
          '',
          (item) => typeof item === 'number' && item % 30 === 0
        )
    )
    .test(
      'hasRange',
      'The time range between breaks should be at least 30 minutes.',
      breakTimerValidate
    )
    .test(
      'hasIntersection',
      'Break times for Relax, Stretch and Move should be unique.',
      breakTimerValidateIntersection
    ),
});

const BreaktimerSettings: React.FC<IWithUser> = ({ user }) => {
  const dispatch = useAppDispatch();
  const { isUser, user: currentUser } = useAuth();
  const currentUserSettings = useAppSelector(getBreakTimerSettings);
  const { id } = user || (currentUser as IUser);
  const settings = user
    ? user?.breakTimers.reduce((acc, item) => {
        acc[item.type.name] = item.timePoints;
        return acc;
      }, {} as TBreaktimerSettings)
    : currentUserSettings;
  const [openTimeSettings, setTimeSettings] = useState<boolean>(false);

  const onBreakTimersSave = useCallback(
    async (values: any, actions: FormikHelpers<IBreakTimerSetting>) => {
      const breaktimers = Object.entries(values).map(
        ([key, value]) =>
          ({
            type: key,
            timePoints: value,
          } as IBreakTimerSetting)
      );

      try {
        if (isUser || id === currentUser?.id) {
          await dispatch(changeBreaktimerSettings(breaktimers)).unwrap();
        } else {
          await dispatch(
            updateUserBreakTimerSettings({
              id: user?.id as number,
              breaktimers,
            })
          ).unwrap();
        }
        toast.success('Your changes will be applied starting tomorrow', {
          position: 'top-right',
          autoClose: DEFAULT_TOAST_TIME,
          hideProgressBar: false,
        });
      } catch (error: any) {
        console.error(error);
        toast.error(`${error}`, { position: 'top-right' });
      } finally {
        actions.setSubmitting(false);
        actions.resetForm({ values });
      }
    },
    [dispatch]
  );

  const changeTimer = useCallback(
    (
      count: number,
      values: TBreaktimerSettings,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void
    ) => {
      if (values) {
        Object.entries(values).map(([key, item]) => {
          (values[key as keyof TBreaktimerSettings] as number[]).forEach(
            (time: number, index) => {
              setFieldValue(`${key}.${index}`, time + count);
            }
          );
        });
      }
    },
    []
  );

  return (
    <>
      {settings && Object.values(settings).every(({ length }) => length > 0) ? (
        <Formik
          initialValues={{
            ...(settings as Required<TBreaktimerSettings>),
          }}
          onSubmit={(values, actions) =>
            onBreakTimersSave(values, actions as any)
          }
          validationSchema={BreaktimerSchema}
        >
          {({
            handleSubmit,
            setFieldValue,
            isValid,
            isSubmitting,
            dirty,
            values,
          }) => (
            <BreaktimerForm onSubmit={handleSubmit}>
              <TitleBox display="flex" alignItems="flex-end">
                <Typography variant="h1">
                  Set Break Timer Notifications
                </Typography>
                <Tooltip
                  title={
                    'To allow for Daylight Savings Time or to shift Time Zones, you can adjust your Break Timer start times here.'
                  }
                >
                  <IconButton
                    style={{ margin: '0 5px' }}
                    color={openTimeSettings ? 'primary' : 'default'}
                    onClick={() => setTimeSettings((val) => !val)}
                  >
                    <TimeSettingsIcon />
                  </IconButton>
                </Tooltip>
                {openTimeSettings && (
                  <Box display="flex">
                    <CountButton
                      onClick={() => changeTimer(60, values, setFieldValue)}
                    >
                      +
                    </CountButton>
                    <CountButton
                      onClick={() => changeTimer(-60, values, setFieldValue)}
                    >
                      -
                    </CountButton>
                  </Box>
                )}
              </TitleBox>
              <BreaktimerItem type={EBrakeTimerTypes.HYDRATE} />
              <BreaktimerItem type={EBrakeTimerTypes.RELAX} />
              <BreaktimerItem type={EBrakeTimerTypes.STRETCH} />
              <BreaktimerItem type={EBrakeTimerTypes.MOVE} />
              <Box display="flex" justifyContent="center">
                <Button
                  type="submit"
                  disabled={!(isValid && dirty && !isSubmitting)}
                  color="secondary"
                  variant="outlined"
                >
                  Save
                </Button>
              </Box>
            </BreaktimerForm>
          )}
        </Formik>
      ) : (
        <>
          <FormTitle>Set Break Timer Notifications</FormTitle>
          <Skeleton height="200" animation="wave" />
        </>
      )}
    </>
  );
};

export default BreaktimerSettings;

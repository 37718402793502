import { ChewronLeftIcon, ChewronRightIcon } from '@/components/icons';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { getMyProgress } from '@/redux/actions/activity';
import { getCompanyProgress } from '@/redux/actions/company';
import { getUserProgress } from '@/redux/actions/users';
import { myStatisticSelector } from '@/redux/selectors/activitySelector';
import { companyStatistic } from '@/redux/selectors/companySelectors';
import { adminUserStatistic } from '@/redux/selectors/users';
import { EActivityLevel, EPeriod } from '@/types/consts';
import { IActivityProgress } from '@/types/models';
import {
  getEndOfMonth,
  getEndOfWeek,
  getStartOfMonth,
  getStartOfWeek,
} from '@/utils/dateHelpers';
import { CircularProgress, Tab, Tabs } from '@mui/material';
import {
  eachDayOfInterval,
  format,
  Interval,
  parse,
  subMonths,
  subWeeks,
} from 'date-fns';
import React, { useEffect, useState } from 'react';
import {
  DiagramContainer,
  PeriodIconButton,
  PeriodLabel,
  PeriodSwitcher,
  PersonalActivityContainer,
  SubTitle,
  TabsContainer,
  Title,
} from './components';
import ProgressActivityDiagram from './PersonalActivityDiagram';

interface IActivity {
  mental: number[];
  social: number[];
  physical: number[];
}

interface PersonalActivityProps {
  userId?: number | null;
  companyId?: number | null;
}

const PersonalActivity: React.FC<PersonalActivityProps> = ({
  userId,
  companyId,
}) => {
  const dispatch = useAppDispatch();
  const myProgress = useAppSelector(myStatisticSelector);
  const userProgress = useAppSelector(adminUserStatistic);
  const companyProgress = useAppSelector(companyStatistic);
  const [progress, setProgress] = useState<IActivityProgress[] | null>(null);
  const [activity, setActivity] = useState<IActivity | null>(null);
  const [labels, setLabels] = useState<string[]>([]);
  const [period, setPeriod] = useState<EPeriod>(EPeriod.WEEK);
  const [periodStep, setPeriodStep] = useState<number>(0);
  const [interval, setPeriodInterval] = useState<Interval | null>(null);

  useEffect(() => {
    setPeriodStep(0);
  }, [period]);

  useEffect(() => {
    const start =
      period === EPeriod.WEEK
        ? getStartOfWeek(subWeeks(new Date(), periodStep))
        : getStartOfMonth(subMonths(new Date(), periodStep));
    const end =
      period === EPeriod.WEEK
        ? getEndOfWeek(subWeeks(new Date(), periodStep))
        : getEndOfMonth(subMonths(new Date(), periodStep));

    setPeriodInterval({
      start,
      end,
    });
    const dataRange = {
      from: format(start, 'yyyy-MM-dd'),
      to: format(end, 'yyyy-MM-dd'),
      dataLevel: EActivityLevel.STATISTIC,
    };
    if (companyId) {
      dispatch(
        getCompanyProgress({
          ...dataRange,
          companyId,
        })
      );
    } else if (!userId) {
      dispatch(getMyProgress(dataRange));
    } else {
      dispatch(
        getUserProgress({
          ...dataRange,
          userId,
        })
      );
    }
  }, [periodStep, period, userId, companyId]);

  useEffect(() => {
    setProgress(
      companyId ? companyProgress : userId ? userProgress : myProgress
    );
  }, [myProgress, userProgress, companyProgress, userId, companyId]);

  useEffect(() => {
    if (progress) {
      setLabels(
        progress.map(({ date }) => {
          const [dateString] = date.split('T');
          const day = parse(dateString, 'yyyy-MM-dd', new Date());
          return `${day.getMonth() + 1}/${day.getDate()}`;
        })
      );
      setActivity(
        progress.reduce(
          (curr, { social, mental, physical }) => ({
            social: [...curr.social, social],
            mental: [...curr.mental, mental],
            physical: [...curr.physical, physical],
          }),
          {
            social: [],
            mental: [],
            physical: [],
          } as IActivity
        )
      );
    } else if (interval) {
      setLabels(
        eachDayOfInterval(interval).map(
          (day) => `${day.getMonth() + 1}/${day.getDate()}`
        )
      );
      setActivity(
        eachDayOfInterval(interval).reduce(
          (curr, _) => ({
            social: [...curr.social, 0],
            mental: [...curr.mental, 0],
            physical: [...curr.physical, 0],
          }),
          {
            social: [],
            mental: [],
            physical: [],
          } as IActivity
        )
      );
    }
  }, [progress, interval]);

  return (
    <PersonalActivityContainer>
      <Title>{companyId ? 'Activity' : 'Personal Activity'}</Title>
      <TabsContainer component={'section'}>
        <Tabs
          value={period}
          onChange={(e, value) => setPeriod(value)}
          centered
          textColor={'secondary'}
        >
          <Tab value={EPeriod.WEEK} label="Week" />
          <Tab value={EPeriod.MONTH} label="Month" />
        </Tabs>
      </TabsContainer>
      <DiagramContainer>
        <SubTitle>Wellbeing Essential Daily Progress</SubTitle>
        <PeriodSwitcher>
          <PeriodIconButton
            aria-label="prev"
            onClick={() => setPeriodStep((step) => step + 1)}
          >
            <ChewronLeftIcon />
          </PeriodIconButton>
          <PeriodLabel>
            {!periodStep
              ? `Current ${period}`
              : period === EPeriod.WEEK
              ? `${labels[0]} - ${labels[labels.length - 1]}`
              : format(interval?.start as Date, 'MMMM')}
          </PeriodLabel>
          <PeriodIconButton
            aria-label="next"
            disabled={!periodStep}
            onClick={() => setPeriodStep((step) => step - 1)}
          >
            <ChewronRightIcon />
          </PeriodIconButton>
        </PeriodSwitcher>
        {activity ? (
          <ProgressActivityDiagram
            labels={labels}
            mental={activity.mental}
            physical={activity.physical}
            social={activity.social}
          />
        ) : (
          <CircularProgress />
        )}
      </DiagramContainer>
    </PersonalActivityContainer>
  );
};

export default PersonalActivity;

import { useEffect, useRef } from 'react';
import ReactPerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar';

import 'react-perfect-scrollbar/dist/css/styles.css';
import styled, { css } from 'styled-components';

const baseScrollbar = css`
  & .ps {
    &__rail-x:hover,
    &__rail-y:hover,
    &__rail-x:focus,
    &__rail-y:focus,
    &__rail-x.ps--clicking,
    &__rail-y.ps--clicking {
      background-color: transparent;
    }
  }
  & .ps {
    &__thumb-y {
      min-height: 32px;
      min-width: 12px;
    }
  }
  & .ps {
    &__thumb-x {
      min-height: 12px;
      min-width: 32px;
    }
  }
`;

export const Scrollbar = styled.div`
  ${baseScrollbar}
`;

export const PerfectScrollbar = styled(ReactPerfectScrollbar)<ScrollBarProps>`
  ${baseScrollbar}
`;

interface ScrollbarComponentProps extends ScrollBarProps {
  isscrolltop?: boolean;
}

const ScrollbarComponent: React.FC<ScrollbarComponentProps> = (props) => {
  const { children, isscrolltop } = props;
  const ref = useRef<HTMLElement>();

  function scrollTop() {
    const curr = ref.current;
    if (curr) {
      curr.scrollTop = 0;
    }
  }

  useEffect(() => {
    if (isscrolltop) {
      scrollTop();
    }
  }, [children]);

  return (
    <PerfectScrollbar
      containerRef={(element) => (ref.current = element)}
      {...props}
    >
      {children}
    </PerfectScrollbar>
  );
};

export default ScrollbarComponent;

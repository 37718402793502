import { IColumsName } from '@/pages/super-admin/company/TeamDashboard/types';
import {
  createCompany,
  deleteCompany,
  getCompanies,
  getCompany,
  getCompanyActivityLog,
  getCompanyMessagingStat,
  getCompanyPointManagementHistory,
  getCompanyProgress,
  getTeamActivitiesById,
  getUsersPointManagementHistory,
  searchCompanies,
  updateCompany,
  updateCompanyFlexPoints,
  updateCompanyRewardPoints,
} from '@/redux/actions/company';
import { EActivityLevel } from '@/types/consts';
import {
  IActivityLog,
  IActivityProgress,
  ICompany,
  IMessagesStat,
  IPointsHistory,
  IUser,
} from '@/types/models';
import { createSlice } from '@reduxjs/toolkit';

export interface ITeamDashboardOneUser {
  availablePoints: string;
  earnedPoints: string;
  firstName: string;
  knowledgeCenterProgress: string;
  lastName: string;
  mentalTotal: string;
  messagesSent: string;
  physicalTotal: string;
  redeemedPoints: string;
  socialTotal: string;
  title: string;
  totalRewardPoints: string;
  user: IUser;
  userId: number;
  workspaceProgress: number;
}
export interface CompanyState {
  companiesLoading: boolean;
  companyLoading: boolean;
  companies: ICompany[] | null;
  filteredCompanies: ICompany[] | null;
  filteredCount: number | null;
  companiesCount: number;
  company: ICompany | null;
  progressStatistic: IActivityProgress[] | null;
  progress: IActivityProgress[] | null;
  activitiesLog: IActivityLog[] | null;
  searchText: string;
  messagesStat: IMessagesStat | null;
  pointsHistory: IPointsHistory[] | null;
  countAllPointsHistory: number;
  teamDashboard: ITeamDashboardOneUser[] | null;
  companyTabs: string;
}

const initialState: CompanyState = {
  companiesLoading: false,
  companyLoading: false,
  companies: null,
  companiesCount: 0,
  company: null,
  searchText: '',
  progressStatistic: null,
  progress: null,
  activitiesLog: null,
  filteredCompanies: null,
  filteredCount: null,
  messagesStat: null,
  pointsHistory: null,
  countAllPointsHistory: 0,
  teamDashboard: null,
  companyTabs: '',
};

export const { actions, reducer } = createSlice({
  name: 'company',
  initialState,
  reducers: {
    resetCompanyState: () => ({
      ...initialState,
      loading: false,
    }),
    setCompaniesLoading: (state, { payload }) => {
      state.companiesLoading = payload;
    },
    setCompanyLoading: (state, { payload }) => {
      state.companyLoading = payload;
    },
    setCompanyTabs: (state, { payload }) => {
      state.companyTabs = payload;
    },
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
      state.filteredCompanies = null;
      state.filteredCount = null;
    },
    addUserToCompany: (state, { payload }) => {
      if (state.company) {
        state.company = {
          ...state.company,
          users: [...state.company.users, ...payload],
        };
      }
    },
    setCompanyAvailablePoints: (state, { payload }) => {
      if (state.companies) {
        state.companies = [
          ...state.companies.map((company) => {
            if (company.id === payload.companyId) {
              return {
                ...company,
                points: payload.points,
              };
            }
            return company;
          }),
        ];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.pending, (state) => {
        state.companiesLoading = true;
      })
      .addCase(createCompany.pending, (state) => {
        state.companyLoading = true;
      })
      .addCase(getCompany.pending, (state) => {
        state.companyLoading = true;
      })
      .addCase(deleteCompany.pending, (state) => {
        state.companyLoading = true;
      })
      .addCase(getCompanies.fulfilled, (state, { payload, meta }) => {
        if (!meta.arg.withoutSave) {
          state.companies = state.companies
            ? [...state.companies, ...payload.companies]
            : (payload.companies as ICompany[]);
          state.companiesCount = payload.countAll;
          state.companiesLoading = false;
        }
      })
      .addCase(searchCompanies.fulfilled, (state, { payload }) => {
        state.filteredCompanies = payload.companies as ICompany[];
        state.filteredCount = payload.countAll;
        state.companiesLoading = false;
      })
      .addCase(createCompany.fulfilled, (state, { payload }) => {
        state.company = payload as ICompany;

        if (state.companies && state.companies.length) {
          state.companies = [...state.companies, payload];
          state.companiesCount = state.companiesCount + 1;
        }

        state.companyLoading = false;
      })
      .addCase(getCompany.fulfilled, (state, { payload }) => {
        state.company = payload as ICompany;
        state.companyLoading = false;
      })
      .addCase(getCompanyProgress.fulfilled, (state, { payload }) => {
        if (payload.dataLevel === EActivityLevel.STATISTIC) {
          state.progressStatistic = payload.data;
        } else {
          state.progress = payload.data;
        }
      })
      .addCase(getCompanyActivityLog.fulfilled, (state, { payload }) => {
        state.activitiesLog =
          payload.offset && state.activitiesLog?.length
            ? [...state.activitiesLog, ...payload.data]
            : payload.data;
      })
      .addCase(updateCompany.fulfilled, (state, { payload }) => {
        state.company = payload as ICompany;
        if (state.companies && state.companies.length) {
          state.companies = [
            ...state.companies.map((item) => {
              if (item.id === payload.id) {
                return {
                  ...item,
                  ...payload,
                };
              }
              return item;
            }),
          ];
        }
      })
      .addCase(
        updateCompanyRewardPoints.fulfilled,
        (state, { payload, meta }) => {
          if (!state.company) {
            return;
          }
          state.company.points.availablePoints = meta.arg.amount;
          if (state.companies?.length) {
            state.companies = [
              ...state.companies.map((company) => {
                if (company.id === state.company?.id) {
                  return {
                    ...company,
                    points: {
                      ...company.points,
                      availablePoints: meta.arg.amount,
                    },
                  };
                }
                return company;
              }),
            ];
          }
        }
      )
      .addCase(
        updateCompanyFlexPoints.fulfilled,
        (state, { payload, meta }) => {
          if (!state.company) {
            return;
          }
          state.company.points.flexPoints = meta.arg.amount;
          if (state.companies?.length) {
            state.companies = [
              ...state.companies.map((company) => {
                if (company.id === state.company?.id) {
                  return {
                    ...company,
                    points: {
                      ...company.points,
                      flexPoints: meta.arg.amount,
                    },
                  };
                }
                return company;
              }),
            ];
          }
        }
      )
      .addCase(deleteCompany.fulfilled, (state) => {
        state.companyLoading = false;
      })
      .addCase(getCompanyMessagingStat.fulfilled, (state, { payload }) => {
        state.messagesStat = payload;
      })
      .addCase(
        getCompanyPointManagementHistory.fulfilled,
        (state, { payload, meta }) => {
          state.countAllPointsHistory = payload.countAll;
          if (!state.pointsHistory || meta.arg.offset === 0) {
            state.pointsHistory = payload.logs;
          } else {
            state.pointsHistory = [...state.pointsHistory, ...payload.logs];
          }
        }
      )
      .addCase(
        getUsersPointManagementHistory.fulfilled,
        (state, { payload, meta }) => {
          state.countAllPointsHistory = payload.countAll;
          if (!state.pointsHistory || meta.arg.offset === 0) {
            state.pointsHistory = payload.logs;
          } else {
            state.pointsHistory = [...state.pointsHistory, ...payload.logs];
          }
        }
      )
      .addCase(getTeamActivitiesById.fulfilled, (state, payload) => {
        state.teamDashboard = payload.payload;
      });
  },
});

export const {
  setCompaniesLoading,
  setCompanyLoading,
  resetCompanyState,
  setSearchText,
  addUserToCompany,
  setCompanyAvailablePoints,
  setCompanyTabs,
} = actions;

export default reducer;

import ConfirmModal, { ModalStatuses } from '@/components/dialogs/ConfirmModal';
import { DeleteCompanyIcon } from '@/components/icons';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAuth from '@/hooks/useAuth';
import { deleteCompany } from '@/redux/actions/company';
import { DEFAULT_TOAST_TIME } from '@/types/consts';
import { getRoutePrefixByRoleName } from '@/utils/routeRoleHelpers';
import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import styled from 'styled-components';

const DeleteButton = styled(Button)`
  background-color: #feebef;
  color: ${({ theme }) => theme.palette.redColor.main};

  &:hover {
    background-color: #c2d2e1;
  }
`;

const CompanyDeleteButton = () => {
  const { companyId } = useParams();
  const { userRole } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleOpen = () => {
    setConfirmationOpen(true);
  };

  const handleDelete = () => {
    dispatch(deleteCompany(Number(companyId)))
      .unwrap()
      .then(() => {
        setConfirmationOpen(false);
        navigate(getRoutePrefixByRoleName(userRole) + '/companies');
        toast.success('Company was successfully deleted!', {
          position: 'top-right',
          autoClose: DEFAULT_TOAST_TIME,
          hideProgressBar: false,
        });
      });
  };

  return (
    <>
      <DeleteButton
        onClick={handleOpen}
        type={'button'}
        variant={'contained'}
        color={'primary'}
      >
        <Typography variant={'body1'} mr={1}>
          Delete Company
        </Typography>
        <DeleteCompanyIcon />
      </DeleteButton>
      <ConfirmModal
        confirmStatus={ModalStatuses.PRIMARY}
        text={
          'This action is non-reversible. To confirm company deletion click Continue.'
        }
        isOpen={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default CompanyDeleteButton;

import { ELocalStoreKeys } from '@/types/consts';
import axios, { AxiosResponse } from 'axios';

const AuthKey = process.env.REACT_APP_COMETCHAT_AUTH;
const COMETCHAT_URL = `https://${process.env.REACT_APP_COMETCHAT_ID}.api-${process.env.REACT_APP_COMETCHAT_REGION}.cometchat.io/v3/`;

const getToken = () => {
  return localStorage.getItem(ELocalStoreKeys.CHAT_TOKEN);
};

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const cometchatInstance = axios.create({
  baseURL: COMETCHAT_URL,
  headers: defaultHeaders,
});

const handleRequest = (config: any) => {
  config.headers['apiKey'] = process.env.REACT_APP_COMETCHAT_REST_KEY;
  return config;
};

const handleResponse = (response: AxiosResponse) => {
  return response;
};

export const setupChatResponseInterceptor = () => {
  cometchatInstance.interceptors.request.use(handleRequest);
  cometchatInstance.interceptors.response.use((response) =>
    handleResponse(response)
  );
};

export default cometchatInstance;

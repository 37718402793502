import RewardPointsHistoryWrapper from '@/components/progressMeter/RewardPointsHistory/RewardPointsHistoryWrapper';
import { PointsActivityContainer } from '@/components/user-profile/reward-points/components';
import MonthlyPointsActivity from '@/components/user-profile/reward-points/MonthlyPointsActivity';
import TotalRewardPoints from '@/components/user-profile/reward-points/TotalRewardPoints';
import useAppDispatch from '@/hooks/useAppDispatch';
import { getMyRewardPoints } from '@/redux/actions/activity';
import { Container, Grid } from '@mui/material';
import React, { useEffect } from 'react';

const MyRewardPoints: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMyRewardPoints());
  }, [dispatch]);

  return (
    <Container>
      <Grid
        container
        columnGap={2}
        flexWrap={'nowrap'}
        alignItems={'stretch'}
        my={6}
      >
        <Grid item md={6}>
          <MonthlyPointsActivity />
        </Grid>
        <Grid item md={6}>
          <TotalRewardPoints />
        </Grid>
      </Grid>
      <PointsActivityContainer>
        <RewardPointsHistoryWrapper />
      </PointsActivityContainer>
    </Container>
  );
};

export default MyRewardPoints;

import {
  ICompanyPerioProgress,
  IProgressTableRow,
  IUser,
} from '@/types/models';

export const progressPeriodRows = (
  data: ICompanyPerioProgress[]
): IProgressTableRow[] => {
  return data.map(
    ({ user, mental, physical, social, total }) =>
      ({
        id: user.id,
        email: user.email,
        mental: +mental,
        menterTotals: +total || 0,
        user: {
          firstName: user.firstName || '',
          lastName: user.lastName,
          email: user.email,
          profileImage: user.profileImage,
        },
        phycical: +physical,
        social: +social,
        jobTitle: user.jobTitle,
      } as IProgressTableRow)
  );
};

export const numberColumnSortingComparator = (v1: string, v2: string) => {
  return +(v1 || 0) - +(v2 || 0);
};

export const nameColumnSortByLastName = (v1: IUser, v2: IUser) => {
  return (v1?.lastName || '').localeCompare(v2?.lastName || '');
};

import { ELocalStoreKeys } from '@/types/consts';

export const removeAuthTokens = () => {
  localStorage.removeItem(ELocalStoreKeys.ACCESS_TOKEN);
  localStorage.removeItem(ELocalStoreKeys.REFRESH_TOKEN);
};

export const saveTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(ELocalStoreKeys.ACCESS_TOKEN, accessToken);
  localStorage.setItem(ELocalStoreKeys.REFRESH_TOKEN, refreshToken);
};

import { UsersState } from '@/redux/reducers/users';
import { getAvarageActivity } from '@/utils/arrayHelpers';
import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

export const getUsersSelector = createSelector(
  (state: DefaultRootState) => state.users,
  ({ users }: UsersState) => users
);

export const getSearchUserMode = createSelector(
  (state: DefaultRootState) => state.users,
  ({ isSearch }: UsersState) => isSearch
);

export const getSearchedUsers = createSelector(
  (state: DefaultRootState) => state.users,
  ({ filteredUsers }: UsersState) => filteredUsers
);

export const getFilteredUsersCount = createSelector(
  (state: DefaultRootState) => state.users,
  ({ isSearch, usersCount, filteredCount }: UsersState) =>
    isSearch ? filteredCount : usersCount
);

export const getUserListLoading = createSelector(
  (state: DefaultRootState) => state.users,
  ({ loading }: UsersState) => loading
);

export const adminSelectedUser = createSelector(
  (state: DefaultRootState) => state.users,
  ({ user }: UsersState) => user
);

export const getOneUserLoaded = createSelector(
  (state: DefaultRootState) => state.users,
  ({ userLoading }: UsersState) => userLoading
);

export const adminUserProgressSelector = createSelector(
  (state: DefaultRootState) => state.users,
  ({ progress }: UsersState) =>
    progress
      ? getAvarageActivity(progress)
      : { mental: 0, social: 0, physical: 0 }
);

export const adminUserKnowledgeBaseSelector = createSelector(
  (state: DefaultRootState) => state.users,
  ({ knowledgeBase }: UsersState) => knowledgeBase
);

export const adminUserStatistic = createSelector(
  (state: DefaultRootState) => state.users,
  ({ progressStatistic }: UsersState) => progressStatistic
);

export const adminUserActivityLog = createSelector(
  (state: DefaultRootState) => state.users,
  ({ activitiesLog }: UsersState) => activitiesLog
);

export const adminUserRewardPointsLog = createSelector(
  (state: DefaultRootState) => state.users,
  ({ rewardPointsLog }: UsersState) => rewardPointsLog
);

export const adminUserRewardPointsSelector = createSelector(
  (state: DefaultRootState) => state.users,
  ({ rewardPoints }: UsersState) => rewardPoints
);

export const getRewardPointsLoaded = createSelector(
  (state: DefaultRootState) => state.users,
  ({ rewardPointsLoading }: UsersState) => rewardPointsLoading
);

export const adminUserDailyStreak = createSelector(
  (state: DefaultRootState) => state.users,
  ({ dailyStreak }: UsersState) => dailyStreak
);

export const adminUserMessagingStats = createSelector(
  (state: DefaultRootState) => state.users,
  ({ messagesStat }: UsersState) => messagesStat
);

import useAppSelector from '@/hooks/useAppSelector';
import { selectState } from '@/redux/selectors/componentsManage';
import { ListItem, ListItemText } from '@mui/material';
import React from 'react';
import styled, { css } from 'styled-components';
import { WorkShopNavIcon } from '../icons';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface INavItemProps {
  active?: boolean;
  isOpen?: boolean;
}

type ListItemType = {
  active?: number;
  open: boolean;
};

export const Title = styled(ListItemText)<{ open: boolean }>`
  color: ${(props) => props.theme.sidebar.item.color.main};
  white-space: nowrap;
  transition: all 0.3s ease;

  ${({ open }) =>
    !open &&
    css`
      opacity: 0;
      width: 0;
      max-width: 0;
    `};

  ${({ open, theme }) =>
    open &&
    css`
      margin-left: ${theme.spacing(2)};
    `};
`;

export const NavListItem = styled(ListItem)<ListItemType>`
  border-left-width: 4px;
  border-left-style: solid;
  border-left-color: transparent;
  transition: all 0.3s ease;

  &:hover {
    ${Title} {
      color: ${(props) => props.theme.palette.common.white};
    }
  }

  ${({ open, active }) =>
    open &&
    !active &&
    css`
      &:hover {
        border-left-color: ${(props) =>
          props.theme.sidebar.item.active.background};
      }
    `}

  ${({ active }) =>
    active &&
    css`
      border-left: ${({ theme }) => theme.sidebar.item.active.border};

      ${Title} {
        color: ${({ theme }) => theme.palette.common.white};
      }
    `}
`;

export const ListIconIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 25px;
  color: ${(props) => props.theme.sidebar.item.color.main};
`;

export const ChatItemContentBasic = styled.div<INavItemProps>`
  width: ${({ isOpen }) => (isOpen ? '100%' : 'auto')};
  display: flex;
  justify-content: ${({ isOpen }) => (!isOpen ? 'center' : 'flex-start')};
  padding: ${({ isOpen, theme }) =>
    isOpen ? theme.spacing(2, 3, 2, 6) : theme.spacing(2, 3)};
  align-items: center;
  font-weight: 400;
  text-decoration: none;

  ${({ isOpen, theme }) =>
    !isOpen &&
    css`
      margin: ${theme.spacing(0, 'auto')};
    `};
`;

export const ChatItemContent = styled(ChatItemContentBasic)<INavItemProps>`
  border-radius: ${({ isOpen, theme }) =>
    isOpen ? theme.spacing(0, 2, 2, 0) : theme.spacing(2)};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.sidebar.item.active.background};
  }

  ${({ active, isOpen, theme }) =>
    active &&
    css`
      color: ${theme.palette.common.white};
      background-color: ${theme.sidebar.item.active.background};
    `}
`;

type ListItemLinkType = NavLinkProps & { active?: number; open: boolean };

export const WorkShopLink = styled.a`
  text-decoration: none;
`;

const WorkLifeNavItem: React.FC = () => {
  const open = useAppSelector(selectState);

  return (
    <WorkShopLink
      href="http://remoteworx.com/shop"
      target="_blank"
      rel="noreferrer"
    >
      <NavListItem open={open} disablePadding>
        <ChatItemContent isOpen={open} active={false}>
          <ListIconIconWrapper>
            <WorkShopNavIcon />
          </ListIconIconWrapper>
          <Title open={open}>WorkLifeShop</Title>
        </ChatItemContent>
      </NavListItem>
    </WorkShopLink>
  );
};

export default WorkLifeNavItem;

import {
  confirmWorkLifeShopEmail,
  deleteAvatar,
  getMyCompany,
  login,
  logout,
  me,
  updateMe,
  updateNotification,
  uploadAvatar,
} from '@/redux/actions/user';
import { IUser } from '@/types/models';
import { saveTokens } from '@/utils/apiCallHelpers';
import { createSlice } from '@reduxjs/toolkit';
import { RejectedAction } from '.';

export interface UserState {
  loading: boolean;
  user: IUser | null;
  myTeam?: IUser[];
}

const initialState: UserState = {
  loading: true,
  user: null,
};

export const { actions, reducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserState: () => ({
      ...initialState,
      loading: false,
    }),
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setMyTeam: (state, { payload }) => {
      state.myTeam = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(me.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        saveTokens(payload.accessToken, payload.refreshToken);
        state.user = payload as IUser;
        state.loading = false;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(me.fulfilled, (state, { payload }) => {
        state.user = payload as IUser;
        state.loading = false;
      })
      .addCase(updateMe.fulfilled, (state, { payload }) => {
        state.user = {
          ...state.user,
          ...(payload as IUser),
        };
      })
      .addCase(updateNotification.fulfilled, (state, { payload }) => {
        state.user = {
          ...(state.user as IUser),
          newMessagesSounds: (payload as IUser).newMessagesSounds,
          breakTimesSounds: (payload as IUser).breakTimesSounds,
          progressMetersSounds: (payload as IUser).progressMetersSounds,
        };
      })
      .addCase(uploadAvatar.fulfilled, (state, { payload }) => {
        state.user = {
          ...(state.user as IUser),
          profileImage: payload.profileImage,
        };
      })
      .addCase(confirmWorkLifeShopEmail.fulfilled, (state, { payload }) => {
        state.user = {
          ...(state.user as IUser),
          workLifeShopEmailConfirm: true,
        };
      })
      .addCase(deleteAvatar.fulfilled, (state, { payload }) => {
        state.user = {
          ...(state.user as IUser),
          profileImage: null,
        };
      })
      .addCase(getMyCompany.fulfilled, (state, { payload }) => {
        state.myTeam = payload.rwAdmin
          ? [...payload.users, payload.rwAdmin]
          : [...payload.users];
      })
      .addMatcher(
        (action): action is RejectedAction => action.type.endsWith('/rejected'),
        (state, action) => {
          state.loading = false;
          // add error handler for all error exept Invalid login and password
        }
      );
  },
});

export const { setLoading, resetUserState, setMyTeam } = actions;

export default reducer;

import styled from 'styled-components';

interface IProgressMeterSummaryWrapper {
  withBorder: boolean;
}

export const ActivityBreakdownContainer = styled.div`
  width: 100%;
  padding: 26px 0 29px 32px;
`;

export const BlockLabel = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const DateColumn = styled.div`
  width: 55px;
  margin-right: 15px;
`;

export const TaskColumn = styled.div`
  width: 200px;
  margin-right: 10px;
  display: flex;
  justify-self: start;
`;

export const ProgressValueColumn = styled.div`
  width: 70px;
  display: flex;
  justify-content: center;
`;

export const UserColumn = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: center;
  .MuiAvatar-root {
    margin-right: 5px;
  }
`;

export const SimpleTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 12px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  .number {
    margin-right: 8px;
  }
`;

export const SimpleTableHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.palette.text.secondary};
  margin-bottom: 15px;
`;

export const SimpleTableWrapper = styled.div<IProgressMeterSummaryWrapper>`
  padding: 8px 23px 8px 0;
  border-right: ${({ withBorder }) =>
    withBorder ? '1px solid #d0d0d966' : 'none'};
  height: 460px;
`;

import { ChatState } from '@/redux/reducers/chat';
import { RootState } from '@/redux/store';
import { EConversationType } from '@/types/consts';
import { IGroupChat, IPersonalChat, IUnreadMessageInfo } from '@/types/models';
import { createSelector } from '@reduxjs/toolkit';

export const chatUserSelector = createSelector(
  (state: RootState) => state.chat,
  ({ userChat }: ChatState) => userChat
);

export const conversationsSelector = (type: EConversationType) =>
  createSelector(
    (state: RootState) => state.chat,
    ({ conversations }: ChatState) =>
      conversations?.filter(
        ({ conversationType }) => conversationType === type
      ) || null
  );

export const currentChatSelector = createSelector(
  (state: RootState) => state.chat,
  ({ currentChat }: ChatState) => currentChat
);

export const currentConversation = createSelector(
  (state: RootState) => state.chat,
  ({ currentChat, conversations }: ChatState) => {
    const chat = conversations?.find(({ conversationWith }) =>
      currentChat?.isGroup
        ? (conversationWith as IGroupChat).guid === currentChat.chatId
        : (conversationWith as IPersonalChat).uid === currentChat?.chatId
    );
    return chat || null;
  }
);

export const messagesSelector = createSelector(
  (state: RootState) => state.chat,
  ({ messages }: ChatState) => messages
);

export const membersSelector = createSelector(
  (state: RootState) => state.chat,
  ({ members }: ChatState) => members
);

export const unreadMessageCount = createSelector(
  (state: RootState) => state.chat,
  ({ conversations }: ChatState) =>
    conversations?.reduce(
      (prev, curr) => prev + Number(curr?.unreadMessageCount || 0),
      0
    )
);

export const unreadMessageBySender = createSelector(
  (state: RootState) => state.chat,
  ({ conversations }: ChatState): Array<IUnreadMessageInfo> | undefined =>
    conversations?.reduce((prev, curr) => {
      return [
        ...prev,
        {
          type: curr.conversationType,
          senderId:
            curr.conversationType === EConversationType.PERSONAL
              ? (curr.conversationWith as IPersonalChat).uid
              : undefined,
          guid:
            curr.conversationType === EConversationType.GROUP
              ? (curr.conversationWith as IGroupChat).guid
              : undefined,
          unread: Number(curr?.unreadMessageCount || 0),
        },
      ];
    }, [] as Array<IUnreadMessageInfo>)
);

export const getCompanyChatSupport = createSelector(
  (state: RootState) => state.chat,
  ({ companyChatSupport }: ChatState) => companyChatSupport
);

export const getSelectedCompanyId = createSelector(
  (state: RootState) => state.chat,
  ({ selectedCompanyId }: ChatState) => selectedCompanyId
);

export const hasMoreMessagesSelector = createSelector(
  (state: RootState) => state.chat,
  ({ hasMoreMessages }) => hasMoreMessages
);

export const pinnedMessageSelector = createSelector(
  (state: RootState) => state.chat,
  ({ pinnedMessage }) => pinnedMessage
);

export const unreadedMessagesList = createSelector(
  (state: RootState) => state.chat,
  ({ unreadedMessages }) => unreadedMessages
);

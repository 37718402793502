import { BackToPage } from '@/components/admin/company/Tools';
import { ChewronLeftIcon } from '@/components/icons';
import { EKnowledgeBaseItemTypes } from '@/components/knowledgeBase/models';
import PdfContainer from '@/components/knowledgeBase/PdfContainer';
import VideoContainer from '@/components/knowledgeBase/VideoContainer';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import {
  KnowledgeBaseHeaderBlock,
  KnowledgeBaseRoot,
} from '@/pages/knowledge-base/components';
import {
  completeKnowledgeBaseItem,
  finishReadKnowledgeBaseItem,
  getKnowledgeBaseData,
} from '@/redux/actions/knowledge-base';
import { getKnowledgeBaseItem } from '@/redux/selectors/knowledgeBaseSelector';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DEFAULT_TOAST_TIME } from '@/types/consts';

function KnowledgeBase() {
  const { itemId } = useParams();
  const item = useAppSelector(getKnowledgeBaseItem(Number(itemId)));
  const dispatch = useAppDispatch();

  const itIsVideo = !!(item && item.type === EKnowledgeBaseItemTypes.VIDEO);
  const isAlreadyCompleted = !!(item && item.alreadyCompleted);
  const isFinishReading = !!(item && item.alreadyReaded);
  const isVisibleButton = isAlreadyCompleted || isFinishReading;

  useEffect(() => {
    dispatch(getKnowledgeBaseData());
  }, []);

  const getPoints = useCallback(async () => {
    try {
      await dispatch(completeKnowledgeBaseItem(itemId as string));
      toast.success(
        `${itIsVideo ? 'Video' : 'Guide'} was completed successfully`,
        {
          position: 'top-right',
          autoClose: DEFAULT_TOAST_TIME,
          hideProgressBar: false,
        }
      );
    } catch (e: any) {
      toast.error(`${e.error}`, { position: 'top-right' });
    }
  }, [dispatch, itIsVideo, itemId]);

  const buttonName = isAlreadyCompleted ? 'Completed' : 'Click for Points';

  const finishReading = () =>
    dispatch(finishReadKnowledgeBaseItem(itemId as string));

  return (
    <KnowledgeBaseRoot>
      {!item && <CircularProgress />}
      {item && (
        <>
          <KnowledgeBaseHeaderBlock type={item.type}>
            <BackToPage
              LinkComponent={Link}
              to={'/knowledge-base'}
              startIcon={<ChewronLeftIcon />}
              color={'secondary'}
            >
              Back To Knowledge Base
            </BackToPage>
            <Box className="title">
              <Typography variant="h2" justifyContent="center" align="center">
                {item.title}
              </Typography>
            </Box>
            {isVisibleButton && (
              <Button
                variant="contained"
                color="secondary"
                className="button-centered"
                disabled={isAlreadyCompleted}
                onClick={() => getPoints()}
              >
                {buttonName}
              </Button>
            )}
            <Box className="points-block">
              <Box display="flex">
                <Box className="label">{item.type}</Box>
                <Box className="points">{item.points + ' pts'}</Box>
              </Box>
            </Box>
          </KnowledgeBaseHeaderBlock>
          {item.type === EKnowledgeBaseItemTypes.GUIDE && (
            <PdfContainer
              fileUrl={item.fileUrl as string}
              alreadyCompleted={item.alreadyCompleted}
              finishReading={finishReading}
            />
          )}
          {item.type === EKnowledgeBaseItemTypes.VIDEO && (
            <Box marginLeft="5%" marginRight="5%">
              <VideoContainer
                fileUrl={item.fileUrl as string}
                previewUrl={item.previewUrl as string}
                finishReading={finishReading}
              />
            </Box>
          )}
        </>
      )}
    </KnowledgeBaseRoot>
  );
}

export default KnowledgeBase;

import {
  Dialog,
  DialogContent,
  DialogSubTitle,
  DialogTitle,
  IconButton,
} from '@/components/admin/user/components';
import TransferPointsForm from '@/components/admin/user/TransferPointsForm';
import { CloseIcon } from '@/components/icons';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { numberWithCommas } from '@/pages/super-admin/company/helpers';
import { toggleModal } from '@/redux/reducers/componentsManage';
import { myRewardPointsSelector } from '@/redux/selectors/activitySelector';
import { selectStateModal } from '@/redux/selectors/componentsManage';
import React from 'react';
import styled from 'styled-components';
import { adminUserRewardPointsSelector } from '@/redux/selectors/users';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 820px !important;
    margin-left: auto;
    margin-right: auto;
    max-width: 820px !important;
    padding: 40px !important;
  }
`;

const TransferPointsModal = () => {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector(selectStateModal);
  const rewardPoints = useAppSelector(myRewardPointsSelector);
  const adminUserRewardPoints = useAppSelector(adminUserRewardPointsSelector);

  const handleClose = () => {
    dispatch(toggleModal(false));
  };

  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalState}
    >
      <DialogTitle>
        Transfer Points
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogSubTitle>
        {`${
          adminUserRewardPoints
            ? `${adminUserRewardPoints.user.firstName} ${adminUserRewardPoints.user.lastName} has`
            : 'You have'
        } ${numberWithCommas(
          adminUserRewardPoints
            ? adminUserRewardPoints.totalPoints
            : rewardPoints?.totalPoints
        )} Reward Points. Please enter the amount of points you would like to transfer to ${
          adminUserRewardPoints ? 'his/her' : 'your'
        } Work+Life Shop Account`}
      </DialogSubTitle>
      <DialogContent>
        <TransferPointsForm />
      </DialogContent>
    </StyledDialog>
  );
};

export default TransferPointsModal;

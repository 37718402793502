import DataGrid from '@/components/UI/DataGrid';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import { getUsers, resendLink } from '@/redux/actions/users';
import {
  getFilteredUsersCount,
  getSearchUserMode,
  getSearchedUsers,
  getUserListLoading,
  getUsersSelector,
} from '@/redux/selectors/users';
import { DEFAULT_TOAST_TIME, ELimits, EUserRole } from '@/types/consts';
import { IParams, IUser } from '@/types/models';
import { getRoutePrefixByRoleName } from '@/utils/routeRoleHelpers';
import { formatPhoneNumber } from '@/utils/textHelpers';
import { Box, Button, Grid } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

const CustomGridCellResendLink: React.FC<GridRenderCellParams> = (params) => {
  const dispatch = useAppDispatch();

  const handleClick = async (event: any) => {
    event.stopPropagation();
    try {
      await dispatch(resendLink(params.row.id)).unwrap();
      toast.success('Invitation link was successfully resent', {
        position: 'top-right',
        autoClose: DEFAULT_TOAST_TIME,
        hideProgressBar: false,
      });
    } catch (e: any) {
      toast.error(e.error, {
        position: 'top-right',
      });
    }
  };

  return (
    <Grid container alignItems={'center'} justifyContent="left" gap={2}>
      {params.value ? (
        'Confirmed'
      ) : (
        <Button onClick={handleClick} variant="outlined" color="secondary">
          Resend
        </Button>
      )}
    </Grid>
  );
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Id', hide: true },
  { field: 'userName', headerName: 'Name', flex: 1 },
  { field: 'companyName', headerName: 'Company', flex: 1 },
  { field: 'jobTitle', headerName: 'Job Title', flex: 1 },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 2,
    cellClassName: 'phone',
    valueFormatter: (params) => formatPhoneNumber(params.value),
  },
  { field: 'email', headerName: 'Email', flex: 2 },
  {
    field: 'teamAdmin',
    headerName: 'Team Admin Y/N',
    flex: 1,
  },
  {
    field: 'isConfirm',
    headerName: 'Confirmed',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return <CustomGridCellResendLink {...params} />;
    },
  },
];

const rowsMapper = (users: IUser[]) =>
  users.map((user) => ({
    id: user.id,
    userName: `${user.firstName || ''} ${user.lastName || ''}`,
    companyName: (user.companies && user.companies[0]?.name) || 'N/A',
    jobTitle: user.jobTitle || 'N/A',
    phone: user.phone || '',
    email: user.email,
    teamAdmin: user?.role && user.role.name === EUserRole.TEAMLEAD ? 'Y' : 'N',
    isConfirm: user.isConfirm,
  }));

const UsersTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const isSearch = useAppSelector(getSearchUserMode);
  const allUsers = useAppSelector(getUsersSelector);
  const filteredUsers = useAppSelector(getSearchedUsers);
  const [users, setUsers] = useState(allUsers);
  const count = useAppSelector(getFilteredUsersCount);
  const loading = useAppSelector(getUserListLoading);
  const { userRole } = useAuth();
  const navigate = useNavigate();

  const handleRowClick = (params: GridRowParams) => {
    navigate(
      getRoutePrefixByRoleName(userRole) + `/user/${params.id}/information`
    );
  };

  useEffect(() => {
    dispatch(getUsers({ limit: ELimits.USER }));
  }, []);

  useEffect(() => {
    setUsers(isSearch ? filteredUsers : allUsers);
  }, [isSearch, filteredUsers, allUsers]);

  const onPageChange = useCallback(
    (page: number) => {
      if (
        users?.length &&
        count &&
        !isSearch &&
        users.length < count &&
        (page + 1) * ELimits.USER > users.length
      ) {
        const pagination: IParams = {
          limit: (page + 1) * ELimits.USER - users.length,
          offset: users?.length - 1,
        };
        dispatch(getUsers(pagination));
      }

      const element = document.querySelector('.MuiDataGrid-columnHeadersInner');
      if (element) {
        element.scrollIntoView();
      }
    },
    [count, users?.length, isSearch]
  );

  return (
    <Box marginBottom={5}>
      {users && (
        <DataGrid
          columns={columns}
          rowCount={count}
          loading={loading}
          rows={rowsMapper(users)}
          pageSize={50}
          rowsPerPageOptions={[50]}
          onPageChange={onPageChange}
          onRowClick={handleRowClick}
        />
      )}
    </Box>
  );
};

export default UsersTable;

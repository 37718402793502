import {
  Box,
  Button,
  Container,
  OutlinedInput,
  Typography,
} from '@mui/material';
import styled from 'styled-components';

export const CompanyPointSection = styled('section')`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: ${({ theme }) => theme.spacing(12, 2, 35)};
`;

export const CompanyPointsTitle = styled(Typography)`
  color: #5d6081;
  text-align: center;
`;

export const CompanyPointValue = styled(Box)`
  background: #dfeafa;
  border-radius: 8px;
  font-size: 36px;
  color: #5d6081;
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const CompanyPointManagmentSection = styled('section')`
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 24px 64px rgba(0, 5, 62, 0.15);
  border-radius: 16px;
  padding: ${({ theme }) => theme.spacing(7, 9, 12)};
  transform: translateY(-${({ theme }) => theme.spacing(25)});
`;

export const CompanyPointManagmentTitle = styled(Typography)`
  font-size: 16px;
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

export const CompanyPointManagmentTable = styled(Box)``;

export const CompanyPointOutlinedField = styled(OutlinedInput)`
  & .MuiOutlinedInput-input {
    background-color: ${({ theme }) => theme.palette.background.default};
  }
`;

export const UpdateAvailableButton = styled(Button)`
  background-color: #e0f3f6;
  color: #0099ff;
  border-radius: 8px;
  padding: 0 15px;

  &:hover {
    background-color: #c2d2e1;
  }

  & svg {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
`;

// company activity page
export const GeneralContainer = styled.div`
  position: relative;
`;

export const ContentContainer = styled(Container)`
  position: relative;
`;

export const BackgroundHalf = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 300px;
  background: ${({ theme }) => theme.palette.background.default};
`;

export const DailyProgressContainer = styled(Box)`
  height: 316px;
  background: ${({ theme }) => theme.palette.neutral.main};
  box-shadow: 0px 24px 64px rgba(0, 5, 62, 0.15);
  border-radius: 16px;
  margin: 43px 70px 31px;
  padding: 35px 32px 34px;
`;

export const ActivityLogContainer = styled.div`
  background: #eff0f7cc;
  border-radius: 16px;
  padding: 14px 29px 11px 5px;
  margin-top: 49px;
`;

export const CompanyProgressContainer = styled.div`
  margin: 43px 70px 31px;
`;

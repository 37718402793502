import {
  Dialog,
  DialogContent,
  DialogSubTitle,
  DialogTitle,
  IconButton,
} from '@/components/admin/user/components';
import { CloseIcon } from '@/components/icons';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { toggleModal } from '@/redux/reducers/componentsManage';
import { selectStateModal } from '@/redux/selectors/componentsManage';
import React from 'react';
import EditTotalPointsRewardForm from './EditTotalPointsRewardForm';

const EditTotalPointsRewardModal = () => {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector(selectStateModal);

  const handleClose = () => {
    dispatch(toggleModal(false));
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalState}
      maxWidth={'lg'}
    >
      <DialogTitle>
        Edit Master Points
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogSubTitle>
        Please enter the Reward Points amount for this company.
      </DialogSubTitle>
      <DialogContent>
        <EditTotalPointsRewardForm />
      </DialogContent>
    </Dialog>
  );
};

export default EditTotalPointsRewardModal;

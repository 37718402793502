import { WithType } from '@/components/knowledgeBase/KnowledgeBaseSmallPreviewItem/components';
import { EKnowledgeBaseItemTypes } from '@/components/knowledgeBase/models';
import ColorfulHorizontalProgress from '@/components/progressMeter/ColorfulHorizontalProgress/ColorfulHorizontalProgress';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { BarWrapper } from '@/pages/knowledge-base/components';
import { getUserById, getUserKnowledgeBase } from '@/redux/actions/users';
import {
  adminSelectedUser,
  adminUserKnowledgeBaseSelector,
} from '@/redux/selectors/users';
import { IUser } from '@/types/models';
import { Box, Container, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const KnowledgeText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const KnowledgeInfo = styled(Box)`
  padding: 30px 40px;
  border: 1px solid #e2e8ed;
  border-radius: 16px;
  margin-top: 56px;
  margin-bottom: 27px;
`;

const KnowledgeBaseCompletness = styled(Box)`
  background: #f3f6f8cc;
  border-radius: 16px;
  padding: 44px 60px;
`;

const KnowledgeType = styled(Box)<WithType>`
  font-weight: bold;
  border-radius: 2px;
  width: 43px;
  height: 18px;
  text-align: center;
  margin-right: 5px;
  color: white;
  font-size: 10px;
  text-transform: capitalize;
  background-color: ${(props) => props.theme?.knowledgeBase[props.type]?.color};
`;

const HeaderRow = styled(Box)`
  display: flex;
  border-bottom: 1px solid #e2e8ed;
  margin-bottom: 19px;
  padding-bottom: 13px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const UserKnowladgeBase: FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const user = useAppSelector(adminSelectedUser) as IUser;
  const knowledgeBase = useAppSelector(adminUserKnowledgeBaseSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user) {
      userId && dispatch(getUserById(+userId));
    }
  }, [user, userId]);

  useEffect(() => {
    if (user?.id) {
      dispatch(getUserKnowledgeBase(Number(user.id)));
    }
  }, [dispatch, user?.id]);

  return (
    <Container>
      <KnowledgeInfo>
        <Box display="flex" mb="24px">
          <KnowledgeText variant="h2">Knowledge Base</KnowledgeText>
          <BarWrapper>
            <ColorfulHorizontalProgress
              display="flex"
              current={
                knowledgeBase?.filter((item) => item.alreadyCompleted).length
              }
              max={knowledgeBase?.length}
            />
          </BarWrapper>
        </Box>
        <KnowledgeText>
          Welcome to the Knowledge Base. Here you’ll find the latest information
          to support your wellbeing.
          <Typography fontWeight={'bold'}>
            Earn Reward Points for watching Videos and reading Guides, up to 1
            per day, 5 per month.
          </Typography>
        </KnowledgeText>
      </KnowledgeInfo>

      <KnowledgeBaseCompletness>
        <HeaderRow>
          <Box minWidth="40px" marginRight="20px">
            Type
          </Box>
          <Box flexGrow={1}>Title</Box>
          <Box minWidth="90px" marginLeft="20px" textAlign="end">
            Completed Y/N
          </Box>
        </HeaderRow>
        {knowledgeBase?.map((item) => (
          <Box display="flex" key={item.id} marginBottom="14px">
            <Box minWidth="40px" marginRight="20px">
              <KnowledgeType
                type={(item.type as any).name as EKnowledgeBaseItemTypes}
              >
                {(item.type as any).name}
              </KnowledgeType>
            </Box>
            <Box flexGrow={1}>{item.title}</Box>
            <Box minWidth="90px" marginLeft="20px" textAlign="end">
              {item.alreadyCompleted ? 'Y' : 'N'}
            </Box>
          </Box>
        ))}
      </KnowledgeBaseCompletness>
    </Container>
  );
};

export default UserKnowladgeBase;

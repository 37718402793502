import {
  PointsProggressLabel,
  PointsProggressTitle,
} from '@/components/admin/user/components';
import { FlexBlock } from '@/components/progressMeter/WorkSpaceProgress/component';
import { createProgressArray } from '@/pages/dashboard/MyWorkspace/MyWorkspaceHeaderMenu/helpers';
import { ProgressItem } from '@/pages/dashboard/MyWorkspace/MyWorkspaceHeaderMenu/types';
import { WorkSpaceProgressSlider } from '@/pages/dashboard/MyWorkspace/MyWorkspaceHeaderMenu/WorkSpaceProgressSlider';
import React, { useEffect, useState } from 'react';

interface WorkSpaceProgressProps {
  completed: number;
  from: number;
}

const WorkSpaceProgress: React.FC<WorkSpaceProgressProps> = ({
  completed,
  from,
}) => {
  const [progressComplete, setProgressComplete] = useState<ProgressItem[]>(
    createProgressArray(completed)
  );
  useEffect(() => {
    setProgressComplete(createProgressArray(completed));
  }, [completed]);

  return (
    <FlexBlock direction="column">
      <PointsProggressTitle variant={'h2'}>
        My Workspace Progress
      </PointsProggressTitle>
      <PointsProggressLabel>
        <b>
          {completed} / {from} Completed
        </b>
      </PointsProggressLabel>
      <FlexBlock direction="row">
        {progressComplete.map((progress) => (
          <WorkSpaceProgressSlider
            key={progress.id}
            progress={progress}
            total={5}
          />
        ))}
      </FlexBlock>
    </FlexBlock>
  );
};

export default WorkSpaceProgress;

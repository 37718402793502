import {
  CloseIcon,
  EmojiIcon,
  LinkIcon,
  NoNotificationIcon,
  PictureIcon,
  SendIcon,
} from '@/components/icons';
import { FileInput } from '@/components/user-profile/avatar/components';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { sendImage, sendMessage } from '@/redux/actions/chat';
import { currentChatSelector } from '@/redux/selectors/chatSelector';
import { getUserSelector } from '@/redux/selectors/userSelectors';
import {
  EChatStatus,
  EConversationType,
  EMessageCategory,
  EMessageType,
} from '@/types/consts';
import { ICurrentChat, IUser } from '@/types/models';
import { uidToId } from '@/utils/chatHelpers';
import { Box, InputLabel, Menu, OutlinedInput } from '@mui/material';
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AdminOfflineBlock,
  ButtonsBlock,
  CloseIconWrapper,
  ImagePreview,
  LinkAddContainer,
  LinkMenuButton,
  MessageField,
  MessageIconButton,
  Popover,
  SendButton,
  SendImageWrapper,
  SendMessageBlock,
  SendMessageBox,
  SendMessageContainer,
} from '../components';

export interface ISendMessageProps {
  isWidget?: boolean;
  plusHeight: number;
}

export interface ISendMessageBlockProps {
  plusHeight: number;
}

interface ISendMessage {
  setIsScrolled?: React.Dispatch<React.SetStateAction<boolean>>;
  isWidget?: boolean;
  plusHeight: number;
  setPlusHeight: React.Dispatch<React.SetStateAction<number>>;
}

const SendMessage: FC<ISendMessage> = ({
  setIsScrolled,
  isWidget = false,
  plusHeight,
  setPlusHeight,
}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUserSelector) as IUser;
  const rwAdmin = user?.companies[0]?.rwAdminId;
  const chat = useAppSelector(currentChatSelector) as ICurrentChat;
  const [text, setText] = useState<string>('');
  const fileInput = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState<string | null>(null);
  const [anchorMenu, setAnchorMenu] = useState<any>(null);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [anchorLinkModal, setAnchorLinkModal] = useState<any>(null);
  const [msgLink, setMsgLink] = useState('');
  const [caption, setCaption] = useState('');

  const disableSend = useMemo(
    () => !isSending && !text?.trim() && !file,
    [text, file, isSending]
  );

  useEffect(() => {
    setText('');
    setFile(null);
    setImage(null);
    setMsgLink('');
    setCaption('');
    setPlusHeight(0);
  }, [chat.chatId]);

  const handleMessage = useCallback(() => {
    if (!isSending) {
      setIsSending(true);
      if (text) {
        dispatch(
          sendMessage({
            receiver: chat.chatId as string,
            receiverType: chat.isGroup
              ? EConversationType.GROUP
              : EConversationType.PERSONAL,
            category: EMessageCategory.MESSAGE,
            type: EMessageType.TEXT,
            data: {
              text,
            },
          })
        )
          .unwrap()
          .finally(() => {
            setText('');
            setPlusHeight(0);
            setIsSending(false);
          });
      } else if (file && image) {
        dispatch(
          sendImage({
            receiverId: chat.chatId as string,
            receiverType: chat.isGroup
              ? EConversationType.GROUP
              : EConversationType.PERSONAL,
            file,
            messageType: EMessageType.IMAGE,
            msgLink,
            caption,
          })
        )
          .unwrap()
          .finally(() => {
            setFile(null);
            setImage(null);
            setIsSending(false);
            setMsgLink('');
            setCaption('');
            setPlusHeight(0);
          });
      }
    }
  }, [text, chat, file, image, msgLink, caption, isSending]);

  const onUploadBtnClick = useCallback(() => {
    if (fileInput?.current) {
      fileInput.current.click();
    }
  }, [fileInput]);

  const onImageUpload = useCallback((e: any) => {
    e.preventDefault();
    const files = e.target.files;
    if (files[0]) {
      setFile(files[0]);
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as any);
        setText('');
        e.target.value = null;
      };
      reader.readAsDataURL(files[0]);
    }
  }, []);

  const clearImage = useCallback(() => {
    setImage(null);
    setFile(null);
    setMsgLink('');
    setCaption('');
    setPlusHeight(0);
  }, []);

  const closeMenu = () => {
    setAnchorMenu(null);
    setIsScrolled && setIsScrolled(true);
  };

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeLinkModal = () => {
    setAnchorLinkModal(null);
    setIsScrolled && setIsScrolled(true);
  };

  const toggleLinkModal = (event: React.SyntheticEvent) => {
    setAnchorLinkModal(event.currentTarget);
  };

  const deleteLink = (event: React.SyntheticEvent) => {
    setMsgLink('');
    closeLinkModal();
  };

  const selectEmoji = useCallback((emojiData: EmojiClickData) => {
    setText((text) => `${text}${emojiData.emoji}`);
    setIsScrolled && setIsScrolled(true);
  }, []);

  const keyPressHandle = useCallback(
    (event: any) => {
      if (event.keyCode === 13 && !event.shiftKey) {
        event.preventDefault();
        !disableSend && !isSending && handleMessage();
      }
    },
    [disableSend, text, isSending]
  );

  useEffect(() => {
    const messageField = document.getElementById('message_field');
    const heightMessageField = () =>
      messageField && setPlusHeight(messageField.clientHeight - 20);

    if (messageField) {
      messageField.addEventListener('keyup', heightMessageField);

      return () => {
        messageField.removeEventListener('keyup', heightMessageField);
      };
    }
  }, []);

  return (
    <SendMessageContainer isWidget={isWidget} plusHeight={plusHeight}>
      {uidToId(chat.user?.uid) === rwAdmin &&
        chat.user?.status === EChatStatus.OFFLINE && (
          <AdminOfflineBlock display="flex" alignItems="center">
            <Box mr="8px" mt="4px" display="block">
              <NoNotificationIcon />
            </Box>
            I am currently offline and will reply to you as soon as possible
          </AdminOfflineBlock>
        )}
      <SendMessageBlock plusHeight={plusHeight}>
        {image && (
          <SendImageWrapper>
            <ImagePreview src={image} />
            <CloseIconWrapper onClick={() => clearImage()}>
              <CloseIcon />
            </CloseIconWrapper>
          </SendImageWrapper>
        )}
        <SendMessageBox>
          <Box>
            {image ? (
              <MessageField
                id="message_field"
                multiline
                maxRows={9}
                placeholder="Add a caption ..."
                onBlur={(e) => setCaption(e.target.value)}
                onChange={(e) => setCaption(e.target.value)}
                value={caption}
                plusHeight={plusHeight}
              />
            ) : (
              <MessageField
                id="message_field"
                multiline
                maxRows={9}
                placeholder="Enter Message ..."
                onBlur={(e) => setText(e.target.value)}
                onChange={(e) => setText(e.target.value)}
                value={text}
                onKeyDown={keyPressHandle}
                plusHeight={plusHeight}
              />
            )}
          </Box>
          <Box display="flex" justifyContent="end">
            <MessageIconButton
              disableRipple
              disabled={!image}
              aria-owns={Boolean(anchorLinkModal) ? `link-modal` : undefined}
              aria-haspopup="true"
              onClick={toggleLinkModal}
            >
              <LinkIcon stroke={!image ? '#C9C7E4' : '#27A80A'} />
            </MessageIconButton>
            <Popover
              id={'link-modal'}
              anchorEl={anchorLinkModal}
              open={Boolean(anchorLinkModal)}
              onClose={closeLinkModal}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <LinkAddContainer>
                <InputLabel>Add link to the image</InputLabel>
                <OutlinedInput
                  fullWidth
                  name="link"
                  placeholder="Paste URL"
                  onChange={(e) => setMsgLink(e.target.value)}
                  value={msgLink}
                  type={'text'}
                />
                <ButtonsBlock>
                  <LinkMenuButton
                    variant="outlined"
                    onClick={deleteLink}
                    color="secondary"
                  >
                    Cancel
                  </LinkMenuButton>
                  <LinkMenuButton
                    size="small"
                    color="primary"
                    onClick={() => closeLinkModal()}
                  >
                    Save
                  </LinkMenuButton>
                </ButtonsBlock>
              </LinkAddContainer>
            </Popover>
            <MessageIconButton disableRipple onClick={() => onUploadBtnClick()}>
              <PictureIcon />
            </MessageIconButton>
            <MessageIconButton
              disableRipple
              disabled={!!image}
              aria-owns={Boolean(anchorMenu) ? `menu-emoji` : undefined}
              aria-haspopup="true"
              onClick={toggleMenu}
            >
              <EmojiIcon fill={!!image ? '#C9C7E4' : '#8F92B9'} />
            </MessageIconButton>
            <Menu
              id={`menu-emoji`}
              anchorEl={anchorMenu}
              open={Boolean(anchorMenu)}
              onClose={closeMenu}
            >
              <EmojiPicker
                onEmojiClick={selectEmoji}
                autoFocusSearch={false}
                theme={Theme.AUTO}
                skinTonesDisabled
              />
            </Menu>
            <SendButton
              startIcon={<SendIcon />}
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => handleMessage()}
              disabled={disableSend}
            >
              Send
            </SendButton>
          </Box>
        </SendMessageBox>

        <FileInput
          type="file"
          accept=".png, .jpeg, .jpg, .PNG, .JPEG, .JPG"
          ref={fileInput}
          onChange={onImageUpload}
        />
      </SendMessageBlock>
    </SendMessageContainer>
  );
};

export default SendMessage;

import {
  actionNames,
  EActivitiesActions,
} from '@/components/progressMeter/ActivityBreakdown/models';
import ScrollbarComponent from '@/components/scrollbar/Scrollbar';
import { AvatarType, CustomAvatar } from '@/components/UI/CustomAvatar';
import useAppDispatch from '@/hooks/useAppDispatch';
import { getMyActivity } from '@/redux/actions/activity';
import { getCompanyActivityLog } from '@/redux/actions/company';
import { getUserActivityLog } from '@/redux/actions/users';
import { ELimits } from '@/types/consts';
import { IActivityLog } from '@/types/models';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import { Box, Skeleton } from '@mui/material';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import {
  DateColumn,
  ProgressValueColumn,
  SimpleTableHeaderRow,
  SimpleTableRow,
  SimpleTableWrapper,
  TaskColumn,
  UserColumn,
} from './components';
import { DatePicker } from '@mui/x-date-pickers';
import { CalendarIconWrapper } from '@/components/form-elements/components';
import { DatePickerInput } from '@/components/user-profile/reward-points/components';
import { IActivityItem } from '@/components/progressMeter/RewardPointsHistory/RewardPointsHistoryWrapper';

export interface IActivityBreakdownProps {
  activitiesLog: IActivityLog[] | null;
  companyId?: number | null;
  userId?: number | null;
  isCompanyView?: boolean;
  activityDate: IActivityItem;
  setActivityDate: (val: any) => void;
}

interface IInfiniteLoadingWrapperProps {
  hasNextPage: boolean;
  items: Array<IActivityLog>;
  isNextPageLoading: boolean;
  isCompanyView?: boolean;
  loadNextPage: () => void;
}

const InfiniteLoadingWrapper: React.FC<IInfiniteLoadingWrapperProps> = ({
  hasNextPage,
  isNextPageLoading,
  items,
  loadNextPage,
  isCompanyView,
}) => {
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  const Item = ({ index, style }: { index: number; style: any }) => {
    if (!isItemLoaded(index)) {
      return <div>Loading...</div>;
    }

    const {
      id,
      action,
      createdAt,
      socialMeters,
      mentalMeters,
      physicalMeters,
      user,
      descriptions,
    } = items[index];

    const date = new Date(createdAt);
    const dtDateOnly = new Date(
      date.valueOf() + date.getTimezoneOffset() * 60 * 1000
    );

    return (
      <SimpleTableRow style={style} key={id}>
        <Box display="flex">
          <DateColumn>{format(dtDateOnly, 'MM/dd/yy')}</DateColumn>
          <TaskColumn>
            {action === EActivitiesActions.COMPLETE_WORKSPACE_LEVEL &&
            descriptions
              ? descriptions
              : actionNames[action]}
          </TaskColumn>
        </Box>
        {isCompanyView && (
          <UserColumn>
            <CustomAvatar
              diameter="30px"
              avatartype={AvatarType.USER}
              alt={
                user?.firstName
                  ? `${user.firstName} ${user.lastName}`
                  : user?.email
              }
              src={user?.profileImage || undefined}
            >
              {!user
                ? ''
                : getUserAvatarLetter({
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                  })}
            </CustomAvatar>
            {!user
              ? ' '
              : user?.firstName
              ? `${user.firstName} ${user.lastName}`
              : user?.email}
          </UserColumn>
        )}
        <Box display="flex" justifySelf="flex-end">
          <ProgressValueColumn>{mentalMeters}</ProgressValueColumn>
          <ProgressValueColumn>{physicalMeters}</ProgressValueColumn>
          <ProgressValueColumn>{socialMeters}</ProgressValueColumn>
        </Box>
      </SimpleTableRow>
    );
  };

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      threshold={3}
    >
      {({ onItemsRendered, ref }) => (
        <FixedSizeList
          height={360}
          itemSize={36}
          width="100%"
          itemCount={itemCount}
          onItemsRendered={onItemsRendered}
          ref={ref}
          outerElementType={ScrollbarComponent}
        >
          {Item}
        </FixedSizeList>
      )}
    </InfiniteLoader>
  );
};

const ActivityBreakdown: React.FC<IActivityBreakdownProps> = ({
  activitiesLog,
  companyId,
  userId,
  isCompanyView,
  activityDate,
  setActivityDate,
}) => {
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMoreData, setMoreDataState] = useState<boolean>(true);

  useEffect(() => {
    setInitialLoading(true);
    setMoreDataState(true);
  }, [activityDate.startDate, activityDate.endDate]);

  useEffect(() => {
    if (activitiesLog && initialLoading) {
      setMoreDataState(
        !!activitiesLog?.length && activitiesLog.length >= ELimits.ACTIVITY
      );
      setInitialLoading(false);
    }
  }, [activitiesLog?.length, initialLoading]);

  const loadMore = useCallback(() => {
    setLoading(true);
    const logPagination = {
      limit: ELimits.ACTIVITY,
      offset: (activitiesLog as IActivityLog[]).length,
      from: activityDate.startDate,
      to: activityDate.endDate,
    };
    const loadingPromise = companyId
      ? dispatch(
          getCompanyActivityLog({
            ...logPagination,
            companyId,
          })
        )
      : !userId
      ? dispatch(getMyActivity(logPagination))
      : dispatch(
          getUserActivityLog({
            ...logPagination,
            userId,
          })
        );
    loadingPromise
      .unwrap()
      .then((result) => {
        setMoreDataState(
          result.data.length && result.data.length >= ELimits.ACTIVITY
        );
      })
      .finally(() => setLoading(false));
  }, [
    activitiesLog,
    activityDate.endDate,
    activityDate.startDate,
    companyId,
    dispatch,
    userId,
  ]);

  return (
    <>
      <Box display="flex">
        <DatePicker
          value={activityDate.startDate}
          onChange={(value: Date | null) => {
            if (value !== null) {
              setActivityDate((prev: any) => ({
                ...prev,
                startDate: value,
              }));
            }
          }}
          InputProps={{
            placeholder: 'start date',
          }}
          components={{
            OpenPickerIcon: () => <CalendarIconWrapper />,
          }}
          maxDate={new Date()}
          minDate={new Date('01/01/2020')}
          renderInput={(params) => (
            <DatePickerInput
              className="date-picker"
              placeholder={params.InputProps?.placeholder}
              onChange={params.inputProps?.onChange}
              value={params.inputProps?.value}
              endAdornment={params.InputProps?.endAdornment}
              inputRef={params.inputRef}
            />
          )}
        />
        <Box sx={{ width: 10 }} />
        <DatePicker
          value={activityDate.endDate}
          onChange={(value: Date | null) => {
            if (value !== null) {
              setActivityDate((prev: any) => ({
                ...prev,
                endDate: value,
              }));
            }
          }}
          InputProps={{
            placeholder: 'end date',
          }}
          components={{
            OpenPickerIcon: () => <CalendarIconWrapper />,
          }}
          maxDate={new Date()}
          minDate={new Date('01/01/2020')}
          renderInput={(params) => (
            <DatePickerInput
              className="date-picker"
              placeholder={params.InputProps?.placeholder}
              onChange={params.inputProps?.onChange}
              value={params.inputProps?.value}
              endAdornment={params.InputProps?.endAdornment}
              inputRef={params.inputRef}
            />
          )}
        />
      </Box>
      {activitiesLog ? (
        <SimpleTableWrapper withBorder={!isCompanyView}>
          {activitiesLog.length ? (
            <>
              <SimpleTableHeaderRow>
                <Box display="flex">
                  <DateColumn>Date</DateColumn>
                  <TaskColumn>Action</TaskColumn>
                </Box>
                {isCompanyView && <UserColumn>User</UserColumn>}
                <Box display="flex" justifySelf="flex-end">
                  <ProgressValueColumn>Mental</ProgressValueColumn>
                  <ProgressValueColumn>Physical</ProgressValueColumn>
                  <ProgressValueColumn>Social</ProgressValueColumn>
                </Box>
              </SimpleTableHeaderRow>

              <InfiniteLoadingWrapper
                items={activitiesLog}
                hasNextPage={hasMoreData}
                isNextPageLoading={loading}
                loadNextPage={loadMore}
                isCompanyView={isCompanyView}
              />
            </>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center">
              No Activity
            </Box>
          )}
        </SimpleTableWrapper>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default ActivityBreakdown;

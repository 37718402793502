import { NotificationsIcon } from '@/components/icons';
import NotificationsList from '@/components/notifications/NotificationsInfiniteLoader';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import {
  getNotificationsData,
  readNotifications,
} from '@/redux/actions/notifications';
import { getNotificationsSelector } from '@/redux/selectors/notificationsSelectors';
import { ELimits } from '@/types/consts';

import { IconButton, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import {
  Indicator,
  NotificationHeader,
  NotificationsBox,
  Popover,
} from './components';

function HeaderNotificationsDropdown() {
  const ref = useRef(null);
  const notifications = useAppSelector(getNotificationsSelector());
  const unreadNotifications = notifications.filter((item) => !item.isShow);
  const [idsToRead, setIdsToRead] = useState<Array<number>>([]);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const notificationsLog = notifications?.length
    ? [...notifications].sort((value) => {
        return value.isShow ? 1 : -1;
      })
    : notifications;

  const readNotification = (id: number) => {
    if (!idsToRead.includes(id)) {
      setIdsToRead([...idsToRead, id]);
    }
  };

  useEffect(() => {
    const logPagination = {
      limit: ELimits.ACTIVITY,
      offset: 0,
    };

    dispatch(getNotificationsData(logPagination));
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(readNotifications(idsToRead));
    setIdsToRead([]);
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          {unreadNotifications.length ? (
            <Indicator
              badgeContent={
                notifications &&
                notifications.length &&
                unreadNotifications.length > 0 &&
                ''
              }
            >
              <NotificationsIcon />
            </Indicator>
          ) : (
            <NotificationsIcon />
          )}
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          {unreadNotifications && unreadNotifications.length ? (
            <Typography
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                setIdsToRead(unreadNotifications.map(({ id }) => id))
              }
            >
              Dismiss All
            </Typography>
          ) : null}
        </NotificationHeader>
        {notifications && notifications.length ? (
          <NotificationsBox>
            <NotificationsList
              notificationsLog={notificationsLog}
              readNotification={readNotification}
              idsToRead={idsToRead}
            />
          </NotificationsBox>
        ) : null}
      </Popover>
    </>
  );
}

export default HeaderNotificationsDropdown;

export enum EUser {
  login = 'user/login',
  getUser = 'user/getUser',
  logout = 'user/logout',
  forgotPassword = 'user/forgotPassword',
  resetPassword = 'user/resetPassword',
  changePassword = 'user/changePassword',
  changeUserData = 'user/updateUser',
  updateUserNotification = 'user/userNotification',
  uploadAvatar = 'user/uploadAvatar',
  deleteAvatar = 'user/deleteAvatar',
  getMyCompany = 'user/getMyCompany',
  confirmWorkLifeShopEmail = 'user/confirmWorkLifeShopEmail',
}

import { Box } from '@mui/material';
import styled from 'styled-components';

export const UserProfileContainer = styled.div`
  background-color: ${(props) => props.theme.palette.neutral.main};
  padding: 35px 59px 35px 62px;
`;

export const ProfileTitle = styled.h1`
  text-align: center;
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 17px;
`;

export const TabsContainer = styled(Box)`
  & .MuiTabs-root {
    padding: 0px;
    background: transparent;
  }
`;

import { TBreaktimerSettings } from '@/components/breaktimer/constants';
import { IBreakTimerGetter } from '@/types/models';

export const adaptBreakTimerSettings = (
  breakTimers: Array<IBreakTimerGetter>
) => {
  return breakTimers.reduce(
    (curr, { timePoints, type }) => ({
      ...curr,
      [type.name]: timePoints,
    }),
    {} as TBreaktimerSettings
  );
};

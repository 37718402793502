import {
  ContactRemoteWorxIcon,
  DashboardIcon,
  DashboardIconActive,
  KnowledgeBaseIcon,
  KnowledgeBaseIconActive,
  MemberIcon,
  MessageCenterIcon,
  MessageCenterIconActive,
  MyWorkspaceIcon,
  MyWorkspaceIconActive,
  RemoteWorxGuideIcon,
  TeamIcon,
} from '@/components/icons';
import { SidebarItemsType } from '@/types/sidebar';

export const menuSection = {
  title: 'Menu',
  pages: [
    {
      href: '/',
      icon: DashboardIcon,
      activeIcon: DashboardIconActive,
      title: 'Dashboard',
    },
    {
      href: '/message-center',
      icon: MessageCenterIcon,
      activeIcon: MessageCenterIconActive,
      title: 'Message Center',
      badge: '12',
    },
    {
      href: '/knowledge-base',
      icon: KnowledgeBaseIcon,
      activeIcon: KnowledgeBaseIconActive,
      title: 'Knowledge Base',
    },
    {
      href: '/my-workspace',
      icon: MyWorkspaceIcon,
      activeIcon: MyWorkspaceIconActive,
      title: 'My Workspace',
    },
  ],
} as SidebarItemsType;

export const messagingSectionItems = {
  title: 'Messaging',
  pages: [
    {
      icon: TeamIcon,
      title: 'Team',
    },
    {
      icon: MemberIcon,
      title: 'Team Member',
    },
  ],
} as SidebarItemsType;

export const helpSectionItems = {
  title: 'Help',
  pages: [
    {
      href: '/userguide',
      icon: RemoteWorxGuideIcon,
      title: 'User Guide',
    },
    {
      href: '/support',
      icon: ContactRemoteWorxIcon,
      title: 'Support',
    },
  ],
} as SidebarItemsType;

import { ICompanyWithUnread } from '@/components/message-center/ConversationList/CompanySelector/CompanySelectorWarapper';
import useAppDispatch from '@/hooks/useAppDispatch';
import { getCompanies } from '@/redux/actions/company';
import { unreadMessageBySender } from '@/redux/selectors/chatSelector';
import { getMyTeam, getUserSelector } from '@/redux/selectors/userSelectors';
import { EUserRole } from '@/types/consts';
import { ICompany } from '@/types/models';
import { idToUid } from '@/utils/chatHelpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const useUnreadMessagesCount = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(getUserSelector);
  const unreadBySender = useSelector(unreadMessageBySender);
  const teammates = useSelector(getMyTeam);
  const [companies, setCompanies] = useState<ICompany[] | null>(null);

  useEffect(() => {
    if (user?.role.name === EUserRole.RW_ADMIN) {
      rwAdminCompanies();
    } else {
      setCompanies(user?.companies || null);
    }
  }, [user?.role]);

  const rwAdminCompanies = useCallback(() => {
    dispatch(
      getCompanies({
        withoutSave: true,
        limit: 100,
      })
    )
      .unwrap()
      .then((result: { companies: ICompany[]; countAll: number }) => {
        if (result.countAll < 100) {
          return result;
        } else {
          return dispatch(
            getCompanies({
              withoutSave: true,
              limit: result.countAll,
            })
          ).unwrap();
        }
      })
      .then((resCompanies) => {
        setCompanies(resCompanies.companies);
      });
  }, []);

  const unreadMessageByCompany: ICompanyWithUnread[] | null = useMemo(() => {
    if (companies && unreadBySender && user?.role.name === EUserRole.RW_ADMIN) {
      return companies.map((company) => {
        const teamIds = company.users.map(({ id }) => idToUid(id));
        const groupId = `company-${company.id}`;
        return {
          id: company.id,
          name: company.name,
          unread: unreadBySender.reduce((acc, unreadInfo) => {
            const count =
              unreadInfo.guid === groupId ||
              (unreadInfo.senderId && teamIds.includes(unreadInfo.senderId))
                ? unreadInfo.unread
                : 0;
            return acc + count;
          }, 0),
        } as ICompanyWithUnread;
      });
    }
    return null;
  }, [unreadBySender, companies, user?.role]);

  const onlyUserActiveMessage: number | null = useMemo(() => {
    if (companies && unreadBySender && teammates) {
      const companyIds = companies.map(({ id }) => `company-${id}`);
      const teamsMembersIds = teammates.map(({ id }) => idToUid(id));
      return unreadBySender.reduce((acc, currUnread) => {
        if (
          companyIds.some((id) => id === currUnread.guid) ||
          teamsMembersIds.some((id) => currUnread.senderId === id)
        ) {
          return acc + currUnread.unread;
        }
        return acc;
      }, 0);
    }
    return null;
  }, [companies, unreadBySender, user?.role]);

  const onlyTeamMessagesCount: number | null = useMemo(() => {
    if (companies && unreadBySender && user?.role.name === EUserRole.RW_ADMIN) {
      const companyIds = companies.map(({ id }) => `company-${id}`);
      const teamsMembersIds = companies
        .map(({ users }) => users.map(({ id }) => idToUid(id)))
        .flat();
      return unreadBySender.reduce((acc, currUnread) => {
        if (
          companyIds.some((id) => id === currUnread.guid) ||
          teamsMembersIds.some((id) => currUnread.senderId === id)
        ) {
          return acc + currUnread.unread;
        }
        return acc;
      }, 0);
    }
    return null;
  }, [companies, unreadBySender]);

  return {
    companies,
    unreadMessageByCompany,
    onlyTeamMessagesCount,
    onlyUserActiveMessage,
  };
};

export default useUnreadMessagesCount;

import { pdfActive } from '@/components/icons/knowledge-base';
import { EKnowledgeBaseItemTypes } from '@/components/knowledgeBase/models';
import { Box, StyledComponentProps } from '@mui/material';
import styled from 'styled-components';

interface WithType extends StyledComponentProps {
  type: EKnowledgeBaseItemTypes;
}

interface WithPreview extends WithType {
  hasPreview: boolean;
}

export const KnowledgeBasePreviewBlock = styled.div<WithPreview>`
  margin: 8px 0;
  color: ${(props) => props.theme.knowledgeBase.color};

  &:hover {
    cursor: pointer;
  }

  .preview-image {
    border-radius: 4px;
    height: 280px;
    width: 500px;

    &.completed {
      opacity: 0.5;
    }

    img {
      &:hover {
        content: ${(props) => {
          if (
            props.type === EKnowledgeBaseItemTypes.GUIDE &&
            !props.hasPreview
          ) {
            return `url(${pdfActive});`;
          }
          return '';
        }};
        filter: ${(props) => {
          if (
            props.type !== EKnowledgeBaseItemTypes.GUIDE ||
            props.hasPreview
          ) {
            return `brightness(0.7);`;
          }
          return '';
        }};
      }

      height: 280px;
      width: 500px;
      border-radius: 10px;
    }

    svg {
      width: 50px;
      height: 40px;
      position: relative;
      top: -60%;
      left: 45%;
      pointer-events: none;
    }
  }
`;

export const PointsBlock = styled(Box)<WithType>`
  padding: 12px 0;

  .label {
    &.completed {
      opacity: 0.5;
    }

    font-weight: bold;
    border-radius: 2px;
    width: 43px;
    height: 18px;
    text-align: center;
    margin-right: 5px;
    color: white;
    font-size: 10px;
    text-transform: capitalize;
    background-color: ${(props) => props.theme.knowledgeBase[props.type].color};
  }

  .points {
    line-height: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.knowledgeBase[props.type].color};
    font-size: 12px;
  }

  .title-block {
    &.completed {
      opacity: 0.5;
    }

    font-size: 14px;
    line-height: 15px;
    margin-top: 7px;
  }

  .completed-box {
    color: ${(props) =>
      props.theme.knowledgeBase[EKnowledgeBaseItemTypes.GUIDE].color};
    margin-left: 280px;
    font-size: 10px;

    .checkmark {
      margin-left: 5px;
    }
  }
`;

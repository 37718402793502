import handleResponseError from '@/redux/middlewares/errorInterceptor';
import { ELocalStoreKeys } from '@/types/consts';
import axios, { AxiosResponse } from 'axios';

const getToken = () => {
  return localStorage.getItem(ELocalStoreKeys.ACCESS_TOKEN);
};

const defaultHeaders = { 'Content-Type': 'application/json' };

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: defaultHeaders,
  timeout: 1000 * 60 * 5, // 5 min test
});

const handleRequest = (config: any) => {
  const token = getToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
};

const handleResponse = (response: AxiosResponse) => {
  return response;
};

export const setupResponseInterceptor = (store: any) => {
  instance.interceptors.request.use(handleRequest);
  instance.interceptors.response.use(
    (response) => handleResponse(response),
    (error) => handleResponseError(error, store)
  );
};

export default instance;

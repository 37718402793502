import {
  CompanyPointManagmentContainer,
  CompanyPointManagmentSection,
  CompanyPointManagmentTable,
  CompanyPointManagmentTitle,
  CompanyPointManagmentTitleBlock,
  CompanyPointManagmentTitleContainer,
  CompanyPointSection,
  CompanyPointsTitle,
  CompanyPointValue,
  NameWrapper,
  PointsManagementTable,
} from '@/components/team-admin/points-managment/components';
import EditTotalPointsRewardModal from '@/components/user-profile/reward-points/EditTotalPointsRewardModal';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import useCompany from '@/hooks/useCompany';
import {
  changeAutoCalculatorCompany,
  getCompany,
  getCompany as getCompanyAction,
  getCompanyUsersRewardPoints,
  giftCompanyUsersRewardPoints,
  loadRewardPointsReport,
  refundRewardPoints,
  updateCompany,
  updateCompanyUsersRewardPoints,
} from '@/redux/actions/company';
import { setCompanyAvailablePoints } from '@/redux/reducers/company';
import {
  toggleModal,
  toggleModalFlex,
} from '@/redux/reducers/componentsManage';
import { getCompany as getCompanySelector } from '@/redux/selectors/companySelectors';
import { DEFAULT_TOAST_TIME } from '@/types/consts';
import {
  ICompany,
  IUpdateUsersAvailablePoints,
  IUpdateUsersGiftedPoints,
} from '@/types/models';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import { Box, Button, Container, Grid, Tooltip } from '@mui/material';
import {
  GridCellEditCommitParams,
  GridEditInputCell,
  GridPagination,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ConfirmModal, { ModalStatuses } from '../dialogs/ConfirmModal';
import { NumberFormatCustom } from '../form-elements/NumberInput';
import { DownloadIcon, InfoRedIcon } from '../icons';
import { AvatarType, CustomAvatar } from '../UI/CustomAvatar';
import CompanyPointsFooter from './CompanyPointsFooter';
import PointsManagmentHistoryWrapper from './PointsManagmentHistory/PointsManagmentHistoryWrapper';
import EditFlexPointsRewardModal from '@/components/user-profile/reward-points/EditFlexPointsRewardModal';
import { DownloadReportButton } from '@/components/admin/company/Tools';
import Switch from '@/components/UI/Switch';

const CustomGridCell: FC<GridRenderCellParams> = (params) => {
  if (!params.value) {
    return <></>;
  }

  const { profileImage, firstName, lastName, email, isConfirm } = params.value;

  return (
    <Box display="flex" width="100%">
      <CustomAvatar
        diameter={24}
        avatartype={AvatarType.USER}
        sx={{
          mr: 2,
        }}
        alt="Remy Sharp"
        src={profileImage || undefined}
      >
        {getUserAvatarLetter({
          firstName,
          lastName,
          email,
        })}
      </CustomAvatar>
      <NameWrapper>
        {firstName ? firstName + ' ' + lastName : email}
      </NameWrapper>
      {!isConfirm && (
        <Box width="20px" ml="5px" display="flex" alignItems="center">
          <Tooltip title="This user has not accepted their invitation">
            <InfoRedIcon />
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

interface EditCellProps extends GridRenderEditCellParams {
  title: string;
}

const EditInputCell = (props: EditCellProps) => {
  const { error, title } = props;

  return (
    <Tooltip open={!!error} title={title}>
      <Box>
        <GridEditInputCell
          {...props}
          inputComponent={NumberFormatCustom as any}
        />
      </Box>
    </Tooltip>
  );
};

const renderEditCell = (params: EditCellProps) => {
  return <EditInputCell {...params} />;
};

const CompanyPointsManagement = () => {
  const { isSuperAdmin, isRwAdmin, isTeamLead, user } = useAuth();
  const { company: myCompany } = useCompany();
  const dispatch = useAppDispatch();

  const adminCompany = useAppSelector(getCompanySelector) as ICompany;

  const company = isTeamLead ? (myCompany as ICompany) : adminCompany;
  const availablePoints = company?.points.availablePoints;
  const flexPoints = company?.points.flexPoints;

  const [rows, setRows] = useState<Array<any>>([]);
  const [availablePointsState, setAvailablePointsState] =
    useState(availablePoints);
  const [editedAvailablePointsRows, setEditedAvailablePointsRows] =
    useState<any>({});
  const [flexPointsState, setFlexPointsState] = useState(flexPoints);
  const [editedGiftedPointsRows, setEditedGiftedPointsRows] = useState<any>({});
  const [availableConfirmOpen, setAvailableConfirmationOpen] = useState(false);
  const [preparedAvailable, setPreparedAvailable] =
    useState<Array<IUpdateUsersAvailablePoints> | null>(null);

  const [giftedConfirmOpen, setGiftedConfirmationOpen] = useState(false);
  const [preparedGifted, setPreparedGifted] =
    useState<Array<IUpdateUsersGiftedPoints> | null>(null);
  const [toggleAutoCalculator, setToggleAutoCalculator] =
    useState<boolean>(true);
  const handleOpen = () => {
    if (!isSuperAdmin && !isRwAdmin) {
      return;
    }

    dispatch(toggleModal(true));
  };

  const handleFlexOpen = () => {
    if (!isSuperAdmin && !isRwAdmin) {
      return;
    }

    dispatch(toggleModalFlex(true));
  };

  useEffect(() => {
    if (!availablePoints) {
      return;
    }

    const availablePointsChanged =
      Object.entries(editedAvailablePointsRows).reduce(
        (acc: number, [key, value]: any) => {
          const delta =
            Number(value.availablePoints) -
            rows.find((row: any) => row.id === Number(key))?.availablePoints;
          acc += delta;
          return acc;
        },
        0
      ) || 0;

    const giftedPointsChanged =
      Object.entries(editedGiftedPointsRows).reduce(
        (acc: number, [key, value]: any) => {
          const delta =
            Number(value.points) -
            rows.find((row: any) => row.id === Number(key))?.points;
          acc += delta;
          return acc;
        },
        0
      ) || 0;

    setAvailablePointsState(availablePoints - availablePointsChanged);

    setFlexPointsState(flexPoints - giftedPointsChanged);
  }, [
    flexPoints,
    availablePoints,
    editedGiftedPointsRows,
    editedAvailablePointsRows,
  ]);

  useEffect(() => {
    if (isTeamLead && user && !company) {
      dispatch(getCompany(user.companies[0].id)).unwrap();
    }

    if (!company) {
      return;
    }

    setToggleAutoCalculator(company.autoRefundRewardPoints);
    dispatch(getCompanyUsersRewardPoints(company.id))
      .unwrap()
      .then((resp) => {
        const mappedRows = resp.map((item: any) => ({
          id: item.user.id,
          points: 0,
          ...item,
        }));
        setRows(mappedRows);
      });
  }, [dispatch, company]);

  const onCellEdit = (params: GridCellEditCommitParams) => {
    if (params.field === 'availablePoints') {
      const changed = { ...editedAvailablePointsRows };

      changed[params.id] = {
        ...changed[params.id],
        [params.field]: +params.value,
      };

      setEditedAvailablePointsRows(changed);
    }

    if (params.field === 'points') {
      const changed = { ...editedGiftedPointsRows };

      changed[params.id] = {
        ...changed[params.id],
        [params.field]: +params.value,
      };

      setEditedGiftedPointsRows(changed);
    }
  };

  const handleAvailableClick = useCallback(
    (preparedRows: Array<IUpdateUsersAvailablePoints>) => {
      setPreparedAvailable(preparedRows);
      setAvailableConfirmationOpen(true);
    },
    []
  );

  const updateAvailbalePoints = useCallback(() => {
    setAvailableConfirmationOpen(false);
    dispatch(
      updateCompanyUsersRewardPoints({
        companyId: company.id,
        data: preparedAvailable as Array<IUpdateUsersAvailablePoints>,
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Users available points were successfully updated!', {
          position: 'top-right',
          autoClose: DEFAULT_TOAST_TIME,
          hideProgressBar: false,
        });
        return dispatch(getCompanyAction(company.id)).unwrap();
      })
      .then((company: ICompany) => {
        setEditedAvailablePointsRows({});
        if (company) {
          dispatch(
            setCompanyAvailablePoints({
              companyId: company.id,
              points: company.points,
            })
          );
        }
      })
      .catch(({ error }: any) => {
        toast.error(error, {
          position: 'top-right',
        });
      });
  }, [company?.id, preparedAvailable]);

  const handleGiftedClick = useCallback(
    (preparedRows: Array<IUpdateUsersGiftedPoints>) => {
      setPreparedGifted(preparedRows);
      setGiftedConfirmationOpen(true);
    },
    []
  );

  const updateGiftedPoints = useCallback(() => {
    setGiftedConfirmationOpen(false);
    dispatch(
      giftCompanyUsersRewardPoints({
        companyId: company.id,
        data: preparedGifted as Array<IUpdateUsersGiftedPoints>,
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Users Gifted points were successfully updated!', {
          position: 'top-right',
          autoClose: DEFAULT_TOAST_TIME,
          hideProgressBar: false,
        });
        return dispatch(getCompanyAction(company.id)).unwrap();
      })
      .then((company: ICompany) => {
        if (company) {
          dispatch(
            setCompanyAvailablePoints({
              companyId: company.id,
              points: company.points,
            })
          );
        }
        setEditedGiftedPointsRows({});
        // here call reset
        // const newRows = rows.map((row: any) => {
        //   const newRow = apiRef.current.getRow(row.id);
        //   newRow.totalPoints =
        //     Number(newRow.totalPoints) + Number(newRow.points);
        //   newRow.points = 0;
        //   return newRow;
        // });

        // apiRef.current.restoreState(newRows as GridInitialStateCommunity);
      })
      .catch(({ error }: any) => {
        toast.error(error, {
          position: 'top-right',
        });
      });
  }, [preparedGifted, company?.id]);

  const autoCalculatorChange = () => {
    setToggleAutoCalculator(!toggleAutoCalculator);
    dispatch(
      changeAutoCalculatorCompany({
        id: company.id,
        autoRefundRewardPoints: !toggleAutoCalculator,
      })
    );
  };

  const handleResetPoint = async () => {
    await dispatch(refundRewardPoints(company.id));
    await dispatch(getCompanyUsersRewardPoints(company.id))
      .unwrap()
      .then((resp) => {
        const mappedRows = resp.map((item: any) => ({
          id: item.user.id,
          points: 0,
          ...item,
        }));
        setRows(mappedRows);
      });
    await dispatch(getCompany(company.id));
  };

  const handleLoadRewardPointsReport = () => {
    if (company?.id) {
      dispatch(loadRewardPointsReport(company.id))
        .unwrap()
        .then((reportData: any) => {
          const blob = new Blob([reportData], {
            type: 'text/csv;charset=utf-8;',
          });

          const link = document.createElement('a');
          if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute(
              'download',
              `Reward Points Report(${company.name}).csv`
            );
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        });
    }
  };

  const onPageChange = () => {
    const element = document.querySelector('.MuiDataGrid-columnHeadersInner');
    if (element) {
      element.scrollIntoView();
    }
  };

  return (
    <>
      <Container maxWidth={'lg'}>
        <CompanyPointSection>
          <Box display="flex">
            <Container>
              <CompanyPointsTitle variant={'h2'}>
                Master Points
              </CompanyPointsTitle>
              <Grid container justifyContent={'center'} mt={4}>
                <CompanyPointValue onClick={handleOpen}>
                  {availablePointsState?.toLocaleString('en', {
                    useGrouping: true,
                  })}
                </CompanyPointValue>
                <EditTotalPointsRewardModal />
              </Grid>
            </Container>
            <Container>
              <CompanyPointsTitle variant={'h2'}>
                Flex Points
              </CompanyPointsTitle>
              <Grid container justifyContent={'center'} mt={4}>
                <CompanyPointValue onClick={handleFlexOpen}>
                  {flexPointsState?.toLocaleString('en', {
                    useGrouping: true,
                  })}
                </CompanyPointValue>
                <EditFlexPointsRewardModal />
              </Grid>
            </Container>
            {isSuperAdmin && (
              <Container>
                <Grid
                  item
                  md={12}
                  display="flex"
                  alignItems="center"
                  justifyContent={'center'}
                >
                  <Switch
                    sx={{ marginRight: 3 }}
                    name={'autoRefundRewardPoints'}
                    placeholder={'Reward Points'}
                    checked={toggleAutoCalculator}
                    onChange={autoCalculatorChange}
                  />
                  <CompanyPointsTitle variant={'h3'}>
                    Auto Calculator
                  </CompanyPointsTitle>
                </Grid>
                <Grid container justifyContent={'center'} mt={4}>
                  {!toggleAutoCalculator && (
                    <Button
                      color={'secondary'}
                      onClick={handleResetPoint}
                      sx={{ width: 130, fontSize: 12 }}
                    >
                      Reset Points
                    </Button>
                  )}
                </Grid>
              </Container>
            )}
          </Box>
        </CompanyPointSection>
      </Container>

      <Container maxWidth={'lg'}>
        <CompanyPointManagmentSection>
          <CompanyPointManagmentContainer>
            <CompanyPointManagmentTitleContainer>
              {isSuperAdmin && (
                <DownloadReportButton
                  color={'secondary'}
                  variant={'outlined'}
                  startIcon={<DownloadIcon />}
                  onClick={handleLoadRewardPointsReport}
                >
                  Download
                </DownloadReportButton>
              )}
              <CompanyPointManagmentTitleBlock>
                <CompanyPointManagmentTitle>
                  {format(new Date(), 'MMMM')} Transactions
                </CompanyPointManagmentTitle>
              </CompanyPointManagmentTitleBlock>
            </CompanyPointManagmentTitleContainer>

            <CompanyPointManagmentTable>
              <div style={{ width: '100%' }}>
                <PointsManagementTable
                  autoHeight
                  pageSize={50}
                  rowsPerPageOptions={[50]}
                  rowHeight={66}
                  rows={rows}
                  columns={[
                    { field: 'id', headerName: 'Id', hide: true },
                    {
                      field: 'user',
                      headerName: '',
                      sortable: false,
                      flex: 1.5,
                      renderCell: (params: GridRenderCellParams) => {
                        return <CustomGridCell {...params} />;
                      },
                    },
                    {
                      field: 'availablePoints',
                      headerName: 'Available',
                      sortable: false,
                      editable: true,
                      type: 'number',
                      headerAlign: 'center',
                      align: 'center',
                      flex: 1,
                      preProcessEditCellProps: (
                        params: GridPreProcessEditCellProps
                      ) => {
                        const delta =
                          Number(params.props.value) -
                          rows.find((row: any) => row.id === Number(params.id))
                            ?.availablePoints;
                        const hasError = {
                          error:
                            delta > Number(availablePoints) ||
                            Number(params.props.value) <
                              rows.find(
                                (row: any) => row.id === Number(params.id)
                              )?.earnedPoints,
                          title:
                            delta > Number(availablePoints)
                              ? 'Additional Master Points Required'
                              : 'Value must be greater than users already earned points!',
                        };

                        return { ...params.props, ...hasError };
                      },
                      renderEditCell: (props) =>
                        renderEditCell(props as EditCellProps),
                      valueFormatter: (params) =>
                        Number(params.value).toLocaleString('en'),
                    },
                    {
                      field: 'earnedPoints',
                      headerName: 'Earned',
                      sortable: false,
                      type: 'number',
                      headerAlign: 'center',
                      align: 'center',
                      flex: 1,
                      valueFormatter: (params) =>
                        Number(params.value).toLocaleString('en'),
                    },
                    {
                      field: 'points',
                      headerName: 'Gifted',
                      sortable: false,
                      editable: true,
                      type: 'number',
                      headerAlign: 'center',
                      align: 'center',
                      flex: 1,
                      preProcessEditCellProps: (
                        params: GridPreProcessEditCellProps
                      ) => {
                        const hasError =
                          params.props.value > Number(flexPoints) ||
                          params.props.value < 0;
                        return { ...params.props, error: hasError };
                      },
                      renderEditCell: (props) =>
                        renderEditCell({
                          ...props,
                          title: 'Additional Flex Points Required',
                        }),
                      valueFormatter: (params) =>
                        Number(params.value).toLocaleString('en'),
                    },
                    {
                      field: 'totalPoints',
                      headerName: 'Total Reward Points',
                      sortable: false,
                      type: 'number',
                      headerAlign: 'left',
                      cellClassName: 'total',
                      headerClassName: 'total',
                      align: 'left',
                      flex: 2,
                      valueFormatter: (params) =>
                        Number(params.value).toLocaleString('en'),
                    },
                  ]}
                  disableColumnFilter
                  disableColumnMenu
                  pagination
                  onPageChange={onPageChange}
                  onCellEditCommit={onCellEdit}
                  components={{
                    Footer: () => (
                      <>
                        <GridPagination />
                        <CompanyPointsFooter
                          rows={rows}
                          editedAvailablePointsRows={editedAvailablePointsRows}
                          onUpdatedAvailablePoints={handleAvailableClick}
                          editedGiftedPointsRows={editedGiftedPointsRows}
                          onGiftedPoints={handleGiftedClick}
                        />
                      </>
                    ),
                  }}
                />
              </div>
            </CompanyPointManagmentTable>
          </CompanyPointManagmentContainer>
        </CompanyPointManagmentSection>
        <PointsManagmentHistoryWrapper />
      </Container>
      <ConfirmModal
        textConfirm="Allocate"
        confirmStatus={ModalStatuses.PRIMARY}
        title="Warning!"
        text={
          'You are about to allocate points. This action cannot be undone. Press "Allocate" to continue.'
        }
        isOpen={availableConfirmOpen}
        onClose={() => setAvailableConfirmationOpen(false)}
        onConfirm={() => updateAvailbalePoints()}
      />
      <ConfirmModal
        textConfirm="Confirm"
        confirmStatus={ModalStatuses.PRIMARY}
        title="Warning!"
        text={
          'You are about to gift points. This action cannot be undone. Press "Confirm" to continue.'
        }
        isOpen={giftedConfirmOpen}
        onClose={() => setGiftedConfirmationOpen(false)}
        onConfirm={() => updateGiftedPoints()}
      />
    </>
  );
};

export default CompanyPointsManagement;

import { EUsers } from '@/redux/constants';
import instance from '@/redux/middlewares/api';
import { addUserToCompany } from '@/redux/reducers/company';
import { setLoading } from '@/redux/reducers/users';
import { RootState } from '@/redux/store';
import { EUserRole, roles } from '@/types/consts';
import {
  IActivityLogPagination,
  IActivityRange,
  IBreakTimerSetting,
  IDateRange,
  IMessagesStat,
  IParams,
  IRoles,
  IUpdateUserImage,
  IUser,
  IUserCreate,
  IUsersPaginated,
  IUserUpdate,
} from '@/types/models';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getRoleIdByRoleName = (roleName: IRoles) => {
  return roles[roleName];
};

const getUsersUrlByRoleName = (roleName?: string) => {
  if (roleName === EUserRole.TEAMLEAD) {
    return 'team-admin/users';
  }

  if (roleName === EUserRole.SUPER_ADMIN) {
    return 'super-admin/users';
  }

  if (roleName === EUserRole.RW_ADMIN) {
    return 'rw-admin/users';
  }

  return 'users';
};

const getUsers = createAsyncThunk(
  EUsers.getUsersList,
  async (params: IParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoading(true));
      const { user } = thunkAPI.getState() as RootState;
      const role = user.user?.role.name;
      const getUsersUrl = getUsersUrlByRoleName(role);
      const users = await instance.get<{ countAll: number; users: IUser[] }>(
        getUsersUrl,
        {
          params: {
            limit: params.limit,
            offset: params.offset,
            onlyDeleted: params.onlyDeleted,
          },
        }
      );
      return users.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(setLoading(false));
    }
  }
);

const getUsersByParams = createAsyncThunk(
  EUsers.getUsers,
  async (params: IParams, thunkAPI) => {
    try {
      const { user } = thunkAPI.getState() as RootState;
      const role = user.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(role);
      const users = await instance.get<IUsersPaginated>(getUsersUrl, {
        params: {
          rolesId: params.roles?.map(getRoleIdByRoleName) || [],
          company: params.company,
          rwCompany: params.rwCompany,
          name: params.name,
          limit: params.limit,
          onlyDeleted: params.onlyDeleted,
        },
      });
      if (users.data.countAll > users.data.users.length) {
        return (
          await instance.get<IUsersPaginated>(getUsersUrl, {
            params: {
              rolesId: params.roles?.map(getRoleIdByRoleName) || [],
              company: params.company,
              rwCompany: params.rwCompany,
              name: params.name,
              limit: users.data.countAll,
              offset: 0,
            },
          })
        ).data;
      }
      return users.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getUserById = createAsyncThunk(
  EUsers.getUser,
  async (userId: number, thunkAPI) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;
      const getUsersUrl = getUsersUrlByRoleName(role);

      const user = await instance.get<IUser>(`${getUsersUrl}/${userId}`);
      return user.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getUserProgress = createAsyncThunk(
  EUsers.getUserProgress,
  async (range: IActivityRange, thunkAPI) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(role);
      const user = await instance.get(
        `${getUsersUrl}/${range.userId}/progress-meters?from=${range.from}&to=${range.to}`
      );
      return {
        data: user.data,
        dataLevel: range.dataLevel,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getUserKnowledgeBase = createAsyncThunk(
  EUsers.getUserKnowledgeBase,
  async (id: number, thunkAPI) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(role);
      const response = await instance.get(
        `${getUsersUrl}/${id}/knowledge-base`
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getUserActivityLog = createAsyncThunk(
  EUsers.getUserActivity,
  async (logPagination: IActivityLogPagination, thunkAPI) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(role);

      const user = await instance.get(
        `${getUsersUrl}/${logPagination.userId}/activities`,
        {
          params: {
            offset: logPagination.offset,
            limit: logPagination.limit,
            from: logPagination.from?.toDateString(),
            to: logPagination.to?.toDateString(),
          },
        }
      );
      return {
        data: user.data,
        offset: logPagination.offset,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getUserRewardPointsLog = createAsyncThunk(
  EUsers.getUserRewardPointsLog,
  async (logPagination: IActivityLogPagination, thunkAPI) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(role);

      const user = await instance.get(
        `${getUsersUrl}/${logPagination.userId}/reward-points-log`,
        {
          params: {
            offset: logPagination.offset,
            limit: logPagination.limit,
            from: logPagination.from?.toDateString(),
            to: logPagination.to?.toDateString(),
          },
        }
      );
      return {
        data: user.data,
        offset: logPagination.offset,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getUserRewardPoints = createAsyncThunk(
  EUsers.getUserRewardPoints,
  async (userId: number, thunkAPI) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(role);
      const response = await instance.get(
        `${getUsersUrl}/${userId}/reward-points`
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getUserDailyStreak = createAsyncThunk(
  EUsers.getUserDailyStreak,
  async (dateRange: IDateRange, thunkAPI) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(role);
      const response = await instance.get(
        `${getUsersUrl}/${dateRange.userId}/streaks`,
        {
          params: {
            from: dateRange.from,
            to: dateRange.to,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const getUserMessagesStat = createAsyncThunk(
  EUsers.getUserMessagesStat,
  async (userId: number, thunkAPI) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(role);
      const user = await instance.get<IMessagesStat>(
        `${getUsersUrl}/${userId}/messages-stats`
      );
      return user.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const updateUser = createAsyncThunk(
  EUsers.updateUser,
  async (updatedUserData: IUserUpdate, thunkAPI) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(role);

      const user = await instance.patch<IUser>(
        `${getUsersUrl}/${updatedUserData.id}`,
        updatedUserData
      );
      return user.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const createUser = createAsyncThunk(
  EUsers.createUser,
  async (createUserData: IUserCreate[], thunkAPI) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(role);

      const user = await instance.post<IUser[]>(
        `${getUsersUrl}/new-user`,
        createUserData
      );
      thunkAPI.dispatch(addUserToCompany(user.data));

      return user.data[0];
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const uploadUserAvatar = createAsyncThunk(
  EUsers.uploadAvatar,
  async (updatedAvatarUserData: IUpdateUserImage, thunkAPI) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(role);

      const formData = new FormData();
      formData.append(
        'profile_image',
        updatedAvatarUserData.blob as Blob,
        'avatar_image.jpeg'
      );
      const response = await instance.post<IUser>(
        `${getUsersUrl}/${updatedAvatarUserData.id}/image`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const deleteUserAvatar = createAsyncThunk(
  EUsers.deleteUserAvatar,
  async (id: number, thunkAPI) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(role);

      const response = await instance.delete(`${getUsersUrl}/${id}/image`);
      return {
        userId: id,
        data: response.data,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const updateUserBreakTimerSettings = createAsyncThunk(
  EUsers.updateBreakTimersSetting,
  async (
    { id, breaktimers }: { id: number; breaktimers: IBreakTimerSetting[] },
    thunkAPI
  ) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(role);

      const response = await instance.post(
        `${getUsersUrl}/${id}/update-break-timers-settings`,
        breaktimers
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

interface IDeleteUser {
  id: number;
  isSoft?: boolean;
}

const deleteUser = createAsyncThunk(
  EUsers.deleteUser,
  async (props: IDeleteUser, thunkAPI) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(role);

      const response = await instance.delete(`${getUsersUrl}/${props.id}`, {
        data: {
          isSoft: props.isSoft,
        },
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const restoreUser = createAsyncThunk(
  EUsers.restoreUser,
  async (id: number, thunkAPI) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(role);

      const response = await instance.post(`${getUsersUrl}/${id}`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const resendLink = createAsyncThunk(
  EUsers.resendLink,
  async (id: number, thunkAPI) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(role);

      const response = await instance.get(`${getUsersUrl}/${id}/send-invite`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const redeemPoints = createAsyncThunk(
  EUsers.redeemPoints,
  async (
    {
      points,
      email,
      userId,
    }: { points: number; email: string; userId?: number },
    thunkAPI
  ) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const role = userState.user?.role.name;

      let getUsersUrl = getUsersUrlByRoleName(role);
      if (role === EUserRole.TEAMLEAD) {
        getUsersUrl += `/${userState.user?.id}`;
      }
      if (role === EUserRole.RW_ADMIN || role === EUserRole.SUPER_ADMIN) {
        getUsersUrl += `/${userId}`;
      }
      const response = await instance.post(
        `${getUsersUrl}/reward-points/transfer`,
        { email, points }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

interface IChangeRole {
  id: number;
  role: EUserRole;
}

const changeRole = createAsyncThunk(
  EUsers.changeRole,
  async ({ id, role }: IChangeRole, thunkAPI) => {
    try {
      const { user: userState } = thunkAPI.getState() as RootState;
      const myRole = userState.user?.role.name;

      const getUsersUrl = getUsersUrlByRoleName(myRole);

      const response = await instance.post(`${getUsersUrl}/${id}/change-role`, {
        role,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export {
  getUsers,
  getUsersByParams,
  getUserById,
  getUserActivityLog,
  getUserProgress,
  getUserKnowledgeBase,
  updateUser,
  createUser,
  uploadUserAvatar,
  deleteUserAvatar,
  updateUserBreakTimerSettings,
  getUserRewardPoints,
  getUserDailyStreak,
  deleteUser,
  getUserRewardPointsLog,
  resendLink,
  restoreUser,
  getUserMessagesStat,
  changeRole,
  redeemPoints,
};

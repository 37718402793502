import { Hydrate, Move, Relax, Stretch } from '@/components/icons/breaktimers';
import IconDone from '@/components/icons/breaktimers/CustomComponents/IconDone';
import IconInactive from '@/components/icons/breaktimers/CustomComponents/IconInactive';
import IconStart from '@/components/icons/breaktimers/CustomComponents/IconStart';

export enum EBrakeTimerState {
  INACTIVE = 'inactive',
  WAITING_FOR_INTERACTION = 'waiting',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
}

export enum EBrakeTimerTypes {
  HYDRATE = 'hydrate',
  RELAX = 'relax',
  STRETCH = 'stretch',
  MOVE = 'move',
}

export enum EExtraBrakeTimerTypes {
  BONUS = 'bonus',
}

export enum EActionsBreakTimerStatus {
  COMPLETED = 'completed',
  FAILED = 'failed',
  PENDING = 'pending',
  STARTING = 'starting',
  PRE_COMPLETED = 'pre-completed',
  PROCESSING = 'processing',
}

export const finishedActionStatuses = [
  EActionsBreakTimerStatus.FAILED,
  EActionsBreakTimerStatus.COMPLETED,
];

export const breakTimersData = {
  [EBrakeTimerTypes.HYDRATE]: {
    text: 'Hydrate',
    durationSeconds: 30,
    image: Hydrate,
    failedText: 'Take a Drink!',
  },
  [EBrakeTimerTypes.MOVE]: {
    text: 'Move',
    durationSeconds: 600,
    image: Move,
    failedText: 'Take a Break!',
  },
  [EBrakeTimerTypes.RELAX]: {
    text: 'Relax',
    durationSeconds: 300,
    image: Relax,
    failedText: 'Take a Break!',
  },
  [EBrakeTimerTypes.STRETCH]: {
    text: 'Stretch',
    durationSeconds: 120,
    image: Stretch,
    failedText: 'Take a Break!',
  },
};

export const breakTimersStatesIcons = {
  [EBrakeTimerState.INACTIVE]: {
    Icon: IconInactive,
  },
  [EBrakeTimerState.WAITING_FOR_INTERACTION]: {
    Icon: IconStart,
  },
  [EBrakeTimerState.PROCESSING]: {
    Icon: IconInactive,
  },
  [EBrakeTimerState.COMPLETED]: {
    Icon: IconDone,
  },
};

export type TBreaktimerSettings = {
  [key in EBrakeTimerTypes]?: number[];
};

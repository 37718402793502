import { ICategory } from '@/redux/reducers/workspace';
import { FunctionComponent, SVGProps } from 'react';

export interface WorkspaceItem {
  id?: number;
  name: string;
  title?: string;
  descriptions?: string;
  link?: string;
  category?: ICategory;
  categoryId?: number;
  status: boolean;
  zIndex: number;
  clipPath: string;
  linkTitle?: string;
  cursor: 'initial' | 'pointer';
  component: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  picture?: any;
  grayColor: number;
  bright: number;
}

export interface WorkspaceModalItem {
  isOpen: boolean;
  item: WorkspaceItem | null;
}

export enum WorkspaceMenuStatus {
  view = 'Workspace View',
  build = 'Build My Workspace',
}

import CreateUserButtonComponent from '@/components/admin/user/CreateUserButton';
import AddTeammembersModal from '@/components/team-admin/AddTeammembersModal';
import { Box, Grid, Typography } from '@mui/material';
import CompanyTeamList from './company/TeamList';

const Team = () => {
  return (
    <Box>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={4}
      >
        <Typography variant={'h3'}>Team</Typography>
        <CreateUserButtonComponent />
        <AddTeammembersModal />
      </Grid>
      <CompanyTeamList />
    </Box>
  );
};

export default Team;

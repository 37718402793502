import { Grid } from '@mui/material';
import styled from 'styled-components';

export const BuildMyWorkspaceBlock = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.default};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .item-empty {
    width: 478px;
    height: 0px;
  }
`;

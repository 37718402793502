import {
  deleteMessage,
  getMembers,
  getMessages,
  getPinnedMassages,
  getPrevMessages,
  getUnreadMessagesByChat,
  getUserConversation,
  loginChatUser,
  pinMessage,
  sendImage,
  sendMessage,
  unpinMessage,
  updateMessages,
  updateMessagesRead,
  updateUserStatus,
} from '@/redux/actions/chat';
import { EConversationType, ELimits } from '@/types/consts';
import {
  IConversation,
  ICurrentChat,
  IMessage,
  IPinnedMessage,
  IUserChat,
} from '@/types/models';
import { createSlice } from '@reduxjs/toolkit';
import { getChatSupportsByCompanyId } from '@/redux/actions/company';
import { idToUid } from '@/utils/chatHelpers';

export interface ChatState {
  userChat: IUserChat | null;
  conversations: IConversation[] | null;
  currentChat: ICurrentChat | null;
  unreadedMessages?: IMessage[];
  messages: IMessage[] | null;
  members: IUserChat[] | null;
  hasMoreMessages?: boolean;
  selectedCompanyId?: number;
  pinnedMessage?: IPinnedMessage;
  companyChatSupport: string[];
}

const initialState: ChatState = {
  userChat: null,
  conversations: null,
  currentChat: null,
  messages: null,
  members: null,
  companyChatSupport: [],
};

export const { actions, reducer } = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    resetChatState: () => initialState,
    setCurrentChat: (state, { payload }) => {
      state.currentChat = payload;
      state.messages = null;
      state.unreadedMessages = undefined;
    },
    updateDeletedMessage: (state, { payload }) => {
      if (state.messages) {
        state.messages = [...state.messages.filter(({ id }) => id !== payload)];
      }
    },
    setSelectedCompanyId: (state, { payload }) => {
      state.selectedCompanyId = payload;
    },
    setMembers: (state, { payload }) => {
      state.members = payload;
    },
    setPinnedMessage: (state, { payload }) => {
      state.pinnedMessage = payload;
    },
    unpinDeletedMessage: (state) => {
      state.pinnedMessage = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginChatUser.fulfilled, (state, { payload }) => {
        state.userChat = payload;
      })
      .addCase(getMembers.fulfilled, (state, { payload }) => {
        state.members = payload;
      })
      .addCase(updateUserStatus.fulfilled, (state, { payload }) => {
        if (state.members?.length) {
          state.members = [
            ...state.members.map((user) => {
              if (user.uid === payload.uid) {
                return payload as IUserChat;
              }
              return user;
            }),
          ];
        }
        if (state.currentChat?.user?.uid === payload.uid) {
          state.currentChat = {
            ...state.currentChat,
            user: payload as IUserChat,
          };
        }
      })
      .addCase(deleteMessage.fulfilled, (state, { payload }) => {
        if (state.messages) {
          state.messages = [
            ...state.messages.filter(({ id }) => id !== payload),
          ];

          if (state.conversations && state.messages) {
            state.conversations = [
              ...state.conversations.map((conv) => {
                if (conv.lastMessage?.id === payload) {
                  return {
                    ...conv,
                    lastMessage: state.messages
                      ? state.messages[state.messages.length - 1]
                      : undefined,
                  };
                }
                return conv;
              }),
            ];
          }
        }
      })
      .addCase(getMessages.fulfilled, (state, { payload, meta }) => {
        if (state.currentChat && meta.arg.chatId === state.currentChat.chatId) {
          state.hasMoreMessages = payload.length >= ELimits.MESSAGES;
          state.messages = payload;
        }
      })
      .addCase(getPrevMessages.fulfilled, (state, { payload, meta }) => {
        if (state.currentChat && meta.arg.chatId === state.currentChat.chatId) {
          state.hasMoreMessages = payload.length >= ELimits.MESSAGES;
          const additionalMessages = payload;
          state.messages = state.messages
            ? [...additionalMessages, ...state.messages]
            : [...additionalMessages];
        }
      })
      .addCase(getUnreadMessagesByChat.fulfilled, (state, { payload }) => {
        state.unreadedMessages = payload;
      })
      .addCase(getUserConversation.fulfilled, (state, { payload }) => {
        state.conversations = payload;
      })
      .addCase(sendMessage.fulfilled, (state, { payload }) => {
        if (
          state.currentChat &&
          (state.currentChat.chatId === payload.receiver ||
            state.currentChat.chatId === payload.sender) &&
          !state.messages?.some(({ id }) => id === payload.id)
        ) {
          state.messages = state.messages?.length
            ? [...state.messages, payload]
            : [payload];
          if (state.conversations) {
            state.conversations = [
              ...state.conversations.map((conv) => {
                if (conv?.conversationId === payload?.consversationId) {
                  return {
                    ...conv,
                    lastMessage: payload,
                  };
                }
                return conv;
              }),
            ];
          }
        }
      })
      .addCase(sendImage.fulfilled, (state, { payload }) => {
        if (
          state.currentChat &&
          (state.currentChat.chatId === payload.receiver ||
            state.currentChat.chatId === payload.sender) &&
          !state.messages?.some(({ id }) => id === payload.id)
        ) {
          state.messages = state.messages?.length
            ? [...state.messages, payload]
            : [payload];
          if (state.conversations) {
            state.conversations = [
              ...state.conversations.map((conv) => {
                if (conv?.conversationId === payload?.conversationId) {
                  return {
                    ...conv,
                    lastMessage: payload,
                  };
                }
                return conv;
              }),
            ];
          }
        }
      })
      .addCase(updateMessages.fulfilled, (state, { payload }) => {
        if (payload) {
          if (
            state.currentChat &&
            ((payload.receiverType === EConversationType.GROUP &&
              state.currentChat.chatId === payload?.receiver) ||
              (payload.receiverType === EConversationType.PERSONAL &&
                state.currentChat.chatId === payload?.sender)) &&
            !state.messages?.some(({ id }) => id === payload.id)
          ) {
            state.messages = state.messages?.length
              ? [...state.messages, payload]
              : [payload];
          }
          if (state.conversations) {
            state.conversations = [
              ...state.conversations.map((conv) => {
                if (conv?.conversationId === payload?.conversationId) {
                  return {
                    ...conv,
                    lastMessage: payload,
                    unreadMessageCount:
                      payload.sender !== state.userChat?.uid
                        ? `${Number(conv.unreadMessageCount) + 1}`
                        : conv.unreadMessageCount,
                  };
                }
                return conv;
              }),
            ];
          }
        }
      })
      .addCase(updateMessagesRead.fulfilled, (state, { payload }) => {
        state.conversations = state.conversations?.length
          ? [
              ...state.conversations.map((conv) => {
                if (
                  conv.conversationId === payload.conversationId &&
                  conv.lastMessage?.id
                ) {
                  return {
                    ...conv,
                    unreadMessageCount:
                      payload.message.id >= conv.lastMessage?.id
                        ? '0'
                        : payload.unreadCount.toString(),
                  };
                }
                return conv;
              }),
            ]
          : state.conversations;
        if (
          state.messages &&
          ((state.currentChat?.chatId === payload.message.receiver &&
            state.currentChat?.isGroup) ||
            (!state.currentChat?.isGroup &&
              state.currentChat?.chatId === payload.message.sender))
        ) {
          state.messages = [
            ...state.messages.map((message) => {
              if (message.id <= payload.message.id) {
                return {
                  ...message,
                  isUnread: false,
                };
              }
              return message;
            }),
          ];
          if (state.unreadedMessages) {
            state.unreadedMessages = [];
          }
        }
      })
      .addCase(getPinnedMassages.fulfilled, (state, { payload }) => {
        state.pinnedMessage = payload;
      })
      .addCase(pinMessage.fulfilled, (state, { payload }) => {
        state.pinnedMessage = payload;
      })
      .addCase(unpinMessage.fulfilled, (state) => {
        state.pinnedMessage = undefined;
      })
      .addCase(getChatSupportsByCompanyId.fulfilled, (state, { payload }) => {
        state.companyChatSupport = payload.map(({ id }) => idToUid(id));
      });
  },
});

export const {
  resetChatState,
  setCurrentChat,
  updateDeletedMessage,
  setSelectedCompanyId,
  setMembers,
  setPinnedMessage,
  unpinDeletedMessage,
} = actions;

export default reducer;

import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useUnreadMessagesCount from '@/hooks/useUnreadMessagesCount';
import { getMembers } from '@/redux/actions/chat';
import { getUsersByParams } from '@/redux/actions/users';
import { setMembers, setSelectedCompanyId } from '@/redux/reducers/chat';
import { setMyTeam } from '@/redux/reducers/user';
import {
  chatUserSelector,
  getSelectedCompanyId,
} from '@/redux/selectors/chatSelector';
import { ICompany, IUser } from '@/types/models';
import { idToUid } from '@/utils/chatHelpers';
import { Box } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import ChatCompanySelect from './CompanyChatSelect';

export interface ICompanyWithUnread extends ICompany {
  unread: number;
}

const CompanySelectWrapper: FC = () => {
  const dispatch = useAppDispatch();
  const selectedCompany = useAppSelector(getSelectedCompanyId);
  const [companyTeam, setCompanyTeam] = useState<IUser[] | null>(null);
  const chatUser = useAppSelector(chatUserSelector);
  const { companies, unreadMessageByCompany } = useUnreadMessagesCount();

  useEffect(() => {
    setCompanyTeam(null);
    dispatch(setMyTeam(null));
    dispatch(setMembers(null));
  }, []);

  const companyName = useMemo(() => {
    return companies?.find(({ id }) => id === selectedCompany)?.name;
  }, [selectedCompany, companies]);

  useEffect(() => {
    if (companyName) {
      dispatch(setMyTeam(null));
      dispatch(setMembers(null));
      setCompanyTeam(null);
      dispatch(
        getUsersByParams({
          company: companyName,
        })
      )
        .unwrap()
        .then(({ users }) => {
          if (users) {
            dispatch(setMyTeam(users));
            setCompanyTeam(users);
          }
        });
    }
  }, [companyName]);

  useEffect(() => {
    if (companyTeam?.length && !!chatUser?.uid) {
      dispatch(
        getMembers([
          ...companyTeam.filter((item) => !!item).map(({ id }) => idToUid(id)),
        ])
      );
    }
  }, [companyTeam, chatUser?.uid]);

  const changeCompany = useCallback((companyId: number | null) => {
    dispatch(setSelectedCompanyId(companyId));
  }, []);

  return (
    <Box width="100%">
      {unreadMessageByCompany?.length && (
        <ChatCompanySelect
          options={unreadMessageByCompany}
          defaultCompany={unreadMessageByCompany[0].id}
          onCompanySelect={changeCompany}
        />
      )}
    </Box>
  );
};

export default CompanySelectWrapper;

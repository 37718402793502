import { ProgressItem } from '@/pages/dashboard/MyWorkspace/MyWorkspaceHeaderMenu/types';
import {
  BoxText,
  SliderBox,
  SliderWrapper,
  WorkSpaceProgressSliderBox,
} from '@/pages/dashboard/MyWorkspace/MyWorkspaceHeaderMenu/WorkSpaceProgressSlider/component';
import theme from '@/theme/theme';
import React from 'react';

interface ProgressProps {
  total: number;
  progress: ProgressItem;
}

export const WorkSpaceProgressSlider: React.FC<ProgressProps> = ({
  total,
  progress,
}) => {
  return (
    <SliderBox>
      <BoxText
        myColor={
          progress.value === 5
            ? theme.palette.text.secondary
            : theme.palette.breakTimer.move.dark
        }
      >
        {progress.name}
      </BoxText>
      <SliderWrapper>
        <WorkSpaceProgressSliderBox
          value={progress.value || 0}
          max={total}
          step={1}
          marks
          myColor={
            progress.value > 0
              ? theme.palette.border.main
              : theme.palette.custom.light
          }
          myDisplay={progress.value === 0 ? 'none' : 'inerrit'}
        />
      </SliderWrapper>
    </SliderBox>
  );
};

import { PointsLabel } from '@/components/user-profile/reward-points/components';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import BuildMyWorkspace from '@/pages/dashboard/MyWorkspace/BuildMyWorkspace';
import { initialValue } from '@/pages/dashboard/MyWorkspace/constants';
import { BoxWrapper } from '@/pages/dashboard/MyWorkspace/MyWorkspaceHeaderMenu/component';
import { createProgressArray } from '@/pages/dashboard/MyWorkspace/MyWorkspaceHeaderMenu/helpers';
import { ProgressItem } from '@/pages/dashboard/MyWorkspace/MyWorkspaceHeaderMenu/types';
import { WorkSpaceProgressSlider } from '@/pages/dashboard/MyWorkspace/MyWorkspaceHeaderMenu/WorkSpaceProgressSlider';
import { WorkspaceItem } from '@/pages/dashboard/MyWorkspace/types';
import {
  StatisticProgress,
  WorkspaceContainer,
} from '@/pages/super-admin/user/component';
import { getUserById } from '@/redux/actions/users';
import {
  getUserWorkspaceItems,
  getUserWorkspaceLevels,
} from '@/redux/actions/workSpace';
import { adminSelectedUser } from '@/redux/selectors/users';
import { getUserSelector } from '@/redux/selectors/userSelectors';
import {
  currentItemsSelector,
  workSpaceCountCategoriesSelector,
} from '@/redux/selectors/workspaceSelector';
import { Box, Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const UserWorkspace = () => {
  const { userId } = useParams<{ userId: string }>();
  const currentUser = useAppSelector(getUserSelector);
  const user = useAppSelector(adminSelectedUser);
  const dispatch = useAppDispatch();
  const currentItems = useAppSelector(currentItemsSelector);
  const [state, setState] = useState<WorkspaceItem[]>(initialValue);
  const [progressComplete, setProgressComplete] = useState<ProgressItem[]>([]);

  useEffect(() => {
    if (!user) {
      userId && dispatch(getUserById(+userId));
    }
  }, [user, userId]);

  useEffect(() => {
    setProgressComplete(
      createProgressArray(currentItems.filter((item) => item.status).length - 2)
    );
  }, [currentItems]);

  useEffect(() => {
    if (user) {
      dispatch(getUserWorkspaceItems(user.id));
      dispatch(getUserWorkspaceLevels(user.id));
    }
  }, [user]);

  useEffect(() => {
    const fullState = state.map((value) => {
      const currentItem = currentItems.find((item) => item.name === value.name);
      return { ...value, ...currentItem };
    });

    setState(fullState);
  }, [currentItems, user]);

  if (currentItems.length === 0 || !currentUser || !user) {
    return null;
  }
  return (
    <Container>
      <WorkspaceContainer mt={4}>
        <PointsLabel variant={'h2'} mb={4}>
          Workspace
        </PointsLabel>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <StatisticProgress>
            <b>
              {currentItems.filter((item) => item.status).length - 2} / 32
              Wellness Items Acquired
            </b>
          </StatisticProgress>
          <Box>
            <BoxWrapper>
              {progressComplete.map((progress) => (
                <WorkSpaceProgressSlider
                  key={progress.id}
                  progress={progress}
                  total={5}
                />
              ))}
            </BoxWrapper>
          </Box>
        </Box>
      </WorkspaceContainer>
      <Grid
        container
        columnGap={2}
        flexWrap={'nowrap'}
        alignItems={'stretch'}
        my={6}
      >
        <BuildMyWorkspace state={state} setState={setState} canEdit={false} />
      </Grid>
    </Container>
  );
};

export default UserWorkspace;

import { AvatarType, CustomAvatar } from '@/components/UI/CustomAvatar';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import { GridRenderCellParams } from '@mui/x-data-grid';

const CustomGridCell: React.FC<GridRenderCellParams> = (params) => {
  if (!params.value) {
    return <></>;
  }

  const { profileImage, firstName, lastName, email } = params.value;

  const isFullName = firstName && lastName;

  return (
    <>
      <CustomAvatar
        diameter={24}
        avatartype={AvatarType.USER}
        sx={{
          mr: 2,
        }}
        alt="Remy Sharp"
        src={profileImage || undefined}
      >
        {getUserAvatarLetter({ firstName, lastName, email })}
      </CustomAvatar>
      {isFullName ? `${firstName} ${lastName}` : email}
    </>
  );
};

export default CustomGridCell;

import ResendLinkButton from '@/components/admin/user/ResendLinkUserButton';
import { BillingContactIcon, DeleteCompanyIcon } from '@/components/icons';

import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { getUserById } from '@/redux/actions/users';
import { getUserSelector } from '@/redux/selectors/userSelectors';
import theme from '@/theme/theme';
import { IUser } from '@/types/models';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import { formatPhoneNumber } from '@/utils/textHelpers';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AvatarType, CustomAvatar } from '../UI/CustomAvatar';

const DeleteButton = styled(Button)`
  width: 100%;
  height: 42px;
  background-color: #feebef;
  color: ${({ theme }) => theme.palette.redColor.main};

  &:hover {
    background-color: #c2d2e1;
  }
`;

const UserAvatar = styled(Avatar)`
  background-color: orange;
  margin: ${({ theme }) => theme.spacing(0, 'auto')};
  width: 46px;
  height: 46px;
`;

const UserEmail = styled(Typography)`
  color: #0099ff;
  text-decoration: underline;
  text-align: center;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

const UserPhone = styled(Typography)`
  color: #5b587d;
  text-align: center;
`;

interface IUserPopoverContent {
  userId: number | null;
  handleDelete: () => void;
}

const UserPopoverContent: React.FC<IUserPopoverContent> = ({
  userId,
  handleDelete,
}) => {
  const dispatch = useAppDispatch();
  const authUser = useAppSelector(getUserSelector);
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    userId &&
      dispatch(getUserById(Number(userId)))
        .unwrap()
        .then((user) => {
          if (user) {
            setUser(user);
          }
          setLoading(false);
        });
  }, [dispatch, userId]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ width: '250px' }}>
      <CustomAvatar
        diameter={46}
        avatartype={AvatarType.USER}
        sx={{
          margin: theme.spacing(0, 'auto'),
        }}
        alt="Remy Sharp"
      >
        {!user
          ? ''
          : getUserAvatarLetter({
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
            })}
      </CustomAvatar>
      {user?.email && <UserEmail>{user.email}</UserEmail>}
      {user?.phone && (
        <UserPhone my={1}>{formatPhoneNumber(user.phone)}</UserPhone>
      )}

      {user?.companies[0]?.billingContactId === user?.id && (
        <UserPhone my={1}>
          <BillingContactIcon /> Billing Contact
        </UserPhone>
      )}

      <Grid container justifyContent="center">
        {!user?.isConfirm && (
          <Grid item md={6}>
            <ResendLinkButton userId={user?.id as number} />
          </Grid>
        )}
        {user?.id !== authUser?.id && (
          <Grid item md={4}>
            <DeleteButton
              type={'button'}
              variant={'contained'}
              color={'primary'}
              onClick={handleDelete}
            >
              <DeleteCompanyIcon />
            </DeleteButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default UserPopoverContent;

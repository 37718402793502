import DataGrid from '@/components/UI/DataGrid';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import CustomGridCell from '@/pages/super-admin/company/CustomGridCell';
import { getCompanies as getCompaniesAction } from '@/redux/actions/company';
import {
  getCompaniesLoading,
  getCompanySearchText,
  getFilteredCount,
  getSearchedCompanies,
} from '@/redux/selectors/companySelectors';
import { ELimits } from '@/types/consts';
import { ICompany } from '@/types/models';
import { getRoutePrefixByRoleName } from '@/utils/routeRoleHelpers';
import { numberColumnSortingComparator } from '@/utils/tableHelpers';
import { formatPhoneNumber } from '@/utils/textHelpers';
import { Box } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import React, { useCallback, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Id', hide: true },
  { field: 'name', headerName: 'Company', flex: 2 },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 2,
    cellClassName: 'phone',
    valueFormatter: (params) => formatPhoneNumber(params.value),
  },
  { field: 'email', headerName: 'Email', flex: 2 },
  {
    field: 'teamAdmin',
    headerName: 'Team Admin',
    flex: 2,
    renderCell: (params: GridRenderCellParams) => {
      return <CustomGridCell {...params} />;
    },
  },
  {
    field: 'rwAdmin',
    headerName: 'RW Admin',
    flex: 2,
    renderCell: (params: GridRenderCellParams) => {
      return <CustomGridCell {...params} />;
    },
  },
  {
    field: 'masterPoints',
    headerName: 'Master Points',
    flex: 1,
    valueFormatter: (params) => Number(params.value).toLocaleString('en'),
    sortComparator: numberColumnSortingComparator,
  },
];

const rowsMapper = (companies: ICompany[]) =>
  companies.map((company) => ({
    id: company.id,
    name: company.name,
    phone: company.teams[0]?.lead?.phone || '',
    email: company.teams[0]?.lead?.email || '',
    teamAdmin: company.teams[0].lead && {
      profileImage: company.teams[0].lead?.profileImage,
      firstName: company.teams[0].lead?.firstName,
      lastName: company.teams[0].lead?.lastName,
      email: company.teams[0].lead?.email,
    },
    rwAdmin: company.rwAdmin && {
      profileImage: company.rwAdmin?.profileImage,
      firstName: company.rwAdmin?.firstName,
      lastName: company.rwAdmin?.lastName,
      email: company.rwAdmin?.email,
    },
    masterPoints: company.points?.availablePoints || 0,
  }));

const CompanyTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const companies = useAppSelector(getSearchedCompanies);
  const count = useAppSelector(getFilteredCount);
  const search = useAppSelector(getCompanySearchText);
  const loading = useAppSelector(getCompaniesLoading);
  const navigate = useNavigate();
  const { userRole } = useAuth();

  const handleRowClick = (params: GridRowParams) => {
    navigate(
      getRoutePrefixByRoleName(userRole) + `/company/${params.id}/information`
    );
  };

  useEffect(() => {
    if (!companies && !search) {
      dispatch(getCompaniesAction({ limit: ELimits.COMPANY }));
    }
  }, [dispatch, companies, search]);

  const onPageChange = useCallback(
    (page: number) => {
      if (
        companies?.length &&
        count &&
        !search &&
        companies.length < count &&
        (page + 1) * ELimits.COMPANY > companies.length
      ) {
        const pagination = {
          limit: (page + 1) * ELimits.COMPANY - companies.length,
          offset: companies?.length - 1,
        };
        dispatch(getCompaniesAction(pagination));
      }

      const element = document.querySelector('.MuiDataGrid-columnHeadersInner');
      if (element) {
        element.scrollIntoView();
      }
    },
    [count, companies?.length, search]
  );

  return (
    <Box>
      {companies && (
        <DataGrid
          columns={columns}
          rowCount={count}
          loading={loading}
          rows={rowsMapper(companies)}
          pageSize={50}
          rowsPerPageOptions={[50]}
          onPageChange={onPageChange}
          onRowClick={handleRowClick}
        />
      )}
    </Box>
  );
};

export default CompanyTable;

import { IconButton } from '@/components/admin/user/components';
import { CloseIcon } from '@/components/icons';
import {
  ButtonWithoutStyles,
  WorkspaceItemPopoverBlock,
} from '@/pages/dashboard/MyWorkspace/MyWorkspaceView/WorkspaceItemPopover/component';
import {
  WorkspaceItem,
  WorkspaceMenuStatus,
} from '@/pages/dashboard/MyWorkspace/types';
import { Grid, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface BasicPopoverProps {
  children: React.ReactNode;
  item: WorkspaceItem | null;
  onClose: () => void;
  status: WorkspaceMenuStatus;
}

export const WorkspaceItemPopover: React.FC<BasicPopoverProps> = ({
  children,
  item,
  onClose,
  status,
}) => {
  const navigate = useNavigate();
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setCoordinates({ x: event.pageY, y: event.pageX });
      setAnchorEl(event.currentTarget);
    },
    []
  );
  const redirect = useCallback(() => navigate('/my-workspace'), [navigate]);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
    onClose();
  }, [onClose]);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  return (
    <ButtonWithoutStyles
      onClick={
        status === WorkspaceMenuStatus.view
          ? (event) => handleClick(event)
          : () => redirect()
      }
    >
      {children}
      {item && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: coordinates.x, left: coordinates.y }}
        >
          <WorkspaceItemPopoverBlock>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>

            <Grid container spacing={2}>
              <Grid
                item
                display="flex"
                justifyContent="center"
                alignItems="center"
                m={5}
              >
                <item.picture className="box-1" />
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs m={5}>
                  <Grid item>
                    <Typography variant={'h1'} className="box-2">
                      {item.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={'h3'} mb={2}>
                      {item.descriptions}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <a href={item.link} target="_blank" rel="noreferrer">
                      <Typography variant={'h3'} className="box-5">
                        {item.linkTitle}
                      </Typography>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </WorkspaceItemPopoverBlock>
        </Popover>
      )}
    </ButtonWithoutStyles>
  );
};

import React from 'react';
import { Box } from '@mui/material';
import { FormTitle } from '@/components/form-elements/components';

const QRCode: React.FC = () => {
  return (
    <Box width="100%">
      <FormTitle>Mobile Apps</FormTitle>
      <Box
        display="flex"
        width="100%"
        justifyContent={'center'}
        alignItems={'flex-end'}
      >
        <Box height="200" width="375">
          <img
            height={200}
            width={375}
            src={`/static/images/qr-code.png`}
            alt={'QR Code'}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default QRCode;

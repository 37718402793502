import styled from 'styled-components';

export const DashboardWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.custom.dark};
  height: 100%;
  padding: 0;
  padding-left: 5px;
  padding-bottom: 10px;

  .extra-break-timers-panel {
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding-top: 20px;
  }

  .break-timer-text {
    text-decoration: none;
    font-weight: 500;
    font-size: 12px;
    color: ${({ theme }) => theme.palette.secondary.light};
  }

  @media screen and (min-width: 320px) and (max-width: 480px) {
    .workspace-dashboard {
      min-height: 163px;
      width: 100%;
      margin-bottom: 20px;
    }
  }
  @media screen and (min-width: 481px) and (max-width: 640px) {
    .workspace-dashboard {
      min-height: 230px;
    }
  }
  @media screen and (min-width: 641px) and (max-width: 800px) {
    .workspace-dashboard {
      min-height: 235px;
    }
  }
  @media screen and (min-width: 801px) and (max-width: 960px) {
    .workspace-dashboard {
      min-height: 270px;
    }
  }
  @media screen and (min-width: 961px) and (max-width: 1024px) {
    .workspace-dashboard {
      min-height: 300px;
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1070px) {
    .workspace-dashboard {
      min-height: 330px;
    }
  }
  @media screen and (min-width: 1071px) and (max-width: 1150px) {
    .workspace-dashboard {
      min-height: 340px;
    }
  }
  @media screen and (min-width: 1151px) and (max-width: 1200px) {
    .workspace-dashboard {
      min-height: 372px;
    }
  }
  @media screen and (min-width: 1201px) and (max-width: 1280px) {
    .workspace-dashboard {
      min-height: 395px;
    }
  }
  @media screen and (min-width: 1281px) and (max-width: 1350px) {
    .workspace-dashboard {
      min-height: 430px;
    }
  }
  @media screen and (min-width: 1351px) and (max-width: 1440px) {
    .workspace-dashboard {
      min-height: 460px;
    }
  }

  @media screen and (min-width: 1441px) and (max-width: 1534px) {
    .workspace-dashboard {
      min-height: 477px;
    }
  }

  @media screen and (min-width: 1535px) and (max-width: 1600px) {
    .workspace-dashboard {
      min-height: 494px;
    }
  }
  @media screen and (min-width: 1601px) and (max-width: 1730px) {
    .workspace-dashboard {
      min-height: 530px;
    }
  }
  @media screen and (min-width: 1731px) and (max-width: 1800px) {
    .workspace-dashboard {
      min-height: 566px;
    }
  }
  @media screen and (min-width: 1801px) and (max-width: 1900px) {
    .workspace-dashboard {
      min-height: 594px;
    }
  }
  @media screen and (min-width: 1901px) and (max-width: 2000px) {
    .workspace-dashboard {
      min-height: 636px;
    }
  }
  @media screen and (min-width: 2001px) and (max-width: 2200px) {
    .workspace-dashboard {
      min-height: 680px;
    }
  }
  @media screen and (min-width: 2201px) and (max-width: 2400px) {
    .workspace-dashboard {
      min-height: 770px;
    }
  }
  @media screen and (min-width: 2401px) and (max-width: 2699px) {
    .workspace-dashboard {
      min-height: 848px;
    }
  }
  @media screen and (min-width: 2700px) {
    .workspace-dashboard {
      min-height: 976px;
    }
  }
  .workspace-dashboard {
    margin-bottom: 50px;
    width: 100%;
  }
`;

import ConfirmModal, { ModalStatuses } from '@/components/dialogs/ConfirmModal';
import { DeleteCompanyIcon } from '@/components/icons';
import DataGrid from '@/components/UI/DataGrid';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import { deleteUser, getUsers, restoreUser } from '@/redux/actions/users';
import {
  getFilteredUsersCount,
  getSearchedUsers,
  getSearchUserMode,
  getUserListLoading,
  getUsersSelector,
} from '@/redux/selectors/users';
import { DEFAULT_TOAST_TIME, ELimits } from '@/types/consts';
import { IParams, IUser } from '@/types/models';
import { getRoutePrefixByRoleName } from '@/utils/routeRoleHelpers';
import { Box, Button, Grid } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const DeleteButton = styled(Button)`
  background-color: #feebef;
  color: ${({ theme }) => theme.palette.redColor.main};

  &:hover {
    background-color: #c2d2e1;
  }
`;

const CustomGridCellRestore: React.FC<GridRenderCellParams> = (params) => {
  const dispatch = useAppDispatch();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleOpen = (event: any) => {
    event.stopPropagation();
    setConfirmationOpen(true);
  };

  const handleClickDelete = () => {
    dispatch(deleteUser({ id: params.row.id, isSoft: false }))
      .unwrap()
      .then(() => {
        setConfirmationOpen(false);
        toast.success('User was successfully deleted!', {
          position: 'top-right',
          autoClose: DEFAULT_TOAST_TIME,
          hideProgressBar: false,
        });
      });
  };

  const handleClickRestore = async (event: any) => {
    event.stopPropagation();
    try {
      await dispatch(restoreUser(params.row.id)).unwrap();
      toast.success('User was successfully restored!', {
        position: 'top-right',
        autoClose: DEFAULT_TOAST_TIME,
        hideProgressBar: false,
      });
    } catch (e: any) {
      toast.error(e.error, {
        position: 'top-right',
      });
    }
  };
  return (
    <Grid container alignItems={'center'} justifyContent="center" gap={2}>
      <Button onClick={handleClickRestore} variant="outlined" color="secondary">
        Restore
      </Button>
      <DeleteButton onClick={handleOpen}>Delete</DeleteButton>
      <ConfirmModal
        confirmStatus={ModalStatuses.WARNING}
        confirmIcon={<DeleteCompanyIcon />}
        title="Warning!"
        isOpen={confirmationOpen}
        textConfirm="Delete user"
        text="Delete with caution. User will be deleted permanently! Deleting this user, will permanently delete all data related to this user, including reward points and billing information. Deleted data will not be recoverable"
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleClickDelete}
      />
    </Grid>
  );
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Id', hide: true },
  { field: 'userName', headerName: 'Name', flex: 1 },
  { field: 'companyName', headerName: 'Company', flex: 1 },
  { field: 'title', headerName: 'Title', flex: 1 },
  { field: 'email', headerName: 'Email', flex: 2 },
  {
    field: 'isConfirm',
    headerName: 'Actions',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return <CustomGridCellRestore {...params} />;
    },
  },
];

const rowsMapper = (users: IUser[]) =>
  users.map((user) => ({
    id: user.id,
    userName: `${user.firstName || ''} ${user.lastName || ''}`,
    companyName: (user.companies && user.companies[0]?.name) || 'N/A',
    title: user.title || 'N/A',
    phone: user.phone || '',
    email: user.email,
    isConfirm: user.isConfirm,
  }));

const DeletedUsersTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const isSearch = useAppSelector(getSearchUserMode);
  const allUsers = useAppSelector(getUsersSelector);
  const filteredUsers = useAppSelector(getSearchedUsers);
  const [users, setUsers] = useState(allUsers);
  const count = useAppSelector(getFilteredUsersCount);
  const loading = useAppSelector(getUserListLoading);
  const { userRole } = useAuth();
  const navigate = useNavigate();

  const handleRowClick = (params: GridRowParams) => {
    navigate(
      getRoutePrefixByRoleName(userRole) + `/user/${params.id}/information`
    );
  };

  useEffect(() => {
    dispatch(getUsers({ limit: ELimits.USER, onlyDeleted: true }));
  }, [dispatch]);

  useEffect(() => {
    setUsers(isSearch ? filteredUsers : allUsers);
  }, [isSearch, filteredUsers, allUsers]);

  const onPageChange = useCallback(
    (page: number) => {
      if (
        users?.length &&
        count &&
        !isSearch &&
        users.length < count &&
        (page + 1) * ELimits.USER > users.length
      ) {
        const pagination: IParams = {
          limit: (page + 1) * ELimits.USER - users.length,
          offset: users?.length - 1,
          onlyDeleted: true,
        };
        dispatch(getUsers(pagination));
      }

      const element = document.querySelector('.MuiDataGrid-columnHeadersInner');
      if (element) {
        element.scrollIntoView();
      }
    },
    [dispatch, count, users?.length, isSearch]
  );

  return (
    <Box>
      {users && (
        <DataGrid
          columns={columns}
          rowCount={count}
          loading={loading}
          rows={rowsMapper(users)}
          pageSize={50}
          rowsPerPageOptions={[50]}
          onPageChange={onPageChange}
          onRowClick={handleRowClick}
        />
      )}
    </Box>
  );
};

export default DeletedUsersTable;

import {
  Form,
  Placeholder,
  UpdateButton,
} from '@/components/team-admin/components';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import useCompany from '@/hooks/useCompany';
import { FormControlWrapper } from '@/pages/auth/components';
import {
  createCompanyShippingAddress,
  updateCompanyShippingAddress,
} from '@/redux/actions/company-shipping-adress';
import { getCompanyLoading } from '@/redux/selectors/companySelectors';
import { getCountriesSelector } from '@/redux/selectors/country';
import { getStatesSelector } from '@/redux/selectors/state';
import { ICompany, IState } from '@/types/models';
import {
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

type ICompanyValues = Pick<ICompany, keyof ReturnType<typeof initialValues>>;

const initialValues = (company: ICompany | null) => {
  const shippingAddress =
    company && company.shippingAddress && company.shippingAddress[0];

  return {
    streetAddress: shippingAddress?.streetAddress || '',
    streetAddress2: shippingAddress?.streetAddress2 || '',
    countryId: shippingAddress?.countryId || 0,
    stateId: shippingAddress?.stateId || 0,
    city: shippingAddress?.city || '',
    zipCode: shippingAddress?.zipCode || '',
  };
};

const validationSchema = Yup.object().shape({
  streetAddress: Yup.string().required('Required'),
  streetAddress2: Yup.string().optional(),
  countryId: Yup.number().positive().min(1).required('Required'),
  stateId: Yup.number().optional(),
  city: Yup.string().required('Required'),
  zipCode: Yup.string().optional(),
});

const CompanyShippingInformationForm = () => {
  const dispatch = useAppDispatch();
  const { company } = useCompany();
  const companyLoading = useAppSelector(getCompanyLoading);

  const { isSuperAdmin, isRwAdmin } = useAuth();
  const states = useAppSelector(getStatesSelector);
  const countries = useAppSelector(getCountriesSelector);

  const [submitError, setSubmitError] = React.useState<string>('');
  const [isFormSubmited, setFormSubmited] = React.useState<boolean>(false);
  const [filteredStates, setFilteredStates] = useState<IState[]>(
    states.filter((state) => state.id === initialValues(company).stateId)
  );

  const handleCatchError = ({ error }: any) => {
    setSubmitError(error);
  };

  useEffect(() => {
    const countryId = initialValues(company).countryId;
    countryId &&
      setFilteredStates(
        states.filter((state) => state.country.id === countryId)
      );
  }, [company, states]);

  const handleOnSubmit = (
    values: ICompanyValues,
    { setSubmitting }: FormikHelpers<ICompanyValues>
  ) => {
    setFormSubmited(false);
    setSubmitError('');

    if (
      company &&
      company.shippingAddress.length &&
      company.shippingAddress[0].id
    ) {
      dispatch(
        updateCompanyShippingAddress({
          id: company?.shippingAddress[0].id,
          ...values,
          companyId: company.id,
        })
      )
        .unwrap()
        .then(() => setFormSubmited(true))
        .then(() =>
          setTimeout(() => {
            setFormSubmited(false);
          }, 2000)
        )
        .catch(handleCatchError)
        .finally(() => setSubmitting(false));
    } else {
      company &&
        dispatch(
          createCompanyShippingAddress({
            ...values,
            companyId: company.id,
          })
        )
          .unwrap()
          .then(() => setFormSubmited(true))
          .then(() =>
            setTimeout(() => {
              setFormSubmited(false);
            }, 2000)
          )
          .catch(handleCatchError)
          .finally(() => setSubmitting(false));
    }
  };

  if (companyLoading) {
    return <CircularProgress />;
  }

  return (
    <Formik
      initialValues={initialValues(company)}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({
        errors,
        handleBlur,
        setFieldValue,
        handleChange,
        touched,
        values,
      }) => {
        return (
          <Form>
            <Grid container flexDirection={'column'} height={'100%'}>
              <Typography variant="h3" my={5} noWrap={true}>
                Company Shipping Information
              </Typography>

              <Grid container flexDirection={'row'}>
                <Grid item md={isSuperAdmin || isRwAdmin ? 6 : 10}>
                  <FormControlWrapper
                    hiddenLabel={true}
                    error={!!errors.countryId && !!touched.countryId}
                  >
                    {!!values.countryId && <InputLabel>Country*</InputLabel>}
                    <Select
                      disabled={!countries || isSuperAdmin || isRwAdmin}
                      displayEmpty
                      name={'countryId'}
                      value={values.countryId}
                      error={Boolean(touched.countryId && errors.countryId)}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setFilteredStates(
                          states.filter(
                            (state) => state.country.id === event.target.value
                          )
                        );
                        setFieldValue('stateId', '');
                        handleChange(event);
                      }}
                      renderValue={(selected) => {
                        if (selected === 0) {
                          return <Placeholder>Country*</Placeholder>;
                        }

                        const country = countries.find(
                          ({ id }) => id === selected
                        );
                        return (
                          country?.name || <Placeholder>Country*</Placeholder>
                        );
                      }}
                      input={<OutlinedInput />}
                    >
                      <MenuItem value={''}>
                        <Placeholder>Country</Placeholder>
                      </MenuItem>
                      {countries.map((country) => (
                        <MenuItem key={country.id} value={country.id}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>

                    {touched.countryId && errors.countryId && (
                      <FormHelperText id="countryId" error>
                        Required
                      </FormHelperText>
                    )}
                  </FormControlWrapper>
                </Grid>
                <Grid item md={isSuperAdmin || isRwAdmin ? 6 : 10}>
                  <FormControlWrapper
                    hiddenLabel={true}
                    error={!!errors.streetAddress && !!touched.streetAddress}
                  >
                    {!!values.streetAddress && (
                      <InputLabel>Street Address*</InputLabel>
                    )}
                    <OutlinedInput
                      disabled={isSuperAdmin || isRwAdmin}
                      name={'streetAddress'}
                      placeholder={'Street Address*'}
                      value={values.streetAddress}
                      error={Boolean(
                        touched.streetAddress && errors.streetAddress
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      type={'text'}
                    />
                    {touched.streetAddress && errors.streetAddress && (
                      <FormHelperText id="streetAddress" error>
                        {errors.streetAddress}
                      </FormHelperText>
                    )}
                  </FormControlWrapper>
                </Grid>
                <Grid item md={isSuperAdmin || isRwAdmin ? 6 : 10}>
                  <FormControlWrapper
                    hiddenLabel={true}
                    error={!!errors.streetAddress2 && !!touched.streetAddress2}
                  >
                    {!!values.streetAddress2 && (
                      <InputLabel>Street Address (Line 2)</InputLabel>
                    )}
                    <OutlinedInput
                      disabled={isSuperAdmin || isRwAdmin}
                      name={'streetAddress2'}
                      placeholder={'Street Address (Line 2)'}
                      value={values.streetAddress2}
                      error={Boolean(
                        touched.streetAddress2 && errors.streetAddress2
                      )}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type={'text'}
                    />
                    {touched.streetAddress2 && errors.streetAddress2 && (
                      <FormHelperText id="streetAddress2" error>
                        {errors.streetAddress2}
                      </FormHelperText>
                    )}
                  </FormControlWrapper>
                </Grid>
                <Grid item md={isSuperAdmin || isRwAdmin ? 6 : 10}>
                  <FormControlWrapper
                    hiddenLabel={true}
                    error={!!errors.city && !!touched.city}
                  >
                    {!!values.city && <InputLabel>City*</InputLabel>}
                    <OutlinedInput
                      disabled={isSuperAdmin || isRwAdmin}
                      name={'city'}
                      placeholder={'City*'}
                      value={values.city}
                      error={Boolean(touched.city && errors.city)}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type={'text'}
                    />
                    {touched.city && errors.city && (
                      <FormHelperText id="city" error>
                        {errors.city}
                      </FormHelperText>
                    )}
                  </FormControlWrapper>
                </Grid>
                <Grid item md={isSuperAdmin || isRwAdmin ? 6 : 10}>
                  <FormControlWrapper
                    style={{ marginBottom: !!values.zipCode ? '30px' : '52px' }}
                    hiddenLabel={true}
                    error={!!errors.stateId && !!touched.stateId}
                  >
                    {!!values.stateId && <InputLabel>State</InputLabel>}
                    <Select
                      disabled={
                        !states ||
                        values.countryId === 0 ||
                        isSuperAdmin ||
                        isRwAdmin
                      }
                      displayEmpty
                      name={'stateId'}
                      value={values.stateId}
                      error={Boolean(touched.stateId && errors.stateId)}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      renderValue={(selected) => {
                        if (selected === 0) {
                          return <Placeholder>State</Placeholder>;
                        }

                        const state = states.find(({ id }) => id === selected);
                        return state?.name || <Placeholder>State</Placeholder>;
                      }}
                      input={<OutlinedInput />}
                    >
                      <MenuItem value={''}>
                        <Placeholder>State</Placeholder>
                      </MenuItem>
                      {filteredStates.map((state) => (
                        <MenuItem key={state.id} value={state.id}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>

                    {touched.stateId && errors.stateId && (
                      <FormHelperText id="stateId" error>
                        Required
                      </FormHelperText>
                    )}
                  </FormControlWrapper>
                </Grid>
                <Grid item md={isSuperAdmin || isRwAdmin ? 6 : 10}>
                  <FormControlWrapper
                    hiddenLabel={true}
                    error={!!errors.zipCode && !!touched.zipCode}
                  >
                    {!!values.zipCode && <InputLabel>Zip Code</InputLabel>}
                    <OutlinedInput
                      disabled={isSuperAdmin || isRwAdmin}
                      name={'zipCode'}
                      placeholder={'Zip Code'}
                      value={values.zipCode}
                      error={Boolean(touched.zipCode && errors.zipCode)}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type={'text'}
                    />
                    {touched.zipCode && errors.zipCode && (
                      <FormHelperText id="zipCode" error>
                        {errors.zipCode}
                      </FormHelperText>
                    )}
                  </FormControlWrapper>
                </Grid>
              </Grid>

              {isFormSubmited && (
                <FormHelperText error>
                  Company Shipping Address was successfully updated
                </FormHelperText>
              )}

              {!!submitError && (
                <FormHelperText error>{submitError}</FormHelperText>
              )}

              {!isRwAdmin && !isSuperAdmin && (
                <Grid item alignSelf={'center'} mt={'auto'} mb={3}>
                  <UpdateButton
                    type={'submit'}
                    variant={'contained'}
                    color={'primary'}
                  >
                    Update
                  </UpdateButton>
                </Grid>
              )}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CompanyShippingInformationForm;

import activityReducer from '@/redux/reducers/activity';
import breakTimerReducer from '@/redux/reducers/breaktimer';
import chatReducer from '@/redux/reducers/chat';
import companyReducer from '@/redux/reducers/company';
import sidebarReducer from '@/redux/reducers/componentsManage';
import countryReducer from '@/redux/reducers/country';
import knowledgeBaseReducer from '@/redux/reducers/knowledgeBase';
import notificationsReducer from '@/redux/reducers/notifications';
import stateReducer from '@/redux/reducers/state';
import userReducer from '@/redux/reducers/user';
import usersReducer from '@/redux/reducers/users';
import workSpaceReducer from '@/redux/reducers/workspace';

import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    user: userReducer,
    breakTimers: breakTimerReducer,
    componentsManage: sidebarReducer,
    company: companyReducer,
    users: usersReducer,
    state: stateReducer,
    country: countryReducer,
    notification: notificationsReducer,
    knowledgeBase: knowledgeBaseReducer,
    activity: activityReducer,
    chat: chatReducer,
    workSpace: workSpaceReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

declare module 'react-redux' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultRootState extends RootState {}
}

import CompanyBillingAddressForm from '@/components/admin/company/CompanyBillingAddressForm';
import CompanyDeleteButton from '@/components/admin/company/CompanyDeleteButton';
import Team from '@/components/admin/Team';
import CompanyShippingInformationForm from '@/components/team-admin/CompanyShippingInformationForm';
import useAuth from '@/hooks/useAuth';
import { Box, Container, Divider as MuiDivider, Grid } from '@mui/material';
import styled from 'styled-components';

const BillingAddress = styled(Box)`
  padding: ${({ theme }) => theme.spacing(1, 0, 10)};
  background-color: ${({ theme }) => theme.palette.background.default};
`;

const BillingAddressContainer = styled(Box)`
  max-width: 768px;
  margin: 0 auto;
`;

const Divider = styled(MuiDivider)`
  margin: ${({ theme }) => theme.spacing(12, 0, 8)};
`;

const Information = () => {
  const { isSuperAdmin } = useAuth();

  return (
    <>
      <BillingAddress component={'section'}>
        <BillingAddressContainer>
          <CompanyBillingAddressForm />
        </BillingAddressContainer>
      </BillingAddress>
      <BillingAddress component={'section'}>
        <BillingAddressContainer>
          <CompanyShippingInformationForm />
        </BillingAddressContainer>
      </BillingAddress>
      <Box component={'section'} paddingY={12}>
        <Container maxWidth={'lg'}>
          <Grid
            container
            justifyContent={'center'}
            flexWrap={'nowrap'}
            columnGap={8}
          >
            {/* <Grid item md={6}>
              <CompanyLogoSelect />
            </Grid> */}
            <Grid item md={4}>
              <Team />
            </Grid>
          </Grid>
          <Divider />
          {isSuperAdmin && <CompanyDeleteButton />}
        </Container>
      </Box>
    </>
  );
};

export default Information;

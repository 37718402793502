import ScrollbarComponent from '@/components/scrollbar/Scrollbar';
import {
  helpSectionItems,
  menuSection,
  messagingSectionItems,
} from '@/components/sidebar/dashboardItems';
import SidebarNavSection from '@/components/sidebar/SidebarNavSection';
import useAppSelector from '@/hooks/useAppSelector';
import { selectState } from '@/redux/selectors/componentsManage';
import React from 'react';
import styled from 'styled-components';
import MessagingNavSection from './MessagingNav/MessagingNavSection';
import WorkLifeNavItem from './WorkLifeNavItem';

const ScrollbarWrapper = styled('div')<{ open: boolean }>`
  padding-right: ${({ open, theme }) => theme.spacing(open ? 3 : 0)};
`;

const SidebarNav: React.FC = () => {
  const open = useAppSelector(selectState);

  return (
    <ScrollbarComponent>
      <ScrollbarWrapper open={open}>
        <SidebarNavSection
          pages={menuSection.pages}
          title={menuSection.title}
          withWorkShop={true}
        />
        <MessagingNavSection title={messagingSectionItems.title} />
        <SidebarNavSection
          pages={helpSectionItems.pages}
          title={helpSectionItems.title}
        />
      </ScrollbarWrapper>
    </ScrollbarComponent>
  );
};

export default SidebarNav;

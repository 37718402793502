import { EBrakeTimerTypes } from '@/components/breaktimer/constants';
import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { BreakTimersState } from '../reducers/breaktimer';

export const getBreakTimerData = (breakTimerType: EBrakeTimerTypes) =>
  createSelector(
    (state: DefaultRootState) => state.breakTimers,
    ({ [breakTimerType]: state }: BreakTimersState) => state
  );

export const getBreakTimerSettings = createSelector(
  (state: DefaultRootState) => state.breakTimers,
  ({ breaktimersSettings }: BreakTimersState) => breaktimersSettings
);

export const getBonusBreakTimer = createSelector(
  (state: DefaultRootState) => state.breakTimers,
  ({ bonus }: BreakTimersState) => bonus
);

export const getBreakTimerLoadings = createSelector(
  (state: DefaultRootState) => state.breakTimers,
  ({ loading }: BreakTimersState) => loading
);

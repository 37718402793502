import { EKnowledgeBase } from '@/redux/constants/knowledgeBase';
import instance from '@/redux/middlewares/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getKnowledgeBaseData = createAsyncThunk(
  EKnowledgeBase.getData,
  async (_, thunkAPI) => {
    try {
      const response = await instance.get(
        'knowledge-base/get-for-current-user'
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const completeKnowledgeBaseItem = createAsyncThunk(
  EKnowledgeBase.completeData,
  async (id: string, thunkAPI) => {
    try {
      const { data } = await instance.get('knowledge-base/complete/' + id);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const finishReadKnowledgeBaseItem = createAsyncThunk(
  EKnowledgeBase.finishReadingData,
  async (id: string, thunkAPI) => {
    try {
      const { data } = await instance.get('knowledge-base/reading/' + id);
      return data;
    } catch (error: any) {
      return thunkAPI.fulfillWithValue(error);
    }
  }
);

const knowledgeBaseStat = createAsyncThunk(
  EKnowledgeBase.getKnowledgeStat,
  async (_, thunkAPI) => {
    try {
      const response = await instance.get(
        'knowledge-base/knowledge-base-stats'
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export {
  getKnowledgeBaseData,
  completeKnowledgeBaseItem,
  finishReadKnowledgeBaseItem,
  knowledgeBaseStat,
};

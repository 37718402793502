import NotificationItem from '@/components/notifications/NotificationItem';
import ScrollbarComponent from '@/components/scrollbar/Scrollbar';
import useAppDispatch from '@/hooks/useAppDispatch';
import { getNotificationsData } from '@/redux/actions/notifications';
import { ELimits } from '@/types/consts';
import { INotification } from '@/types/models';
import { Skeleton } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

export interface IRewardPointsHistoryProps {
  notificationsLog: INotification[] | null;
  idsToRead: number[];
  readNotification: (id: number) => void;
}

interface IInfiniteLoadingWrapperProps {
  hasNextPage: boolean;
  items: Array<INotification>;
  isNextPageLoading: boolean;
  loadNextPage: () => void;
  idsToRead: number[];
  readNotification: (id: number) => void;
}

const InfiniteLoadingWrapper: React.FC<IInfiniteLoadingWrapperProps> = ({
  hasNextPage,
  isNextPageLoading,
  items,
  loadNextPage,
  readNotification,
  idsToRead,
}: IInfiniteLoadingWrapperProps) => {
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  const Item = ({ index, style }: { index: number; style: any }) => {
    if (!isItemLoaded(index)) {
      return <div>Loading...</div>;
    }

    return (
      <NotificationItem
        notification={items[index]}
        key={`notification-${index}`}
        style={style}
        readNotification={readNotification}
        idsToRead={idsToRead}
      />
    );
  };

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      threshold={4}
    >
      {({ onItemsRendered, ref }) => (
        <FixedSizeList
          height={500}
          itemSize={130}
          width="100%"
          itemCount={itemCount}
          onItemsRendered={onItemsRendered}
          ref={ref}
          outerElementType={ScrollbarComponent}
        >
          {Item}
        </FixedSizeList>
      )}
    </InfiniteLoader>
  );
};

const NotificationsList: React.FC<IRewardPointsHistoryProps> = ({
  notificationsLog,
  readNotification,
  idsToRead,
}) => {
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMoreData, setMoreDataState] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (notificationsLog && initialLoading) {
      setMoreDataState(
        !!notificationsLog?.length &&
          notificationsLog.length >= ELimits.ACTIVITY
      );
      setInitialLoading(false);
    }
  }, [notificationsLog?.length, initialLoading]);

  const loadMore = useCallback(() => {
    setLoading(true);
    const logPagination = {
      limit: ELimits.ACTIVITY,
      offset: (notificationsLog as INotification[]).length,
    };

    const loadingPromise = dispatch(getNotificationsData(logPagination));

    loadingPromise
      .unwrap()
      .then((result) => {
        setMoreDataState(
          result.data.length && result.data.length >= ELimits.ACTIVITY
        );
      })
      .finally(() => setLoading(false));
  }, [notificationsLog]);

  return (
    <>
      {notificationsLog ? (
        <>
          <InfiniteLoadingWrapper
            items={notificationsLog}
            hasNextPage={hasMoreData}
            isNextPageLoading={loading}
            loadNextPage={loadMore}
            idsToRead={idsToRead}
            readNotification={readNotification}
          />
        </>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default NotificationsList;

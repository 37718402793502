import {
  EBrakeTimerState,
  EBrakeTimerTypes,
} from '@/components/breaktimer/constants';
import { StyledComponentProps } from '@mui/material';
import styled from 'styled-components';

interface WithTypeAndState extends StyledComponentProps {
  type: EBrakeTimerTypes;
  state: EBrakeTimerState;
}

export const BreakTimerBlock = styled.div<WithTypeAndState>`
  height: 80px;
  width: -webkit-fill-available;
  border-radius: 8px;
  &:not(:last-child) {
    margin-right: 7px;
  }
  margin-top: 5px;
  padding: 12px 3px 7px 3px;
  text-align: center;
  display: flex;
  background-color: ${(props) => props.theme.palette.common.white};
  font-family: ${(props) => props.theme.typography.fontFamily};
  color: ${(props) => {
    if (props.state === EBrakeTimerState.INACTIVE) {
      return props.theme.palette.breakTimer[props.type].light;
    }

    return props.theme.palette.breakTimer[props.type].main;
  }};
  border: ${(props) => {
    if (
      props.state === EBrakeTimerState.PROCESSING ||
      props.state === EBrakeTimerState.COMPLETED
    ) {
      return `2px solid ${props.theme.palette.breakTimer[props.type].main}`;
    }

    return `1px solid ${props.theme.palette.common.white}`;
  }};

  transform: ${(props) => {
    if (props.state === EBrakeTimerState.WAITING_FOR_INTERACTION) {
      return `scale(1)`;
    }

    return `unset`;
  }};

  animation: ${(props) => {
    if (props.state === EBrakeTimerState.WAITING_FOR_INTERACTION) {
      return `pulse-${props.type} 1s infinite`;
    }

    return `none`;
  }};

  .breaktimer-image {
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }

  .action-button {
    width: 22px;
    height: 22px;
    margin: auto 0 auto auto;
    padding-bottom: 5px;
  }

  @keyframes pulse-hydrate {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0
        ${(props) =>
          props.theme.palette.breakTimer[EBrakeTimerTypes.HYDRATE].main};
    }

    50% {
      transform: scale(1);
      box-shadow: 0 0 4px 4px
        ${(props) =>
          props.theme.palette.breakTimer[EBrakeTimerTypes.HYDRATE].main};
    }

    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0
        ${(props) =>
          props.theme.palette.breakTimer[EBrakeTimerTypes.HYDRATE].main};
    }
  }

  @keyframes pulse-move {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0
        ${(props) => props.theme.palette.breakTimer[EBrakeTimerTypes.MOVE].main};
    }

    50% {
      transform: scale(1);
      box-shadow: 0 0 4px 4px
        ${(props) => props.theme.palette.breakTimer[EBrakeTimerTypes.MOVE].main};
    }

    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0
        ${(props) => props.theme.palette.breakTimer[EBrakeTimerTypes.MOVE].main};
    }
  }

  @keyframes pulse-stretch {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0
        ${(props) =>
          props.theme.palette.breakTimer[EBrakeTimerTypes.STRETCH].main};
    }

    50% {
      transform: scale(1);
      box-shadow: 0 0 4px 4px
        ${(props) =>
          props.theme.palette.breakTimer[EBrakeTimerTypes.STRETCH].main};
    }

    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0
        ${(props) =>
          props.theme.palette.breakTimer[EBrakeTimerTypes.STRETCH].main};
    }
  }

  @keyframes pulse-relax {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0
        ${(props) =>
          props.theme.palette.breakTimer[EBrakeTimerTypes.RELAX].main};
    }

    50% {
      transform: scale(1);
      box-shadow: 0 0 4px 4px
        ${(props) =>
          props.theme.palette.breakTimer[EBrakeTimerTypes.RELAX].main};
    }

    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0
        ${(props) =>
          props.theme.palette.breakTimer[EBrakeTimerTypes.RELAX].main};
    }
  }
`;

export const BreakTimerTextBlock = styled.div<WithTypeAndState>`
  margin-bottom: auto;
  height: auto;
  text-align: left;

  .name {
    font-size: 16px;
    animation: ${(props) => {
      if (props.state === EBrakeTimerState.WAITING_FOR_INTERACTION) {
        return `pulse-text-${props.type} 1s infinite`;
      }

      return `none`;
    }};
  }

  .countdown {
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => {
      if (props.state === EBrakeTimerState.INACTIVE) {
        return props.theme.palette.breakTimer[props.type].dark;
      }

      return props.theme.palette.breakTimer[props.type].main;
    }};
  }

  .done {
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => {
      return props.theme.palette.breakTimer[props.type].main;
    }};
  }

  .checkmarks {
    display: flex;
    margin-top: 2px;

    .checkmark {
      width: 12px;
      height: 12px;
      margin-right: 2px;
    }
  }

  @keyframes pulse-text-hydrate {
    0% {
      color: ${(props) =>
        props.theme.palette.breakTimer[EBrakeTimerTypes.HYDRATE].dark};
    }

    50% {
      color: ${(props) => {
        return props.theme.palette.breakTimer[EBrakeTimerTypes.HYDRATE].main;
      }};
    }

    100% {
      color: ${(props) =>
        props.theme.palette.breakTimer[EBrakeTimerTypes.HYDRATE].dark};
    }
  }

  @keyframes pulse-text-move {
    0% {
      color: ${(props) =>
        props.theme.palette.breakTimer[EBrakeTimerTypes.MOVE].dark};
    }

    50% {
      color: ${(props) => {
        return props.theme.palette.breakTimer[EBrakeTimerTypes.MOVE].main;
      }};
    }

    100% {
      color: ${(props) =>
        props.theme.palette.breakTimer[EBrakeTimerTypes.MOVE].dark};
    }
  }

  @keyframes pulse-text-stretch {
    0% {
      color: ${(props) =>
        props.theme.palette.breakTimer[EBrakeTimerTypes.STRETCH].dark};
    }

    50% {
      color: ${(props) => {
        return props.theme.palette.breakTimer[EBrakeTimerTypes.STRETCH].main;
      }};
    }

    100% {
      color: ${(props) =>
        props.theme.palette.breakTimer[EBrakeTimerTypes.STRETCH].dark};
    }
  }

  @keyframes pulse-text-relax {
    0% {
      color: ${(props) =>
        props.theme.palette.breakTimer[EBrakeTimerTypes.RELAX].dark};
    }

    50% {
      color: ${(props) => {
        return props.theme.palette.breakTimer[EBrakeTimerTypes.RELAX].main;
      }};
    }

    100% {
      color: ${(props) =>
        props.theme.palette.breakTimer[EBrakeTimerTypes.RELAX].dark};
    }
  }
`;

import GlobalStyle from '@/components/GlobalStyle';
import AuthGuard from '@/components/guards/AuthGuard';
import NonAdminGuard from '@/components/guards/NonAdminGuard';
import Header from '@/components/header/Header';

import ScrollbarComponent from '@/components/scrollbar/Scrollbar';
import Sidebar from '@/components/sidebar/Sidebar';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAuth from '@/hooks/useAuth';
import useNotifications from '@/hooks/useNotification';

import { AppContent, MainContent, Root } from '@/layouts/components';
import { getBrakeTimersData } from '@/redux/actions/breaktimers';
import ChatProvider from '@/sokets/ChatSocket';
import CssBaseline from '@mui/material/CssBaseline';
import React, { useEffect } from 'react';

const Dashboard: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { isTeamLead, isUser } = useAuth();
  const { setNewFavicon } = useNotifications();

  useEffect(() => {
    if (isUser || isTeamLead) {
      dispatch(getBrakeTimersData());
    }
  }, [isTeamLead, isUser]);

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        setNewFavicon('favicon.ico');
      }
    });
    return () => {
      document.removeEventListener('visibilitychange', () => {});
    };
  }, [setNewFavicon]);

  return (
    <AuthGuard>
      <NonAdminGuard>
        <ChatProvider>
          <ScrollbarComponent isscrolltop={true}>
            <Root>
              <CssBaseline />
              <GlobalStyle />
              <Sidebar />
              <AppContent>
                <Header />
                <MainContent>{children}</MainContent>
              </AppContent>
            </Root>
          </ScrollbarComponent>
        </ChatProvider>
      </NonAdminGuard>
    </AuthGuard>
  );
};

export default Dashboard;

import useAppSelector from '@/hooks/useAppSelector';
import { unreadMessageCount } from '@/redux/selectors/chatSelector';
import { SidebarPageType } from '@/types/sidebar';
import { List } from '@mui/material';
import React from 'react';
import SidebarNavListItem from './SidebarNavListItem';
import useUnreadMessagesCount from '@/hooks/useUnreadMessagesCount';

type SidebarNavListProps = {
  pages: SidebarPageType[];
};

const SidebarNavList: React.FC<SidebarNavListProps> = ({ pages }) => {
  const { onlyUserActiveMessage: unreadCount } = useUnreadMessagesCount();

  return (
    <List disablePadding>
      {pages.map((page) => (
        <SidebarNavListItem
          key={page.title}
          title={page.title}
          href={page.href}
          icon={page.icon}
          activeIcon={page.activeIcon}
          badge={page.badge && unreadCount ? unreadCount : undefined}
        />
      ))}
    </List>
  );
};

export default SidebarNavList;

import palette from '@/theme/palette';
import { SimplePaletteColorOptions } from '@mui/material';
import { Components } from '@mui/material/styles/components';

const components: Components = {
  MuiToolbar: {
    defaultProps: {
      disableGutters: true,
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        height: '44px',
        borderRadius: '4px',
        ...(ownerState.color === 'info' && {
          backgroundColor: `${palette.background?.paper}`,
          color: `${palette.text?.primary}`,
          '&:hover': {
            backgroundColor: '#DDE5ED',
          },
          '&:active': {
            backgroundColor: '#C2D2E1',
          },
          '&:disabled': {
            backgroundColor: '#B9C5D6',
            color: `${palette.neutral?.main}`,
            cursor: 'not-allowed',
          },
        }),
        ...(ownerState.color === 'secondary' && {
          backgroundColor: 'transparent',
          color: `${(palette.primary as SimplePaletteColorOptions).main}`,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: `${(palette.primary as SimplePaletteColorOptions).main}`,
          '&:hover': {
            background: `${
              (palette.primary as SimplePaletteColorOptions).light
            }`,
            borderColor: `${
              (palette.primary as SimplePaletteColorOptions).main
            }`,
          },
          '&:active': {
            background: `${
              (palette.primary as SimplePaletteColorOptions).dark
            }`,
            color: `${palette.neutral?.main}`,
            border: 'none',
          },
          '&:disabled': {
            backgroundColor: '#B9C5D6',
            color: `${palette.neutral?.main}`,
            border: 'none',
            cursor: 'not-allowed',
          },
        }),
        ...(ownerState.color === 'primary' && {
          backgroundColor: `${
            (palette.primary as SimplePaletteColorOptions).main
          }`,
          color: `${(palette.neutral as SimplePaletteColorOptions).main}`,
          border: 'none',
          '&:hover': {
            background: `#2CC30B`,
          },
          '&:active': {
            background: `${
              (palette.primary as SimplePaletteColorOptions).dark
            }`,
          },
          '&:disabled': {
            backgroundColor: '#B9C5D6',
            color: `${palette.neutral?.main}`,
            border: 'none',
            cursor: 'not-allowed',
          },
        }),
      }),
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
    styleOverrides: {
      root: {
        width: '100%',
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'h6',
      },
    },
    styleOverrides: {
      action: {
        marginTop: '-4px',
        marginRight: '-4px',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '6px',
        boxShadow:
          'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
        backgroundImage: 'none',
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        borderRight: 'none',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
        boxShadow: 'none',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        position: 'unset',
        fontSize: '12px',
        transform: 'none',
        color: `${palette.selector.light}`,
        fontStyle: 'italic',
        marginBottom: '7px',
        '&.Mui-focused': {
          color: '#666A95',
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: '12px',
        transform: 'none',
        color: `${palette.selector.light}`,
        fontStyle: 'italic',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        border: `solid 1px ${palette.border.light}`,
      },
      input: {
        '&[type="password"]': {
          letterSpacing: '2px',
        },
        background: `${palette?.neutral?.main}`,
        color: `${palette.selector.dark}`,
        border: 'none',
        borderRadius: '4px',
        fontSize: '14px',
        '::placeholder': {
          color: `${palette.selector.light}`,
          fontStyle: 'italic',
          letterSpacing: '0px !important',
        },

        '&.MuiInputBase::placeholder': {
          opacity: 1,
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: '0px',
        marginRight: '0px',
        fontSize: '14px',
        fontWeight: '600',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '6px',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        padding: '0px',
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      outlinedPrimary: {
        border: 'none',
      },
      root: {
        '&.Mui-selected': {
          border: 'none',
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      flexContainer: {
        width: 'max-content',
        margin: '0 auto',
        border: `1px solid ${palette.border.light}`,
        borderRadius: '60px',
      },
      indicator: {
        display: 'none',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minWidth: '150px',

        '&.Mui-selected': {
          backgroundColor: '#4B4E75',
          borderRadius: '60px',
          color: '#fff',
        },
      },
    },
  },
};

export default components;

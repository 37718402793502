import useAppSelector from '@/hooks/useAppSelector';
import {
  getUserRole,
  getUserSelector,
  isAuthorized,
} from '@/redux/selectors/userSelectors';
import { EUserRole } from '@/types/consts';

const useAuth = () => {
  const isAuthenticated = useAppSelector(isAuthorized);
  const userRole = useAppSelector(getUserRole);
  const user = useAppSelector(getUserSelector);

  const isSuperAdmin = userRole === EUserRole.SUPER_ADMIN;
  const isRwAdmin = userRole === EUserRole.RW_ADMIN;
  const isTeamLead = userRole === EUserRole.TEAMLEAD;
  const isUser = userRole === EUserRole.USER;

  return {
    isAuthenticated,
    userRole,
    user,
    isSuperAdmin,
    isRwAdmin,
    isTeamLead,
    isUser,
  };
};
export default useAuth;

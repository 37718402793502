import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { getMyActivity } from '@/redux/actions/activity';
import { getCompanyActivityLog } from '@/redux/actions/company';
import { getUserActivityLog } from '@/redux/actions/users';
import { myActivitySelector } from '@/redux/selectors/activitySelector';
import { companyActivityLog } from '@/redux/selectors/companySelectors';
import { adminUserActivityLog } from '@/redux/selectors/users';
import { ELimits } from '@/types/consts';
import { IActivityLog } from '@/types/models';
import React, { useEffect, useState } from 'react';
import ActivityBreakdown from './ActivityBreakdown';
import { ActivityBreakdownContainer, BlockLabel } from './components';
import { getDateMinusSixtyDays } from '@/utils/dateHelpers';
interface ActivityBreakdownWrapperProps {
  companyId?: number | null;
  userId?: number | null;
  isCompanyView?: boolean;
}

export interface IActivityItem {
  startDate: Date;
  endDate: Date;
}

const initialData = {
  startDate: getDateMinusSixtyDays(new Date()),
  endDate: new Date(),
};

const ActivityBreakdownWrapper: React.FC<ActivityBreakdownWrapperProps> = ({
  companyId,
  userId,
  isCompanyView,
}) => {
  const dispatch = useAppDispatch();
  const [activitiesLog, setActivityLog] = useState<IActivityLog[] | null>(null);
  const [activityDate, setActivityDate] = useState<IActivityItem>(initialData);
  const myActivityLog = useAppSelector(myActivitySelector);
  const adminUserLog = useAppSelector(adminUserActivityLog);
  const companyLog = useAppSelector(companyActivityLog);

  useEffect(() => {
    const logPagination = {
      limit: ELimits.ACTIVITY,
      offset: 0,
      from: activityDate.startDate,
      to: activityDate.endDate,
    };
    if (companyId) {
      dispatch(
        getCompanyActivityLog({
          ...logPagination,
          companyId,
        })
      );
    } else if (!userId) {
      dispatch(getMyActivity(logPagination));
    } else {
      dispatch(
        getUserActivityLog({
          ...logPagination,
          userId,
        })
      );
    }
  }, [
    userId,
    companyId,
    activityDate.startDate,
    activityDate.endDate,
    dispatch,
  ]);

  useEffect(() => {
    if (companyId) {
      return setActivityLog(companyLog);
    }

    if (userId) {
      return setActivityLog(adminUserLog);
    }

    if (!isCompanyView) {
      return setActivityLog(myActivityLog);
    }

    setActivityLog(null);
  }, [
    myActivityLog,
    adminUserLog,
    userId,
    companyLog,
    companyId,
    isCompanyView,
  ]);

  return (
    <ActivityBreakdownContainer>
      <BlockLabel>Progress Meter Summary</BlockLabel>
      <ActivityBreakdown
        activitiesLog={activitiesLog}
        companyId={companyId}
        userId={userId}
        isCompanyView={isCompanyView}
        activityDate={activityDate}
        setActivityDate={setActivityDate}
      />
    </ActivityBreakdownContainer>
  );
};

export default ActivityBreakdownWrapper;

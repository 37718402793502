import { Box } from '@mui/material';
import styled from 'styled-components';

export const SwitchLabel = styled.div`
  width: 103px;
  font-weight: 400;
  font-size: 12px;
  margin-right: 4px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const NotificationRow = styled(Box)`
  margin-bottom: 10px;
`;

export const NotificationContainer = styled(Box)`
  padding-top: 9px;
`;

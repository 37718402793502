import UserImage from '@/components/user-profile/avatar/UserImage';
import BreaktimerSettings from '@/components/user-profile/breaktimer-settings/BreaktimerSettings';
import ChangePassword from '@/components/user-profile/change-password/ChangePassword';
import NotificationSettings from '@/components/user-profile/notification-settings/NotificationSettings';
import ProfileForm from '@/components/user-profile/profile-forms/ProfileForm';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAuth from '@/hooks/useAuth';
import { getCountries } from '@/redux/actions/country';
import { getStates } from '@/redux/actions/state';
import { confirmWorkLifeShopEmail } from '@/redux/actions/user';
import { DEFAULT_TOAST_TIME } from '@/types/consts';
import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import QRCode from '@/components/user-profile/qr-code/QRCode';

const PersonalInfo: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isTeamLead, isUser, user } = useAuth();
  const [search] = useSearchParams();
  const token = search.get('token');

  useEffect(() => {
    if (!token || !user || user?.workLifeShopEmailConfirm) {
      return;
    }

    dispatch(confirmWorkLifeShopEmail({ emailAuth: token }))
      .unwrap()
      .then((res) => {
        toast.success('Your Work+Life Shop email confirmed successfully!', {
          position: 'top-right',
          autoClose: DEFAULT_TOAST_TIME,
          hideProgressBar: false,
        });
      })
      .catch((e: any) => {
        toast.error(e.error, {
          position: 'top-right',
        });
      });
  }, [user]);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getStates());
  }, [dispatch]);

  return (
    <Grid container columnSpacing={10} justifyContent={'center'}>
      <Grid item xs={5}>
        <ProfileForm />
      </Grid>
      <Grid item xs={5}>
        <UserImage />
        <QRCode />
        {(isTeamLead || isUser) && <BreaktimerSettings />}
        {(isTeamLead || isUser) && <NotificationSettings />}
        <ChangePassword />
      </Grid>
    </Grid>
  );
};

export default PersonalInfo;

import useAppSelector from '@/hooks/useAppSelector';
import { useTimeout } from '@/hooks/useTimeout';
import { SecreteItems } from '@/pages/dashboard/MyWorkspace/constants';
import MyWorkspaceItem from '@/pages/dashboard/MyWorkspace/MyWorkspaceView/MyWorkspaceItem';
import { WorkspaceItemPopover } from '@/pages/dashboard/MyWorkspace/MyWorkspaceView/WorkspaceItemPopover';
import {
  WorkspaceItem,
  WorkspaceMenuStatus,
  WorkspaceModalItem,
} from '@/pages/dashboard/MyWorkspace/types';
import { currentItemsSelector } from '@/redux/selectors/workspaceSelector';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

interface MyWorkspaceViewProps {
  state: WorkspaceItem[];
  setState: (value: WorkspaceItem[]) => void;
  status: WorkspaceMenuStatus;
}

const MyWorkspaceView: React.FC<MyWorkspaceViewProps> = ({
  state,
  setState,
  status,
}) => {
  const currentItems = useAppSelector(currentItemsSelector);
  const timeout = useTimeout();
  const ref = useRef<HTMLInputElement>(null);
  const [modalState, setModalState] = useState<WorkspaceModalItem>({
    isOpen: false,
    item: null,
  });
  const [tempActive, setTempActive] = useState<number[]>([]);
  const [isTempVisible, setIsTempVisible] = useState(false);

  const onOpen = useCallback(
    (id: number) => {
      if (id === 500) {
        const box = state.find((item) => item.name === SecreteItems.BOX);
        box && setModalState({ isOpen: true, item: box });
      } else {
        const currentModalItem = state.find((item) => item.id === id);
        if (
          currentModalItem &&
          !(
            currentModalItem.name === 'Scene' ||
            (currentModalItem.name === SecreteItems.BOX &&
              !currentModalItem.link) ||
            (currentModalItem.name === SecreteItems.STICKER &&
              !currentModalItem.link)
          )
        ) {
          setModalState({ isOpen: true, item: currentModalItem });
        }
      }
    },
    [state]
  );

  const changeTempStatus = useCallback(
    (id: number) => {
      onOpen(id);
      clearTimeout(timeout.current);
      setTempActive([...tempActive, id]);
      setIsTempVisible(true);
    },
    [onOpen, tempActive, timeout]
  );
  const onClose = useCallback(() => {
    setModalState({ isOpen: false, item: null });
  }, []);

  useEffect(() => {
    const fullState = state.map((value) => {
      const currentItem = currentItems.find((item) => item.name === value.name);
      return { ...value, ...currentItem };
    });

    setState(fullState);
  }, [currentItems]);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setIsTempVisible(false);
    }, 10000);
  }, [tempActive, timeout]);

  useEffect(() => {
    if (!isTempVisible) setTempActive([]);
  }, [isTempVisible]);

  return (
    <div>
      <WorkspaceItemPopover
        item={modalState.item}
        onClose={onClose}
        status={status}
      >
        <div className="my-work-space">
          <div className="wrapper" ref={ref}>
            {state.map((item) => (
              <MyWorkspaceItem
                gray2={item.grayColor}
                bright={item.bright}
                key={item.name}
                item={item}
                changeStatus={changeTempStatus}
                tempActive={tempActive}
              >
                <item.component />
              </MyWorkspaceItem>
            ))}
          </div>
        </div>
      </WorkspaceItemPopover>
    </div>
  );
};

export default memo(MyWorkspaceView);

import useAppSelector from '@/hooks/useAppSelector';
import { selectState } from '@/redux/selectors/componentsManage';

import {
  Chip,
  ListItem as MuiListItem,
  ListItemProps,
  ListItemText,
} from '@mui/material';
import React from 'react';
import {
  NavLink,
  NavLinkProps,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';
import styled, { css } from 'styled-components';

export const ListIconIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 25px;
`;

export const ListItemIcon = styled(({ icon: Icon, ...props }) => {
  return <Icon {...props} />;
})`
  ${({ active }) =>
    active &&
    css`
      & > * {
        stroke: ${({ theme }) => theme.palette.common.white};
      }
    `}
`;

export const Badge = styled(Chip)<{ open: boolean }>`
  height: 20px;
  position: absolute;
  right: ${({ open }) => (open ? '-3px' : '10px')};
  top: calc(50% - 10px);
  background-color: ${({ open, theme }) =>
    open ? theme.sidebar.badge.background : '#2fd60a'};
  border-radius: ${({ theme }) => theme.spacing(3)};
  z-index: 1;

  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 10px;
    cursor: pointer;
    color: ${({ open, theme }) =>
      open ? theme.sidebar.badge.color : theme.palette.common.white};
    padding-left: ${(props) => props.theme.spacing(1.5)};
    padding-right: ${(props) => props.theme.spacing(1.5)};
  }
`;

export const Title = styled(ListItemText)<{ open: boolean }>`
  color: ${(props) => props.theme.sidebar.item.color.main};
  white-space: nowrap;
  transition: all 0.3s ease;

  ${({ open }) =>
    !open &&
    css`
      opacity: 0;
      width: 0;
      max-width: 0;
    `};

  ${({ open, theme }) =>
    open &&
    css`
      margin-left: ${theme.spacing(2)};
    `};
`;

type ListItemLinkType = NavLinkProps & { active?: number; open: boolean };

export const ListItemLink = styled(NavLink)<ListItemLinkType>`
  width: ${({ open }) => (open ? '100%' : 'auto')};
  display: flex;
  justify-content: center;
  padding: ${({ open, theme }) =>
    open ? theme.spacing(2, 3, 2, 6) : theme.spacing(2, 3)};
  align-items: center;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.sidebar.item.active.background};
    border-radius: ${({ open, theme }) =>
      open ? theme.spacing(0, 2, 2, 0) : theme.spacing(2)};
  }

  &:active,
  &:visited {
    text-decoration: none;
  }

  ${({ active, open, theme }) =>
    active &&
    open &&
    css`
      border-radius: ${theme.spacing(0, 2, 2, 0)};
    `};

  ${({ active, open, theme }) =>
    active &&
    !open &&
    css`
      border-radius: ${theme.spacing(2)};
    `};

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.sidebar.item.active.background};
    `};

  ${({ open, theme }) =>
    !open &&
    css`
      margin: ${theme.spacing(0, 'auto')};
    `};
`;

type ListItemType = {
  active?: number;
  open: boolean;
};

export const ListItem = styled(MuiListItem)<ListItemType>`
  border-left-width: 4px;
  border-left-style: solid;
  border-left-color: transparent;
  transition: all 0.3s ease;

  &:hover {
    ${Title} {
      color: ${(props) => props.theme.palette.common.white};
    }
  }

  ${({ open, active }) =>
    open &&
    !active &&
    css`
      &:hover {
        border-left-color: ${(props) =>
          props.theme.sidebar.item.active.background};
      }
    `}

  ${({ active }) =>
    active &&
    css`
      border-left: ${({ theme }) => theme.sidebar.item.active.border};

      ${Title} {
        color: ${({ theme }) => theme.palette.common.white};
      }
    `}
`;

type SidebarNavListItemProps = ListItemProps & {
  href: string;
  icon: React.FC<any>;
  activeIcon?: React.FC<any>;
  badge?: number;
  title: string;
};

const SidebarNavListItem: React.FC<SidebarNavListItemProps> = (props) => {
  const { title, href, icon: Icon, activeIcon: ActiveIcon, badge } = props;

  const open = useAppSelector(selectState);

  let resolved = useResolvedPath(href);
  let match = !!useMatch({ path: resolved.pathname, end: true });

  return (
    <ListItem active={+match} open={open} disablePadding>
      <ListItemLink active={+match} open={open} to={href}>
        <ListIconIconWrapper>
          {match ? (
            ActiveIcon ? (
              <ListItemIcon icon={ActiveIcon} />
            ) : (
              <ListItemIcon icon={Icon} active={+true} />
            )
          ) : (
            <ListItemIcon icon={Icon} />
          )}
        </ListIconIconWrapper>
        <Title open={open}>{title}</Title>
        {!!badge && <Badge open={open} label={badge} />}
      </ListItemLink>
    </ListItem>
  );
};

export default SidebarNavListItem;

import { PointsActivityTitle } from '@/components/user-profile/reward-points/components';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import {
  getCompanyPointManagementHistory,
  getUsersPointManagementHistory,
} from '@/redux/actions/company';
import {
  getCompany,
  getCountAllPointManagementHistory,
  getPointManagementHistory,
} from '@/redux/selectors/companySelectors';
import { ECompanyActivitySelect, ELimits } from '@/types/consts';
import { getStartOfMonth } from '@/utils/dateHelpers';
import { Tab, Tabs } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { RewardPointsHistoryContainer, TabsContainer } from './components';
import PointsManagementHistory from './PointsManagementHistory';

export interface IActivityItem {
  startDate: Date;
  endDate: Date;
}

const initialData = {
  startDate: getStartOfMonth(new Date()),
  endDate: new Date(),
};

const PointsManagmentHistoryWrapper: FC = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(getCompany);
  const rewardPointsHistory = useAppSelector(getPointManagementHistory);
  const countAllPointsHistory = useAppSelector(
    getCountAllPointManagementHistory
  );
  const [activityDate, setActivityDate] = useState<IActivityItem>(initialData);
  const [recipients, setRecipients] = useState<Array<any>>([]);

  const [groupMode, setGroupMode] = useState<ECompanyActivitySelect>(
    ECompanyActivitySelect.TEAM
  );

  useEffect(() => {
    if (company?.id) {
      const logPagination = {
        limit: ELimits.ACTIVITY,
        from: activityDate.startDate,
        to: activityDate.endDate,
        offset: 0,
        id: company.id,
      };
      if (groupMode === ECompanyActivitySelect.TEAM) {
        dispatch(getCompanyPointManagementHistory(logPagination));
      } else {
        dispatch(
          getUsersPointManagementHistory({
            ...logPagination,
            userIds: recipients.length ? recipients : undefined,
          })
        );
      }
    }
  }, [groupMode, activityDate, company?.id, recipients]);

  return (
    <RewardPointsHistoryContainer>
      <PointsActivityTitle variant={'h2'} mb={6}>
        Points Management History
      </PointsActivityTitle>
      <TabsContainer component={'section'}>
        <Tabs
          value={groupMode}
          onChange={(_, value) => setGroupMode(value)}
          centered
          textColor={'secondary'}
        >
          <Tab value={ECompanyActivitySelect.TEAM} label="Company" />
          <Tab value={ECompanyActivitySelect.INDIVIDUAL} label="User" />
        </Tabs>
      </TabsContainer>
      {company?.id && (
        <PointsManagementHistory
          historyGroup={groupMode}
          countAllPointsHistory={countAllPointsHistory}
          pointsHistory={rewardPointsHistory}
          activityDate={activityDate}
          recipients={recipients}
          setActivityDate={setActivityDate}
          setRecipients={setRecipients}
        />
      )}
    </RewardPointsHistoryContainer>
  );
};

export default PointsManagmentHistoryWrapper;

import { IKnowledgeBaseItem } from '@/components/knowledgeBase/models';
import { StatLabel } from '@/components/progressMeter/components';
import {
  createUser,
  deleteUser,
  getUserActivityLog,
  getUserById,
  getUserDailyStreak,
  getUserKnowledgeBase,
  getUserMessagesStat,
  getUserProgress,
  getUserRewardPoints,
  getUserRewardPointsLog,
  getUsers,
  getUsersByParams,
  redeemPoints,
  restoreUser,
  updateUser,
} from '@/redux/actions/users';
import { EActivityLevel } from '@/types/consts';
import {
  IActivityLog,
  IActivityProgress,
  IDailyStreak,
  IMessagesStat,
  IRewardPoints,
  IRewardPointsLog,
  IUser,
} from '@/types/models';
import { createSlice } from '@reduxjs/toolkit';

export interface UsersState {
  loading: boolean;
  users: IUser[] | null;
  filteredUsers: IUser[] | null;
  usersCount: number;
  filteredCount: number;
  user: IUser | null;
  userLoading: boolean;
  isSearch: boolean;
  progressStatistic: IActivityProgress[] | null;
  progress: IActivityProgress[] | null;
  activitiesLog: IActivityLog[] | null;
  rewardPointsLog: IRewardPointsLog[] | null;
  rewardPointsLoading: boolean;
  knowledgeBase: IKnowledgeBaseItem[] | null;
  rewardPoints: IRewardPoints | null;
  dailyStreak: IDailyStreak | null;
  messagesStat: IMessagesStat | null;
}

const initialState: UsersState = {
  loading: false,
  users: null,
  filteredUsers: null,
  user: null,
  userLoading: false,
  filteredCount: 0,
  usersCount: 0,
  isSearch: false,
  progressStatistic: null,
  progress: null,
  activitiesLog: null,
  rewardPointsLog: null,
  rewardPointsLoading: false,
  knowledgeBase: null,
  rewardPoints: null,
  dailyStreak: null,
  messagesStat: null,
};

const initialStateUser = {
  user: null,
  userLoading: false,
  progressStatistic: null,
  progress: null,
  activitiesLog: null,
  rewardPointsLog: null,
  knowledgeBase: null,
  rewardPoints: null,
  dailyStreak: null,
};

export const { actions, reducer } = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUserListState: () => ({
      ...initialState,
      loading: false,
    }),
    resetUser: (state) => {
      state.user = null;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setSearchMode: (state, { payload }) => {
      state.isSearch = payload;
    },
    resetAdminUserState: (state) => ({
      ...state,
      ...initialStateUser,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        const newUsers = state.users
          ? payload.users.filter(
              (user) =>
                !(
                  state.users &&
                  state.users.find((oneUser) => oneUser.id === user.id)
                )
            )
          : payload.users;
        state.users =
          state && state.users ? [...state.users, ...newUsers] : newUsers;
        state.usersCount = payload.countAll;
        state.loading = false;
      })
      .addCase(getUsersByParams.pending, (state) => {
        state.loading = true;
        state.filteredUsers = null;
        state.filteredCount = 0;
      })
      .addCase(getUsersByParams.fulfilled, (state, { payload }) => {
        state.filteredUsers = payload.users as IUser[];
        state.filteredCount = payload.countAll;
        state.loading = false;
      })
      .addCase(getUserById.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getUserById.fulfilled, (state, { payload }) => {
        state.user = payload as IUser;
        state.userLoading = false;
      })
      .addCase(createUser.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        if (state.users?.length) {
          state.users = [...state.users, payload];
        } else {
          state.users = [payload];
        }
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        if (state.users?.length) {
          state.users = [
            ...state.users.map((item) => {
              if (item.id === payload.id) {
                return {
                  ...item,
                  ...payload,
                };
              }
              return item;
            }),
          ];
        }
        if (state.filteredUsers?.length) {
          state.filteredUsers = [
            ...state.filteredUsers.map((item) => {
              if (item.id === payload.id) {
                return {
                  ...item,
                  ...payload,
                };
              }
              return item;
            }),
          ];
        }
        state.userLoading = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(updateUser.rejected, (state) => {
        state.userLoading = false;
      })
      .addCase(deleteUser.fulfilled, (state, { payload, meta }) => {
        if (state.users?.length) {
          state.users = [...state.users.filter(({ id }) => id !== meta.arg.id)];
        }
        if (state.filteredUsers?.length) {
          state.filteredUsers = [
            ...state.filteredUsers.filter(({ id }) => id !== meta.arg.id),
          ];
        }
      })
      .addCase(restoreUser.fulfilled, (state, { payload, meta }) => {
        if (state.users?.length) {
          state.users = [...state.users.filter(({ id }) => id !== meta.arg)];
        }
        if (state.filteredUsers?.length) {
          state.filteredUsers = [
            ...state.filteredUsers.filter(({ id }) => id !== meta.arg),
          ];
        }
      })
      .addCase(getUserProgress.fulfilled, (state, { payload }) => {
        if (payload.dataLevel === EActivityLevel.STATISTIC) {
          state.progressStatistic = payload.data;
        } else {
          state.progress = payload.data;
        }
      })
      .addCase(getUserProgress.rejected, (state) => {
        state.progressStatistic = null;
        state.progress = null;
      })
      .addCase(getUserKnowledgeBase.fulfilled, (state, { payload }) => {
        if (payload) {
          state.knowledgeBase = payload;
        }
      })
      .addCase(getUserActivityLog.fulfilled, (state, { payload }) => {
        state.activitiesLog =
          payload.offset && state.activitiesLog?.length
            ? [...state.activitiesLog, ...payload.data]
            : payload.data;
      })
      .addCase(getUserRewardPointsLog.fulfilled, (state, { payload }) => {
        state.rewardPointsLog =
          payload.offset && state.rewardPointsLog?.length
            ? [...state.rewardPointsLog, ...payload.data]
            : payload.data;
      })
      .addCase(getUserRewardPoints.fulfilled, (state, { payload }) => {
        state.rewardPoints = payload;
      })
      .addCase(redeemPoints.fulfilled, (state, { meta }) => {
        state.rewardPointsLoading = false;

        if (!state.rewardPoints) {
          return;
        }

        state.rewardPoints.totalPoints =
          state.rewardPoints.totalPoints - meta.arg.points;
      })
      .addCase(redeemPoints.pending, (state) => {
        state.rewardPointsLoading = true;
      })
      .addCase(redeemPoints.rejected, (state) => {
        state.rewardPointsLoading = false;
      })
      .addCase(getUserDailyStreak.fulfilled, (state, { payload }) => {
        state.dailyStreak = payload;
      })
      .addCase(getUserMessagesStat.fulfilled, (state, { payload }) => {
        state.messagesStat = payload;
      });
  },
});

export const {
  setLoading,
  resetUserListState,
  setSearchMode,
  resetAdminUserState,
  resetUser,
} = actions;

export default reducer;

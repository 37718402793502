import { StyledComponentProps } from '@mui/material';
import styled from 'styled-components';

export const OneCategoryBlock = styled.div`
  padding: 20px 50px 20px 50px;
  .item-name {
    font-style: normal;
    font-weight: 1000;
    font-size: 14px;
    line-height: 23px;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

interface CategoryTitleBlocProps extends StyledComponentProps {
  color: string;
}

export const CategoryTitleBlock = styled.div<CategoryTitleBlocProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 45px;
  color: ${(props) => `${props.color}`};
`;

import {
  ArcElement,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  DoughnutController,
} from 'chart.js';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  DiagramLabel,
  DiagramTitle,
  DoughnutContainer,
  DoughnutTop,
} from './components';

interface IMeterProps {
  title: string;
  mainColor: string;
  value: number;
}

ChartJS.register(ArcElement, DoughnutController);

const config: ChartOptions<'doughnut'> = {
  rotation: -90,
  circumference: 180,
  maintainAspectRatio: false,
  cutout: 25,
};

const DoughnutMeter: React.FC<IMeterProps> = ({ value, title, mainColor }) => {
  const defaultData: ChartData<'doughnut'> = {
    datasets: [
      {
        label: '',
        backgroundColor: '#8f92b910',
        data: [100],
        borderRadius: 70,
        borderWidth: 0,
      },
    ],
  };
  const data: ChartData<'doughnut'> = {
    datasets: [
      {
        label: title,
        data: [value, 100 - value],
        backgroundColor: [mainColor, 'transparent'],
        borderRadius: 70,
        borderWidth: 0,
      },
    ],
  };
  return (
    <div>
      <DiagramTitle>{title}</DiagramTitle>
      <DoughnutContainer>
        <Doughnut data={defaultData} options={config} height={64} width={108} />
        <DoughnutTop data={data} options={config} height={64} width={108} />
        <DiagramLabel>{value}%</DiagramLabel>
      </DoughnutContainer>
    </div>
  );
};

export default DoughnutMeter;

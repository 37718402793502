import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import { getCompany as getCompanySelector } from '@/redux/selectors/companySelectors';
import { getRoutePrefixByRoleName } from '@/utils/routeRoleHelpers';
import { Box, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHref, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { setCompanyTabs, setSearchText } from '@/redux/reducers/company';
import useAppDispatch from '@/hooks/useAppDispatch';

function a11yProps(index: number) {
  return {
    id: `company-tab-${index}`,
    'aria-controls': `company-tabpanel-${index}`,
  };
}

const TabsContainer = styled(Box)`
  padding: ${({ theme }) => theme.spacing(6, 0)};
  background-color: ${({ theme }) => theme.palette.background.default};
`;

const CompanyTabs = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(getCompanySelector);
  const [value, setValue] = useState<string>('information');
  const navigate = useNavigate();
  const location = useLocation();
  const href = useHref(location.pathname);
  const { userRole } = useAuth();
  const { period } = useParams();

  useEffect(() => {
    dispatch(setCompanyTabs(value));
  }, [dispatch, value]);

  useEffect(() => {
    const type = href.split('/').slice(-2);
    setValue(period ? `${type[0]}/${period}` : type[1]);
  }, [href, period]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);

    const [company, companyId] = href.split('/').slice(2);
    navigate(
      getRoutePrefixByRoleName(userRole) +
        `/${company}/${companyId}/${newValue}`
    );
  };

  return (
    <TabsContainer component={'section'}>
      <Tabs
        value={value}
        onChange={handleChange}
        centered
        textColor={'secondary'}
      >
        <Tab value={'information'} label="Information" {...a11yProps(0)} />
        <Tab value={'activity'} label="Activity" {...a11yProps(1)} />
        <Tab
          value={'point-management'}
          label="Points Management"
          {...a11yProps(2)}
          disabled={company ? !company?.rewardPoints : false}
        />
        <Tab
          value={'team-dashboard'}
          label="Team Dashboard"
          {...a11yProps(3)}
        />
        <Tab value={'progress/week'} label="Week Progress" {...a11yProps(3)} />
        <Tab
          value={'progress/month'}
          label="Month Progress"
          {...a11yProps(3)}
        />
      </Tabs>
    </TabsContainer>
  );
};

export default CompanyTabs;

import { MyWorkspaceItemBlock } from '@/pages/dashboard/MyWorkspace/MyWorkspaceView/MyWorkspaceItem/component';
import { WorkspaceItem } from '@/pages/dashboard/MyWorkspace/types';
import React, { memo, useMemo } from 'react';

interface MyWorkspaceItemProps {
  children: React.ReactNode;
  changeStatus: (id: number) => void;
  item: WorkspaceItem;
  tempActive: number[];
  gray2: number;
  bright: number;
}

const MyWorkspaceItem: React.FC<MyWorkspaceItemProps> = ({
  children,
  item,
  changeStatus,
  tempActive,
  gray2,
  bright,
}) => {
  const clickHandler = () => {
    item?.id && changeStatus(item?.id);
  };
  const findTempActive = useMemo(() => {
    return tempActive.length
      ? tempActive.find((temp) => temp === item.id)
      : false;
  }, [item.id, tempActive]);

  return (
    <MyWorkspaceItemBlock
      className={item.name === 'Light' ? 'light' : ''}
      onClick={clickHandler}
      zIndex={item.zIndex}
      cLipPath={item.clipPath}
      cursor={item.cursor}
      gray2={gray2}
      bright={bright}
      grey={!!(item.status || findTempActive)}
    >
      {children}
    </MyWorkspaceItemBlock>
  );
};

export default memo(MyWorkspaceItem);

import { NotificationIcons } from '@/components/notifications/constants';
import { INotification } from '@/types/models';
import {
  Avatar as MuiAvatar,
  Box,
  ListItem,
  ListItemText,
  SvgIcon,
} from '@mui/material';
import React from 'react';
import {
  CustomListItemAvatar,
  DateBlock,
  NotificationBox,
  SmallIndicator,
  Title,
} from './components';

interface INotificationProps {
  notification: INotification;
  style: any;
  idsToRead: number[];
  readNotification: (id: number) => void;
}

const Notification: React.FC<INotificationProps> = ({
  notification,
  style,
  idsToRead,
  readNotification,
}: INotificationProps) => {
  const { description, title, isShow, notificationType, createdAt, id } =
    notification;
  const Icon = NotificationIcons[notificationType]?.icon || null;

  return (
    <ListItem
      component={NotificationBox}
      style={style}
      onMouseEnter={() => readNotification(id)}
    >
      <CustomListItemAvatar>
        <MuiAvatar
          sx={{
            background: NotificationIcons[notificationType]?.color || '#FFF',
          }}
        >
          <SvgIcon>
            <Icon />
          </SvgIcon>
        </MuiAvatar>
      </CustomListItemAvatar>
      <ListItemText
        primary={
          <Box display="flex">
            <Title maxWidth="70%">{title}</Title>
            <DateBlock marginLeft="auto">
              {new Date(createdAt).toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'short',
              })}
            </DateBlock>
          </Box>
        }
        secondary={description}
      />
      <Box>
        {!isShow && !idsToRead.includes(id) && (
          <SmallIndicator>
            <SvgIcon fontSize="small">{/* <Icon /> */}</SvgIcon>
          </SmallIndicator>
        )}
      </Box>
    </ListItem>
  );
};

export default Notification;

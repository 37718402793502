import { CountryState } from '@/redux/reducers/country';
import { RootState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';

export const getCountriesSelector = createSelector(
  (state: RootState) => state.country,
  ({ countries }: CountryState) => countries
);

export const getCountryLoading = createSelector(
  (state: RootState) => state.country,
  ({ loading }: CountryState) => loading
);

export const getCountry = createSelector(
  (state: RootState) => state.country,
  ({ country }: CountryState) => country
);

export const getCountrySearchText = createSelector(
  (state: RootState) => state.country,
  ({ searchText }: CountryState) => searchText
);

import styled from 'styled-components';
import { Button } from '@mui/material';

export const TransferPointsButton = styled(Button)`
  background-color: #e0f3f6;
  color: #0099ff;
  border-radius: 8px;
  padding: 0 15px;

  &:hover {
    background-color: #c2d2e1;
  }
`;

export const CancelButton = styled(Button)`
  min-width: 160px;
  background-color: ${({ theme }) => theme.palette.redColor.light};
  color: ${({ theme }) => theme.palette.redColor.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.warning.main};
  }
`;

export const ConfirmButton = styled(Button)`
  min-width: 160px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.neutral.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

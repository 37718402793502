import {
  ButtonText,
  CreateUserButton,
} from '@/components/admin/user/components';
import { CreateUserIcon } from '@/components/icons';
import useAppDispatch from '@/hooks/useAppDispatch';
import { toggleModal } from '@/redux/reducers/componentsManage';

const CreateUserButtonComponent = () => {
  const dispatch = useAppDispatch();

  return (
    <CreateUserButton onClick={() => dispatch(toggleModal(true))}>
      <ButtonText>Create User</ButtonText>
      <CreateUserIcon />
    </CreateUserButton>
  );
};

export default CreateUserButtonComponent;

import {
  ActivityIcon,
  MyPointsIcon,
  SignOutIcon,
  UserProfileIcon,
} from '@/components/icons';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAuth from '@/hooks/useAuth';
import { logout } from '@/redux/actions/user';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import { getRoutePrefixByRoleName } from '@/utils/routeRoleHelpers';

import {
  IconButton as MuiIconButton,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AvatarType, CustomAvatar } from '../UI/CustomAvatar';

const Menu = styled(MuiMenu)`
  & .MuiMenu-list {
    padding: ${({ theme }) => theme.spacing(3, 0, 4)};
  }

  & .MuiMenu-paper {
    min-width: ${({ theme }) => theme.spacing(36)};
    border-radius: ${({ theme }) => theme.spacing(3)};
  }
`;

const MenuItem = styled(MuiMenuItem)`
  padding: ${({ theme }) => theme.spacing(2, 5)};
`;

const MenuItemIcon = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 22px;
  margin-right: ${({ theme }) => theme.spacing(3)};
`;

const IconButton = styled(MuiIconButton)`
  margin-left: ${({ theme }) => theme.spacing(3)};
  padding: 0;
  box-shadow: 0px 7px 12px rgba(0, 5, 62, 0.25);

  svg {
    width: 22px;
    height: 22px;
  }
`;

const UserAvatar = styled('div')`
  width: 46px;
  height: 46px;
  background-color: orange;
  border-radius: 50%;

  img {
    border-radius: 50%;
    width: 46px;
    height: 46px;
  }
`;

function HeaderUserDropdown() {
  const { isUser, isTeamLead, user, userRole } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const openLink = (link: string) => {
    navigate(getRoutePrefixByRoleName(userRole) + link);
    setAnchorMenu(null);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await dispatch(logout());
    navigate('/login');
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <CustomAvatar
            src={user?.profileImage || undefined}
            diameter={46}
            avatartype={AvatarType.USER}
          >
            {!user
              ? ''
              : getUserAvatarLetter({
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: user.email,
                })}
          </CustomAvatar>
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={() => openLink('/edit-profile/info')}>
          <MenuItemIcon>
            <UserProfileIcon />
          </MenuItemIcon>
          Profile
        </MenuItem>
        {(isTeamLead || isUser) && (
          <MenuItem onClick={() => openLink('/edit-profile/activity')}>
            <MenuItemIcon>
              <ActivityIcon />
            </MenuItemIcon>
            My Activity
          </MenuItem>
        )}
        {(isTeamLead || isUser) && !!user?.companies[0]?.rewardPoints && (
          <MenuItem onClick={() => openLink('/edit-profile/points')}>
            <MenuItemIcon>
              <MyPointsIcon />
            </MenuItemIcon>
            My Points
          </MenuItem>
        )}

        <MenuItem onClick={handleSignOut}>
          <MenuItemIcon>
            <SignOutIcon />
          </MenuItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default HeaderUserDropdown;

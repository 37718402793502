import { Box } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import {
  DataGrid as MuiDataGrid,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import styled from 'styled-components';

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      color={'primary'}
      variant={'outlined'}
      shape={'rounded'}
      count={pageCount}
      hidePrevButton
      hideNextButton
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
};

const DataGridWrapper = styled(Box)`
  & .MuiDataGrid-root {
    border: none;
    & .MuiDataGrid-virtualScroller {
      position: inherit !important;
    }
    & .MuiDataGrid {
      &-main {
        overflow: visible;
      }

      &-columnHeaders {
        background: #ffffff;
        box-shadow: 0px 4px 24px rgba(0, 5, 62, 0.1);
        border-radius: 6px;
        transform: translateY(-50%);
      }

      &-columnHeader {
        padding: ${({ theme }) => theme.spacing(0, 4)};
      }

      &-columnSeparator {
        display: none;
      }

      &-row {
        background: #f9fbfc;
        border-radius: 3px;
        border: solid #F2F3F9;
        transition: all 0.4s ease;
        max-height: 100% !important;
        min-height: 100% !important;

         max-height: none !important,
        &:not(&:last-child) {
          margin-bottom: ${({ theme }) => theme.spacing(1)};
        }

        &:hover {
          transform: translateY(-3px);
          box-shadow: 0px 4px 24px rgba(0, 5, 62, 0.1);
          background-color: #edf1fa;
          cursor: pointer;

          & .MuiChip-root {
            background-color: #fafafd;
            cursor: pointer;
          }
        }
      }

      &-cell {
        padding: ${({ theme }) => theme.spacing(0, 4)};
        border-bottom: none;
        overflow: hidden;
        white-space: break-spaces !important;
        max-height: 100% !important;
        min-height: 52px !important;
        &.phone {
          .MuiDataGrid-cellContent {
            width: 150px;
          }
        }
      }

      &-footerContainer {
        border-top: none;
        display: flex;
        justify-content: end;
      }
    }
  }
`;

const DataGrid = (props: any) => {
  return (
    <DataGridWrapper>
      <MuiDataGrid
        {...props}
        autoHeight
        getRowHeight={() => 'auto'}
        rowsPerPageOptions={[props.pageSize]}
        pagination
        components={{
          Pagination: CustomPagination,
        }}
      >
        <CustomPagination />
      </MuiDataGrid>
    </DataGridWrapper>
  );
};

export default DataGrid;

import { EBrakeTimerTypes } from '@/components/breaktimer/constants';
import { PaletteOptions } from '@mui/material/styles/createPalette';

const palette: PaletteOptions = {
  primary: {
    main: '#2FD60A',
    dark: '#27A80A',
    light: 'rgba(47, 214, 10, 0.1)',
  },
  secondary: {
    main: '#4B4E75',
    light: '#C9C7E4',
  },
  custom: {
    light: '#fff',
    dark: '#293951',
  },
  neutral: {
    main: '#fff',
    light: '#7A7898',
    dark: '#293951',
  },
  background: {
    default: '#F2F3F9',
    paper: '#F1F5F9',
  },
  text: {
    primary: '#000',
    secondary: '#5B587D',
  },
  error: {
    main: '#FFA800',
  },
  warning: {
    main: '#EEB2BC',
  },
  selector: {
    main: '#E3F2FD',
    dark: '#2C2D32',
    light: '#666A95',
  },
  redColor: {
    main: '#FF375B',
    light: '#E9D5D8',
  },
  border: {
    main: '#0099FF',
    light: '#E2E8ED',
  },
  breakTimer: {
    [EBrakeTimerTypes.MOVE]: {
      main: '#FF5C00',
      light: '#F5AD63',
      dark: '#8F92B9',
    },
    [EBrakeTimerTypes.STRETCH]: {
      main: '#9D27B0',
      light: '#B39DDB',
      dark: '#8F92B9',
    },
    [EBrakeTimerTypes.HYDRATE]: {
      main: '#0099FF',
      light: '#4EB7FE',
      dark: '#8F92B9',
    },
    [EBrakeTimerTypes.RELAX]: {
      main: '#2FD60A',
      light: '#26C9A8',
      dark: '#8F92B9',
    },
  },
};

export default palette;

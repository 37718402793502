import { DialogContent } from '@/components/admin/user/components';
import { CloseIcon } from '@/components/icons';
import { Box, Button, DialogActions } from '@mui/material';
import 'cropperjs/dist/cropper.css';
import React, { useCallback, useRef } from 'react';
import Cropper from 'react-cropper';
import {
  CloseIconWrapper,
  DialogWrapper,
  ModalDialogTitle,
  Title,
} from './components';

interface IImageCropperProps {
  image: string;
  visible: boolean;
  close: (image?: string, blob?: Blob) => void;
}

const ImageCropperModal: React.FC<IImageCropperProps> = ({
  image,
  visible,
  close,
}) => {
  const handleClose = useCallback(() => {
    close && close();
  }, [close]);

  const cropperRef = useRef<HTMLImageElement>(null);

  const saveImage = useCallback(() => {
    const cropper = (cropperRef?.current as any)?.cropper;
    const croppedImage = cropper.getCroppedCanvas().toDataURL();
    cropper.getCroppedCanvas().toBlob((blob: Blob) => {
      close && close(croppedImage, blob);
    }, 'image/jpeg');
  }, [close]);

  return (
    <DialogWrapper
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={visible}
    >
      <ModalDialogTitle>
        <Title display="flex" alignContent="center">
          User Avatar
        </Title>
        <CloseIconWrapper aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseIconWrapper>
      </ModalDialogTitle>
      <DialogContent>
        <Cropper
          src={image}
          style={{ height: 400, width: '100%' }}
          initialAspectRatio={1}
          aspectRatio={1}
          guides={false}
          ref={cropperRef}
        />
      </DialogContent>
      <DialogActions>
        <Box width="100%" display="flex" justifyContent="center">
          <Button color="secondary" onClick={saveImage}>
            Save photo
          </Button>
        </Box>
      </DialogActions>
    </DialogWrapper>
  );
};

export default ImageCropperModal;

import useAppDispatch from '@/hooks/useAppDispatch';
import useAuth from '@/hooks/useAuth';
import useNotifications from '@/hooks/useNotification';
import { updateProgress, updateRewardPoints } from '@/redux/actions/activity';
import { updateBreakTimer } from '@/redux/actions/breaktimers';
import { addNotification } from '@/redux/actions/notifications';
import { ELocalStoreKeys, EUserRole } from '@/types/consts';
import { IActivityProgress, IRewardPoints } from '@/types/models';
import React, { createContext } from 'react';
import { io, Socket } from 'socket.io-client';

interface ISocketContext {
  socket?: Socket;
}

const WebSocketContext = createContext({});

export { WebSocketContext };

let socket: Socket | undefined = undefined;
let ws: ISocketContext = {
  socket: undefined,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }: any) => {
  const dispatch = useAppDispatch();
  const { isAuthenticated, userRole, user } = useAuth();
  const { notify } = useNotifications();

  if (!socket && isAuthenticated && userRole !== EUserRole.SUPER_ADMIN) {
    const token = localStorage.getItem(ELocalStoreKeys.ACCESS_TOKEN);
    socket = io(process.env.REACT_APP_API_URL + '/sockets', {
      transports: ['websocket'],
      auth: {
        token,
      },
    });

    socket.on('error', (msg) => {
      console.log(msg);
    });

    socket.on('breake-timer/action/run', (updatedBreakTimer: any) => {
      if (
        user?.breakTimesSounds &&
        userRole !== EUserRole.SUPER_ADMIN &&
        userRole !== EUserRole.RW_ADMIN
      ) {
        notify(
          `HEY! Its time to ${updatedBreakTimer?.breakTimer?.type?.name}!`,
          true,
          require('@/audio/break_timer_active.mp3')
        );
      }
      dispatch(updateBreakTimer(updatedBreakTimer));
    });

    socket.on('reward-points/real-time', (rewardPoints: IRewardPoints) => {
      dispatch(updateRewardPoints(rewardPoints));
    });

    socket.on(
      'progress-meters/real-time',
      (progressMeter: IActivityProgress[]) => {
        if (
          user?.progressMetersSounds &&
          userRole !== EUserRole.SUPER_ADMIN &&
          userRole !== EUserRole.RW_ADMIN
        ) {
          notify(``, true, require('@/audio/progress_metters.mp3'), false);
        }
        dispatch(updateProgress(progressMeter));
      }
    );

    socket.on('breake-timer/action/changed', (updatedBreakTimer: any) => {
      dispatch(updateBreakTimer(updatedBreakTimer));
    });

    socket.on('notification', (notification: any) => {
      dispatch(addNotification(notification));
    });

    socket.on('disconnect', function () {
      console.log('Socket disconnected');
      socket = undefined;
    });

    socket.on('connect', function () {
      console.log('Socket connected');
    });

    ws = {
      socket: socket,
    };
  }

  return (
    <WebSocketContext.Provider value={ws as ISocketContext}>
      {children}
    </WebSocketContext.Provider>
  );
};

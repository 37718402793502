import { IconWrapper } from '@/components/form-elements/components';
import { ReactComponent as InvisibleIcon } from '@/components/icons/Eye-closed.svg';
import { ReactComponent as VisibleIcon } from '@/components/icons/Eye.svg';
import useAppDispatch from '@/hooks/useAppDispatch';
import {
  AuthButton,
  AuthError,
  AuthNavLink,
  CenteredButtonContainer,
  FlexRow,
  FormContainer,
  FormControlWrapper,
} from '@/pages/auth/components';
import { login } from '@/redux/actions/user';
import { EUserRole } from '@/types/consts';
import { ILogin } from '@/types/models';
import { removeSpaces } from '@/utils/textHelpers';
import {
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

const LoginSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  email: Yup.string()
    .trim()
    .min(5, 'Too Short!')
    .max(50, 'Too Long!')
    .email('Incorrect email')
    .required('Required'),
});

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string>('');

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((val) => !val);
  }, [setShowPassword]);

  const onLogin = useCallback(
    (values: ILogin, actions: FormikHelpers<ILogin>) => {
      dispatch(login(values))
        .unwrap()
        .then((user) => {
          if (
            user.role.name === EUserRole.SUPER_ADMIN ||
            user.role.name === EUserRole.RW_ADMIN
          ) {
            navigate('/admin/companies');
          }

          if (
            (user.role.name === EUserRole.TEAMLEAD ||
              user.role.name === EUserRole.USER) &&
            !user.profileImage
          ) {
            navigate('/edit-profile/info');
          }
        })
        .catch(({ error }) => {
          if (error.status === 401) {
            setLoginError('Invalid Email/Password combination');
          }
        })
        .finally(() => actions.setSubmitting(false));
    },
    [dispatch, navigate]
  );

  return (
    <div>
      {!!loginError && <AuthError> {loginError} </AuthError>}
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={LoginSchema}
        onSubmit={(values, actions) => onLogin(values, actions)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          isValid,
        }) => (
          <FormContainer onSubmit={handleSubmit}>
            <FormControlWrapper
              hiddenLabel={!values.email}
              error={!!errors.email && !!touched.email}
            >
              {!!values.email && <InputLabel>Email</InputLabel>}
              <OutlinedInput
                fullWidth
                type="email"
                name="email"
                placeholder="Email"
                onChange={(e) =>
                  setFieldValue('email', removeSpaces(e.target.value), true)
                }
                onBlur={(e) =>
                  setFieldValue('email', removeSpaces(e.target.value), true)
                }
                value={values.email}
                error={!!errors.email && !!touched.email}
              />
              {errors.email && touched.email && (
                <FormHelperText id="email" error>
                  {errors.email}
                </FormHelperText>
              )}
            </FormControlWrapper>
            <FormControlWrapper
              hiddenLabel={!values.password}
              error={!!errors.password && !!touched.password}
            >
              {!!values.password && <InputLabel>Password</InputLabel>}
              <OutlinedInput
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                fullWidth
                error={!!errors.password && !!touched.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconWrapper
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      disableRipple
                    >
                      {showPassword ? <InvisibleIcon /> : <VisibleIcon />}
                    </IconWrapper>
                  </InputAdornment>
                }
              />
              {errors.password && touched.password && (
                <FormHelperText id="password" error>
                  {errors.password}
                </FormHelperText>
              )}
            </FormControlWrapper>
            <FlexRow justifyContent="flex-end">
              <AuthNavLink to="/forgot-password">Forgot password?</AuthNavLink>
            </FlexRow>
            <CenteredButtonContainer>
              <AuthButton
                variant="outlined"
                color="secondary"
                type="submit"
                disabled={isSubmitting || !isValid}
              >
                Log In
              </AuthButton>
            </CenteredButtonContainer>
          </FormContainer>
        )}
      </Formik>
    </div>
  );
};

export default Login;

import ConfirmModal, { ModalStatuses } from '@/components/dialogs/ConfirmModal';
import ErrorModal from '@/components/dialogs/ErrorModal';
import { CheckboxChecked, CheckboxDefault } from '@/components/icons';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { OneItemBlock } from '@/pages/dashboard/MyWorkspace/BuildMyWorkspace/OneItem/component';
import { WorkspaceItem } from '@/pages/dashboard/MyWorkspace/types';
import { addUserWorkspaceItem } from '@/redux/actions/workSpace';
import { workSpaceDailyChack } from '@/redux/selectors/workspaceSelector';
import theme from '@/theme/theme';
import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';

interface IModalTitle {
  title: string;
  dialogTitle: string;
}

interface OneItemProps {
  item: WorkspaceItem;
  setState: React.Dispatch<React.SetStateAction<WorkspaceItem[]>>;
  canEdit: boolean;
}

export const OneItem: React.FC<OneItemProps> = ({
  item,
  setState,
  canEdit,
}) => {
  const dispatch = useAppDispatch();
  const dailyCheck = useAppSelector(workSpaceDailyChack);
  const [isModalView, setIsModalView] = useState(false);
  const [error, setError] = useState<IModalTitle>({
    dialogTitle: '',
    title: '',
  });
  const [isCongraModalOpen, setCongraModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState<IModalTitle>({
    dialogTitle: '',
    title: '',
  });
  const closeModal = () => {
    setIsModalView(false);
  };
  const closeErrorModal = () => {
    setError({ dialogTitle: '', title: '' });
  };
  const changeModalTitle = (count: number) => {
    if (count === 5) {
      setCongraModalOpen(true);
      setModalTitle({
        dialogTitle: 'Awesome!',
        title:
          "You've achieved the BASIC level in your My Workspace setup. Each month you may add up to 5 items to achieve the next level.",
      });
    } else if (count === 10) {
      setCongraModalOpen(true);
      setModalTitle({
        dialogTitle: 'Awesome!',
        title:
          "You've achieved the ADVANCED level in your My Workspace setup. Next month you may add up to 5 items to achieve the PRO level.",
      });
    } else if (count === 15) {
      setCongraModalOpen(true);
      setModalTitle({
        dialogTitle: 'Awesome!',
        title:
          "You've achieved the PRO level in your My Workspace setup. Next month you may add up to 5 items to achieve the MENTOR level.",
      });
    } else if (count === 20) {
      setCongraModalOpen(true);
      setModalTitle({
        dialogTitle: 'Awesome!',
        title:
          "You've achieved the MENTOR level in your My Workspace setup. You now have achieved the highest level and support others to improve their spaces too!",
      });
    } else {
      setCongraModalOpen(true);
      setModalTitle({
        dialogTitle: 'Congrats on upgrading your workspace!',
        title:
          'You just received wellbeing credit for adding this item, and it is now highlighted in color in your Workspace View. You will continue to receive credit for adding items that are in your space (1 per day, and up to 5 per month).',
      });
      setIsModalView(false);
    }
  };
  const openModal = () => {
    if (dailyCheck) {
      setError({
        dialogTitle: 'Looking to add another item to your workspace?',
        title:
          'You may check off up to 1 item per day, and 5 items per month to receive credit and boost your wellbeing meters. Please log in again tomorrow and check off your new workspace item.',
      });
    } else if (canEdit) {
      setModalTitle({
        dialogTitle: 'Upgrading your Workspace?',
        title:
          'You’ve checked an item to add to your Workspace. Click “Continue” to add this item or click “Cancel” to go back to the items list. You can add 1 item per day (Up to 5 items per month).',
      });
      setIsModalView(true);
    }
  };

  const changeStatus = () => {
    item.id &&
      dispatch(
        addUserWorkspaceItem({ itemId: item.id, changeModalTitle, setState })
      )
        .unwrap()
        .catch((e) => {
          if (
            e.error.includes(
              'UnprocessableEntityException: Item was added today'
            )
          ) {
            setError({
              dialogTitle: 'Looking to add another item to your workspace?',
              title:
                'You may check off up to 1 item per day, and 5 items per month to receive credit and boost your wellbeing meters. Please log in again tomorrow and check off your new workspace item.',
            });
          } else if (
            e.error.includes(
              'UnprocessableEntityException: Сategory limit reached per month'
            )
          ) {
            setError({
              dialogTitle: 'Congrats on upgrading your workspace!',
              title:
                'It takes time to build a workspace that works for you. With that in mind, Level-ups are limited to one new level per month so that you have time to experience new upgrades at each level.',
            });
          }
        })
        .finally(() => setIsModalView(false));
  };

  return (
    <OneItemBlock>
      <Grid container className="grid-wrapper">
        <Grid className="picture">
          {item.picture && <item.picture width="100%" height="100%" />}
        </Grid>
        <Grid item sm container marginLeft="50px" width="200px">
          <Grid item xs container className="checkbox-wrapper" spacing={2}>
            <div className="item-name">{item.name}</div>
            <Box className="checkbox">
              {item.status ? (
                <CheckboxChecked />
              ) : (
                <CheckboxDefault
                  cursor={canEdit ? 'pointer' : 'initial'}
                  onClick={openModal}
                />
              )}
              {canEdit ? (
                <Box marginLeft="10px" color={theme.knowledgeBase.color}>
                  Add item to My Workspace
                </Box>
              ) : (
                <Box marginLeft="10px" color={theme.knowledgeBase.color}>
                  My Office Has This
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        {isModalView && (
          <ConfirmModal
            withCloseIcon={false}
            confirmStatus={ModalStatuses.PRIMARY}
            onConfirm={changeStatus}
            onClose={closeModal}
            title={modalTitle.dialogTitle}
            text={modalTitle.title}
            isOpen={isModalView}
          />
        )}
      </Grid>
      {error.title && (
        <ErrorModal
          withCloseIcon={false}
          isOpen={!!error}
          text={error.title}
          title={error.dialogTitle}
          onClose={closeErrorModal}
        />
      )}
      {isCongraModalOpen && (
        <ErrorModal
          withCloseIcon={false}
          isOpen={isCongraModalOpen}
          title={modalTitle.dialogTitle}
          text={modalTitle.title}
          onClose={() => setCongraModalOpen(false)}
        />
      )}
    </OneItemBlock>
  );
};

import {
  createState,
  deleteState,
  getState,
  getStates,
  getStatesByCountry,
  updateState,
} from '@/redux/actions/state';
import { IState } from '@/types/models';
import { createSlice } from '@reduxjs/toolkit';

export interface StateState {
  loading: boolean;
  states: IState[];
  state: IState | null;
  searchText: string;
}

const initialState: StateState = {
  loading: false,
  states: [],
  state: null,
  searchText: '',
};

export const { actions, reducer } = createSlice({
  name: 'state',
  initialState,
  reducers: {
    resetStateState: () => ({
      ...initialState,
      loading: false,
    }),
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStatesByCountry.pending, (state) => {
        state.loading = true;
      })
      .addCase(createState.pending, (state) => {
        state.loading = true;
      })
      .addCase(getState.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateState.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteState.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStates.fulfilled, (state, { payload }) => {
        state.states = payload as IState[];
        state.loading = false;
      })
      .addCase(getStatesByCountry.fulfilled, (state, { payload }) => {
        state.states = payload as IState[];
        state.loading = false;
      })
      .addCase(createState.fulfilled, (state, { payload }) => {
        state.state = payload as IState;
        state.loading = false;
      })
      .addCase(getState.fulfilled, (state, { payload }) => {
        state.state = payload as IState;
        state.loading = false;
      })
      .addCase(updateState.fulfilled, (state, { payload }) => {
        state.state = payload as IState;
        state.loading = false;
      })
      .addCase(deleteState.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export const { setLoading, resetStateState, setSearchText } = actions;

export default reducer;

import { Box, Grid } from '@mui/material';
import { FC, useState } from 'react';
import styled from 'styled-components';
import {
  WorkLifeShopBagIcon,
  WorkLifeShopBagIconHover,
  WorkLifeShopIcon,
} from '../icons';

interface IWorkShopLink {
  size: number;
}

const WorkLifeShopWrapper = styled(Grid)<{ size: number }>`
  @property --myColor1 {
    syntax: '<color>';
    initial-value: #651e7e;
    inherits: false;
  }

  @property --myColor2 {
    syntax: '<color>';
    initial-value: #26c9a8;
    inherits: false;
  }
  width: auto;
  max-height: ${({ theme }) => theme.spacing(14)};
  transition: --myColor1 0.15s, --myColor2 0.5s;
  background: linear-gradient(
    99.68deg,
    var(--myColor1) -11.54%,
    var(--myColor2) 152.02%
  );
  padding-top: ${({ size }) => size * 0.066 + 'px'};
  border-radius: ${({ size }) => size * 0.166 + 'px'};
  &:hover {
    --myColor1: #2c294c;
    --myColor2: #2c294c;
  }
`;

const BoxWrapper = styled(Box)`
  opacity: 1;
  animation: '1s show ease-in-out';
  @keyframes show {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

const BtnAnimation = styled(Box)`
  animation: '1s show ease-in-out';
  @keyframes show {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  } ;
`;

const WorkShopLink: FC<IWorkShopLink> = ({ size }) => {
  const [isHovered, setIsHoverd] = useState(false);
  return (
    <a href="http://remoteworx.com/shop" target="_blank" rel="noreferrer">
      <WorkLifeShopWrapper
        size={size}
        onMouseOut={() => setIsHoverd(true)}
        onMouseOver={() => setIsHoverd(false)}
        container
        justifyContent={'center'}
        alignItems={'center'}
        height={size}
      >
        <BoxWrapper>
          <WorkLifeShopIcon height={size * 0.733} />
        </BoxWrapper>
        {isHovered ? (
          <BtnAnimation>
            <WorkLifeShopBagIcon height={size * 0.733} />
          </BtnAnimation>
        ) : (
          <BtnAnimation>
            <WorkLifeShopBagIconHover height={size * 0.733} />
          </BtnAnimation>
        )}
      </WorkLifeShopWrapper>
    </a>
  );
};

export default WorkShopLink;

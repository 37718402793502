import {
  DialogActions,
  DialogContent,
  FormControlWrapper,
} from '@/components/admin/user/components';
import CompanySelect from '@/components/form-elements/CompanySelect';

import useAppDispatch from '@/hooks/useAppDispatch';
import useAuth from '@/hooks/useAuth';
import { createUser } from '@/redux/actions/users';
import { EUserRole } from '@/types/consts';

import {
  Button,
  CircularProgress,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const roles = [
  {
    name: 'user',
    view_name: 'User',
  },
  {
    name: 'rw_admin',
    view_name: 'RW Admin',
  },
  {
    name: 'team_admin',
    view_name: 'Team Admin',
  },
];

type IUserValues = {
  emails: string;
  companyId: number;
  role: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
};

const initialValues = (companyId?: string) => ({
  emails: '',
  companyId: Number(companyId) || 0,
  role: roles[0].name,
  firstName: '',
  lastName: '',
  jobTitle: '',
});

const validationSchema = Yup.object().shape({
  emails: Yup.string().required('Required'),
  companyId: Yup.number().optional(),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  jobTitle: Yup.string().optional(),
});

const teamLeadValidationSchema = Yup.object().shape({
  emails: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  jobTitle: Yup.string().optional(),
});

const CreateUserForm = () => {
  const { companyId } = useParams();
  const { user, userRole, isRwAdmin, isSuperAdmin, isTeamLead } = useAuth();

  const dispatch = useAppDispatch();

  const [submitError, setSubmitError] = React.useState<string>('');
  const [isFormSubmited, setFormSubmited] = React.useState<boolean>(false);
  const [availableRoles, setAvailableRoles] = React.useState(roles);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (isTeamLead || isRwAdmin) {
      setAvailableRoles(
        availableRoles.filter((role) => role.name !== EUserRole.RW_ADMIN)
      );
    }
    if (isSuperAdmin) {
      setAvailableRoles(roles);
    }
  }, [user, userRole]);

  const handleCatchError = ({ error }: any) => {
    if (error.includes('UnprocessableEntityException: Emails')) {
      setSubmitError(error.slice(36));
    } else setSubmitError(error);
  };

  const handleOnSubmit = (
    values: IUserValues,
    { setSubmitting, resetForm, setFieldError }: FormikHelpers<IUserValues>
  ) => {
    const emails = values.emails.replaceAll(' ', '').split(',');
    const companyId =
      user && userRole === EUserRole.TEAMLEAD
        ? user.companies[0].id
        : values.companyId;

    const adaptedValues = emails.map((email) => ({
      email,
      companyId: companyId || undefined,
      role: values.role,
      firstName: values.firstName,
      lastName: values.lastName,
      jobTitle: values.jobTitle,
    }));
    const validationSchema = Yup.string().email();
    setFormSubmited(false);
    setSubmitError('');

    if (!emails.every((email) => validationSchema.isValidSync(email))) {
      setFieldError(
        'emails',
        'Field should contain valid emails, separated by coma'
      );
      setSubmitting(false);
      return;
    }

    dispatch(createUser(adaptedValues))
      .unwrap()
      .then(() => setFormSubmited(true))
      .then(() =>
        resetForm({
          values: {
            emails: '',
            companyId: values.companyId,
            role: roles[0].name,
            firstName: '',
            lastName: '',
            jobTitle: '',
          },
        })
      )
      .then(() =>
        setTimeout(() => {
          setFormSubmited(false);
        }, 6000)
      )
      .catch(handleCatchError)
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={initialValues(companyId)}
      validationSchema={
        user && userRole === EUserRole.TEAMLEAD
          ? teamLeadValidationSchema
          : validationSchema
      }
      onSubmit={handleOnSubmit}
      validate={(values) => {
        if (
          values.companyId === 0 &&
          user &&
          userRole === EUserRole.SUPER_ADMIN &&
          values.role !== 'rw_admin'
        ) {
          return { companyId: 'Required' };
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
        isSubmitting,
      }) => {
        return (
          <Form>
            <DialogContent>
              <FormControlWrapper
                margin={'dense'}
                fullWidth
                hiddenLabel={false}
                error={!!errors.emails && !!touched.emails}
              >
                <OutlinedInput
                  name={'emails'}
                  placeholder={'Emails'}
                  label={''}
                  value={values.emails}
                  error={Boolean(touched.emails && errors.emails)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                />
                {touched.emails && errors.emails && (
                  <FormHelperText id="email" error>
                    {errors.emails}
                  </FormHelperText>
                )}
              </FormControlWrapper>
              <FormControlWrapper
                margin={'dense'}
                fullWidth
                hiddenLabel={false}
                error={!!errors.firstName && !!touched.firstName}
              >
                <OutlinedInput
                  name={'firstName'}
                  placeholder={'First Name'}
                  label={''}
                  value={values.firstName}
                  error={Boolean(touched.firstName && errors.firstName)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                />
                {touched.firstName && errors.firstName && (
                  <FormHelperText id="firstName" error>
                    {errors.firstName}
                  </FormHelperText>
                )}
              </FormControlWrapper>
              <FormControlWrapper
                margin={'dense'}
                fullWidth
                hiddenLabel={false}
                error={!!errors.lastName && !!touched.lastName}
              >
                <OutlinedInput
                  name={'lastName'}
                  placeholder={'Last Name'}
                  label={''}
                  value={values.lastName}
                  error={Boolean(touched.lastName && errors.lastName)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                />
                {touched.lastName && errors.lastName && (
                  <FormHelperText id="lastName" error>
                    {errors.lastName}
                  </FormHelperText>
                )}
              </FormControlWrapper>
              <FormControlWrapper
                margin={'dense'}
                fullWidth
                hiddenLabel={false}
                error={!!errors.companyId && !!touched.companyId}
              >
                {user && userRole === EUserRole.TEAMLEAD && (
                  <OutlinedInput
                    name={'companyId'}
                    placeholder={'Company'}
                    label={''}
                    value={user.companies[0].name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                    fullWidth
                  />
                )}
                {(isSuperAdmin || isRwAdmin) && <CompanySelect />}

                {touched.companyId && errors.companyId && (
                  <FormHelperText id="companyId" error>
                    Required
                  </FormHelperText>
                )}
              </FormControlWrapper>

              <FormControlWrapper
                margin={'dense'}
                fullWidth
                hiddenLabel={false}
                error={!!errors.role && !!touched.role}
              >
                {user && (
                  <Select
                    displayEmpty
                    name={'role'}
                    value={values.role}
                    error={Boolean(touched.role && errors.role)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    renderValue={(selected) => {
                      const role = roles.find(({ name }) => name === selected);
                      return role?.view_name;
                    }}
                    input={<OutlinedInput />}
                  >
                    {availableRoles.map((role) => (
                      <MenuItem key={role.name} value={role.name}>
                        {role.view_name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {touched.role && errors.role && (
                  <FormHelperText id="role" error>
                    {errors.role}
                  </FormHelperText>
                )}
              </FormControlWrapper>
              <FormControlWrapper
                margin={'dense'}
                fullWidth
                hiddenLabel={false}
                error={!!errors.jobTitle && !!touched.jobTitle}
              >
                <OutlinedInput
                  name={'jobTitle'}
                  placeholder={'Job Title'}
                  label={''}
                  error={Boolean(touched.jobTitle && errors.jobTitle)}
                  value={values.jobTitle}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                />
              </FormControlWrapper>
              {touched.jobTitle && errors.jobTitle && (
                <FormHelperText id="jobTitle" error>
                  {errors.jobTitle}
                </FormHelperText>
              )}

              {submitError && (
                <FormHelperText error>{submitError}</FormHelperText>
              )}

              {isFormSubmited && (
                <FormHelperText sx={{ fontSize: 13, color: '#ba841e' }}>
                  Invitation was sent to user’s email
                </FormHelperText>
              )}
            </DialogContent>
            <DialogActions>
              <Button type={'submit'} disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress /> : 'Invite users'}
              </Button>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateUserForm;

import RewardPointsProgressSlider from '@/components/user-profile/reward-points/RewardPointsProgressSlider';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import { getMyRewardPoints } from '@/redux/actions/activity';
import {
  myDailyStreak,
  myRewardPointsSelector,
} from '@/redux/selectors/activitySelector';
import { Box } from '@mui/material';
import { formatDuration } from 'date-fns';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Days,
  Points,
  PointsLabel,
  PointStat,
  ProgressBarTitleRow,
  RedeemButton,
  StreakDays,
  TotalPointRow,
} from './components';
import WorkShopLink from '@/components/UI/WorkShopLink';

const RewardPointsProgress: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const rewardPoints = useAppSelector(myRewardPointsSelector);
  const dailyStreak = useAppSelector(myDailyStreak);
  const { user } = useAuth();

  useEffect(() => {
    dispatch(getMyRewardPoints());
  }, [dispatch]);

  const goToPointManagment = useCallback(() => {
    navigate('/edit-profile/points');
  }, []);

  if (!rewardPoints) {
    return null;
  }

  return (
    <>
      {user && user.companies[0]?.rewardPoints ? (
        <div>
          <TotalPointRow>
            <Points>
              {Number(rewardPoints?.totalPoints).toLocaleString('en')}
            </Points>
            <PointsLabel>Total Reward Points</PointsLabel>
          </TotalPointRow>
          <ProgressBarTitleRow>
            <PointsLabel>Monthly Points Earned</PointsLabel>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <StreakDays>
                100% Goal Reached
                <Days>
                  {formatDuration({ days: dailyStreak?.totalDaysCompleted }) ||
                    '0 days'}
                </Days>
              </StreakDays>
              <StreakDays>
                Longest Consecutive Streak
                <Days>
                  {formatDuration({
                    days: dailyStreak?.maxDaysInARowCompleted,
                  }) || '0 days'}
                </Days>
              </StreakDays>
            </Box>
          </ProgressBarTitleRow>
          <RewardPointsProgressSlider />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <PointStat>
              {`${Number(rewardPoints?.earnedPoints).toLocaleString('en')} / ${
                rewardPoints?.availablePoints
                  ? Number(rewardPoints?.availablePoints).toLocaleString('en')
                  : 0
              }`}
            </PointStat>
            <RedeemButton onClick={goToPointManagment}>
              Transfer Points
            </RedeemButton>
            <WorkShopLink size={30} />
          </Box>
        </div>
      ) : (
        <div>
          <StreakDays>
            100% Goal Reached
            <Days>
              {formatDuration({ days: dailyStreak?.totalDaysCompleted }) ||
                '0 days'}
            </Days>
          </StreakDays>
        </div>
      )}
    </>
  );
};

export default RewardPointsProgress;

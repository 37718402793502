import Chat from '@/components/message-center/Chat/Chat';
import { MessageCenterContainer } from '@/components/message-center/components';
import ConversationList from '@/components/message-center/ConversationList/ConversationList';
import { Box } from '@mui/material';
import React, { FC } from 'react';

const MessageCenter: FC = () => {
  return (
    <MessageCenterContainer>
      <Box display="flex" height="100%">
        <ConversationList />
        <Chat isScrolled />
      </Box>
    </MessageCenterContainer>
  );
};

export default MessageCenter;

import { IClassNameProp } from '@/components/icons/breaktimers/CustomComponents/constants';
import React from 'react';

const IconUndone: React.FC<IClassNameProp> = ({
  className,
}: IClassNameProp) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="10" cy="10" r="10" fill="#8F92B9" />
      <path
        d="M7 9.66667L9.33333 12L13.3333 8"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconUndone;

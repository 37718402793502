import { BrandLogo, BrandName, DrawerMenuIcon } from '@/components/icons';

import SidebarNav from '@/components/sidebar/SidebarNav';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { toggle } from '@/redux/reducers/componentsManage';

import { selectState } from '@/redux/selectors/componentsManage';
import theme from '@/theme/theme';

import {
  Drawer as MuiDrawer,
  DrawerProps,
  IconButton as MuiIconButton,
  IconButtonProps,
  ListItemButton,
} from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const drawerWidth = 230;

const openedDrawerCss = css`
  width: ${drawerWidth}px;
`;

const cloedDrawerCss = css`
  width: ${100}px;
`;

const Drawer = styled(MuiDrawer)<DrawerProps>`
  ${({ open }) => (open ? openedDrawerCss : cloedDrawerCss)};
  transition: width 0.3s ease;

  ${({ theme }) => theme.breakpoints.up('md')} {
    ${({ open }) => (open ? openedDrawerCss : cloedDrawerCss)};
  }

  & .MuiDrawer-paper {
    ${({ open }) => (open ? openedDrawerCss : cloedDrawerCss)};
    background-color: ${({ theme }) => theme.sidebar.background};
    transition: width 0.3s ease;
  }
`;

const IconButton = styled(MuiIconButton)<IconButtonProps>`
  height: 100%;
  padding: ${({ theme }) => theme.spacing(1)};

  &:hover {
    background-color: ${({ theme }) => theme.sidebar.item.active.background};
  }
`;

const ToogleDrawerIcon = styled(DrawerMenuIcon)`
  cursor: pointer;
`;

const SidebarHeader = styled('div')<{ open: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ open, theme }) => theme.spacing(5, open ? 3 : 0, 5, 6)};
`;

const Brand = styled(ListItemButton)<{
  component?: typeof NavLink;
  to?: string;
}>`
  justify-content: center;
  padding: 0;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.sidebar.background};
  }
`;

const BrandLogoIcon = styled(BrandLogo)<{ open: boolean; fill: string }>`
  width: 34px;
  margin-right: ${({ open, theme }) => theme.spacing(open ? 1 : 0)};
  fill: ${({ fill }) => fill}; ;
`;

const BrandNameIcon = styled(BrandName)<{ open: boolean }>`
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: width 0.3s ease;

  ${({ open, theme }) =>
    open &&
    css`
      margin-right: ${theme.spacing(2)};
    `};

  ${({ open }) =>
    !open &&
    css`
      width: 0;
      max-width: 0;
    `};
`;

const Sidebar: React.FC = () => {
  const open = useAppSelector(selectState);
  const dispatch = useAppDispatch();
  const handleDispatchToggleSidebar = () => dispatch(toggle());

  return (
    <Drawer variant="permanent" open={open} PaperProps={{ elevation: 0 }}>
      <SidebarHeader open={open}>
        <Brand component={NavLink} to="/">
          <BrandLogoIcon open={open} fill={theme.palette.secondary.light} />
          <BrandNameIcon open={open} fill={theme.palette.secondary.light} />
        </Brand>
        <IconButton
          aria-label="toggle-sidebar"
          onClick={handleDispatchToggleSidebar}
        >
          <ToogleDrawerIcon />
        </IconButton>
      </SidebarHeader>
      <SidebarNav />
    </Drawer>
  );
};

export default Sidebar;

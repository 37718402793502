import moment from 'moment';

export const secondsToReadableTime = (seconds: number) => {
  const date = new Date(0);
  date.setSeconds(Number(seconds));
  return date.toISOString().substr(14, 5);
};

export const minutesFromUTCMidnightToLocalTime = (minutes: number): string => {
  return moment(0)
    .add('minutes', Number(minutes))
    .utcOffset(new Date().toString())
    .format('hh:mm A');
};

export const timeToMinutesFromMidnight = (date: Date): number => {
  return date.getUTCHours() * 60 + date.getUTCMinutes();
};

import useAuth from '@/hooks/useAuth';
import { getRoutePrefixByRoleName } from '@/utils/routeRoleHelpers';
import React from 'react';
import { Navigate } from 'react-router-dom';

interface TeamAdminGuardType {
  children: React.ReactNode;
}

const NonAdminGuard: React.FC<TeamAdminGuardType> = ({ children }) => {
  const { isAuthenticated, isUser, isTeamLead, userRole } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to={'/login'} />;
  }

  if (isAuthenticated && (isUser || isTeamLead)) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return <Navigate to={getRoutePrefixByRoleName(userRole) + '/companies'} />;
};

export default NonAdminGuard;

import { Box } from '@mui/material';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  LinearScale,
} from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';

interface IPersonalActivityProps {
  labels: string[];
  mental: number[];
  physical: number[];
  social: number[];
}

ChartJS.register(BarElement, BarController, CategoryScale, LinearScale);

const config: ChartOptions<'bar'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      grid: {
        borderDash: [7, 4],
      },
      title: {
        display: true,
        text: '% Completion',
        font: {
          family:
            'Gotham Pro,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol',
          size: 16,
          weight: '500',
        },
        color: '#000',
      },
      ticks: {
        callback: (value) => {
          return value + '%';
        },
      },
      beginAtZero: true,
      max: 100,
      grace: '25%',
    },
    x: {
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: 'Date',
        font: {
          family:
            'Gotham Pro,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol',
          size: 16,
          weight: '500',
        },
        color: '#000',
      },
    },
  },
};

const ProgressActivityDiagram: React.FC<IPersonalActivityProps> = ({
  labels,
  mental,
  physical,
  social,
}) => {
  const data: ChartData<'bar'> = {
    labels,
    datasets: [
      {
        label: 'Mental',
        data: mental,
        backgroundColor: '#2C9AE2',
        borderWidth: 0,
        maxBarThickness: 12,
        borderRadius: 10,
        categoryPercentage: 0.3,
        barPercentage: 1.0,
      },
      {
        label: 'Physical',
        data: physical,
        backgroundColor: '#4FC74D',
        borderWidth: 0,
        maxBarThickness: 12,
        borderRadius: 10,
        categoryPercentage: 0.3,
        barPercentage: 1.0,
      },
      {
        label: 'Social',
        data: social,
        backgroundColor: '#F6BE54',
        borderWidth: 0,
        maxBarThickness: 12,
        borderRadius: 10,
        categoryPercentage: 0.3,
        barPercentage: 1.0,
      },
    ],
  };

  return (
    <Box sx={{ height: 410 }}>
      <Bar options={config} data={data} />
    </Box>
  );
};

export default ProgressActivityDiagram;

import {
  CategoryTitleBlock,
  OneCategoryBlock,
} from '@/pages/dashboard/MyWorkspace/BuildMyWorkspace/OneCategory/components';
import { OneItem } from '@/pages/dashboard/MyWorkspace/BuildMyWorkspace/OneItem';
import { WorkspaceItem } from '@/pages/dashboard/MyWorkspace/types';
import theme from '@/theme/theme';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface OneCategoryProps {
  oneCategory: { id: number; items: WorkspaceItem[] };
  setState: React.Dispatch<React.SetStateAction<WorkspaceItem[]>>;
  canEdit: boolean;
}

const OneCategory: React.FC<OneCategoryProps> = ({
  oneCategory,
  setState,
  canEdit,
}) => {
  const [currentColor, setCurrentColor] = useState(
    theme.palette.secondary.main
  );

  useEffect(() => {
    if (
      oneCategory.items[0]?.category &&
      oneCategory.items[0]?.category?.completeLimitItem <=
        oneCategory.items.filter((item) => item.status).length
    ) {
      setCurrentColor(theme.palette.primary.main);
    }
  }, [oneCategory]);

  return (
    <OneCategoryBlock>
      <Grid>
        <CategoryTitleBlock color={currentColor}>
          {oneCategory.items[0]?.category?.name}
          {oneCategory.items[0]?.category?.completeLimitItem !== 1 && ' *'}
        </CategoryTitleBlock>
        <div>
          {oneCategory.items.map((oneItem) => (
            <OneItem
              item={oneItem}
              key={oneItem.id}
              setState={setState}
              canEdit={canEdit}
            />
          ))}
        </div>
      </Grid>
    </OneCategoryBlock>
  );
};

export default OneCategory;

import { Box } from '@mui/material';
import React from 'react';
import { ColorfulHorizontalProgressBar, ProgressWrapper } from './components';

const ColorfulHorizontalProgress: React.FC<{
  display: string;
  withoutpadding?: boolean;
  current?: number;
  max?: number;
}> = ({ display, withoutpadding = false, current = 0, max = 10 }) => {
  return (
    <ProgressWrapper display={display}>
      <Box className="progress">
        <b>{`${current} / ${max} Completed`}</b>
      </Box>
      <ColorfulHorizontalProgressBar
        withoutpadding={withoutpadding}
        value={current}
        max={max}
        disabled={true}
      />
    </ProgressWrapper>
  );
};

export default ColorfulHorizontalProgress;

import {
  BillingAddress,
  BillingAddressContainer,
  Points,
  ProgressContainer,
} from '@/components/admin/user/components';
import KnolwledgeCenterProggress from '@/components/admin/user/KnolwledgeCenterProggress';
import UpdateUserForm from '@/components/admin/user/UpdateUserForm';
import {
  GeneralInfoBlock,
  ProgressMeterContainer,
  ProgressMeterInfo,
} from '@/components/progressMeter/components';
import ProgressMeter from '@/components/progressMeter/ProgressMeter/ProgressMeter';
import {
  Days,
  PointsLabel,
  StreakDays,
} from '@/components/progressMeter/RewardPoints/components';
import WorkSpaceProgress from '@/components/progressMeter/WorkSpaceProgress';
import UserImage from '@/components/user-profile/avatar/UserImage';
import BreaktimerSettings from '@/components/user-profile/breaktimer-settings/BreaktimerSettings';
import NotificationSettings from '@/components/user-profile/notification-settings/NotificationSettings';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useGetWorkspaceProgress from '@/hooks/useGetWorkspaceProgress';
import { numberWithCommas } from '@/pages/super-admin/company/helpers';
import {
  getUserDailyStreak,
  getUserProgress,
  getUserRewardPoints,
} from '@/redux/actions/users';
import {
  adminSelectedUser,
  adminUserDailyStreak,
  adminUserProgressSelector,
  adminUserRewardPointsSelector,
} from '@/redux/selectors/users';
import { EActivityLevel, EUserRole } from '@/types/consts';
import { IUser } from '@/types/models';
import { Box, CircularProgress, Container, Grid } from '@mui/material';
import { format, formatDuration } from 'date-fns';
import React, { useEffect } from 'react';

const Information = () => {
  const user = useAppSelector(adminSelectedUser) as IUser;
  const userProgress = useAppSelector(adminUserProgressSelector);
  const dispatch = useAppDispatch();
  const adminUserRewardPoints = useAppSelector(adminUserRewardPointsSelector);
  const dailyStreak = useAppSelector(adminUserDailyStreak);
  const { countCategories } = useGetWorkspaceProgress(user ? user.id : null);

  useEffect(() => {
    if (!user || user.role.name === EUserRole.RW_ADMIN) {
      return;
    }

    dispatch(
      getUserProgress({
        from: format(new Date(), 'yyyy-MM-dd'),
        to: format(new Date(), 'yyyy-MM-dd'),
        dataLevel: EActivityLevel.DYNAMIC,
        userId: user.id,
      })
    );
    dispatch(getUserRewardPoints(user?.id));
    dispatch(
      getUserDailyStreak({
        from: format(new Date(), 'yyyy-MM-dd'),
        to: format(new Date(), 'yyyy-MM-dd'),
        userId: user.id,
      })
    );
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <Container>
      <BillingAddress component={'section'} mt={6}>
        <BillingAddressContainer>
          <UpdateUserForm />
        </BillingAddressContainer>
      </BillingAddress>
      {user.role.name !== EUserRole.RW_ADMIN && (
        <>
          <GeneralInfoBlock display="block" alignItems="stretch">
            <ProgressMeterInfo display="flex" flexDirection="column">
              <ProgressMeterContainer alignSelf="center">
                <ProgressMeter
                  social={userProgress.social}
                  mental={userProgress.mental}
                  physical={userProgress.physical}
                />
              </ProgressMeterContainer>
              <Box alignSelf="flex-end" justifySelf="flex-end">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <StreakDays>
                    100% Goal Reached
                    <Days>
                      {formatDuration({
                        days: dailyStreak?.totalDaysCompleted,
                      }) || '0 days'}
                    </Days>
                  </StreakDays>
                  <StreakDays>
                    Longest Consecutive Streak
                    <Days>
                      {formatDuration({
                        days: dailyStreak?.maxDaysInARowCompleted,
                      }) || '0 days'}
                    </Days>
                  </StreakDays>
                </Box>
              </Box>
              <ProgressContainer container flexWrap={'nowrap'} columnGap={12}>
                <Grid item md={6}>
                  <KnolwledgeCenterProggress />
                </Grid>
                <Grid item md={6}>
                  <WorkSpaceProgress from={20} completed={countCategories} />
                </Grid>
              </ProgressContainer>
              {user && user.companies[0]?.rewardPoints && (
                <Grid
                  container
                  alignItems={'center'}
                  flexWrap={'nowrap'}
                  columnGap={2}
                >
                  <Grid
                    item
                    md={3}
                    container
                    alignItems={'center'}
                    columnGap={2}
                  >
                    <PointsLabel>Monthly Points Earned </PointsLabel>
                    <Points>{`${numberWithCommas(
                      adminUserRewardPoints?.earnedPoints
                    )} of ${numberWithCommas(
                      adminUserRewardPoints?.availablePoints
                    )}`}</Points>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    container
                    alignItems={'center'}
                    columnGap={2}
                  >
                    <PointsLabel>Total Reward Points</PointsLabel>
                    <Points>
                      {numberWithCommas(adminUserRewardPoints?.totalPoints)}
                    </Points>
                  </Grid>
                </Grid>
              )}
            </ProgressMeterInfo>
          </GeneralInfoBlock>
          <Box display="flex">
            <Box width="50%">
              <UserImage user={user} />
            </Box>
            <Box>
              <BreaktimerSettings user={user} />
              <NotificationSettings user={user} />
            </Box>
          </Box>
        </>
      )}
    </Container>
  );
};

export default Information;

import { Placeholder } from '@/components/team-admin/components';
import useAppSelector from '@/hooks/useAppSelector';
import useCompany from '@/hooks/useCompany';
import { FormControlWrapper } from '@/pages/auth/components';
import { getCountriesSelector } from '@/redux/selectors/country';
import { getStatesSelector } from '@/redux/selectors/state';
import { ICompany } from '@/types/models';
import {
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

type ICompanyValues = Pick<ICompany, keyof ReturnType<typeof initialValues>>;

const initialValues = (company: ICompany | null) => {
  return {
    name: company?.name || '',
    streetAddress: company?.streetAddress || '',
    streetAddress2: company?.streetAddress2 || '',
    countryId: company?.countryId || 0,
    stateId: company?.stateId || 0,
    city: company?.city || '',
    zipCode: company?.zipCode || '',
  };
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  streetAddress: Yup.string().required('Required'),
  streetAddress2: Yup.string().optional(),
  countryId: Yup.number().positive().min(1).required('Required'),
  stateId: Yup.number().optional(),
  city: Yup.string().required('Required'),
  zipCode: Yup.string().optional(),
});

const CompanyBillingInformationForm = () => {
  const { company } = useCompany();

  const states = useAppSelector(getStatesSelector);
  const countries = useAppSelector(getCountriesSelector);

  const handleOnSubmit = (values: ICompanyValues) => {
    console.log(values);
  };

  return (
    <Formik
      initialValues={initialValues(company)}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({ errors, handleBlur, handleChange, touched, values }) => {
        return (
          <Form>
            <Typography variant="h3" my={5}>
              Company Billing Information
            </Typography>

            <Grid container flexDirection={'column'}>
              <FormControlWrapper
                width="auto"
                hiddenLabel={true}
                error={!!errors.name && !!touched.name}
              >
                {!!values.name && <InputLabel>Company Name*</InputLabel>}
                <OutlinedInput
                  disabled
                  name={'name'}
                  placeholder={'Company Name*'}
                  value={values.name}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={'text'}
                />
                {touched.name && errors.name && (
                  <FormHelperText id={'name'} error>
                    {errors.name}
                  </FormHelperText>
                )}
              </FormControlWrapper>

              <FormControlWrapper
                width="auto"
                hiddenLabel={true}
                error={!!errors.countryId && !!touched.countryId}
              >
                {!!values.countryId && <InputLabel>Country*</InputLabel>}
                <Select
                  disabled
                  displayEmpty
                  name={'countryId'}
                  value={values.countryId}
                  error={Boolean(touched.countryId && errors.countryId)}
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  renderValue={(selected) => {
                    if (selected === 0) {
                      return <Placeholder>Country*</Placeholder>;
                    }

                    const country = countries.find(({ id }) => id === selected);
                    return country?.name || <Placeholder>Country*</Placeholder>;
                  }}
                  input={<OutlinedInput />}
                >
                  <MenuItem value={''}>
                    <Placeholder>Country</Placeholder>
                  </MenuItem>
                  {countries.map((country) => (
                    <MenuItem key={country.id} value={country.id}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>

                {touched.countryId && errors.countryId && (
                  <FormHelperText id="countryId" error>
                    Required
                  </FormHelperText>
                )}
              </FormControlWrapper>

              <FormControlWrapper
                width="auto"
                hiddenLabel={true}
                error={!!errors.streetAddress && !!touched.streetAddress}
              >
                {!!values.streetAddress && (
                  <InputLabel>Street Address*</InputLabel>
                )}
                <OutlinedInput
                  disabled
                  name={'streetAddress'}
                  placeholder={'Street Address*'}
                  value={values.streetAddress}
                  error={Boolean(touched.streetAddress && errors.streetAddress)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                  type={'text'}
                />
                {touched.streetAddress && errors.streetAddress && (
                  <FormHelperText id="streetAddress" error>
                    {errors.streetAddress}
                  </FormHelperText>
                )}
              </FormControlWrapper>

              <FormControlWrapper
                width="auto"
                hiddenLabel={true}
                error={!!errors.streetAddress2 && !!touched.streetAddress2}
              >
                {!!values.streetAddress2 && (
                  <InputLabel>Street Address (Line 2)</InputLabel>
                )}
                <OutlinedInput
                  disabled
                  name={'streetAddress2'}
                  placeholder={'Street Address (Line 2)'}
                  value={values.streetAddress2}
                  error={Boolean(
                    touched.streetAddress2 && errors.streetAddress2
                  )}
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={'text'}
                />
                {touched.streetAddress2 && errors.streetAddress2 && (
                  <FormHelperText id="streetAddress2" error>
                    {errors.streetAddress2}
                  </FormHelperText>
                )}
              </FormControlWrapper>
              <FormControlWrapper
                width="auto"
                hiddenLabel={true}
                error={!!errors.city && !!touched.city}
              >
                {!!values.city && <InputLabel>City*</InputLabel>}
                <OutlinedInput
                  disabled
                  name={'city'}
                  placeholder={'City*'}
                  value={values.city}
                  error={Boolean(touched.city && errors.city)}
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={'text'}
                />
                {touched.city && errors.city && (
                  <FormHelperText id="city" error>
                    {errors.city}
                  </FormHelperText>
                )}
              </FormControlWrapper>

              <FormControlWrapper
                width="auto"
                hiddenLabel={true}
                error={!!errors.stateId && !!touched.stateId}
              >
                {!!values.stateId && <InputLabel>State</InputLabel>}
                <Select
                  disabled
                  displayEmpty
                  name={'stateId'}
                  value={values.stateId}
                  error={Boolean(touched.stateId && errors.stateId)}
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  renderValue={(selected) => {
                    if (selected === 0) {
                      return <Placeholder>State</Placeholder>;
                    }

                    const state = states.find(({ id }) => id === selected);
                    return state?.name || <Placeholder>State</Placeholder>;
                  }}
                  input={<OutlinedInput />}
                >
                  <MenuItem value={''}>
                    <Placeholder>State</Placeholder>
                  </MenuItem>
                  {states.map((state) => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>

                {touched.stateId && errors.stateId && (
                  <FormHelperText id="stateId" error>
                    Required
                  </FormHelperText>
                )}
              </FormControlWrapper>

              <FormControlWrapper
                width="auto"
                error={!!errors.zipCode && !!touched.zipCode}
              >
                {!!values.zipCode && <InputLabel>Zip Code</InputLabel>}
                <OutlinedInput
                  disabled
                  name={'zipCode'}
                  placeholder={'Zip Code'}
                  value={values.zipCode}
                  error={Boolean(touched.zipCode && errors.zipCode)}
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={'text'}
                />
                {touched.zipCode && errors.zipCode && (
                  <FormHelperText id="city" error>
                    {errors.zipCode}
                  </FormHelperText>
                )}
              </FormControlWrapper>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CompanyBillingInformationForm;

import WorkShopLink from '@/components/UI/WorkShopLink';
import TransferPointsModal from '@/components/admin/user/TransferPointsModal';
import { TransferPointsIcon } from '@/components/icons';
import CircularProgressWithLabel from '@/components/knowledgeBase/LoaderWithPercents';
import {
  LabelsColumn,
  PointsLabel,
  PointsValue,
  TotalContainer,
  TransferPointsButton,
} from '@/components/user-profile/reward-points/components';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import { toggleModal } from '@/redux/reducers/componentsManage';
import { myRewardPointsSelector } from '@/redux/selectors/activitySelector';
import { adminUserRewardPointsSelector } from '@/redux/selectors/users';
import { IRewardPoints } from '@/types/models';
import { Grid } from '@mui/material';
import React, { useEffect, useState, FC } from 'react';

const TotalRewardPoints: FC<{ userId?: number }> = ({ userId }) => {
  const dispatch = useAppDispatch();
  const { isSuperAdmin, isRwAdmin } = useAuth();
  const rewardPoints = useAppSelector(myRewardPointsSelector);
  const adminUserRewardPoints = useAppSelector(adminUserRewardPointsSelector);

  const [points, setPoints] = useState<IRewardPoints | null>(null);
  const isAdmin = isSuperAdmin || isRwAdmin;

  useEffect(() => {
    setPoints(userId ? adminUserRewardPoints : rewardPoints);
  }, [rewardPoints, adminUserRewardPoints, userId]);

  const handleOpen = () => {
    dispatch(toggleModal(true));
  };

  if (!points) {
    return <CircularProgressWithLabel></CircularProgressWithLabel>;
  }

  return (
    <TotalContainer>
      <LabelsColumn width="50%" padding="">
        <PointsLabel variant={'h2'} mb={4}>
          Total Reward Points
        </PointsLabel>
        <PointsValue>
          {Number(points?.totalPoints).toLocaleString('en')}
        </PointsValue>
      </LabelsColumn>
      <Grid
        container
        flexDirection={'column'}
        justifyContent={'space-between'}
        width={'50%'}
        padding={'30px'}
      >
        <TransferPointsButton onClick={handleOpen}>
          <TransferPointsIcon />
          Transfer Points
        </TransferPointsButton>
        {!isAdmin && <WorkShopLink size={44} />}

        <TransferPointsModal />
      </Grid>
    </TotalContainer>
  );
};

export default TotalRewardPoints;

import { WorkspaceItem } from '@/pages/dashboard/MyWorkspace/types';
import { EWorkSpace } from '@/redux/constants/workspace';
import { ICategory, IItem } from '@/redux/reducers/workspace';
import { RootState } from '@/redux/store';
import { EUserRole } from '@/types/consts';
import { IUser } from '@/types/models';
import { getUsersUrlByRoleName } from '@/utils/userRoleHelpers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import React from 'react';
import instance from '../middlewares/api';

export interface GetUserWorkspaceItemsResponse {
  createdAt: Date;
  updatedAt: Date;
  item: IItem;
  itemId: number;
  user: IUser;
  userId: number;
}

interface GetUserWorkspaceLevelsResponse {
  countCategories: number;
  createdAt: Date;
  updatedAt: Date;
  lastChangeDate: null | Date;
  levelId: null | number;
  levels: null | {
    id: number;
    name: string;
    passLimitCategories: number;
  };
  userId: number;
  user: IUser;
}

const getUserWorkspaceItems = createAsyncThunk(
  EWorkSpace.getUserWorkSpaceItems,
  async (
    userId: number | undefined,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { workSpace, user } = getState() as RootState;
      const { role, id } = user.user as IUser;
      if (workSpace.items.length === 0) {
        await dispatch(getAllItems());
      }
      if (workSpace.categories.length === 0) {
        await dispatch(getAllCategories());
      }
      const response = await instance.get<GetUserWorkspaceItemsResponse[]>(
        `${getUsersUrlByRoleName(role.name, userId || id)}/workspace/items`
      );

      return response.data.map((item) => item.item);
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    }
  }
);

const getAllCategories = createAsyncThunk(
  EWorkSpace.getAllCategories,
  async (_, thunkAPI) => {
    try {
      const response = await instance.get<ICategory[]>(`workspace/categories`);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

interface IngetDailyCheck {
  userId?: number;
}

const getDailyCheck = createAsyncThunk(
  EWorkSpace.getDailyCheck,
  async (payload: IngetDailyCheck, { rejectWithValue, getState }) => {
    try {
      const { user } = getState() as RootState;
      const { role } = user.user as IUser;
      const userUrl =
        role.name === EUserRole.TEAMLEAD
          ? `/team-admin/users/${payload.userId}`
          : `/users`;
      const response = await instance.get<boolean>(
        userUrl + '/workspace/items/daily-check'
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    }
  }
);

const getAllItems = createAsyncThunk(
  EWorkSpace.getAllItems,
  async (_, thunkAPI) => {
    try {
      const response = await instance.get<IItem[]>(`workspace/items`);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

interface PayloadIn {
  itemId: number;
  changeModalTitle: (count: number) => void;
  setState: React.Dispatch<React.SetStateAction<WorkspaceItem[]>>;
}

const addUserWorkspaceItem = createAsyncThunk(
  EWorkSpace.addUserWorkspaceItem,
  async (
    { itemId, changeModalTitle, setState }: PayloadIn,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const { user } = getState() as RootState;
      const { role } = user.user as IUser;
      const userUrl =
        role.name === EUserRole.TEAMLEAD ? '/team-admin/users' : '/users';
      const response = await instance.post<GetUserWorkspaceItemsResponse>(
        `${userUrl}/workspace/items`,
        {
          itemId,
        }
      );
      await dispatch(getUserWorkspaceLevels());
      setState((prevState) => {
        return prevState.map((one) => {
          if (one.id === itemId) {
            return { ...one, status: true };
          } else return one;
        });
      });
      const { workSpace } = getState() as RootState;
      changeModalTitle(workSpace.countCategories);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    }
  }
);

const getUserWorkspaceLevels = createAsyncThunk(
  EWorkSpace.getUserWorkspaceLevels,
  async (userId: number | undefined, thunkAPI) => {
    try {
      const { user } = thunkAPI.getState() as RootState;
      const { role, id } = user.user as IUser;
      const response = await instance.get<GetUserWorkspaceLevelsResponse>(
        `${getUsersUrlByRoleName(role.name, userId || id)}/workspace/levels`
      );
      return response.data.countCategories;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export {
  getUserWorkspaceItems,
  getAllCategories,
  getAllItems,
  addUserWorkspaceItem,
  getUserWorkspaceLevels,
  getDailyCheck,
};

import { Main } from '@/components/admin/user/components';
import NavigationTabs from '@/components/admin/user/NavigationTabs';
import Tools from '@/components/admin/user/Tools';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { getUserById } from '@/redux/actions/users';
import { adminSelectedUser } from '@/redux/selectors/users';
import { EUserRole } from '@/types/consts';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const User: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { userId } = useParams();
  const user = useAppSelector(adminSelectedUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    userId && dispatch(getUserById(+userId));
  }, [dispatch, userId]);

  return (
    <Main>
      <Tools />
      {user?.role.name !== EUserRole.RW_ADMIN && <NavigationTabs />}
      {children}
    </Main>
  );
};

export default User;

import Breaktimer from '@/components/breaktimer';
import { EBrakeTimerTypes } from '@/components/breaktimer/constants';
import { Gear } from '@/components/icons';
import KnowledgeBaseDashboardBlock from '@/components/knowledgeBase/KnowledgeBaseDashboardBlock';
import useAppDispatch from '@/hooks/useAppDispatch';
import { DashboardWrapper } from '@/pages/dashboard/components';
import { DashboardWorkspace } from '@/pages/dashboard/DashboardWorkspace';
import { getKnowledgeBaseData } from '@/redux/actions/knowledge-base';
import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ChatWidget from './ChatWidget/ChatWidget';
import ProgressMeterWidget from './ProgressMeterWidget/ProgressMeterWidget';
import BonusBreakTimer from '@/components/bonusBreakTimer';

function Dashboard() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getKnowledgeBaseData());
  }, [dispatch]);

  // TO-DO Remove minWidth="170px" if you start building responsive layouts
  return (
    <DashboardWrapper>
      <Grid container>
        <Grid container direction="column" item paddingRight="19px" xs={7}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={3} minWidth="170px">
              <Breaktimer type={EBrakeTimerTypes.HYDRATE} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} minWidth="170px">
              <Breaktimer type={EBrakeTimerTypes.RELAX} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} minWidth="170px">
              <Breaktimer type={EBrakeTimerTypes.STRETCH} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} minWidth="170px">
              <Breaktimer type={EBrakeTimerTypes.MOVE} />
            </Grid>
          </Grid>
          <Grid item className="extra-break-timers-panel">
            <BonusBreakTimer />
            <Link className="break-timer-text" to="/edit-profile/info">
              <Box display="flex" alignItems="center">
                <Box marginRight="5px">Set Break Timers</Box> <Gear />
              </Box>
            </Link>
          </Grid>
          <Grid item>
            <ChatWidget />
          </Grid>
          <Grid item className="workspace-dashboard">
            <DashboardWorkspace />
          </Grid>
        </Grid>
        <Grid container direction="column" item xs={5}>
          <Grid item width="100%">
            <ProgressMeterWidget />
          </Grid>
          <Grid item width="100%">
            <KnowledgeBaseDashboardBlock />
          </Grid>
        </Grid>
      </Grid>
    </DashboardWrapper>
  );
}

export default Dashboard;

import { AvatarType, CustomAvatar } from '@/components/UI/CustomAvatar';
import useAppSelector from '@/hooks/useAppSelector';
import {
  currentChatSelector,
  currentConversation,
  membersSelector,
} from '@/redux/selectors/chatSelector';
import { getMyTeamMember } from '@/redux/selectors/userSelectors';
import { EChatStatus } from '@/types/consts';
import { ICurrentChat, IGroupChat } from '@/types/models';
import { uidToId } from '@/utils/chatHelpers';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import { Box } from '@mui/material';
import React, { FC } from 'react';
import {
  ChatHeaderContainer,
  ChatJobTitle,
  ChatTitle,
  MemberCount,
  StatusDot,
} from '../components';
import { TeamIcon } from '@/components/icons';

const ChatHeader: FC = () => {
  const chat = useAppSelector(currentChatSelector) as ICurrentChat;
  const conversation = useAppSelector(currentConversation);
  const user = useAppSelector(getMyTeamMember(uidToId(chat.user?.uid)));
  const members = useAppSelector(membersSelector);

  return (
    <ChatHeaderContainer>
      {chat.isGroup ? (
        <Box display="flex" flexDirection="row">
          <CustomAvatar
            diameter="88px"
            avatartype={AvatarType.COMPANY}
            alt="Remy Sharp"
          >
            <TeamIcon />
          </CustomAvatar>
          <Box
            display="flex"
            flexDirection="column"
            marginLeft="8px"
            justifyContent="center"
          >
            <ChatTitle>{conversation?.conversationWith.name}</ChatTitle>
            {members?.length && (
              <MemberCount>{members.length + 1} Members</MemberCount>
            )}
          </Box>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" flexDirection="row">
          <Box position="relative">
            <CustomAvatar
              diameter="90px"
              avatartype={AvatarType.USER}
              alt="Remy Sharp"
              src={user?.profileImage || undefined}
            >
              {!user
                ? ''
                : getUserAvatarLetter({
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                  })}
            </CustomAvatar>
            <StatusDot
              isOnline={chat.user?.status === EChatStatus.ONLINE}
            ></StatusDot>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            marginLeft="8px"
            justifyContent="center"
          >
            <ChatTitle style={{ marginLeft: '8px' }}>
              {user?.firstName
                ? `${user?.firstName} ${user.lastName}`
                : user?.email}
            </ChatTitle>
            <ChatJobTitle style={{ marginLeft: '8px' }}>
              {user?.jobTitle}
            </ChatJobTitle>
          </Box>
        </Box>
      )}
    </ChatHeaderContainer>
  );
};

export default ChatHeader;

import CompanyPointsManagement from '@/components/rewardPointsManagement/CompanyPointManagment';
import AuthLayout from '@/layouts/Auth';
import DashboardLayout from '@/layouts/Dashboard';
import SuperAdminLayout from '@/layouts/SuperAdmin';

import {
  Companies,
  Company,
  CompanyActivity,
  CompanyInformation,
  Dashboard,
  DesignTeam,
  ForgotPassword,
  KnowledgeBase,
  Login,
  MessageCenter,
  MyActivity,
  MyRewardPoints,
  MyWorkspace,
  NotFound,
  PersonalInfo,
  Team,
  TeamAdminMyCompany,
  User,
  UserActivity,
  UserInformation,
  UserKnowladgeBase,
  UserProfile,
  UserRewardPoints,
  Users,
  UserSetPassword,
  UserWorkspace,
} from '@/pages';
import KnowledgeBaseItemPage from '@/pages/knowledge-base/KnowledgeBaseItemPage';
import DeletedUsers from '@/pages/super-admin/user/DeletedUsers';
import RWAdmins from '@/pages/super-admin/user/RWAdmins';
import KnowledgeBaseStat from './components/knowledgeBase/KnowledgeStat/KnowledgeStat';
import IFrame from './components/UI/Iframe';
import AdminMessageCenter from './pages/admin-message-center/AdminMessageCenter';
import MeterPeriodProgress from './pages/super-admin/company/MeterPeriodProgress/MeterPeriodProgress';
import TeamDashboard from './pages/super-admin/company/TeamDashboard/TeamDashboard';

const admin_routes = [
  {
    path: 'admin/users',
    element: (
      <SuperAdminLayout>
        <Users />
      </SuperAdminLayout>
    ),
  },
  {
    path: 'admin/rw-admins',
    element: (
      <SuperAdminLayout>
        <RWAdmins />
      </SuperAdminLayout>
    ),
  },
  {
    path: 'admin/message-center',
    element: (
      <SuperAdminLayout>
        <AdminMessageCenter />
      </SuperAdminLayout>
    ),
  },
  {
    path: 'admin/knowledge-stat',
    element: (
      <SuperAdminLayout>
        <KnowledgeBaseStat />
      </SuperAdminLayout>
    ),
  },
  {
    path: 'admin/deleted-users',
    element: (
      <SuperAdminLayout>
        <DeletedUsers />
      </SuperAdminLayout>
    ),
  },
  {
    path: 'admin/user/:userId/information',
    element: (
      <SuperAdminLayout>
        <User>
          <UserInformation />
        </User>
      </SuperAdminLayout>
    ),
  },
  {
    path: 'admin/user/:userId/activity',
    element: (
      <SuperAdminLayout>
        <User>
          <UserActivity />
        </User>
      </SuperAdminLayout>
    ),
  },
  {
    path: 'admin/user/:userId/reward-points',
    element: (
      <SuperAdminLayout>
        <User>
          <UserRewardPoints />
        </User>
      </SuperAdminLayout>
    ),
  },
  {
    path: 'admin/user/:userId/knowledge-base',
    element: (
      <SuperAdminLayout>
        <User>
          <UserKnowladgeBase />
        </User>
      </SuperAdminLayout>
    ),
  },
  {
    path: 'admin/user/:userId/workspace',
    element: (
      <SuperAdminLayout>
        <User>
          <UserWorkspace />
        </User>
      </SuperAdminLayout>
    ),
  },
  {
    path: 'admin/companies',
    element: (
      <SuperAdminLayout>
        <Companies />
      </SuperAdminLayout>
    ),
  },
  {
    path: 'admin/company/:companyId/information',
    element: (
      <SuperAdminLayout>
        <Company>
          <CompanyInformation />
        </Company>
      </SuperAdminLayout>
    ),
  },
  {
    path: 'admin/company/:companyId/activity',
    element: (
      <SuperAdminLayout>
        <Company>
          <CompanyActivity />
        </Company>
      </SuperAdminLayout>
    ),
  },
  {
    path: 'admin/company/:companyId/point-management',
    element: (
      <SuperAdminLayout>
        <Company>
          <CompanyPointsManagement />
        </Company>
      </SuperAdminLayout>
    ),
  },
  {
    path: 'admin/company/:companyId/team-dashboard',
    element: (
      <SuperAdminLayout>
        <Company>
          <TeamDashboard />
        </Company>
      </SuperAdminLayout>
    ),
  },
  {
    path: 'admin/company/:companyId/progress/:period',
    element: (
      <SuperAdminLayout>
        <Company>
          <MeterPeriodProgress />
        </Company>
      </SuperAdminLayout>
    ),
  },
  {
    path: 'admin/edit-profile/info',
    element: (
      <SuperAdminLayout>
        <UserProfile>
          <PersonalInfo />
        </UserProfile>
      </SuperAdminLayout>
    ),
  },
];

const auth_routes = [
  {
    path: '/login',
    element: (
      <AuthLayout>
        <Login />
      </AuthLayout>
    ),
  },
  {
    path: '/forgot-password',
    element: (
      <AuthLayout>
        <ForgotPassword />
      </AuthLayout>
    ),
  },
  {
    path: '/reset-password',
    element: (
      <AuthLayout>
        <UserSetPassword />
      </AuthLayout>
    ),
  },
  {
    path: '/invitation-register',
    element: (
      <AuthLayout>
        <UserSetPassword />
      </AuthLayout>
    ),
  },
];

const routes = [
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '/',
    element: (
      <DashboardLayout>
        <Dashboard />
      </DashboardLayout>
    ),
  },
  {
    path: '/my-workspace',
    element: (
      <DashboardLayout>
        <MyWorkspace />
      </DashboardLayout>
    ),
  },
  {
    path: '/knowledge-base',
    element: (
      <DashboardLayout>
        <KnowledgeBase />
      </DashboardLayout>
    ),
  },
  {
    path: '/knowledge-base/:itemId',
    element: (
      <DashboardLayout>
        <KnowledgeBaseItemPage />
      </DashboardLayout>
    ),
  },
  {
    path: '/message-center',
    element: (
      <DashboardLayout>
        <MessageCenter />
      </DashboardLayout>
    ),
  },
  {
    path: '/team',
    element: (
      <DashboardLayout>
        <Team />
      </DashboardLayout>
    ),
  },
  {
    path: '/design-team',
    element: (
      <DashboardLayout>
        <DesignTeam />
      </DashboardLayout>
    ),
  },
  {
    path: '/userguide',
    element: (
      <DashboardLayout>
        <IFrame iFrameLink="https://v2-embednotion.com/RemoteWorx-User-Guide-5b751542364844038fa334ab0c1ed029" />
      </DashboardLayout>
    ),
  },
  {
    path: '/support',
    element: (
      <DashboardLayout>
        <IFrame iFrameLink="https://share.hsforms.com/1ud-rFoaDQliWxuPTsDHoaA8o7yj" />
      </DashboardLayout>
    ),
  },
  {
    path: '/edit-profile/info',
    element: (
      <DashboardLayout>
        <UserProfile>
          <PersonalInfo />
        </UserProfile>
      </DashboardLayout>
    ),
  },
  {
    path: '/edit-profile/company',
    element: (
      <DashboardLayout>
        <UserProfile>
          <TeamAdminMyCompany />
        </UserProfile>
      </DashboardLayout>
    ),
  },
  {
    path: '/edit-profile/activity',
    element: (
      <DashboardLayout>
        <UserProfile>
          <MyActivity />
        </UserProfile>
      </DashboardLayout>
    ),
  },
  {
    path: '/edit-profile/points',
    element: (
      <DashboardLayout>
        <UserProfile>
          <MyRewardPoints />
        </UserProfile>
      </DashboardLayout>
    ),
  },
  {
    path: '/edit-profile/points-management',
    element: (
      <DashboardLayout>
        <UserProfile>
          <CompanyPointsManagement />
        </UserProfile>
      </DashboardLayout>
    ),
  },
  ...auth_routes,
  ...admin_routes,
];

export default routes;

import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { ReactComponent as CheckboxCheckedIcon } from '@/components/icons/CheckboxChecked.svg';
import { ReactComponent as CheckboxIcon } from '@/components/icons/CheckboxDefault.svg';
import { selectStateModal } from '@/redux/selectors/componentsManage';
import { toggleModal } from '@/redux/reducers/componentsManage';
import {
  DialogTitle,
  IconButton,
  DialogActions,
  DialogContent,
} from '@/components/admin/user/components';
import { CloseIcon } from '@/components/icons';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Paper,
  Typography,
} from '@mui/material';
import React, { FC, useRef, useState } from 'react';
import { Formik } from 'formik';
import {
  AuthButton,
  CenteredButtonContainer,
  ConfirmText,
  Dialog,
  FormContainer,
} from '@/pages/auth/components';
import { agreementText } from '@/pages/auth/AgreementModal/agreementText';

const AgreementModal: FC<{ setAgreement: (agr: boolean) => void }> = ({
  setAgreement,
}) => {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector(selectStateModal);

  const contentRef = useRef<HTMLDivElement>(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState<boolean>(false);

  const handleScroll = () => {
    if (!contentRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
    const isBottom = scrollTop + clientHeight >= scrollHeight * 0.98;
    setIsScrolledToBottom(isScrolledToBottom ? true : isBottom);
  };

  const handleClose = () => {
    dispatch(toggleModal(false));
  };

  return (
    <Dialog
      onClose={() => dispatch(toggleModal(false))}
      aria-labelledby="customized-dialog-title"
      open={modalState}
    >
      <DialogTitle>
        Please read and accept the Medical Guidance Disclaimer, Terms and
        Conditions, and Privacy Policy below:
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ maxHeight: 500 }}>
        <Paper
          style={{
            maxHeight: 300,
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: 10,
          }}
          onScroll={handleScroll}
          ref={contentRef}
        >
          <Typography variant="body1" component="div">
            <div dangerouslySetInnerHTML={{ __html: agreementText }} />
          </Typography>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Formik
          initialValues={{ disclaimer: false, conditions: false }}
          onSubmit={(values) => {
            setAgreement(true);
            dispatch(toggleModal(false));
          }}
        >
          {({
            values: { disclaimer, conditions },
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (
            <FormContainer onSubmit={handleSubmit}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="disclaimer"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={disclaimer}
                    disabled={!isScrolledToBottom}
                    disableRipple
                    icon={<CheckboxIcon height={22} width={22} />}
                    checkedIcon={<CheckboxCheckedIcon height={22} width={22} />}
                  />
                }
                label={
                  <ConfirmText isScrolled={isScrolledToBottom}>
                    By clicking this checkbox, I understand and agree to the
                    Medical Guidance Disclaimer *
                  </ConfirmText>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="conditions"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={conditions}
                    disabled={!isScrolledToBottom}
                    icon={<CheckboxIcon height={22} width={22} />}
                    checkedIcon={<CheckboxCheckedIcon height={22} width={22} />}
                  />
                }
                label={
                  <ConfirmText isScrolled={isScrolledToBottom}>
                    By clicking this checkbox, I have read and agree to the
                    Terms and Conditions and Privacy Policy. *
                  </ConfirmText>
                }
              />

              <CenteredButtonContainer>
                <AuthButton
                  variant="outlined"
                  color="secondary"
                  type="submit"
                  disabled={!(disclaimer && conditions)}
                >
                  Accept
                </AuthButton>
              </CenteredButtonContainer>
            </FormContainer>
          )}
        </Formik>
      </DialogActions>
    </Dialog>
  );
};

export default AgreementModal;

import { Box } from '@mui/material';
import styled from 'styled-components';

export const MetersWidgetContainer = styled.div`
  padding: 22px 25px 25px 30px;
  background-color: ${(props) => props.theme.palette.neutral.main};
  border-bottom-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  width: 100%;
  margin-bottom: 18px;
`;

export const ProgressMeterWrapper = styled.div`
  margin-bottom: 34px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const TabsContainer = styled(Box)`
  & .MuiTabs-root {
    padding: 0 0 24px;
    background: transparent;
  }
`;

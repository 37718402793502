import instance from '@/redux/middlewares/api';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { ELocalStoreKeys, EServerStatusCode } from '../../types/consts';
import { removeAuthTokens, saveTokens } from '../../utils/apiCallHelpers';
import { logoutChatUser } from '../actions/chat';
import { resetUserState } from '../reducers/user';

let isRefreshing = false;
let failedQueue: Array<any> = [];

const processQueue = (error: AxiosError | Error | null, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const handleResponseError = (error: any, store: any) => {
  const { response } = error;
  const refreshToken = localStorage.getItem(ELocalStoreKeys.REFRESH_TOKEN);

  if (!response) {
    return Promise.reject(error);
  }

  const errorMsg = {
    status: error.response.status,
    message: error.response.data.message || '',
    statusText: error.response.statusText,
  };

  if (response.status >= 500 && response.status < 600) {
    errorMsg.statusText = 'Internal server error';
    errorMsg.message = 'Server response with error, please try again later';

    toast.info(`${errorMsg.statusText} \n ${errorMsg.message}`);
  }

  if (response.status === EServerStatusCode.EXPIRED_TOKEN) {
    if (refreshToken) {
      const originalRequest = error.config;

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => instance(originalRequest))
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      isRefreshing = true;

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/auth/new-tokens`,
            {},
            {
              headers: {
                'Refresh-Auth': refreshToken,
              },
            }
          )
          .then(({ data }) => {
            saveTokens(data.accessToken, data.refreshToken);
            processQueue(null, data.accessToken);
            resolve(instance(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);

            removeAuthTokens();
            store.dispatch(resetUserState());
            store.dispatch(logoutChatUser());
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    } else {
      removeAuthTokens();
    }
  }

  return Promise.reject(errorMsg);
};

export default handleResponseError;

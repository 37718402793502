import { Box, BoxProps, ListItem, Typography } from '@mui/material';
import styled, { css } from 'styled-components';

interface INavItemProps {
  active?: boolean;
  isOpen?: boolean;
}

export const UserChatName = styled.div<INavItemProps>`
  display: ${({ isOpen }) => (!isOpen ? 'none' : 'block')};
  font-weight: 400;
  margin-left: 8px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  width: calc(100% - 55px);
`;

export const ChatNavItemBasic = styled(ListItem)<INavItemProps>`
  color: #9a98af;
  padding: 0;
`;

export const ChatNavItem = styled(ChatNavItemBasic)<INavItemProps>`
  border-left-width: 4px;
  border-left-style: solid;
  border-left-color: transparent;
  color: #9a98af;

  &:hover {
    color: ${({ theme }) => theme.palette.neutral.main};
    border-left: ${({ theme }) => theme.sidebar.item.active.border};
  }
  ${({ active }) =>
    active &&
    css`
      border-left: ${({ theme }) =>
        active ? theme.sidebar.item.active.border : 'transparent'};
    `}
`;

export const ChatItemContentBasic = styled.div<INavItemProps>`
  width: ${({ isOpen }) => (isOpen ? '100%' : 'auto')};
  display: flex;
  justify-content: ${({ isOpen }) => (!isOpen ? 'center' : 'flex-start')};
  padding: ${({ isOpen, theme }) =>
    isOpen ? theme.spacing(2, 3, 2, 6) : theme.spacing(2, 3)};
  align-items: center;
  font-weight: 400;
  text-decoration: none;

  ${({ isOpen, theme }) =>
    !isOpen &&
    css`
      margin: ${theme.spacing(0, 'auto')};
    `};
`;

export const ChatItemContent = styled(ChatItemContentBasic)<INavItemProps>`
  border-radius: ${({ isOpen, theme }) =>
    isOpen ? theme.spacing(0, 2, 2, 0) : theme.spacing(2)};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.sidebar.item.active.background};
  }

  ${({ active, isOpen, theme }) =>
    active &&
    css`
      color: ${theme.palette.common.white};
      background-color: ${theme.sidebar.item.active.background};
    `}
`;

export const NavSection = styled(Box)<BoxProps>`
  margin: ${(props) => props.theme.spacing(4, 0)};
`;

export const MainTitle = styled(Typography)<{ open: boolean }>`
  display: block;
  padding: ${({ theme }) => theme.spacing(0, 5)};
  color: ${({ theme }) => theme.sidebar.color};
  font-size: ${({ theme }) => theme.typography.fontSize};
  text-align: ${({ open }) => (open ? 'left' : 'center')};
  transition: all 0.3s ease;
`;

export const ChatNavGroup = styled.div`
  position: relative;
  padding: 18px 0;

  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 25px;
    right: 25px;
    border-bottom: 1px solid #e0dfea1a;
  }
`;

export const BadgeSimple = styled.div<{ open: boolean }>`
  border: 1px solid #5b587d4d;
  border-radius: 15px;
  color: #9c99b8;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4px 6px;

  ${({ open }) =>
    !open &&
    css`
      margin-left: -9px;
    `};
`;

export const RwAdminAvatarContainer = styled(Box)`
  width: 24px;
  height: 24px;
  margin-right: 2px;
  & svg {
    width: 24px;
    height: 24px;
  }
`;

import ConfirmModal, { ModalStatuses } from '@/components/dialogs/ConfirmModal';
import { DeleteCompanyIcon } from '@/components/icons';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAuth from '@/hooks/useAuth';
import { deleteUser } from '@/redux/actions/users';
import { DEFAULT_TOAST_TIME } from '@/types/consts';
import { getRoutePrefixByRoleName } from '@/utils/routeRoleHelpers';
import { Button, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import styled from 'styled-components';

const DeleteButton = styled(Button)`
  background-color: #feebef;
  color: ${({ theme }) => theme.palette.redColor.main};

  &:hover {
    background-color: #c2d2e1;
  }
`;

interface IUserDeleteProps {
  text?: string;
  isSoft?: boolean;
}

const UserDeleteButton: FC<IUserDeleteProps> = ({
  text,
  isSoft = true,
}: IUserDeleteProps) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userRole } = useAuth();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleOpen = () => {
    setConfirmationOpen(true);
  };

  const handleDelete = () => {
    dispatch(deleteUser({ id: Number(userId), isSoft: isSoft }))
      .unwrap()
      .then(() => {
        setConfirmationOpen(false);
        navigate(getRoutePrefixByRoleName(userRole) + '/users');
        toast.success('User was successfully deleted!', {
          position: 'top-right',
          autoClose: DEFAULT_TOAST_TIME,
          hideProgressBar: false,
        });
      });
  };

  return (
    <>
      <DeleteButton
        onClick={handleOpen}
        type="button"
        variant="outlined"
        color="warning"
      >
        <Typography variant={'body1'} mr={1}>
          {text || 'Delete User'}
        </Typography>
        <DeleteCompanyIcon />
      </DeleteButton>
      <ConfirmModal
        confirmStatus={ModalStatuses.WARNING}
        textConfirm="Delete User"
        isOpen={confirmationOpen}
        text={
          isSoft
            ? 'Deleted user can be restored only by RemoteWorx super admin. To confirm user deletion click Delete User.'
            : 'Delete with caution. User will be deleted permanently! Deleting this user, will permanently delete all data related to this user, including reward points and billing information. Deleted data will not be recoverable'
        }
        confirmIcon={<DeleteCompanyIcon />}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};
export default UserDeleteButton;

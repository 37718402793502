import { ProgressContainer } from '@/components/admin/user/components';
import KnolwledgeCenterProggress from '@/components/admin/user/KnolwledgeCenterProggress';
import UserSelect from '@/components/form-elements/UserSelect';
import ActivityBreakdownWrapper from '@/components/progressMeter/ActivityBreakdown/ActivityBreakDownWrapper';
import {
  GeneralInfoBlock,
  MessageStatTitle,
  MyActivityContainer,
  ProgressMeterContainer,
  ProgressMeterInfo,
  StatLabel,
  StatValue,
} from '@/components/progressMeter/components';
import PersonalActivity from '@/components/progressMeter/PersonalActivity/PersonalActivity';
import ProgressMeter from '@/components/progressMeter/ProgressMeter/ProgressMeter';
import {
  Days,
  StreakDays,
} from '@/components/progressMeter/RewardPoints/components';
import WorkSpaceProgress from '@/components/progressMeter/WorkSpaceProgress';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useCompany from '@/hooks/useCompany';
import { SecreteItems } from '@/pages/dashboard/MyWorkspace/constants';
import { TabsContainer } from '@/pages/dashboard/ProgressMeterWidget/components';
import { numberWithCommas } from '@/pages/super-admin/company/helpers';
import { getMyDailyStreak, getMyProgress } from '@/redux/actions/activity';
import {
  getUserDailyStreak,
  getUserMessagesStat,
  getUserProgress,
  getUsersByParams,
} from '@/redux/actions/users';
import {
  getUserWorkspaceItems,
  getUserWorkspaceLevels,
} from '@/redux/actions/workSpace';
import {
  myDailyStreak,
  myProgressSelector,
} from '@/redux/selectors/activitySelector';
import {
  adminUserDailyStreak,
  adminUserMessagingStats,
  adminUserProgressSelector,
} from '@/redux/selectors/users';
import { getUserSelector } from '@/redux/selectors/userSelectors';
import {
  currentItemsSelector,
  workSpaceCountCategoriesSelector,
} from '@/redux/selectors/workspaceSelector';
import { EActivityLevel, EPeriod, EUserRole } from '@/types/consts';
import { IActivityProgress, IDailyStreak, IUser } from '@/types/models';
import {
  getEndOfMonth,
  getEndOfWeek,
  getStartOfMonth,
  getStartOfWeek,
} from '@/utils/dateHelpers';
import {
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Tab,
  Tabs,
} from '@mui/material';
import { format, formatDuration } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';

const MyActivity: React.FC = () => {
  const dispatch = useAppDispatch();
  const { role, id } = useAppSelector(getUserSelector) as IUser;
  const myProgress = useAppSelector(myProgressSelector);
  const adminProgress = useAppSelector(adminUserProgressSelector);
  const myStreak = useAppSelector(myDailyStreak);
  const userStreak = useAppSelector(adminUserDailyStreak);
  const messageStat = useAppSelector(adminUserMessagingStats);
  const countCategories = useAppSelector(workSpaceCountCategoriesSelector);
  const current = useAppSelector(currentItemsSelector);
  const { company } = useCompany();
  const [companyTeam, setCompanyTeam] = useState<IUser[] | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const [progress, setProgress] = useState<Omit<
    IActivityProgress,
    'date'
  > | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [dailyStreak, setDailyStreak] = useState<IDailyStreak | null>(null);
  const [period, setPeriod] = useState<EPeriod>(EPeriod.DAY);

  useEffect(() => {
    const dateRange = {
      from: format(getStartOfMonth(new Date()), 'yyyy-MM-dd'),
      to: format(getEndOfMonth(new Date()), 'yyyy-MM-dd'),
    };
    if (userId) {
      dispatch(
        getUserDailyStreak({
          ...dateRange,
          userId,
        })
      );
      dispatch(getUserMessagesStat(userId));
      dispatch(getUserWorkspaceLevels(userId));
      dispatch(getUserWorkspaceItems(userId));
    } else {
      dispatch(getMyDailyStreak(dateRange));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (role.name === EUserRole.TEAMLEAD) {
      dispatch(getUsersByParams({ company: company?.name }))
        .unwrap()
        .then(({ users }) => {
          if (users) {
            setCompanyTeam(users);
          }
        });
    } else {
      setDataLoading(false);
    }
  }, [dispatch, company?.name, role]);

  useEffect(() => {
    if (!dataLoading) {
      const start =
        period === EPeriod.WEEK ? getStartOfWeek(new Date()) : new Date();
      const end =
        period === EPeriod.WEEK ? getEndOfWeek(new Date()) : new Date();
      const progressParams = {
        from: format(start, 'yyyy-MM-dd'),
        to: format(end, 'yyyy-MM-dd'),
        dataLevel: EActivityLevel.DYNAMIC,
      };
      if (userId) {
        dispatch(
          getUserProgress({
            ...progressParams,
            userId,
          })
        );
      } else {
        dispatch(getMyProgress(progressParams));
      }
    }
  }, [period, userId, dataLoading]);

  useEffect(() => {
    setProgress(userId ? adminProgress : myProgress);
  }, [myProgress, adminProgress, userId]);

  useEffect(() => {
    setDailyStreak(userId ? userStreak : myStreak);
  }, [myStreak, userStreak, userId]);

  const currentLength = useMemo(
    () =>
      current?.filter(
        (oneItem) =>
          oneItem.status === true &&
          !(
            oneItem.name === SecreteItems.BOX ||
            oneItem.name === SecreteItems.STICKER
          )
      )?.length,
    [current]
  );
  const totalLength = useMemo(
    () =>
      current?.filter(
        (oneItem) =>
          !(
            (oneItem.status === false && oneItem.name === SecreteItems.BOX) ||
            (oneItem.status === false && oneItem.name === SecreteItems.STICKER)
          )
      )?.length,
    [current]
  );

  return (
    <MyActivityContainer>
      {companyTeam && (
        <UserSelect
          options={companyTeam}
          onUserSelect={(userId) => {
            setUserId(userId);
            setDataLoading(false);
          }}
          defaultUser={id}
          showDefaultUserAsCurrent={true}
          currentUserTitle="My activity"
        />
      )}
      {dataLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <GeneralInfoBlock display="flex" alignItems="stretch">
            <ActivityBreakdownWrapper userId={userId} />
            <ProgressMeterInfo display="flex" flexDirection="column">
              <TabsContainer component={'section'}>
                <Tabs
                  value={period}
                  onChange={(e, value) => setPeriod(value)}
                  centered
                  textColor={'secondary'}
                >
                  <Tab value={EPeriod.DAY} label="Day" />
                  <Tab value={EPeriod.WEEK} label="Week" />
                </Tabs>
              </TabsContainer>
              <Box mt={2}>
                {progress ? (
                  <ProgressMeterContainer alignSelf="center">
                    <ProgressMeter
                      mental={progress.mental}
                      social={progress.social}
                      physical={progress.physical}
                    />
                  </ProgressMeterContainer>
                ) : (
                  <Skeleton height={150} />
                )}
                <Box mt={4} alignSelf="flex-end" justifySelf="flex-end">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                  >
                    <StreakDays>
                      100% Goal Reached
                      <Days>
                        {formatDuration({
                          days: dailyStreak?.totalDaysCompleted,
                        }) || '0 days'}
                      </Days>
                    </StreakDays>
                    <StreakDays>
                      Longest Consecutive Streak
                      <Days>
                        {formatDuration({
                          days: dailyStreak?.maxDaysInARowCompleted,
                        }) || '0 days'}
                      </Days>
                    </StreakDays>
                  </Box>
                </Box>
              </Box>
            </ProgressMeterInfo>
          </GeneralInfoBlock>
          <PersonalActivity userId={userId} />
          {userId && (
            <ProgressContainer container flexWrap={'nowrap'} columnGap={12}>
              <Grid item md={6}>
                <KnolwledgeCenterProggress userId={userId} />
              </Grid>
              <Grid item md={6}>
                <WorkSpaceProgress from={20} completed={countCategories} />
              </Grid>
            </ProgressContainer>
          )}
          {messageStat && (
            <Box paddingX="70px" marginTop="36px">
              <MessageStatTitle>Messaging</MessageStatTitle>
              <Box display="flex">
                <Box display="flex">
                  <StatLabel>Messages Sent This Week</StatLabel>
                  <StatValue>
                    {numberWithCommas(messageStat.weekCount)}
                  </StatValue>
                </Box>
                <Box display="flex" marginLeft="30px">
                  <StatLabel>Messages Sent This Month</StatLabel>
                  <StatValue>
                    {numberWithCommas(messageStat.monthCount)}
                  </StatValue>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </MyActivityContainer>
  );
};

export default MyActivity;

import {
  nameColumnSortByLastName,
  numberColumnSortingComparator,
} from '@/utils/tableHelpers';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import CustomGridCell from '../CustomGridCell';

export const progresColumns: GridColDef[] = [
  { field: 'id', headerName: 'Id', hide: true, align: 'left' },
  {
    field: 'user',
    headerName: 'Name',
    flex: 2,
    align: 'left',
    renderCell: (params: GridRenderCellParams) => {
      return <CustomGridCell {...params} />;
    },
    sortComparator: nameColumnSortByLastName,
  },

  {
    field: 'mental',
    headerName: 'Mental',
    flex: 1,
    align: 'center',
    sortComparator: numberColumnSortingComparator,
    valueFormatter: (params) => Number(params.value).toLocaleString('en'),
  },
  {
    field: 'phycical',
    headerName: 'Physical',
    flex: 1,
    align: 'center',
    sortComparator: numberColumnSortingComparator,
    valueFormatter: (params) => Number(params.value).toLocaleString('en'),
  },
  {
    field: 'social',
    headerName: 'Social',
    flex: 1,
    align: 'center',
    sortComparator: numberColumnSortingComparator,
    valueFormatter: (params) => Number(params.value).toLocaleString('en'),
  },
  {
    field: 'menterTotals',
    headerName: 'Meters Total',
    flex: 1,
    align: 'center',
    sortComparator: numberColumnSortingComparator,
    valueFormatter: (params) => Number(params.value).toLocaleString('en'),
  },
  {
    field: 'jobTitle',
    headerName: 'Job Title',
    flex: 1,
    align: 'left',
  },
  { field: 'email', headerName: 'Email', flex: 2, align: 'left' },
];

import useAppDispatch from '@/hooks/useAppDispatch';
import { getSimpleCompanies } from '@/redux/actions/company';
import { ICompany } from '@/types/models';
import { MenuItem, OutlinedInput, Select } from '@mui/material';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Placeholder } from './components';

const CompanySelect: React.FC = () => {
  const dispatch = useAppDispatch();
  const [companies, setCompanies] = useState<ICompany[] | null>(null);
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext<{ companyId: number }>();

  useEffect(() => {
    dispatch(getSimpleCompanies())
      .unwrap()
      .then((companies) => {
        setCompanies(companies);
      });
  }, []);

  return (
    <Select
      displayEmpty
      type={'number'}
      name={'companyId'}
      value={values.companyId}
      error={Boolean(touched.companyId && errors.companyId)}
      fullWidth
      onBlur={handleBlur}
      onChange={handleChange}
      renderValue={(selected) => {
        if (selected === 0) {
          return <Placeholder>Company</Placeholder>;
        }
        const company =
          companies && companies.find(({ id }) => id === selected);
        return company?.name;
      }}
      input={<OutlinedInput />}
    >
      <MenuItem value={values.companyId}>Company</MenuItem>
      {companies &&
        companies.map((company) => (
          <MenuItem key={company.id} value={company.id}>
            {company.name}
          </MenuItem>
        ))}
    </Select>
  );
};

export default CompanySelect;

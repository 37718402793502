import CompanyTabs from '@/components/admin/company/CompanyTabs';
import Tools from '@/components/admin/company/Tools';
import useAppDispatch from '@/hooks/useAppDispatch';
import { getCompany } from '@/redux/actions/company';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Main = styled('main')`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Company: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { companyId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCompany(Number(companyId)));
  }, [dispatch, companyId]);

  return (
    <Main>
      <Tools />
      <CompanyTabs />
      {children}
    </Main>
  );
};

export default Company;

import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { getCompany as getCompanyAction } from '@/redux/actions/company';
import { getCompany as getCompanySelector } from '@/redux/selectors/companySelectors';

const useCompany = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(getCompanySelector);

  const getCompanyById = (companyId: number) =>
    dispatch(getCompanyAction(companyId));

  return {
    company,
    getCompanyById,
  };
};

export default useCompany;

import React from 'react';

interface TFrameProps {
  iFrameLink: string;
}
const IFrame: React.FC<TFrameProps> = ({ iFrameLink }) => {
  return <iframe src={iFrameLink} height="100%" width="100%" />;
};

export default IFrame;

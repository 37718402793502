import { PointsActivityTitle } from '@/components/user-profile/reward-points/components';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { getMyRewardPointsLog } from '@/redux/actions/activity';
import { getUserRewardPointsLog } from '@/redux/actions/users';
import { myRewardPointsLogSelector } from '@/redux/selectors/activitySelector';
import { adminUserRewardPointsLog } from '@/redux/selectors/users';
import { getUserSelector } from '@/redux/selectors/userSelectors';
import { ELimits } from '@/types/consts';
import { IRewardPointsLog, IUser } from '@/types/models';
import { getDateMinusSixtyDays } from '@/utils/dateHelpers';
import React, { useEffect, useState } from 'react';
import { RewardPointsHistoryContainer } from './components';
import RewardPointsHistory from './RewardPointsHistory';

interface ActivityBreakdownWrapperProps {
  isCompanyView?: boolean;
  userId?: number | null;
}

export interface IActivityItem {
  startDate: Date;
  endDate: Date;
}

const initialData = {
  startDate: getDateMinusSixtyDays(new Date()),
  endDate: new Date(),
};

const RewardPointsHistoryWrapper: React.FC<ActivityBreakdownWrapperProps> = ({
  isCompanyView,
  userId,
}) => {
  const dispatch = useAppDispatch();
  const { role } = useAppSelector(getUserSelector) as IUser;
  const [rewardPointsLog, setRewardPointsLog] = useState<
    IRewardPointsLog[] | null
  >(null);
  const myActivityLog = useAppSelector(myRewardPointsLogSelector);
  const adminUserLog = useAppSelector(adminUserRewardPointsLog);
  const [activityDate, setActivityDate] = useState<IActivityItem>(initialData);

  useEffect(() => {
    const logPagination = {
      limit: ELimits.ACTIVITY,
      from: activityDate.startDate,
      to: activityDate.endDate,
      offset: 0,
    };
    if (!userId) {
      dispatch(getMyRewardPointsLog({ logPagination, earned: false }));
    } else {
      dispatch(
        getUserRewardPointsLog({
          ...logPagination,
          userId,
        })
      );
    }
  }, [role, userId, activityDate]);

  useEffect(() => {
    setRewardPointsLog(userId ? adminUserLog : myActivityLog);
  }, [myActivityLog, adminUserLog, userId]);

  return (
    <RewardPointsHistoryContainer>
      <PointsActivityTitle variant={'h2'} mb={6}>
        Reward Points History
      </PointsActivityTitle>
      <RewardPointsHistory
        userId={userId}
        rewardPointsLog={rewardPointsLog}
        isCompanyView={isCompanyView}
        activityDate={activityDate}
        setActivityDate={setActivityDate}
      />
    </RewardPointsHistoryContainer>
  );
};

export default RewardPointsHistoryWrapper;

import useAuth from '@/hooks/useAuth';
import * as React from 'react';
import { Navigate } from 'react-router-dom';

interface AdminGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by admin users
function SuperAdminGuard({ children }: AdminGuardType) {
  const { isAuthenticated, isRwAdmin, isSuperAdmin } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to={'/login'} />;
  }

  if (isAuthenticated && (isRwAdmin || isSuperAdmin)) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return <Navigate to={'/'} />;
}

export default SuperAdminGuard;

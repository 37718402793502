import Switch from '@/components/UI/Switch';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import { FormControlWrapper } from '@/pages/auth/components';
import { createCompany, updateCompany } from '@/redux/actions/company';
import { getCountries } from '@/redux/actions/country';
import { getStates, getStatesByCountry } from '@/redux/actions/state';
import { getUsersByParams } from '@/redux/actions/users';
import { toggleDrawer } from '@/redux/reducers/componentsManage';
import {
  getCompany as getCompanySelector,
  getCompanyLoading,
} from '@/redux/selectors/companySelectors';
import { getCountriesSelector } from '@/redux/selectors/country';
import { getStatesSelector } from '@/redux/selectors/state';
import { DEFAULT_TOAST_TIME, EUserRole } from '@/types/consts';
import { IUser } from '@/types/models';
import {
  Button as MuiButton,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as Yup from 'yup';

const Placeholder = styled('em')`
  color: ${({ theme }) => theme.palette.selector.light};
  font-style: italic;
  opacity: 0.42;
`;

const UpdateButton = styled(MuiButton)`
  display: block;
  margin: ${({ theme }) => theme.spacing(3, 'auto')};
`;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  streetAddress: Yup.string().required('Required'),
  streetAddress2: Yup.string().optional(),
  city: Yup.string().required('Required'),
  stateId: Yup.number().optional(),
  countryId: Yup.number().positive().min(1).required('Required'),
  zipCode: Yup.string().optional(),
  rwAdminId: Yup.number().optional(),
  billingContactId: Yup.number().optional(),
  rewardPoints: Yup.boolean().optional(),
});

const CompanyBillingAddress = () => {
  const { companyId } = useParams();
  const { isSuperAdmin } = useAuth();

  const dispatch = useAppDispatch();
  const company = useAppSelector(getCompanySelector);
  const companyLoading = useAppSelector(getCompanyLoading);
  const states = useAppSelector(getStatesSelector);
  const countries = useAppSelector(getCountriesSelector);

  const [submitError, setSubmitError] = React.useState<string>('');
  const [rwAdmins, setRwAdmins] = useState<IUser[]>([]);
  const [companyTeam, setCompanyTeam] = useState<IUser[]>([]);

  const initialValues = {
    name: companyId ? company?.name || '' : '',
    streetAddress: companyId ? company?.streetAddress || '' : '',
    streetAddress2: companyId ? company?.streetAddress2 || '' : '',
    city: companyId ? company?.city || '' : '',
    stateId: companyId ? company?.stateId || 0 : 0,
    countryId: companyId ? company?.countryId || 0 : 0,
    zipCode: companyId ? company?.zipCode || '' : '',
    rwAdminId: companyId ? company?.rwAdminId || 0 : 0,
    billingContactId: companyId ? company?.billingContactId || 0 : 0,
    rewardPoints: companyId ? company?.rewardPoints || false : false,
  };

  const handleCatchError = ({ error }: any) => {
    console.error(error);
    // TODO devide logic for server error and invalid client data
    setSubmitError('Something went wrong');
  };

  const companyName = useMemo(() => company?.name, [company?.name]);

  const handleOnSubmit = (
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    const { stateId, rwAdminId, billingContactId, ...restValues } = values;

    if (companyId) {
      dispatch(
        updateCompany({
          id: +companyId,
          logo: '',
          rwAdminId: rwAdminId !== 0 ? rwAdminId : undefined,
          billingContactId:
            billingContactId !== 0 ? billingContactId : undefined,
          stateId: stateId === 0 ? undefined : stateId,
          ...restValues,
        })
      )
        .unwrap()
        .then(() =>
          toast.success('Company was successfully updated', {
            position: 'top-right',
            autoClose: DEFAULT_TOAST_TIME,
            hideProgressBar: false,
          })
        )
        .catch(handleCatchError)
        .finally(() => {
          setSubmitting(false);
          resetForm({ values });
        });
    } else {
      dispatch(
        createCompany({
          logo: '',
          stateId: stateId === 0 ? undefined : stateId,
          rwAdminId: rwAdminId !== 0 ? rwAdminId : undefined,
          billingContactId:
            billingContactId !== 0 ? billingContactId : undefined,
          ...restValues,
        })
      )
        .unwrap()
        .then(() => {
          toast.success('Company was successfully created', {
            position: 'top-right',
            autoClose: DEFAULT_TOAST_TIME,
            hideProgressBar: false,
          });
          dispatch(toggleDrawer(false));
        })
        .catch(handleCatchError)
        .finally(() => {
          setSubmitting(false);
          resetForm(values);
        });
    }
  };

  useEffect(() => {
    dispatch(getUsersByParams({ roles: [EUserRole.RW_ADMIN] }))
      .unwrap()
      .then(({ users }) => {
        if (users) {
          setRwAdmins(users);
        }
      });

    dispatch(getStates());
    dispatch(getCountries());

    if (companyName) {
      dispatch(getUsersByParams({ company: companyName }))
        .unwrap()
        .then(({ users }) => {
          if (users) {
            setCompanyTeam(users);
          }
        });
    }
  }, [dispatch, companyName]);

  if (companyLoading) {
    return <CircularProgress />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
        isSubmitting,
        isValid,
        dirty,
      }) => {
        return (
          <Form>
            <FormControlWrapper
              hiddenLabel={true}
              error={!!errors.name && !!touched.name}
            >
              {!!values.name && <InputLabel>Company Name*</InputLabel>}
              <OutlinedInput
                disabled={!isSuperAdmin}
                name={'name'}
                placeholder={'Company Name*'}
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                type={'text'}
              />
              {touched.name && errors.name && (
                <FormHelperText id={'name'} error>
                  {errors.name}
                </FormHelperText>
              )}
            </FormControlWrapper>

            <Typography variant="h3" my={5}>
              Billing Address
            </Typography>

            <Grid container>
              <Grid item md={6}>
                <FormControlWrapper
                  hiddenLabel={true}
                  error={!!errors.countryId && !!touched.countryId}
                >
                  {!!values.countryId && <InputLabel>Country*</InputLabel>}
                  <Select
                    displayEmpty
                    disabled={!isSuperAdmin || !countries}
                    name={'countryId'}
                    value={values.countryId}
                    error={Boolean(touched.countryId && errors.countryId)}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={(e) => {
                      dispatch(getStatesByCountry(e.target.value as string));
                      handleChange(e);
                    }}
                    renderValue={(selected) => {
                      if (selected === 0) {
                        return <Placeholder>Country*</Placeholder>;
                      }

                      const country = countries.find(
                        ({ id }) => id === selected
                      );
                      return (
                        country?.name || <Placeholder>Country*</Placeholder>
                      );
                    }}
                    input={<OutlinedInput />}
                  >
                    <MenuItem value={''}>
                      <Placeholder>Country</Placeholder>
                    </MenuItem>
                    {countries.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>

                  {touched.countryId && errors.countryId && (
                    <FormHelperText id="countryId" error>
                      Required
                    </FormHelperText>
                  )}
                </FormControlWrapper>
              </Grid>

              <Grid item md={6}>
                <FormControlWrapper
                  hiddenLabel={true}
                  error={!!errors.streetAddress && !!touched.streetAddress}
                >
                  {!!values.streetAddress && (
                    <InputLabel>Street Address*</InputLabel>
                  )}
                  <OutlinedInput
                    disabled={!isSuperAdmin}
                    name={'streetAddress'}
                    placeholder={'Street Address*'}
                    value={values.streetAddress}
                    error={Boolean(
                      touched.streetAddress && errors.streetAddress
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    type={'text'}
                  />
                  {touched.streetAddress && errors.streetAddress && (
                    <FormHelperText id="streetAddress" error>
                      {errors.streetAddress}
                    </FormHelperText>
                  )}
                </FormControlWrapper>
              </Grid>
              <Grid item md={6}>
                <FormControlWrapper
                  hiddenLabel={true}
                  error={!!errors.streetAddress2 && !!touched.streetAddress2}
                >
                  {!!values.streetAddress2 && (
                    <InputLabel>Street Address (Line 2)</InputLabel>
                  )}
                  <OutlinedInput
                    disabled={!isSuperAdmin}
                    name={'streetAddress2'}
                    placeholder={'Street Address (Line 2)'}
                    value={values.streetAddress2}
                    error={Boolean(
                      touched.streetAddress2 && errors.streetAddress2
                    )}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={'text'}
                  />
                  {touched.streetAddress2 && errors.streetAddress2 && (
                    <FormHelperText id="streetAddress2" error>
                      {errors.streetAddress2}
                    </FormHelperText>
                  )}
                </FormControlWrapper>
              </Grid>
              <Grid item md={6}>
                <FormControlWrapper
                  hiddenLabel={true}
                  error={!!errors.city && !!touched.city}
                >
                  {!!values.city && <InputLabel>City*</InputLabel>}
                  <OutlinedInput
                    disabled={!isSuperAdmin}
                    name={'city'}
                    placeholder={'City*'}
                    value={values.city}
                    error={Boolean(touched.city && errors.city)}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={'text'}
                  />
                  {touched.city && errors.city && (
                    <FormHelperText id="city" error>
                      {errors.city}
                    </FormHelperText>
                  )}
                </FormControlWrapper>
              </Grid>
              <Grid item md={6}>
                <FormControlWrapper
                  hiddenLabel={true}
                  error={!!errors.stateId && !!touched.stateId}
                >
                  {!!values.stateId && <InputLabel>State</InputLabel>}
                  <Select
                    disabled={!isSuperAdmin || !states || !values.countryId}
                    displayEmpty
                    name={'stateId'}
                    value={values.stateId}
                    error={Boolean(touched.stateId && errors.stateId)}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    renderValue={(selected) => {
                      if (selected === 0) {
                        return <Placeholder>State</Placeholder>;
                      }

                      const state = states.find(({ id }) => id === selected);
                      return state?.name || <Placeholder>State</Placeholder>;
                    }}
                    input={<OutlinedInput />}
                  >
                    <MenuItem value={''}>
                      <Placeholder>State</Placeholder>
                    </MenuItem>
                    {states.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>

                  {touched.stateId && errors.stateId && (
                    <FormHelperText id="stateId" error>
                      Required
                    </FormHelperText>
                  )}
                </FormControlWrapper>
              </Grid>
              <Grid item md={6}>
                <FormControlWrapper
                  hiddenLabel={true}
                  error={!!errors.zipCode && !!touched.zipCode}
                >
                  {!!values.zipCode && <InputLabel>Zip Code</InputLabel>}
                  <OutlinedInput
                    disabled={!isSuperAdmin}
                    name={'zipCode'}
                    placeholder={'Zip Code'}
                    value={values.zipCode}
                    error={Boolean(touched.zipCode && errors.zipCode)}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={'text'}
                  />
                  {touched.zipCode && errors.zipCode && (
                    <FormHelperText id="city" error>
                      {errors.zipCode}
                    </FormHelperText>
                  )}
                </FormControlWrapper>
              </Grid>
              {!rwAdmins && (
                <Skeleton animation="wave" width={'100%'} height={40} />
              )}
              {rwAdmins && (
                <Grid item md={6}>
                  <FormControlWrapper
                    hiddenLabel={true}
                    error={!!errors.rwAdminId && !!touched.rwAdminId}
                  >
                    {!!values.rwAdminId && <InputLabel>RW Admin</InputLabel>}
                    <Select
                      disabled={!isSuperAdmin}
                      displayEmpty
                      name={'rwAdminId'}
                      value={values.rwAdminId}
                      error={Boolean(touched.rwAdminId && errors.rwAdminId)}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      renderValue={(selected) => {
                        const selectedAdmin = rwAdmins.find(
                          (admin) => admin.id === values.rwAdminId
                        );
                        if (selected === 0) {
                          return <Placeholder>RW Admin</Placeholder>;
                        }

                        return selectedAdmin ? (
                          selectedAdmin?.firstName ? (
                            selectedAdmin?.firstName +
                            ' ' +
                            selectedAdmin?.lastName
                          ) : (
                            selectedAdmin?.email
                          )
                        ) : (
                          <Placeholder>RW Admin</Placeholder>
                        );
                      }}
                      input={<OutlinedInput />}
                    >
                      <MenuItem value={''}>
                        <Placeholder>RW Admin</Placeholder>
                      </MenuItem>

                      {rwAdmins.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                          {user.firstName && user.lastName
                            ? user.firstName + ' ' + user.lastName
                            : user.email}
                        </MenuItem>
                      ))}
                    </Select>

                    {touched.rwAdminId && errors.rwAdminId && (
                      <FormHelperText id="rwAdminId" error>
                        Required
                      </FormHelperText>
                    )}
                  </FormControlWrapper>
                </Grid>
              )}
              {companyId && !companyTeam && (
                <Skeleton animation="wave" width={'100%'} height={40} />
              )}
              {companyId && companyTeam && (
                <Grid item md={6}>
                  <FormControlWrapper
                    hiddenLabel={true}
                    error={
                      !!errors.billingContactId && !!touched.billingContactId
                    }
                  >
                    {!!values.billingContactId && (
                      <InputLabel>Billing Contact Name</InputLabel>
                    )}
                    <Select
                      disabled={!isSuperAdmin}
                      displayEmpty
                      name={'billingContactId'}
                      value={values.billingContactId}
                      error={Boolean(
                        touched.billingContactId && errors.billingContactId
                      )}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      renderValue={(selected) => {
                        if (selected === 0) {
                          return (
                            <Placeholder>Billing Contact Name</Placeholder>
                          );
                        }

                        const user = companyTeam.find(
                          ({ id }) => id === selected
                        );

                        const username =
                          user?.firstName && user?.lastName
                            ? `${user?.firstName} ${user?.lastName}`
                            : user?.email;

                        return user ? (
                          username
                        ) : (
                          <Placeholder>Billing Contact Name</Placeholder>
                        );
                      }}
                      input={<OutlinedInput />}
                    >
                      <MenuItem value={''}>
                        <Placeholder>Billing Contact Name</Placeholder>
                      </MenuItem>

                      {companyTeam.map(({ id, firstName, lastName, email }) => (
                        <MenuItem key={id} value={id}>
                          {firstName && lastName
                            ? `${firstName} ${lastName}`
                            : email}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControlWrapper>
                </Grid>
              )}
            </Grid>

            <Typography variant="h3" my={5}>
              <b>Reward Points</b>
            </Typography>

            <Grid item md={12} display="flex" alignItems="center">
              <b>Reward Points enabled for this company</b>
              <Switch
                sx={{ marginLeft: 3 }}
                disabled={!isSuperAdmin}
                name={'rewardPoints'}
                placeholder={'Reward Points'}
                checked={values.rewardPoints}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>

            <UpdateButton
              type={'submit'}
              variant={'contained'}
              color={'primary'}
              disabled={
                !(isValid && dirty && initialValues !== values && !isSubmitting)
              }
            >
              {isSubmitting ? (
                <CircularProgress />
              ) : !!company && !!companyId ? (
                'Update'
              ) : (
                'Create Company'
              )}
            </UpdateButton>

            {!!submitError && (
              <FormHelperText error>{submitError}</FormHelperText>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default CompanyBillingAddress;

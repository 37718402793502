export enum EPointsTrigger {
  CHANGE_MASTER_POINTS = 'change_points',
  REDUCE_MASTER_POINTS = 'reduce_points',
  INCREASE_MASTER_POINTS = 'increase_points',
  CHANGE_FLEX_POINTS = 'change_flex_points',
  REDUCE_FLEX_POINTS = 'reduce_flex_points',
  INCREASE_FLEX_POINTS = 'increase_flex_points',
  GIFT_POINTS = 'gifted_points',
  AVAILABLE_POINTS = 'change_available_points',
  TRANSFER_POINTS = 'transfer_points',
}

type IActionNames = {
  [key in EPointsTrigger]: string;
};

export const triggersNames: IActionNames = {
  [EPointsTrigger.REDUCE_MASTER_POINTS]: 'Points Reduced In Master Account',
  [EPointsTrigger.INCREASE_MASTER_POINTS]: 'Points Increased In Master Account',
  [EPointsTrigger.CHANGE_MASTER_POINTS]: 'Points Funded To Master Account',
  [EPointsTrigger.INCREASE_FLEX_POINTS]: 'Points Increased In Flex Account',
  [EPointsTrigger.REDUCE_FLEX_POINTS]: 'Points Funded To Flex Account',
  [EPointsTrigger.CHANGE_FLEX_POINTS]: 'Points Funded To Flex Account',
  [EPointsTrigger.GIFT_POINTS]: 'Points Gifted To User',
  [EPointsTrigger.AVAILABLE_POINTS]: 'Points Allocated To User',
  [EPointsTrigger.TRANSFER_POINTS]: 'Points Transferred To Work+Life Shop',
};

import { EBrakeTimerTypes } from '@/components/breaktimer/constants';
import { ClassNameWithType } from '@/components/icons/breaktimers/CustomComponents/constants';
import React from 'react';
import styled from 'styled-components';

const CustomSvg = styled.svg<{ type: EBrakeTimerTypes }>`
  circle {
    animation-duration: 1s;
    animation-name: ${(props) => 'icon-pulse-' + props.type};
    animation-iteration-count: infinite;
    fill: #8f92b9;
  }

  @keyframes icon-pulse-${(props) => props.type} {
    0% {
      fill: #8f92b9;
    }
    50% {
      fill: ${(props) => props.theme.palette.breakTimer[props.type].main};
    }
    100% {
      fill: #8f92b9;
    }
  }
`;

const IconStart: React.FC<ClassNameWithType> = ({
  className,
  type,
}: ClassNameWithType) => {
  return (
    <CustomSvg
      width="23"
      height="23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      type={type}
    >
      <circle opacity="1" cx="10" cy="10" r="10" />
      <path
        d="M12.5 9.13397C13.1667 9.51888 13.1667 10.4811 12.5 10.866L9.5 12.5981C8.83333 12.983 8 12.5019 8 11.7321L8 8.26795C8 7.49815 8.83333 7.01702 9.5 7.40192L12.5 9.13397Z"
        fill="white"
      />
    </CustomSvg>
  );
};

export default IconStart;

import {
  getMyActivity,
  getMyDailyStreak,
  getMyProgress,
  getMyRewardPoints,
  getMyRewardPointsLog,
  updateProgress,
  updateRewardPoints,
} from '@/redux/actions/activity';
import { EActivityLevel } from '@/types/consts';
import {
  IActivityLog,
  IActivityProgress,
  IDailyStreak,
  IRewardPoints,
  IRewardPointsLog,
} from '@/types/models';
import { createSlice } from '@reduxjs/toolkit';

export interface ActivityState {
  progressStatistic: IActivityProgress[] | null;
  progress: IActivityProgress[] | null;
  activitiesLog: IActivityLog[] | null;
  rewardPointsLog: IRewardPointsLog[] | null;
  rewardPoints: IRewardPoints | null;
  dailyStreak: IDailyStreak | null;
  loading: boolean;
}

const initialState: ActivityState = {
  progressStatistic: null,
  progress: null,
  activitiesLog: null,
  rewardPointsLog: null,
  rewardPoints: null,
  dailyStreak: null,
  loading: false,
};

export const { actions, reducer } = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    resetActivityState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyProgress.fulfilled, (state, { payload }) => {
        if (payload.dataLevel === EActivityLevel.STATISTIC) {
          state.progressStatistic = payload.data;
        } else {
          state.progress = payload.data;
        }
      })
      .addCase(getMyActivity.fulfilled, (state, { payload }) => {
        state.activitiesLog =
          payload.offset && state.activitiesLog?.length
            ? [...state.activitiesLog, ...payload.data]
            : payload.data;
      })
      .addCase(getMyRewardPointsLog.fulfilled, (state, { payload }) => {
        state.rewardPointsLog =
          payload.offset && state.rewardPointsLog?.length
            ? [...state.rewardPointsLog, ...payload.data]
            : payload.data;
      })
      .addCase(updateProgress.fulfilled, (state, { payload }) => {
        if (state.progress && payload.length) {
          state.progress = [
            ...state.progress.map((item) => {
              if (payload[0].date === item.date) {
                return payload[0];
              }
              return item;
            }),
          ];
        }
      })
      .addCase(getMyRewardPoints.fulfilled, (state, { payload }) => {
        state.rewardPoints = payload;
      })
      .addCase(updateRewardPoints.fulfilled, (state, { payload }) => {
        state.rewardPoints = payload;
      })
      .addCase(getMyDailyStreak.fulfilled, (state, { payload }) => {
        state.dailyStreak = payload;
      });
  },
});

export const { resetActivityState, setLoading } = actions;

export default reducer;

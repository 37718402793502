import React from 'react';
import { WorkspaceItem } from '@/pages/dashboard/MyWorkspace/types';
import { Box } from '@mui/material';
import {
  DashboardWorkspaceBlock,
  DashboardTitleWrapper,
} from '@/pages/dashboard/DashboardWorkspace/component';
import MyWorkspaceItem from '@/pages/dashboard/MyWorkspace/MyWorkspaceView/MyWorkspaceItem';
import { useNavigate } from 'react-router-dom';
import { Complete } from '@/components/icons/workspace';

export const DashboardWorkspace = () => {
  const navigate = useNavigate();
  const state: WorkspaceItem[] = [
    {
      name: 'Complete',
      status: true,
      cursor: 'pointer',
      id: 1,
      component: Complete,
      zIndex: 1,
      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
      bright: 100,
      grayColor: 0,
    },
  ];
  const redirect = () => {
    navigate('/my-workspace');
  };

  return (
    <DashboardWorkspaceBlock>
      <DashboardTitleWrapper onClick={() => navigate('/my-workspace')}>
        <Box className="title">My Workspace</Box>
      </DashboardTitleWrapper>
      <Box>
        <Box className="work-space-dashboard">
          <Box className="wrapper">
            {state.map((item) => (
              <MyWorkspaceItem
                gray2={90}
                bright={184}
                key={item.name}
                item={item}
                changeStatus={redirect}
                tempActive={[]}
              >
                <item.component />
              </MyWorkspaceItem>
            ))}
          </Box>
        </Box>
      </Box>
    </DashboardWorkspaceBlock>
  );
};

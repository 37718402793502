import styled from 'styled-components';

export const WorkspaceItemPopoverBlock = styled.div`
  max-width: 500px;
  min-width: 300px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.palette.custom.light};

  .box-2 {
    font: {
      family: 'Gotham Pro,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol';
      size: 20px;
      weight: '500';
    }
    line-height: 45px;
  }

  .box-3 {
    font: {
      family: 'Gotham Pro,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol';
      size: 12px;
      weight: '400';
    }
    line-height: 18px;
  }

  .box-5 {
    font: {
      family: 'Gotham Pro,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol';
      size: 12px;
      weight: '700';
    }
    line-height: 18px;
    text-decoration-line: underline;

    color: #2fd60a;
  }
`;
export const ButtonWithoutStyles = styled.button`
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
`;

import { Box, StyledComponentProps } from '@mui/material';
import styled from 'styled-components';

interface SmallView extends StyledComponentProps {
  smallView: boolean;
}

export const KnowledgeBaseSmallPreviewBlock = styled(Box)<SmallView>`
  position: relative;
  width: ${(props) => (props.smallView ? '50%' : '100%')};
  margin-left: ${(props) => (props.smallView ? 'auto' : 'unset')};
  margin-right: ${(props) => (props.smallView ? 'auto' : 'unset')};
  border: 1px solid #e2e8ed;
  border-radius: 8px;
  padding-top: 50px;
  overflow: auto;

  canvas {
    width: 100% !important;
    height: auto !important;
  }

  .spinner {
    position: absolute;
    margin-left: 45%;
    margin-top: 20%;

    span {
      width: 100px !important;
      height: 100px !important;
    }
  }

  .react-pdf__Page__annotations {
    width: 100% !important;
    height: 100% !important;
  }
`;
export const ScrollBox = styled(Box)`
  background-color: white;
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    height: 30%;
    background-color: rgba(170, 170, 170, 0.6);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(170, 170, 170, 1);
  }
`;
export const CenteredButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
`;

export const PageIndexContainer = styled(Box)`
  position: absolute;
  width: 80px;
  margin-top: 125%;
  margin-left: 43%;
  text-align: center;
  z-index: 999;
  background-color: white;
  border-radius: 4px;
`;

export const DocumentPageIndexContainer = styled(Box)`
  position: absolute;
  border-radius: 8px;
  height: 55px;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 999;
  text-align: center;
`;

import { RewardPointsProgressBar } from '@/components/progressMeter/RewardPoints/components';
import useAppSelector from '@/hooks/useAppSelector';
import { myRewardPointsSelector } from '@/redux/selectors/activitySelector';
import { adminUserRewardPointsSelector } from '@/redux/selectors/users';
import { IRewardPoints } from '@/types/models';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface IRewardPointsProgressSliderProps {
  userId?: number;
}

const RewardPointsProgressSlider: React.FC<
  IRewardPointsProgressSliderProps
> = ({ userId }) => {
  const rewardPoints = useAppSelector(myRewardPointsSelector);
  const adminUserRewardPoints = useAppSelector(adminUserRewardPointsSelector);

  const [points, setPoints] = useState<IRewardPoints | null>(null);

  useEffect(() => {
    setPoints(userId ? adminUserRewardPoints : rewardPoints);
  }, [rewardPoints, adminUserRewardPoints, userId]);

  return (
    <RewardPointsProgressBar
      valueLabelDisplay="on"
      value={Number(points?.earnedPoints) || 0}
      max={Number(points?.availablePoints) || 0}
      color="secondary"
      disabled={true}
      valueLabelFormat={(value) => {
        if (!userId) {
          return null;
        }
        return value.toLocaleString('en').length > 4 ||
          (points?.availablePoints &&
            points?.availablePoints.toLocaleString('en').length > 4) ? (
          <Box marginTop={2}>
            <Box>{Number(value).toLocaleString('en')} |</Box>
            <Box>
              {Number(points?.availablePoints).toLocaleString('en') || 0}
            </Box>
          </Box>
        ) : (
          `${Number(value).toLocaleString('en')} | ${
            points?.availablePoints
              ? Number(points?.availablePoints).toLocaleString('en')
              : 0
          }`
        );
      }}
    />
  );
};

export default RewardPointsProgressSlider;

import { IClassNameProp } from '@/components/icons/breaktimers/CustomComponents/constants';
import React from 'react';

const IconInactive: React.FC<IClassNameProp> = ({
  className,
}: IClassNameProp) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle opacity="0.3" cx="10" cy="10" r="10" fill="#8F92B9" />
      <path
        d="M12.5 9.13397C13.1667 9.51888 13.1667 10.4811 12.5 10.866L9.5 12.5981C8.83333 12.983 8 12.5019 8 11.7321L8 8.26795C8 7.49815 8.83333 7.01702 9.5 7.40192L12.5 9.13397Z"
        fill="white"
      />
    </svg>
  );
};

export default IconInactive;

import CompaniesTable from '@/components/admin/company/CompaniesTable';
import { default as CompanyCreateDrawer } from '@/components/admin/company/CreateCompanyDrawer';
import SearchBar from '@/components/admin/company/SearchBar';
import SearchToolsTabs from '@/components/admin/SearchToolsTabs';
import { CreateCompanyIcon } from '@/components/icons';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAuth from '@/hooks/useAuth';
import { toggleDrawer } from '@/redux/reducers/componentsManage';
import { EUserRole } from '@/types/consts';

import { Box, Button as MuiButton, Container, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const Main = styled('main')`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CompaniesGrid = styled(Box)`
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
  align-items: center;
`;

const CompaniesTableSection = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  flex: 1;
`;

const CreateCompanyButton = styled(MuiButton)`
  background-color: unset;
  color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;

const ButtonText = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

const CreateCompanyButtonComponent = () => {
  const dispatch = useAppDispatch();

  return (
    <CreateCompanyButton onClick={() => dispatch(toggleDrawer(true))}>
      <ButtonText>Create Company</ButtonText>
      <CreateCompanyIcon />
    </CreateCompanyButton>
  );
};

const Companies = () => {
  const { userRole } = useAuth();

  return (
    <Main>
      <CompaniesGrid>
        {userRole === EUserRole.SUPER_ADMIN ? (
          <CreateCompanyButtonComponent />
        ) : (
          <Box />
        )}
        {userRole === EUserRole.SUPER_ADMIN && <CompanyCreateDrawer />}
        <SearchToolsTabs />
      </CompaniesGrid>
      <SearchBar />
      <CompaniesTableSection>
        <Container maxWidth="xl" sx={{ width: '95%' }}>
          <CompaniesTable />
        </Container>
      </CompaniesTableSection>
    </Main>
  );
};

export default Companies;

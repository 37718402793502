import { EUserRole } from '@/types/consts';

export const getRoutePrefixByRoleName = (roleName?: string) => {
  if (roleName === EUserRole.TEAMLEAD) {
    return ``;
  }

  if (roleName === EUserRole.SUPER_ADMIN || roleName === EUserRole.RW_ADMIN) {
    return `/admin`;
  }

  return ``;
};

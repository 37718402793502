import { setupResponseInterceptor } from '@/redux/middlewares/api';
import { store } from '@/redux/store';
import routes from '@/routes';
import theme from '@/theme/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { ThemeProvider } from 'styled-components';
import AuthProvider from './layouts/AuthProvider';
import { setupChatResponseInterceptor } from './redux/middlewares/cometchat';
import WebSocketProvider from './sokets/WebSocket';

setupResponseInterceptor(store);
setupChatResponseInterceptor();

function App() {
  const content = useRoutes(routes);

  return (
    <Provider store={store}>
      <WebSocketProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <ToastContainer
                position={'top-center'}
                autoClose={false}
                hideProgressBar
              />
              {content}
            </ThemeProvider>
          </AuthProvider>
        </LocalizationProvider>
      </WebSocketProvider>
    </Provider>
  );
}

export default App;

import { IUser } from '@/types/models';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import { useSelect } from '@mui/base';
import { Avatar, Box } from '@mui/material';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ScrollbarComponent from '../scrollbar/Scrollbar';
import { AvatarType, CustomAvatar } from '../UI/CustomAvatar';

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const Root = styled('div')`
  position: relative;
  display: inline-block;
  vertical-align: baseline;
  color: #000;
`;

const Toggle = styled('div')`
  min-height: calc(1.5em + 22px);
  min-width: 320px;
  padding: 12px;
  border-radius: 12px;
  text-align: left;
  line-height: 1.5;
  border: 1px solid
    ${({ theme }) => (theme.palette.mode === 'dark' ? grey[700] : grey[200])};
  color: ${({ theme }) =>
    theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: var(
    --color,
    ${({ theme }) => (theme.palette.mode === 'dark' ? grey[900] : '#fff')}
  );
  margin: 0.5em;
  display: inline-flex;
  align-items: center;
  cursor: default;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  & .placeholder {
    opacity: 0.8;
  }
`;

const ListBoxContainer = styled(Box)`
  min-width: 320px;
  position: absolute;
  width: 100%;
  z-index: 1;
  height: auto;
  background: ${({ theme }) =>
    theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid
    ${({ theme }) => (theme.palette.mode === 'dark' ? grey[700] : grey[200])};
  overflow: hidden;
  border-radius: 12px;
  &.hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s 0.5s ease, visibility 0.4s 0.5s step-end;
  }
`;

const Listbox = styled('ul')`
  min-height: calc(1.5em + 22px);
  max-height: 50vh;
  padding: 12px;
  text-align: left;
  line-height: 1.5;
  color: ${({ theme }) =>
    theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  padding: 5px;
  margin: 5px 0 0 0;
  outline: 0px;
  list-style: none;

  & > li {
    padding: 8px;
    border-radius: 0.45em;

    &:hover {
      background: ${({ theme }) =>
        theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    }

    &[aria-selected='true'] {
      background: ${({ theme }) =>
        theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    }
  }
`;

interface Props {
  options: IUser[];
  defaultUser?: number;
  onUserSelect: (userId: number | null) => void;
  showDefaultUserAsCurrent?: boolean;
  currentUserTitle?: string;
}

const UserSelect: React.FC<Props> = ({
  options,
  defaultUser,
  onUserSelect,
  currentUserTitle,
  showDefaultUserAsCurrent,
}) => {
  const listboxRef = useRef<HTMLUListElement>(null);
  const [listboxVisible, setListboxVisible] = useState(false);

  const {
    getButtonProps,
    getListboxProps,
    getOptionProps,
    value: selectedUser,
  } = useSelect<IUser>({
    listboxRef,
    options: options.map((user) => ({
      value: user,
      label: user.firstName ? `${user.firstName} ${user.lastName}` : user.email,
    })),
    defaultValue: options?.find(({ id }) => id === defaultUser) || null,
  });

  useEffect(() => {
    if (listboxVisible) {
      listboxRef.current?.focus();
    }
  }, [listboxVisible]);

  useEffect(() => {
    onUserSelect(selectedUser?.id || null);
  }, [selectedUser, onUserSelect]);

  return (
    <Root
      onMouseOver={() => setListboxVisible(true)}
      onMouseOut={() => setListboxVisible(false)}
      onFocus={() => setListboxVisible(true)}
      onBlur={() => setListboxVisible(false)}
    >
      <Toggle {...getButtonProps()}>
        {selectedUser ? (
          <>
            <CustomAvatar
              diameter={24}
              avatartype={AvatarType.USER}
              sx={{
                mr: 2,
              }}
              alt="User avatar"
              src={selectedUser.profileImage || '/static/images/avatar/1.jpg'}
            >
              {getUserAvatarLetter({
                firstName: selectedUser.firstName,
                lastName: selectedUser.lastName,
                email: selectedUser.email,
              })}
            </CustomAvatar>
            {showDefaultUserAsCurrent && selectedUser.id === defaultUser
              ? currentUserTitle
              : selectedUser.firstName
              ? `${selectedUser.firstName} ${selectedUser.lastName}`
              : selectedUser.email}
          </>
        ) : (
          <span className="placeholder">Select User</span>
        )}
      </Toggle>
      <ListBoxContainer className={listboxVisible ? '' : 'hidden'}>
        <ScrollbarComponent>
          <Listbox {...getListboxProps()}>
            {options.map((user) => (
              <li
                key={user.id}
                {...getOptionProps({
                  label: user.firstName
                    ? `${user.firstName} ${user.lastName}`
                    : user.email,
                  value: user,
                })}
              >
                <Box display="flex">
                  <CustomAvatar
                    diameter={24}
                    sx={{
                      mr: 2,
                    }}
                    avatartype={AvatarType.USER}
                    alt="User avatar"
                    src={user.profileImage || '/static/images/avatar/1.jpg'}
                  >
                    {getUserAvatarLetter({
                      firstName: user.firstName,
                      lastName: user.lastName,
                      email: user.email,
                    })}
                  </CustomAvatar>
                  <Box display="flex" alignItems="center">
                    {showDefaultUserAsCurrent && user.id === defaultUser
                      ? currentUserTitle
                      : user.firstName
                      ? `${user.firstName} ${user.lastName}`
                      : user.email}
                  </Box>
                </Box>
              </li>
            ))}
          </Listbox>
        </ScrollbarComponent>
      </ListBoxContainer>
    </Root>
  );
};

export default UserSelect;

import { IActivityProgress } from '@/types/models';

export const getAvarageActivity = (progress: IActivityProgress[]) => {
  const avgProgress = progress.reduce(
    (result, { mental, social, physical }) => ({
      mental: result.mental + mental,
      social: result.social + social,
      physical: result.physical + physical,
    }),
    {
      mental: 0,
      social: 0,
      physical: 0,
    } as Omit<IActivityProgress, 'date'>
  );
  return {
    social: avgProgress ? Math.ceil(avgProgress.social / progress.length) : 0,
    mental: avgProgress ? Math.ceil(avgProgress.mental / progress.length) : 0,
    physical: avgProgress
      ? Math.ceil(avgProgress.physical / progress.length)
      : 0,
  };
};

import { BrandLogo } from '@/components/icons';
import ScrollbarComponent from '@/components/scrollbar/Scrollbar';
import { RwAdminAvatarContainer } from '@/components/sidebar/MessagingNav/components';
import { AvatarType, CustomAvatar } from '@/components/UI/CustomAvatar';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { setCurrentChat } from '@/redux/reducers/chat';
import {
  chatUserSelector,
  conversationsSelector,
  currentChatSelector,
  getCompanyChatSupport,
  getSelectedCompanyId,
  membersSelector,
} from '@/redux/selectors/chatSelector';
import { getMyTeam, getUserSelector } from '@/redux/selectors/userSelectors';
import { EChatStatus, EConversationType } from '@/types/consts';
import { IGroupChat, IPersonalChat, IUser, IUserChat } from '@/types/models';
import { idToUid, uidToId } from '@/utils/chatHelpers';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import { Box } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import {
  AvatarContainer,
  ChatItemBox,
  ConversationListContainer,
  InnerScrollContainer,
  PersonalChatInfo,
  StatusDot,
  TeamMemberText,
  UserName,
} from '../components';
import ChatUserItem from './ChatUserItem';
import CompanySelectWrapper from './CompanySelector/CompanySelectorWarapper';
import TeamChatListItem from './TeamChatListItem';
import moment from 'moment';

interface IConversationProps {
  isAdmin?: boolean;
}

const ConversationList: FC<IConversationProps> = ({ isAdmin }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUserSelector) as IUser;
  const rwAdmin = user?.companies[0]?.rwAdminId;
  const chatUser = useAppSelector(chatUserSelector);
  const chat = useAppSelector(currentChatSelector);
  const groupChats = useAppSelector(
    conversationsSelector(EConversationType.GROUP)
  );
  const personalChats = useAppSelector(
    conversationsSelector(EConversationType.PERSONAL)
  );
  const members = useAppSelector(membersSelector);
  const companyTeam = useAppSelector(getMyTeam);
  const companySupport = useAppSelector(getCompanyChatSupport);
  const selectedCompany = useAppSelector(getSelectedCompanyId);

  const chatSelect = useCallback(
    (chat: string, isGroupChat: boolean, user?: IUserChat) => {
      dispatch(
        setCurrentChat({
          isGroup: isGroupChat,
          chatId: chat,
          user,
        })
      );
    },
    []
  );

  useEffect(() => {
    if (isAdmin && !!groupChats?.length && selectedCompany) {
      const conversation = groupChats.find(
        ({ conversationId }) =>
          conversationId === `group_company-${selectedCompany}`
      );
      if (conversation) {
        const chatId = (conversation.conversationWith as IGroupChat).guid;
        dispatch(
          setCurrentChat({
            isGroup: true,
            chatId,
            user,
          })
        );
      }
    }
  }, [isAdmin, groupChats?.length, selectedCompany]);

  useEffect(() => {
    if (!!groupChats?.length && !chat && !isAdmin) {
      dispatch(
        setCurrentChat({
          isGroup: true,
          chatId: (groupChats[0].conversationWith as IGroupChat).guid,
          user,
        })
      );
    }
  }, [groupChats, chat, isAdmin]);

  const memberOnline = useMemo(() => {
    return members?.filter(({ status }) => status === EChatStatus.ONLINE);
  }, [members]);

  const rwAdminChat = useMemo(() => {
    return members?.filter(({ uid }) => uid === idToUid(rwAdmin));
  }, [members, rwAdmin]);

  const regularUsers = useMemo(() => {
    return members?.filter(
      ({ uid }) =>
        !companySupport.includes(uid) && (!rwAdmin || uid !== idToUid(rwAdmin))
    );
  }, [members, rwAdmin]);

  const usersSupportChat = useMemo(() => {
    return members?.filter(({ uid }) => companySupport.includes(uid));
  }, [companySupport, members]);

  const sortedChat = useMemo(() => {
    if (!regularUsers) {
      return null;
    }
    return regularUsers
      ?.map((user: IUserChat) => {
        const conversation = personalChats?.find(
          ({ conversationWith }) =>
            (conversationWith as IPersonalChat).uid === user.uid
        );
        return {
          user,
          conversation,
        };
      })
      .sort((itemA, itemB) => {
        if (
          !itemA.conversation?.lastMessage &&
          !itemB.conversation?.lastMessage
        ) {
          if (itemA.user.status === itemB.user.status) {
            return itemA.user.name.localeCompare(itemB.user.name);
          } else {
            return itemB.user.status === EChatStatus.ONLINE ? 1 : -1;
          }
        }
        if (!itemA.conversation?.lastMessage) {
          return 1;
        }
        if (!itemB.conversation?.lastMessage) {
          return -1;
        }
        const lastActivityA = moment.unix(
          itemA.conversation?.lastMessage.sentAt
        );
        const lastActivityB = moment.unix(
          itemB.conversation?.lastMessage.sentAt
        );
        return lastActivityA.isBefore(lastActivityB) ? 1 : -1;
      });
  }, [regularUsers, personalChats]);

  return (
    <ConversationListContainer>
      <Box display="flex" alignItems="center" mb="10px">
        <AvatarContainer>
          <CustomAvatar
            diameter={46}
            avatartype={AvatarType.USER}
            alt="Remy Sharp"
            src={user?.profileImage || undefined}
          >
            {!user
              ? ''
              : getUserAvatarLetter({
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: user.email,
                })}
          </CustomAvatar>
          <StatusDot
            isOnline={chatUser?.status === EChatStatus.ONLINE}
          ></StatusDot>
        </AvatarContainer>
        <PersonalChatInfo>
          <UserName>
            {user?.firstName
              ? `${user?.firstName} ${user.lastName}`
              : user.email}
          </UserName>
        </PersonalChatInfo>
      </Box>
      {isAdmin && <CompanySelectWrapper />}
      <InnerScrollContainer isAdmin={isAdmin}>
        <ScrollbarComponent>
          {rwAdmin && (
            <>
              <Box
                marginLeft="11px"
                marginTop="15px"
                marginBottom="15px"
                display="flex"
              >
                <RwAdminAvatarContainer>
                  <BrandLogo />
                </RwAdminAvatarContainer>
                <Box marginLeft="11px" fontWeight="500">
                  RemoteWorx
                </Box>
              </Box>
              {rwAdminChat?.map((user: IUserChat) => {
                //
                const conversation = personalChats?.find(
                  ({ conversationWith }) =>
                    (conversationWith as IPersonalChat).uid === user.uid
                );
                return (
                  <ChatItemBox
                    key={user.uid}
                    onClick={() =>
                      chat?.user?.uid !== user.uid &&
                      chatSelect(user.uid, false, user)
                    }
                    isactive={chat?.user?.uid === user.uid || false}
                  >
                    <ChatUserItem
                      user={
                        companyTeam?.find(
                          ({ id }) => uidToId(user.uid) === id
                        ) as IUser
                      }
                      isOnline={user?.status === EChatStatus.ONLINE}
                      lastMessage={conversation?.lastMessage || undefined}
                      unread={
                        Number(conversation?.unreadMessageCount) || undefined
                      }
                    />
                  </ChatItemBox>
                );
              })}
              {usersSupportChat?.map((user: IUserChat) => {
                const conversation = personalChats?.find(
                  ({ conversationWith }) =>
                    (conversationWith as IPersonalChat).uid === user.uid
                );
                return (
                  <ChatItemBox
                    key={user.uid}
                    onClick={() =>
                      chat?.user?.uid !== user.uid &&
                      chatSelect(user.uid, false, user)
                    }
                    isactive={chat?.user?.uid === user.uid || false}
                  >
                    <ChatUserItem
                      user={
                        companyTeam?.find(
                          ({ id }) => uidToId(user.uid) === id
                        ) as IUser
                      }
                      isOnline={user?.status === EChatStatus.ONLINE}
                      lastMessage={conversation?.lastMessage || undefined}
                      unread={
                        Number(conversation?.unreadMessageCount) || undefined
                      }
                    />
                  </ChatItemBox>
                );
              })}
            </>
          )}
          {groupChats && (
            <>
              <TeamMemberText>Team Channel</TeamMemberText>
              {groupChats
                ?.filter(({ conversationId }) =>
                  conversationId.includes(
                    `company-${selectedCompany || user?.companies[0]?.id}`
                  )
                )
                .map((conversation) => (
                  <div
                    key={conversation.conversationId}
                    onClick={() =>
                      chat?.chatId !==
                        (conversation.conversationWith as IGroupChat).guid &&
                      chatSelect(
                        `${(conversation.conversationWith as IGroupChat).guid}`,
                        true
                      )
                    }
                  >
                    <TeamChatListItem
                      groupChat={conversation.conversationWith as IGroupChat}
                      unreadMessages={+conversation.unreadMessageCount}
                    />
                  </div>
                ))}
            </>
          )}
          <TeamMemberText>Team Members ({members?.length})</TeamMemberText>
          {/* <TeamMemberText>
            Active {memberOnline?.length ? `(${memberOnline.length})` : ''}
          </TeamMemberText> */}
          {companyTeam &&
            sortedChat?.map(({ user, conversation }) => {
              return (
                <ChatItemBox
                  key={user.uid}
                  onClick={() =>
                    chat?.user?.uid !== user.uid &&
                    chatSelect(user.uid, false, user)
                  }
                  isactive={chat?.user?.uid === user.uid || false}
                >
                  <ChatUserItem
                    user={
                      companyTeam?.find(
                        ({ id }) => uidToId(user.uid) === id
                      ) as IUser
                    }
                    isOnline={user?.status === EChatStatus.ONLINE}
                    lastMessage={conversation?.lastMessage || undefined}
                    unread={
                      Number(conversation?.unreadMessageCount) || undefined
                    }
                  />
                </ChatItemBox>
              );
            })}
        </ScrollbarComponent>
      </InnerScrollContainer>
    </ConversationListContainer>
  );
};

export default ConversationList;

import React, { FC } from 'react';
import {
  BonusBreakerText,
  BonusBreakTimerBlock,
  BonusBreakTimerButton,
} from '@/components/bonusBreakTimer/components';
import {
  ButtonArrowOneIcon,
  ButtonArrowTwoIcon,
  CheckIcon,
} from '@/components/icons';
import { Box } from '@mui/material';
import useAppSelector from '@/hooks/useAppSelector';
import { getBonusBreakTimer } from '@/redux/selectors/breakTimersSelectors';
import {
  EActionsBreakTimerStatus,
  EExtraBrakeTimerTypes,
} from '@/components/breaktimer/constants';
import { completeExtraBreakTimer } from '@/redux/actions/breaktimers';
import useAppDispatch from '@/hooks/useAppDispatch';

const BonusBreakTimer: FC = () => {
  const dispatch = useAppDispatch();

  const bonus = useAppSelector(getBonusBreakTimer);
  const isShowBonusBreakTimer = !!bonus;
  const isBonusBreakTimerCompleted =
    bonus === EActionsBreakTimerStatus.COMPLETED;

  const handleComplete = async () => {
    await dispatch(completeExtraBreakTimer(EExtraBrakeTimerTypes.BONUS));
  };
  return (
    <BonusBreakTimerBlock show={isShowBonusBreakTimer}>
      {isShowBonusBreakTimer && (
        <>
          {isBonusBreakTimerCompleted ? (
            <BonusBreakerText>
              <CheckIcon />
              <BonusBreakerText>Bonus Break Completed!</BonusBreakerText>
            </BonusBreakerText>
          ) : (
            <>
              <BonusBreakerText>Missed a break? Take it now:</BonusBreakerText>
              <BonusBreakTimerButton onClick={handleComplete}>
                <Box sx={{ width: 100 }}>Bonus Break</Box>
                <ButtonArrowOneIcon className={'icon-hover icon-one'} />
                <ButtonArrowTwoIcon className={'icon-hover icon-two'} />
              </BonusBreakTimerButton>
            </>
          )}
        </>
      )}
    </BonusBreakTimerBlock>
  );
};

export default BonusBreakTimer;

interface inGetUserAvatarLetter {
  firstName?: string;
  lastName?: string;
  email: string;
}
export const getUserAvatarLetter = ({
  firstName,
  email,
  lastName,
}: inGetUserAvatarLetter): string => {
  if (firstName && lastName) {
    return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
  }
  if (firstName) {
    return firstName[0].toUpperCase();
  }
  return email[0];
};

import { AvatarType, CustomAvatar } from '@/components/UI/CustomAvatar';
import { currentChatSelector } from '@/redux/selectors/chatSelector';
import { IGroupChat } from '@/types/models';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  ChatName,
  LastMessage,
  MessageBlock,
  TeamChat,
  TeamChatInfo,
  UnreadBadge,
} from '../components';
import { TeamIcon } from '@/components/icons';

interface ITeamChatItemProps {
  groupChat: IGroupChat;
  unreadMessages?: number;
}

const TeamChatListItem: FC<ITeamChatItemProps> = ({
  groupChat,
  unreadMessages,
}) => {
  const chat = useSelector(currentChatSelector);

  return (
    <TeamChat
      bgcolor={chat?.chatId === groupChat.guid ? '#0099ff19' : 'transparent'}
    >
      <CustomAvatar
        diameter={24}
        avatartype={AvatarType.COMPANY}
        sx={{
          fontSize: 16,
        }}
        alt={groupChat.name}
        src={(groupChat as IGroupChat).icon || undefined}
      >
        <TeamIcon />
      </CustomAvatar>
      <TeamChatInfo>
        <ChatName>{groupChat.name}</ChatName>
        <MessageBlock>
          <LastMessage unread={false}>Team Channel</LastMessage>
          {!!unreadMessages && <UnreadBadge>{unreadMessages}</UnreadBadge>}
        </MessageBlock>
      </TeamChatInfo>
    </TeamChat>
  );
};

export default TeamChatListItem;

import { CompanyPointOutlinedField } from '@/components/admin/company/components';
import { NumberFormatCustom } from '@/components/form-elements/NumberInput';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { updateCompanyRewardPoints } from '@/redux/actions/company';
import { toggleModal } from '@/redux/reducers/componentsManage';
import {
  getCompany,
  getCompanyAvailablePoints,
} from '@/redux/selectors/companySelectors';
import { DEFAULT_TOAST_TIME } from '@/types/consts';
import { FormHelperText, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import { ConfirmButton, CancelButton } from '@/components/buttons/components';

const EditTotalPointsRewardForm = () => {
  const dispatch = useAppDispatch();
  const availablePoints = useAppSelector(getCompanyAvailablePoints);
  const company = useAppSelector(getCompany);

  const handleOnSubmit = async (values: any) => {
    if (!company) {
      return;
    }
    try {
      await dispatch(
        updateCompanyRewardPoints({
          amount: values.totalPointsReward,
          companyId: company?.id,
        })
      ).unwrap();

      toast.success('Available points were successfully updated!', {
        position: 'top-right',
        autoClose: DEFAULT_TOAST_TIME,
        hideProgressBar: false,
      });

      handleClose();
    } catch (err: any) {
      toast.error(err, {
        position: 'top-right',
      });
    }
  };

  const handleClose = () => {
    dispatch(toggleModal(false));
  };

  return (
    <Formik
      initialValues={{
        totalPointsReward: availablePoints,
      }}
      onSubmit={handleOnSubmit}
    >
      {({ errors, handleBlur, handleChange, touched, values }) => {
        return (
          <Form>
            <Grid container justifyContent={'center'}>
              <Grid item md={4} mt={4}>
                <CompanyPointOutlinedField
                  name={'totalPointsReward'}
                  placeholder={'Total Points'}
                  value={values.totalPointsReward}
                  error={Boolean(
                    touched.totalPointsReward && errors.totalPointsReward
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={'number'}
                  inputComponent={NumberFormatCustom as any}
                />
                {touched.totalPointsReward && errors.totalPointsReward && (
                  <FormHelperText id={'totalPointsReward'} error>
                    {errors.totalPointsReward}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={'center'}
              alignItems={'center'}
              flexWrap={'nowrap'}
              columnGap={4}
              mt={8}
            >
              <Grid item md={6} container justifyContent={'flex-end'}>
                <ConfirmButton fullWidth type={'submit'}>
                  Confirm
                </ConfirmButton>
              </Grid>
              <Grid item md={6}>
                <CancelButton fullWidth onClick={handleClose}>
                  Cancel
                </CancelButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditTotalPointsRewardForm;

import { createSelector } from '@reduxjs/toolkit';
import { ComponentsManageState } from '../reducers/componentsManage';
import { RootState } from '../store';

export const selectState = createSelector(
  (state: RootState) => state.componentsManage,
  ({ open }: ComponentsManageState) => open
);

export const selectStateDrawer = createSelector(
  (state: RootState) => state.componentsManage,
  ({ drawerOpen }: ComponentsManageState) => drawerOpen
);

export const selectStateModal = createSelector(
  (state: RootState) => state.componentsManage,
  ({ modalOpen }: ComponentsManageState) => modalOpen
);

export const selectStateFlexModal = createSelector(
  (state: RootState) => state.componentsManage,
  ({ modalFlexOpen }: ComponentsManageState) => modalFlexOpen
);

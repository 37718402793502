export enum EActivitiesActions {
  LOG_IN = 'log_in',
  HYDRATE = 'hydrate',
  RELAX = 'relax',
  STRETCH = 'stretch',
  MOVE = 'move',
  COMPLETE_GUIDE = 'complete_guide',
  COMPLETE_VIDEO = 'complete_video',
  COMPLETE_MODULE = 'complete_module',
  PERSONAL_MESSAGING = 'posting_replying',
  TEAM_MESSAGING = 'respond_dayli_reminder',
  COMPLETE_WORKSPACE_LEVEL = 'workspace_complete_level',
  WORKSPACE_SET_ITEM = 'workspace_set_item',
  BONUS = 'bonus',
}

type IActionNames = {
  [key in EActivitiesActions]: string;
};

export const actionNames: IActionNames = {
  [EActivitiesActions.LOG_IN]: 'Daily Login',
  [EActivitiesActions.HYDRATE]: 'Hydrate Break - Completed',
  [EActivitiesActions.RELAX]: 'Relax Break - Completed',
  [EActivitiesActions.STRETCH]: 'Stretch Break - Completed',
  [EActivitiesActions.MOVE]: 'Move Break - Completed',
  [EActivitiesActions.BONUS]: 'Bonus Break',
  [EActivitiesActions.COMPLETE_GUIDE]: 'Knowledge Base - Guide Read',
  [EActivitiesActions.COMPLETE_VIDEO]: 'Knowledge Base - Video Watched',
  [EActivitiesActions.COMPLETE_MODULE]:
    'Knowledge Base - Learning Module Completed',
  [EActivitiesActions.PERSONAL_MESSAGING]: '1-to-1 Direct Message',
  [EActivitiesActions.TEAM_MESSAGING]: 'Daily Team Message Reply',
  [EActivitiesActions.COMPLETE_WORKSPACE_LEVEL]:
    'My Workspace - New Level Achieved',
  [EActivitiesActions.WORKSPACE_SET_ITEM]: 'My Workspace - New Item Added',
};

import DataGrid from '@/components/UI/DataGrid';
import { ChewronLeftIcon, ChewronRightIcon } from '@/components/icons';
import {
  PeriodIconButton,
  PeriodLabel,
  PeriodSwitcher,
} from '@/components/progressMeter/PersonalActivity/components';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAuth from '@/hooks/useAuth';
import useCompany from '@/hooks/useCompany';
import { getCompanyPeriodProgress } from '@/redux/actions/company';
import { EPeriod } from '@/types/consts';
import { IProgressTableRow } from '@/types/models';
import {
  getEndOfMonth,
  getEndOfWeek,
  getStartOfMonth,
  getStartOfWeek,
} from '@/utils/dateHelpers';
import { getRoutePrefixByRoleName } from '@/utils/routeRoleHelpers';
import { progressPeriodRows } from '@/utils/tableHelpers';
import { Box } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import { format, getDaysInMonth, subMonths, subWeeks } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { progresColumns } from './constants';

const MeterPeriodProgress = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { company } = useCompany();
  const [rows, setRows] = useState<IProgressTableRow[] | null>(null);
  const { userRole } = useAuth();
  const { period } = useParams();
  const periodWeek = EPeriod.WEEK.toLowerCase();
  const [periodStep, setPeriodStep] = useState<number>(0);
  const [interval, setPeriodInterval] = useState<Interval | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setPeriodStep(0);
    setRows(null);
  }, [period]);

  useEffect(() => {
    const start =
      period === periodWeek
        ? getStartOfWeek(subWeeks(new Date(), periodStep))
        : getStartOfMonth(subMonths(new Date(), periodStep));
    const end =
      period === periodWeek
        ? getEndOfWeek(subWeeks(new Date(), periodStep))
        : getEndOfMonth(subMonths(new Date(), periodStep));

    setPeriodInterval({
      start,
      end,
    });
    setLoading(true);
  }, [periodStep, period]);

  useEffect(() => {
    if (company && interval) {
      const params = {
        companyId: company.id,
        from: format(interval.start, 'yyyy-MM-dd'),
        to: format(interval.end, 'yyyy-MM-dd'),
        divide: period === periodWeek ? 7 : getDaysInMonth(interval.start),
      };

      dispatch(getCompanyPeriodProgress(params))
        .unwrap()
        .then((res) => {
          if (res) {
            setRows(progressPeriodRows(res));
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  }, [dispatch, company, period, interval]);

  const handleRowClick = (params: GridRowParams) => {
    navigate(
      getRoutePrefixByRoleName(userRole) + `/user/${params.id}/information`
    );
  };

  const swictherLabel = useMemo(() => {
    if (!periodStep || !interval) {
      return `Current ${period}`;
    }
    const startDay = format(interval.start as Date, 'MM/dd');
    const endDay = format(interval.end as Date, 'MM/dd');
    return period === periodWeek
      ? `${startDay} - ${endDay}`
      : format(interval?.end as Date, 'MMMM');
  }, [periodStep, period, interval]);

  if (!rows) {
    return null;
  }

  return (
    <Box>
      {interval && (
        <Box my="15px" mb="45px">
          <PeriodSwitcher>
            <PeriodIconButton
              aria-label="prev"
              onClick={() => setPeriodStep((step) => step + 1)}
            >
              <ChewronLeftIcon />
            </PeriodIconButton>
            <PeriodLabel>{swictherLabel}</PeriodLabel>
            <PeriodIconButton
              aria-label="next"
              disabled={!periodStep}
              onClick={() => setPeriodStep((step) => step - 1)}
            >
              <ChewronRightIcon />
            </PeriodIconButton>
          </PeriodSwitcher>
        </Box>
      )}
      <Box>
        <DataGrid
          columns={progresColumns}
          loading={loading}
          rows={rows}
          pageSize={50}
          rowsPerPageOptions={[50]}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default MeterPeriodProgress;

import { CalendarIcon } from '@/components/icons';
import { Box, Button, FormControl, IconButton } from '@mui/material';
import styled from 'styled-components';

interface FormControlAdditionalProps {
  hiddenLabel?: boolean;
  error?: boolean;
}

export const FormControlWrapper = styled(
  FormControl
)<FormControlAdditionalProps>`
  margin-top: ${({ hiddenLabel }) => (hiddenLabel ? '23px' : 0)};
  margin-bottom: ${({ error }) => (error ? '3px' : '16px')};
  min-width: 360px;
`;

export const IconButtonWrapper = styled(IconButton)`
  margin-bottom: 3px;
  margin-right: 10px;
`;

export const CalendarIconWrapper = styled(CalendarIcon)`
  margin-right: 13px;
`;

export const IconWrapper = styled(IconButton)`
  padding-right: 5px;
`;

export const Placeholder = styled('em')`
  color: ${({ theme }) => theme.palette.selector.light};
  font-style: italic;
  opacity: 0.42;
`;

export const FormTitle = styled.h1`
  font-weight: 500;
  font-size: 20px;
  margin-top: 28px;
  margin-bottom: 10px;
`;

export const TitleBox = styled(Box)`
  font-weight: 500;
  font-size: 20px;
  margin: 28px 0;
`;

export const SubSecondaryButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.primary.light};
`;

export const CountButton = styled(SubSecondaryButton)`
  min-width: 24px;
  width: 24px;
  height: 24px;
  font-weight: 600;
  font-size: 24px;
  &:not(:last-child) {
    margin-right: 4px;
  }
  &:hover {
    color: ${({ theme }) => theme.palette.neutral.main};
  }
`;

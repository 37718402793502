import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import { getCompany } from '@/redux/actions/company';
import { getCountries } from '@/redux/actions/country';
import { getStates } from '@/redux/actions/state';
import { getCompanyLoading } from '@/redux/selectors/companySelectors';
import { CircularProgress, Grid } from '@mui/material';
import React, { useEffect } from 'react';

const MyCompanyPageWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  const companyLoading = useAppSelector(getCompanyLoading);

  useEffect(() => {
    user && dispatch(getCompany(user.companies[0].id));
    user && dispatch(getStates());
    user && dispatch(getCountries());
  }, [dispatch, user]);

  if (companyLoading) {
    return (
      <Grid
        height={'100%'}
        container
        justifyContent={'center'}
        alignItems={'center'}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return <>{children}</>;
};

export default MyCompanyPageWrapper;

import { Box } from '@mui/material';
import styled from 'styled-components';

export const KnowledgeBaseSmallPreviewBlock = styled(Box)`
  width: 100%;
  aspect-ratio: 16 / 9;
  border: 1px solid #e2e8ed;
  border-radius: 8px;

  .spinner {
    position: absolute;
    margin-left: 38%;
    margin-top: 20%;
  }

  .button-centered {
    position: absolute;
    right: 150px;
    top: 220px;
    background-color: ${(props) => props.theme.palette.background.default};
    z-index: 10000;
  }

  .player {
    z-index: 1;

    .media-controls.inline > .controls-bar button {
      display: none !important;
    }
  }
`;

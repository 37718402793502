import { getAvarageActivity } from '@/utils/arrayHelpers';
import { createSelector } from '@reduxjs/toolkit';
import { ActivityState } from '../reducers/activity';
import { RootState } from '../store';

export const myProgressSelector = createSelector(
  (state: RootState) => state.activity,
  ({ progress }: ActivityState) =>
    progress
      ? getAvarageActivity(progress)
      : { mental: 0, social: 0, physical: 0 }
);

export const myStatisticSelector = createSelector(
  (state: RootState) => state.activity,
  ({ progressStatistic }: ActivityState) => progressStatistic
);

export const myActivitySelector = createSelector(
  (state: RootState) => state.activity,
  ({ activitiesLog }: ActivityState) => activitiesLog
);

export const myRewardPointsLogSelector = createSelector(
  (state: RootState) => state.activity,
  ({ rewardPointsLog }: ActivityState) => rewardPointsLog
);

export const myRewardPointsSelector = createSelector(
  (state: RootState) => state.activity,
  ({ rewardPoints }: ActivityState) => rewardPoints
);

export const myDailyStreak = createSelector(
  (state: RootState) => state.activity,
  ({ dailyStreak }: ActivityState) => dailyStreak
);

export const getLoadingActivity = createSelector(
  (state: RootState) => state.activity,
  ({ loading }: ActivityState) => loading
);

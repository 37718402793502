import {
  createCountry,
  deleteCountry,
  getCountries,
  getCountry,
  updateCountry,
} from '@/redux/actions/country';
import { ICountry } from '@/types/models';
import { createSlice } from '@reduxjs/toolkit';

export interface CountryState {
  loading: boolean;
  countries: ICountry[];
  country: ICountry | null;
  searchText: string;
}

const initialState: CountryState = {
  loading: false,
  countries: [],
  country: null,
  searchText: '',
};

export const { actions, reducer } = createSlice({
  name: 'country',
  initialState,
  reducers: {
    resetCountryState: () => ({
      ...initialState,
      loading: false,
    }),
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCountry.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountry.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCountry.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCountry.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountries.fulfilled, (state, { payload }) => {
        state.countries = payload as ICountry[];
        state.loading = false;
      })
      .addCase(createCountry.fulfilled, (state, { payload }) => {
        state.country = payload as ICountry;
        state.loading = false;
      })
      .addCase(getCountry.fulfilled, (state, { payload }) => {
        state.country = payload as ICountry;
        state.loading = false;
      })
      .addCase(updateCountry.fulfilled, (state, { payload }) => {
        state.country = payload as ICountry;
        state.loading = false;
      })
      .addCase(deleteCountry.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export const { setLoading, resetCountryState, setSearchText } = actions;

export default reducer;

import CircularProgressWithLabel from '@/components/knowledgeBase/LoaderWithPercents';
import {
  DocumentPageIndexContainer,
  KnowledgeBaseSmallPreviewBlock,
  PageIndexContainer,
  ScrollBox,
} from '@/components/knowledgeBase/PdfContainer/components';
import { Box, Switch, Grid, Typography } from '@mui/material';
import { FC, useEffect, useCallback, useMemo, useState } from 'react';
// @ts-ignore
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfContainer: FC<{
  fileUrl: string;
  finishReading: () => void;
  alreadyCompleted: boolean;
}> = ({ fileUrl, alreadyCompleted, finishReading }) => {
  const [numPage, setNumPage] = useState(null);
  const [currPage, setCurrPage] = useState(1);
  const [progress, setProgress] = useState(2);
  const [smallView, setSmallView] = useState(true);
  const [scrollHeight, setScrollHeight] = useState(window.innerHeight - 260);

  useEffect(() => {
    const handleResize = () => {
      setScrollHeight(window.innerHeight - 260);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fileObjMemoized = useMemo(
    () => ({
      url: fileUrl,
    }),
    [fileUrl]
  );

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: any) => {
      setNumPage(numPages);

      const scrollableDiv = document.querySelector('.scrollable-div');
      if (scrollableDiv) {
        scrollableDiv.addEventListener('scroll', (e: any) => {
          const scrollHeight = e.target.scrollHeight - e.target.offsetHeight;
          const onePageScrollHeight = Math.trunc(scrollHeight / numPages);
          const currPage = Math.trunc(e.target.scrollTop / onePageScrollHeight);
          setCurrPage((prev) => {
            if (prev !== currPage + 1 && currPage >= 0 && currPage < numPages)
              return currPage + 1;
            return prev;
          });
        });
      }

      if (alreadyCompleted || !numPages) {
        return () => scrollableDiv?.removeEventListener('scroll', () => {});
      }

      const timeout = setTimeout(function () {
        finishReading();
      }, numPages * 30 * 1000);

      return () => {
        clearInterval(timeout);
        scrollableDiv?.removeEventListener('scroll', () => {});
      };
    },
    [alreadyCompleted, numPage]
  );

  const memoizedPages = useMemo(() => {
    return Array.from(new Array(numPage), (el, index) => (
      <Box sx={{ position: 'relative' }}>
        <PageIndexContainer>
          {`Page ${index + 1}/${numPage}`}
        </PageIndexContainer>
        <Page
          title={`Page ${index + 1}/${numPage}`}
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          renderTextLayer={false}
          scale={2}
        />
      </Box>
    ));
  }, [numPage, smallView]);

  return (
    <Box>
      <KnowledgeBaseSmallPreviewBlock smallView={smallView}>
        {numPage && (
          <DocumentPageIndexContainer>
            <Grid container spacing={1} width="100%" margin={0}>
              <Grid
                item
                xs={smallView ? 4 : 3}
                height={50}
                display="flex"
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Typography>Page View</Typography>
                <Switch
                  onChange={(event, checked) => setSmallView(checked)}
                  checked={smallView}
                />
              </Grid>
              <Grid
                item
                xs={smallView ? 4 : 5}
                height={50}
                display="flex"
                alignItems={'center'}
                textAlign={'center'}
                justifyContent={'center'}
              >
                <Typography fontWeight="bold">{`Page ${currPage}/${numPage}`}</Typography>
              </Grid>
            </Grid>
          </DocumentPageIndexContainer>
        )}
        <ScrollBox
          className="scrollable-div"
          sx={{ overflow: 'auto', height: scrollHeight, minHeight: 560 }}
        >
          <Document
            file={fileObjMemoized}
            externalLinkTarget="_blank"
            error={<CircularProgressWithLabel value={progress} />}
            noData={<CircularProgressWithLabel value={progress} />}
            onLoadProgress={(props: any) => {
              const percent = Number(
                Math.round((props.loaded / props.total) * 100)
              );
              if (percent % 10 === 0 && percent !== 0) {
                setProgress(Number(percent));
              }
            }}
            loading={<CircularProgressWithLabel value={progress} />}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {memoizedPages}
          </Document>
        </ScrollBox>
      </KnowledgeBaseSmallPreviewBlock>
    </Box>
  );
};

export default PdfContainer;

import useAppSelector from '@/hooks/useAppSelector';
import {
  currentChatSelector,
  pinnedMessageSelector,
} from '@/redux/selectors/chatSelector';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { ChatContainer } from '../components';
import ChatHeader from './ChatHeader';
import ChatMessagesList, { IChatMessageListMethods } from './ChatMessagesList';
import SendMessage from './SendMessage';
import PinnedMessage from './PinnedMessage';
import useAuth from '@/hooks/useAuth';
import { IUser } from '@/types/models';
import { ELocalStoreKeys, EUserRole } from '@/types/consts';
import { useLocalStorage } from 'usehooks-ts';

interface IChatProps {
  hideHeader?: boolean;
  isWidget?: boolean;
  isScrolled: boolean;
  setIsScrolled?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Chat: FC<IChatProps> = ({
  hideHeader,
  isWidget = false,
  isScrolled,
  setIsScrolled,
}) => {
  const user = useAuth().user as IUser;
  const chat = useAppSelector(currentChatSelector);
  const pinnedMessage = useAppSelector(pinnedMessageSelector);
  const chatMessageListRef = useRef<IChatMessageListMethods>(null);
  const [plusHeight, setPlusHeight] = useState(0);

  const [savedPinnedMsg] = useLocalStorage<string | undefined>(
    ELocalStoreKeys.PINNED_ID,
    undefined
  );

  const isPinnedViewed = useMemo(
    () =>
      user.role.name !== EUserRole.RW_ADMIN &&
      pinnedMessage?.id &&
      savedPinnedMsg &&
      +savedPinnedMsg === +pinnedMessage.id,
    [user.role.name, pinnedMessage?.id, savedPinnedMsg]
  );

  const toPinnedMessage = useCallback(async () => {
    setIsScrolled && (await setIsScrolled(true));
    chatMessageListRef.current?.scrollToPinned();
  }, [setIsScrolled]);

  return (
    <ChatContainer>
      {chat && (
        <>
          {!hideHeader && <ChatHeader />}
          {pinnedMessage &&
            pinnedMessage.chatId === chat.chatId &&
            !isPinnedViewed && (
              <PinnedMessage onPinnedMessageClick={toPinnedMessage} />
            )}
          <ChatMessagesList
            plusHeight={plusHeight}
            hideHeader={hideHeader}
            isScrolled={isScrolled}
            setIsScrolled={setIsScrolled}
            ref={chatMessageListRef}
          />
          <SendMessage
            setIsScrolled={setIsScrolled}
            isWidget={isWidget}
            plusHeight={plusHeight}
            setPlusHeight={setPlusHeight}
          />
        </>
      )}
    </ChatContainer>
  );
};

export default Chat;

import { EKnowledgeBaseItemTypes } from '@/components/knowledgeBase/models';

const knowledgeBase = {
  color: '#5D6081',
  [EKnowledgeBaseItemTypes.GUIDE]: {
    color: '#4FC74D',
  },
  [EKnowledgeBaseItemTypes.MODULE]: {
    color: '#2C9AE2',
  },
  [EKnowledgeBaseItemTypes.VIDEO]: {
    color: '#973BB7',
  },
};

export default knowledgeBase;

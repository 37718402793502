export const removeSpaces = (text: string) => {
  return text.trim().split(' ').join('');
};

export const formatPhoneNumber = (phone: string) => {
  const cleaned = removeSpaces(phone);

  //Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return null;
};

export const urlify = (text: string) => {
  const preparedText = text.replaceAll(/[\n\r]+/gm, (_) => '<br/>');
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return preparedText.replaceAll(urlRegex, (url) => {
    return '<a target="_blank" href="' + url + '">' + url + '</a>';
  });
};

import { Doughnut } from 'react-chartjs-2';
import styled from 'styled-components';

export const DoughnutTop = styled(Doughnut)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 108px;
  height: 64px;
`;

export const DoughnutContainer = styled.div`
  position: relative;
  width: 108px;
  height: 64px;
`;

export const DiagramTitle = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  text-align: center;
  font-weight: 500;
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const DiagramLabel = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 108px;
  text-align: center;
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 18px;
  font-weight: 500;
`;

export const ProgressContainer = styled.div`
  display: flex;
  & > div:not(:last-child) {
    margin-right: 43px;
  }
`;

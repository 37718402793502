export const agreementText = `
<h1>Medical Guidance Disclaimer</h1>
<h2>NO MEDICAL ADVICE IS PROVIDED BY THIS PLATFORM</h2>

<p>The content on the RemoteWorx Platform, including but not limited to text, pictures, photographs, and other materials, is provided solely for informational purposes. The platform's content is not meant to replace expert medical advice, diagnosis, or treatment. When you have any questions about a medical condition or treatment, as well as before starting a new health care routine, you should always consult your physician or other qualified health care provider. You should never ignore professional medical advice or put off seeking it because of something you have read on the RemoteWorx Platform.</p>
<p>You acknowledge and agree that RemoteWorx's platform is an employee wellbeing platform that encourages employees to develop habits that will contribute to their wellbeing. RemoteWorx does not provide health and therapy services and any information given by (or created by) RemoteWorx or its coaches, employees or experts is intended for general use, does not constitute medical advice or health therapy, and is not intended to diagnose, treat or prevent any disease/condition. RemoteWorx is not responsible for any liability arising from an employee's or Customer's reliance on or interpretation of any information received through RemoteWorx's platform as medical advice or health therapy.</p>

<h2>Terms and Conditions </h2>
<p>Last updated: February 2, 2024</p>
<p>Please read these terms and conditions carefully before using Our Website or Service.</p>

<h3>Interpretation and Definitions </h3>
<h3>Interpretation </h3>
<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
<h3>Definitions </h3>
<p>For the purposes of these Terms and Conditions:</p>
<p><b>Affiliate</b> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
<p><b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to RemoteWorx LLC.</p>
<p><b>Device</b> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
<p><b>Service</b> refers to the wellness programs We make available through Our Website.</p>
<p><b>Terms and Conditions</b> (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</p>
<p><b>Third-party Social Media Service</b> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</p>
<p><b>Website</b> refers to RemoteWorx, accessible from <a href="https://remoteworx.com/">https://remoteworx.com/</a></p>
<p><b>You</b> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>

<h3>Acknowledgment </h3>
<p>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</p>
<p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</p>
<p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</p>
<p>You represent that You are over the age of 18. The Company does not permit those under 18 to use the Service.</p>

<h3>Acceptable Use </h3>
<p>Access to Our Website shall be governed by, and provided in accordance with, the following policies:</p>
<p>Access and termination of access to Our Website may be granted or denied in Our sole discretion.</p>
<p>All access to Our Website shall be solely for the purpose of accessing Our Service.</p>

<p>All of Your activity on Our Website shall be subject to monitoring by us. You should not have an expectation of privacy with regard to Your activities on, or information sent to, Our Website.</p>

<p>You represent, warrant and agree that You will not do, nor attempt to do, any of the following:</p>

<ul>
    <li>upload, transmit or otherwise distribute any "time bomb," virus, drop dead device, malicious logic, worm, Trojan horse, trap or back door virus, spyware or other malware or other harmful computer code designed to interrupt, destroy or limit the use of any computer software or hardware, or collect, use or disseminate any message from or through any Device;</li>
    <li>reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for any underlying intellectual property used to provide Our Website or Services;</li>
    <li>access Our Website by any means other than through the interface provided by us;</li>
    <li>use the Website for any fraudulent, illegal or unauthorized purpose;</li>
    <li>infringe on the intellectual property rights of others, including copyrights, trademarks, and patents;</li>
    <li>share Your account credentials with unauthorized users;</li>
    <li>impersonate any other person or entity;</li>
    <li>spam or send unsolicited communications to other users.</li>
    <li>collect or harvest personal information from other users;</li>
    <li>use the Website in a way that is harmful to or offensive to others;</li>
    <li>override any security component of Our Website;</li>
    <li>do anything that could disable, overburden or impair the proper working of Our Website; or</li>
    <li>do anything that violates any local, state, national, or international laws.</li>
</ul>
<p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>

<b>Intellectual Property Rights</b>
<p>Our Website and APIs to access our Website belong exclusively to Us. You agree not to engage in any unauthorized use, reproduction, distribution or publication in electronic or physical form. The entire content of Our Website (except for Your personal information) is copyrighted as a collective work under the United States copyright laws. We own a copyright in the selection, coordination, arrangement, and enhancement of each page of Website. In addition, from time to time there may be information that is made available to You in Our Website that is copyrighted material belonging to Us or a third party. You may not modify, reproduce, publish, transmit, participate in the transfer or sale, create derivative works, use for commercial purposes, or in any way exploit, any of the copyrighted content, in whole or in part except as provided in these Terms and Conditions. Except as otherwise expressly permitted under United States copyright law, no copying, redistribution, retransmission, publication, or commercial exploitation of downloaded material will be permitted without Our express permission and the third-party copyright owner, if applicable. Modification or other unauthorized use of any copyrighted materials displayed on Our Website is a violation of Our copyright and other proprietary rights and may also violate the rights of various other parties and/or applicable laws. Certain logos, product names and trademarks owned by Us are contained within Our Website. You will have no right to use such logos, names and trademarks except for such rights as expressly granted by Us to You.</p>

<b>Indemnity</b>
<p>You agree to indemnify, defend, and hold Us and Our agents, employees, officers, directors, successors, assigns or affiliates, harmless from and against all losses, expenses, damages and costs, including reasonable attorneys’ fees, resulting from (i) any violation of these Terms and Conditions or any activity related to Your account (including negligent or wrongful conduct) by You or any other person accessing Our Services using Your account, (ii) any negligence or willful misconduct by You; or (iii) any violation of applicable law, rule or regulation.</p>

<b>Links to Other Websites</b>
<p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company. The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
<p>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</p>
<b>Termination</b>
<p>We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.</p>
<p>Upon termination, Your right to use the Service and Our Website will cease immediately.</p>

<b>Limitation of Liability</b>
<p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the lesser of the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.</p>
<p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
<p>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>

<b>"AS IS" and "AS AVAILABLE" Disclaimer</b>
<p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind.</p>
<p>TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, THE COMPANY, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, WITH RESPECT TO THE SERVICE, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE.</p>
<p>Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
<p>Without limiting the foregoing, neither the Company nor any of the Company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, Trojan horses, worms, malware, timebombs or other harmful components.</p>
<p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. In such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p>

<b>Not Medical Information Or Health Therapy.</b>
<p>Our Website and Service is not intended to diagnose, treat, cure, or prevent any disease or medical condition. If you have a medical or heart condition, consult your doctor before using Our Service. If you experience a medical emergency, stop using Our Service and consult with a medical professional. We are not responsible for any health problems that may result from consultations, information or activities you learn about or participate in through the use of Our Website or Service.</p>
<p>The accuracy of the data collected and presented through Our Website and Service is not intended to match that of medical devices or scientific measurement devices. We are not responsible for the accuracy, reliability, availability, effectiveness, or correct use of information You receive through the use of Our Website or Service.</p>
<p>We do not endorse any specific tests, physician or medical professional, products, procedures, opinions, or any other information that You may receive through Your use of Our Website and Service.</p>

<b>Governing Law</b>
<p>The laws of the New Jersey, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</p>

<b>Disputes Resolution</b>
<h3>PLEASE READ THIS SECTION CAREFULLY - IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.</h3>
<p>We and You agree that these Terms and Conditions affect interstate commerce and that the Federal Arbitration Act governs the interpretation and enforcement of these arbitration provisions.</p>
<p>In the event of any dispute, claim, or controversy arising out of or in connection with Your access to, and/or use of Our Website and Service, and/or the provision of information, services, and/or technology on or through Our Website and Service, We or You must give the other notice of the dispute, claim, or controversy which notice will include a brief written statement that sets forth the name, address, and contact information of the party giving it, the facts giving rise to the dispute, claim, or controversy, and the relief requested. You must send any such notice to us by by sending us notice through this page on Our website: <a href="https://remoteworx.com/contact">https://remoteworx.com/contact</a>. To the extent that We have Your contact information, We will send any such notice to You by U.S. Mail or to Your email address. We and You will attempt to resolve any dispute, claim, or controversy through informal negotiation within thirty (30) days from the date that any notice of dispute, claim, or controversy is sent. We and You shall use reasonable, good faith, efforts to settle any dispute, claim, or controversy through consultation and good faith negotiations. After thirty (30) days, We or You agree that the matter shall be submitted to arbitration as described in this provision and neither You nor Us shall have the right to file a lawsuit. The notice and thirty (30)-day negotiation period required by this provision shall not apply, however, to disputes, claims, or controversies concerning Our intellectual property or claims of piracy or unauthorized use of Our Website and Service.</p>
<p>If a dispute, claim or controversy is unresolved through informal discussions within thirty (30) days of receipt of notice, either You or Us agree that the matter shall be submitted to binding arbitration to be held in Essex County, New Jersey. The arbitration shall be conducted by a single arbitrator, governed by the Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (collectively, &#8220;AAA Rules&#8221;) of the American Arbitration Association (&#8220;AAA&#8221;), as modified by these Terms and Conditions and administered by the AAA. The AAA Rules and fee information are available at &#8220;<a href="https://www.adr.org/" target="_blank">https://www.adr.org</a>&#8221; or by calling the AAA at 1-800-778-7879.</p>
<p>We and You shall equally bear the cost of any arbitration filing fees and arbitration fees, unless the arbitrator finds the arbitration to be frivolous and awards such costs to be paid to the prevailing party by the non-prevailing party. You are responsible for all other additional costs that You may incur in the arbitration including, but not limited to attorneys' fees and expert witness costs unless We are otherwise specifically required to pay such fees under applicable law. The decision of the arbitrator will be in writing and binding and conclusive on Us and You, and judgment to enforce the decision may be entered by any court of competent jurisdiction. We and You agree that dispositive motions, including without limitation, motions to dismiss and motions for summary judgment will be allowed in the arbitration. The arbitrator must follow these Terms and Conditions and can award the same damages and relief as a court, including injunctive or other equitable relief and attorneys' fees. Notwithstanding the foregoing, We and You agree not to seek any attorneys' fees and expert witness costs unless the arbitrator finds that a claim or defense was frivolous or asserted for an improper purpose. We and You understand that, absent this mandatory arbitration provision, We and You would have the right to sue in court. We and You further understand that, in some instances, the costs of arbitration could exceed the costs of litigation and the right to discovery may be more limited in arbitration than in court.</p>
<p>If any clause within this arbitration provision is found to be illegal or unenforceable that specific clause will be severed from these arbitration provisions, and the remainder of the arbitration provisions will be given full force and effect. In the event some or all of these arbitration provisions are determined to be unenforceable for any reason, or if a claim, dispute, or controversy is brought which is then found by a court to be excluded from the scope of these arbitration provisions, We and You agree to waive, to the fullest extent allowed by law, any trial by jury.</p>

<h3>Class Action Waiver</h3>
<h3>PLEASE READ THIS SECTION CAREFULLY - IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS.</h3>
<p>We and You agree that We and You will resolve any disputes, claims, or controversies on an individual basis, and that any claims brought under these Terms and Conditions in connection with Our Website or Service will be brought in an individual capacity, and not on behalf of, or as part of, any purported class, consolidated, or representative proceeding. We and You further agree that We and You shall not participate in any consolidated, class, or representative proceeding (existing or future) brought by any third party arising under these Terms and Conditions or in connection with Our Website or Service.</p>
<p>If any court or arbitrator determines that the class action waiver set forth in this section is void or unenforceable for any reason or that arbitration can proceed on a class basis, then the disputes, claims, or controversies will not be subject to arbitration and must be litigated in federal court located in New Jersey.</p>
<p>The terms of this provision will apply to any claims asserted by You against Us to the extent that any such claims arise out of Your access to, and/or use of Our Website and Service, and/or the provision of information, services, and/or technology on or through our Website.</p>

<h3>For European Union (EU) Users</h3>
<p>If You are a European Union consumer, You will benefit from any mandatory provisions of the law of the country in which You are resident in.</p>

<h3>United States Legal Compliance</h3>
<p>You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>

<h3>Severability</h3>
<p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>

<h3>Waiver </h3>
<p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.</p>

<h3>Translation Interpretation </h3>
<p>These Terms and Conditions may have been translated if We have made them available to You on Our Service. You agree that the original English text shall prevail in the case of a dispute.</p>

<h3>Changes to These Terms and Conditions </h3>
<p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>
<p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>

<h3>Contact Us </h3>
<p>If You have any questions about these Terms and Conditions, You can contact us by visiting this page on Our website: <a href="https://remoteworx.com/contact">https://remoteworx.com/contact</a>.</p>
 
<h2>Privacy Policy </h2>
<p>Last updated: February 14, 2024</p>
<p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use Our Website and Service and tells You about Your privacy rights and how the law protects You. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>

<h2>Interpretation </h2>
<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

<h2>Definitions </h2>
<p>For the purposes of this Privacy Policy:</p>
 <p><b>Account</b> means a unique account created for You to access Our Service or parts of Our Service through Our Website.</p>
 <p><b>Company</b> (referred to as either "the Company", "We", "Us", or "Our" in this Agreement) refers to RemoteWorx LLC.</p>
 <p><b>Cookies</b> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
 <p><b>Device</b> means any device that can access Our Website and Service such as a computer, a cellphone or a digital tablet.</p>
 <p><b>Do Not Track</b> (DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.</p>
 <p><b>Personal Data</b> is any information that relates to an identified or identifiable individual.</p>
 <p><b>Service</b>refers to the wellness programs We make available through Our Website.</p>
 <p><b>Service Provider</b> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
 <p><b>Usage Data</b> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
 <p><b>Website</b> refers to all RemoteWorx sites, accessible from remoteworx.com, app.remoteworx.com, and remoteworx.com/shop (worklifeshop.com)</p>
 <p><b>You</b> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
 
 <h1>Collecting and Using Your Personal Data</h1>
 <h2>Types of Data Collected</h2>
 <h3>Personal Data</h3>
<p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
<ul>
  <li><b>Work Email address</b> </li>
  <li><b>First name and Last name</b></li>
  <li><b>Phone number</b> </li>
  <li><b>Address, City, State, (Province), ZIP/Postal code</b> </li>
  <li><b>Usage Data</b></li>
</ul>
 
<p>Usage Data is collected automatically when using the Service.</p>
<p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of Our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
<p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
<p>We may also collect information that Your browser sends whenever You visit Our Service or when You access the Service by or through a mobile device.</p>

<h2>Tracking Technologies and Cookies </h2>
<p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
 <p><b>Cookies or Browser Cookies.</b> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of Our Service. Unless You have adjusted Your browser setting so that it will refuse Cookies, Our Service may use Cookies.</p>
 <p><b>Flash Cookies.</b> Certain features of Our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on Our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how You can delete Flash Cookies, please read "<a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling_or_deleting_local_shared_objects.">https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling_or_deleting_local_shared_objects.</a>"</p>
 <p><b>Web Beacons.</b> Certain sections of Our Service and Our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</p>
<p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.</p>
<p>We use both Session and Persistent Cookies for the purposes set out below:</p>
 
<h3>Necessary / Essential Cookies.</h3> 
<p>Type: Session Cookies.</p> 
<p>Administered by: Us.</p> 
<p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>

<h3>Cookies Policy / Notice Acceptance Cookies.</h3> 
<p>Type: Persistent Cookies</p>. 
<p>Administered by: Us.</p> 
<p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>

<h3>Functionality Cookies.</h3> 
<p>Type: Persistent Cookies.</p>
<p>Administered by: Us.</p>
<p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering Your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter Your preferences every time You use the Website.</p>

<h3>Tracking and Performance Cookies.</h3> 
<p>Type: Persistent Cookies.</p> 
<p>Administered by: </p>
<p>Third Parties. Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify You as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device You use to access the Website. We may also use these Cookies to test new pages, features or new functionality of the Website to see how Our users react to them.</p>

<h1>Use of Your Personal Data </h1>
<h3>The Company may use Personal Data for the following purposes: </h3>
<p><b>To provide and maintain Our Service:</b> including to monitor the usage of Our Service.</p>
<p><b>To manage Your Account:</b> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
<p><b>For the performance of a contract:</b> the development, compliance and undertaking of the purchase contract for the products, items or services You or Your employer has purchased or of any other contract with Us through the Service.</p>
<p><b>To contact You:</b> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application’s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
<p><b>To provide You:</b> with news, special offers and general information about other goods, services and events which We offer that are similar to those that You have already purchased or inquired about unless You have opted not to receive such information.</p>
<p><b>To manage Your requests:</b> To attend and manage Your requests to Us.</p>
<p><b>To deliver targeted advertising to You:</b> We may use Your information to develop and display content and advertising (and work with third-party vendors who do so) tailored to Your interests and/or location and to measure its effectiveness.</p>
<p><b>For business transfers:</b> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about Our Service users is among the assets transferred.</p>
<p><b>For other purposes:</b> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of Our promotional campaigns and to evaluate and improve Our Service, products, services, marketing and Your experience.</p>
<h3>We may share Your personal information in the following situations:</h3>
<p><b>With Service Providers:</b> We may share Your personal information with Service Providers to monitor and analyze the use of Our Service, to advertise on third party websites to You after You visited our Service, for payment processing, to contact You.</p>
<p><b>For business transfers:</b> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</p>
<p><b>With Affiliates:</b> We may share Your information with Our affiliates, in which case We will require those affiliates to honor this Privacy Policy. Affiliates include Our subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</p>
<p><b>With business partners:</b> We may share Your information with Our business partners to offer You certain products, services or promotions.</p>
<p><b>With other users:</b> When You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users.</p>
<p><b>With Your consent:</b> We may disclose Your personal information for any other purpose with Your consent.</p>

<h2>Retention of Your Personal Data</h2>
<p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with Our legal obligations (for example, if We are required to retain Your data to comply with applicable laws), resolve disputes, and enforce Our legal agreements and policies.</p>
<p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
<h2>Transfer of Your Personal Data</h2>
<p>Your information, including Personal Data, is processed at the Company’s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on—computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction. Currently, the Company utilizes Amazon Web Services (AWS), US East Zone 1, for its cloud storage and hosting services.</p>
<p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
<p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>

<h2>Disclosure of Your Personal Data </h2>
<h3>Business Transactions </h3>
<p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
<h3>Law enforcement </h3>
<p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
<h3>Other legal requirements </h3>
<p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
<ul>
  <li>Comply with a legal obligation</li>
  <li>Protect and defend the rights or property of the Company</li>
  <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
  <li>Protect the personal safety of Users of the Service or the public</li>
  <li>Protect against legal liability</li>
</ul>

<h2>Security of Your Personal Data </h2>
<p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
<h2>Detailed Information on the Processing of Your Personal Data </h2>
<p>The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies.</p>

<h2>Analytics </h2>
<p>We may use third-party Service providers to monitor and analyze the use of Our Service.</p>

<p><b>Google Analytics </b>- Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of Our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network. You can opt-out of having Your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity. For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a></p>
<p><b>Hubspot </b>- Their Privacy Policy can be viewed at <a href="https://legal.hubspot.com/privacy-policy" target="_blank">https://legal.hubspot.com/privacy-policy</a></p>

<h2>Email Marketing </h2>
<p>We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other information that may be of interest to You. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email We send or by contacting Us.</p>
<p>We may use Email Marketing Service Providers to manage and send emails to You.</p>
<p><b>Mailchimp </b>- Mailchimp is an email marketing sending service provided by The Rocket Science Group LLC. For more information on the privacy practices of Mailchimp, please visit their Privacy policy: <a href="https://mailchimp.com/legal/privacy/" target="_blank">https://mailchimp.com/legal/privacy/</a></p>
<p><b>Hubspot </b>- Their Privacy Policy can be viewed at <a href="https://legal.hubspot.com/privacy-policy" target="_blank">https://legal.hubspot.com/privacy-policy</a></p>

<h2>Payments </h2>
<p>We may provide paid products and/or services within the Service. In that case, We may use third-party services for payment processing (e.g. payment processors).</p>
<p>We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
<p><b>Google Play In-App Payments </b>- Their Privacy Policy can be viewed at <a href="https://www.google.com/policies/privacy/" target="_blank">https://www.google.com/policies/privacy/</a></p>
<p><b>Stripe </b>- Their Privacy Policy can be viewed at <a href="https://stripe.com/us/privacy" target="_blank">https://stripe.com/us/privacy</a></p>
<p><b>PayPal </b>- Their Privacy Policy can be viewed at <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full" target="_blank">https://www.paypal.com/webapps/mpp/ua/privacy-full</a></p>
<p><b>Authorize.net</b>- Their Privacy Policy can be viewed at <a href="https://www.authorize.net/company/privacy/" target="_blank">https://www.authorize.net/company/privacy/</a></p>
<p><b>Square</b>- Their Privacy Policy can be viewed at <a href="https://squareup.com/legal/privacy-no-account" target="_blank">https://squareup.com/legal/privacy-no-account</a></p>
<p><b>Intuit Payments</b>- Their Privacy Policy can be viewed at <a href="https://www.intuit.com/privacy/statement/" target="_blank">https://www.intuit.com/privacy/statement/</a></p>
<p>When You use Our Service to pay a product and/or service via bank transfer, We may ask You to provide information to facilitate this transaction and to verify Your identity.</p>

<h2>Behavioral Remarketing </h2>
<p>The Company uses remarketing services to advertise to You after You have accessed or visited Our Service. We and Our third-party vendors use cookies and non-cookie technologies to help Us recognize Your Device and understand how You use Our Service so that We can improve Our Service to reflect Your interests and send You advertisements that are likely to be of more interest to You.</p>
<p>These third-party vendors collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies and to enable Us to:</p>
<ul>
  <li>Measure and analyze traffic and browsing activity on Our Service</li>
  <li>Show advertisements for Our products and/or services to You on third-party websites or apps</li>
  <li>Measure and analyze the performance of Our advertising campaigns</li>
</ul>

<p>Some of these third-party vendors may use non-cookie technologies that may not be impacted by browser settings that block cookies. Your browser may not permit You to block such technologies. You can use the following third-party tools to decline the collection and use of information for the purpose of serving You interest-based advertising:</p>
<p>The NAI’s opt-out platform: <a href="http://www.networkadvertising.org/choices/" target="_blank">http://www.networkadvertising.org/choices/</a></p>
<p>The EDAA’s opt-out platform <a href="http://www.youronlinechoices.com/" target="_blank">http://www.Youronlinechoices.com/</a></p>
<p>The DAA’s opt-out platform: <a href="http://optout.aboutads.info/?c=2&amp;lang=EN" target="_blank">http://optout.aboutads.info/?c=2&amp;lang=EN</a><br>You may opt-out of all personalized advertising by enabling privacy features on Your mobile device such as Limit Ad Tracking (iOS) and Opt Out of Ads Personalization (Android). See Your mobile device Help system for more information.</p>
<p>We may share information, such as hashed email addresses (if available) or other online identifiers collected on Our Service with these third-party vendors. This allows Our third-party vendors to recognize and deliver Your ads across devices and browsers. To read more about the technologies used by these third-party vendors and their cross-device capabilities please refer to the Privacy Policy of each vendor listed below.</p>
<p>The third-party vendors We use are:</p>

<p><b>Google Ads (AdWords)</b>- Google Ads (AdWords) remarketing service is provided by Google Inc. You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: <a href="http://www.google.com/settings/ads" target="_blank">http://www.google.com/settings/ads</a> Google also recommends installing the Google Analytics Opt-out Browser Add-on – <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a> – for Your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics. For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a></p>
<p><b>Twitter</b> - Twitter remarketing service is provided by Twitter Inc. You can opt-out from Twitter’s interest-based ads by following their instructions: <a href="https://support.twitter.com/articles/20170405" target="_blank">https://support.twitter.com/articles/20170405.</a> You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page: <a href="https://twitter.com/privacy" target="_blank">https://twitter.com/privacy</a></p>
<p><b>Facebook</b> - Facebook remarketing service is provided by Facebook Inc. You can learn more about interest-based advertising from Facebook by visiting this page: <a href="https://www.facebook.com/help/516147308587266" target="_blank">https://www.facebook.com/help/516147308587266</a> To opt-out from Facebook’s interest-based ads, follow these instructions from Facebook: <a href="https://www.facebook.com/help/568137493302217" target="_blank">https://www.facebook.com/help/568137493302217 </a>Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA <a href="http://www.aboutads.info/choices/" target="_blank">http://www.aboutads.info/choices/</a>, the Digital Advertising Alliance of Canada in Canada <a href="http://youradchoices.ca/" target="_blank">http://Youradchoices.ca/</a> or the European Interactive Digital Advertising Alliance in Europe <a href="http://www.youronlinechoices.eu/" target="_blank">http://www.Youronlinechoices.eu/</a>, or opt-out using Youmobile device settings. For more information on the privacy practices of Facebook, please visit Facebook’s Data Policy page: <a href="https://www.facebook.com/privacy/explanation" target="_blank">https://www.facebook.com/privacy/explanation</a></p>
<p><b>AdRoll</b> - AdRoll remarketing service is provided by NextRoll, Inc. You can opt-out of AdRoll remarketing by visiting this Ad Roll Advertising Preferences web page: <a href="http://info.evidon.com/pub_info/573?v=1&amp;nt=1&amp;nw=false" target="_blank">http://info.evidon.com/pub_info/573?v=1&amp;nt=1&amp;nw=false</a> or the Opt Out of Personalized Advertising web page: <a href="https://help.adroll.com/hc/en-us/articles/216599538-Opting-Out-of-Personalized-Advertising" target="_blank">https://help.adroll.com/hc/en-us/articles/216599538-Opting-Out-of-Personalized-Advertising </a>If You are a California resident, please read the Information For California Residents Only section in the Privacy Policy of Next Roll, Inc. <a href="https://www.nextroll.com/privacy#service-13">https://www.nextroll.com/privacy#service-13</a> For more information on the privacy practices of AdRoll, please visit the Next Roll, Inc. Privacy Policy web page: <a href="https://www.nextroll.com/privacy" target="_blank">https://www.nextroll.com/privacy</a></p>

<h2>FOR CALIFORNIA RESIDENTS</h2>
<h2>“Do Not Track” Policy as Required by California Online Privacy Protection Act (CalOPPA)</h2>
<p>Our Service does not respond to Do Not Track signals. Some third-party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.</p>

<h2>California Consumer Protection Act (CCPA) Privacy Policy</h2>
<p>This privacy notice section is for California residents and supplements the information contained in Our Privacy Policy and it applies solely to all visitors, users, and others who reside in the State of California as required by the California Consumer Privacy Act of 2018 ("CCPA").</p>

<h3>Categories of Personal Information Collected </h3>
<p>We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer or Device. The following is a list of categories of Personal Information which We may collect or may have been collected from California residents within the last twelve (12) months.</p>
<p>Please note that the categories and examples provided in the list below are those defined in the CCPA. This does not mean that all examples of that category of Personal Information were in fact collected by us, but reflects Our good faith belief to the best of Our knowledge that some of that information from the applicable category may be and may have been collected. For example, certain categories of Personal Information would only be collected if You provided such Personal Information directly to us.</p>
<h3>• Category A: Identifiers.</h3>
<p>Examples: A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, driver's license number, passport number, or other similar identifiers.</p>
<p>Collected: Yes.</p>

<h3>• Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</h3>
<p>Examples: A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some Personal Information included in this category may overlap with other categories.</p>
<p>Collected: Yes.</p>

<h3>• Category C: Protected classification characteristics under California or federal law.</h3>
<p>Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</p>
<p>Collected: Yes.</p>

<h3>• Category D: Commercial information.</h3>
<p>Examples: Records and history of products or services purchased or considered.</p>
<p>Collected: Yes.</p>

<h3>• Category E: Biometric information.</h3>
<p>Examples: Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</p>
<p>Collected: No.</p>

<h3>• Category F: Internet or other similar network activity.</h3>
<p>Examples: Interaction with Our Services or advertisement.</p>
<p>Collected: Yes.</p>

<h3>• Category G: Geolocation data.</h3>
<p>Examples: Approximate physical location.</p>
<p>Collected: No.</p>

<h3>• Category H: Sensory data.</h3>
<p>Examples: Audio, electronic, visual, thermal, olfactory, or similar information.</p>
<p>Collected: No.</p>

<h3>• Category I: Professional or employment-related information.</h3>
<p>Examples: Current or past job history or performance evaluations.</p>
<p>Collected: Yes.</p>

<h3>•  Category J: Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</h3>
<p>Examples: Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</p>
<p>Collected: No.</p>

<h3>•  Category K: Inferences drawn from other Personal Information.</h3>
<p>Examples: Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</p>
<p>Collected: Yes.</p>
<p>Under CCPA, Personal Information does not include:</p>
<ul>
<li>Publicly available information from government records</li>
<li>Deidentified or aggregated consumer information</li>
<li>Information excluded from the CCPA's scope, such as:</li>
<ul>
    <li>Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data</li>
    <li>Personal Information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994</li>
</ul>
</ul>


<h3>Sources of Personal Information</h3>
<p>We obtain the categories of Personal Information listed above from the following categories of sources:</p>
<ul>
  <li>Directly from You. For example, from the information You provide us when You sign up for Our Services, preferences You express or provide through Our Services, or from Your purchases of Our Services.</li>
  <li>Indirectly from You. For example, from observing Your activity on Our Services.</li>
  <li>Automatically from You. For example, through cookies We or Our Services Providers set on Your Device as You navigate through Our Services.</li>
  <li>From Service Providers. For example, third-party vendors (for instance, third party payment processing, or other third-party vendors that We use to provide the Services to You).</li>
</ul>

<h3>Use of Personal Information for Business Purposes or Commercial Purposes</h3>
<p>We may use or disclose Personal Information We collect for "business purposes" or "commercial purposes" (as defined under the CCPA), which may include the following examples:</p>
<ul>
  <li>To operate Our Services and provide You with Our Services.</li>
  <li>To provide You with support and to respond to Your inquiries, including to investigate and address Your concerns and monitor and improve Our Services.</li>
  <li>To fulfill or meet the reason You provided the information. For example, if You share Your contact information to ask a question about Our Services, We will use that Personal Information to respond to Your inquiry. If You provide Your Personal Information to purchase a product or service, We will use that information to process Your payment through a third-party payment processor and facilitate delivery.</li>
  <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
  <li>As described to You when collecting Your Personal Information or as otherwise set forth in the CCPA.</li>
  <li>For internal administrative and auditing purposes.</li>
  <li>To detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity, including, when necessary, to prosecute those responsible for such activities.</li>
</ul>
<p>Please note that the examples provided above are illustrative and not intended to be exhaustive. For more details on how We use this information, please refer to the "Use of Your Personal Data" section.</p>

<h3>Disclosure of Personal Information for Business Purposes or Commercial Purposes</h3>
<p>We may use or disclose and may have used or disclosed in the last twelve (12) months the following categories of Personal Information for business or commercial purposes:</p>
<ul>
  <li>Category A: Identifiers</li>
  <li>Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</li>
  <li>Category C: Protected classification characteristics under California or federal law.</li>
  <li>Category D: Commercial information</li>
  <li>Category F: Internet or other similar network activity</li>
  <li>Category I: Professional or employment-related information</li>
  <li>Category K: Inferences drawn from other Personal Information</li>
</ul>
<p>Please note that the categories listed above are those defined in the CCPA. This does not mean that all examples of that category of Personal Information were in fact disclosed, but reflects Our good faith belief to the best of Our knowledge that some of that information from the applicable category may be and may have been disclosed.</p>
<p>When We disclose Personal Information for a business purpose or a commercial purpose, We enter a contract that describes the purpose and requires the recipient to both keep that Personal Information confidential and not use it for any purpose except performing the contract.</p>

<h3>Sale of Personal Information </h3>
<p>As defined in the CCPA, "sell" and "sale" mean selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a consumer's Personal Information by the business to a third party for valuable consideration.</p>
<p>We do not, and have not during the last twelve (12) months, sold any Personal Information.</p>

<h3>Share of Personal Information </h3>
<p>We may share Your Personal Information identified in the above categories with the following categories of third parties:</p>
<ul>
  <li>- Service Providers</li>
  <li>- Payment processors</li>
  <li>- Our business partners</li>
  <li>- Third party vendors to whom You or Your agents authorize Us to disclose Your Personal Information in connection with products or services We provide to You</li>
</ul>

<h3>Your Rights Under the CCPA </h3>
<p>The CCPA provides California residents with specific rights regarding their Personal Information. If You are a resident of California, You have the following rights:</p>
<ul>
  <li><b>The right to notice.</b> You have the right to be notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.</li>
  <li><b>The right to request.</b> Under CCPA, You have the right to request that We disclose information to You about Our collection, use, sale, disclosure for business purposes and sharing of Personal Information. Once We receive and confirm Your request, We will disclose to You:</li>
  <ul>
    <li>The categories of Personal Information We collected about You</li>
    <li>The categories of sources for the Personal Information We collected about You</li>
    <li>Our business or commercial purpose for collecting or selling that Personal Information</li>
    <li>The categories of third parties with whom We share that Personal Information</li>
    <li>The specific pieces of Personal Information We collected about You</li>
    <li>If We sold Your Personal Information or disclosed Your Personal Information for a business purpose, We will disclose to You (i) the categories of Personal Information categories sold and (ii) the categories of Personal Information categories disclosed.</li>
  </ul>
  <li> <b>The right to say no to the sale of Personal Information (opt-out). </b>You have the right to direct us to not sell Your Personal Information. We do not sell any Personal Information.</li>
  <li> <b>The right to delete Personal Information. </b>You have the right to request the deletion of Your Personal Information, subject to certain exceptions. Once We receive and confirm Your request, We will delete (and direct Our Service Providers to delete) Your Personal Information from Our records, unless an exception applies. We may deny Your deletion request if retaining the information is necessary for us or Our Service Providers to:</li>
  <ul>
    <li> Complete the transaction for which We collected the Personal Information, provide a good or service that You requested, take actions reasonably anticipated within the context of Our ongoing business relationship with You, or otherwise perform Our contract with You.</li>
    <li> Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
    <li> Debug products to identify and repair errors that impair existing intended functionality.</li>
    <li> Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
    <li> Comply with the California Electronic Communications Privacy Act (Cal. Penal Code &sect; 1546 et. seq.).</li>
    <li> Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if You previously provided informed consent.</li>
    <li> Enable solely internal uses that are reasonably aligned with consumer expectations based on Your relationship with us.</li>
    <li> Comply with a legal obligation.</li>
    <li> Make other internal and lawful uses of that information that are compatible with the context in which You provided it.</li>
  </ul>
  <li> <b>The right not to be discriminated against. </b>You have the right not to be discriminated against for exercising any of Your consumer's rights, including by:</li>
  <ul>
    <li> Denying goods or services to You</li>
    <li> Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties</li>
    <li> Providing a different level or quality of goods or services to You</li>
    <li> Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services</li>
    </ul>
</ul>

<h3>Exercising Your CCPA Data Protection Rights </h3>
<p>In order to exercise any of Your rights under the CCPA, and if You are a California resident, You can contact us at <a href="https://remoteworx.com/contact" target="_blank">https://remoteworx.com/contact</a>. Only You, or a person registered with the California Secretary of State that You authorize to act on Your behalf, may make a verifiable request related to Your Personal Information. Your request to us must:</p>
<ul>
  <li> Provide sufficient information that allows us to reasonably verify You are the person about whom We collected Personal Information or an authorized representative</li>
  <li> Describe Your request with sufficient detail that allows us to properly understand, evaluate, and respond to it. We cannot respond to Your request or provide You with the required information if We cannot: verify Your identity or authority to make the request and confirm that the Personal Information relates to You</li>
  <li>We will disclose and deliver the required information free of charge within 45 days of receiving Your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonably necessary and with prior notice.</li>
</ul>
<p>Any disclosures We provide will only cover the 12-month period preceding the verifiable request's receipt.</p>
<p>For data portability requests, We will select a format to provide Your Personal Information that is readily usable and should allow You to transmit the information from one entity to another entity without hindrance.</p>

<h2>Children's Privacy</h2>
<p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
<p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>

<h2>Links to Other Websites</h2>
<p>Our Service may contain links to other websites that are not operated by Us. If You click on a third-party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
<p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.</p>

<h2>Changes to this Privacy Policy</h2>
<p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
<p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

<h2>Contact Us</h2>
<p>If You have any questions about this Privacy Policy, You can contact us by visiting this page on Our website: <a href="https://remoteworx.com/contact" target="_blank">https://remoteworx.com/contact</a>.</p>

<div style="height: 40px" />
`;

import { AvatarType, CustomAvatar } from '@/components/UI/CustomAvatar';
import { EMessageType } from '@/types/consts';
import { IMessage, IMessageData, IUser } from '@/types/models';
import { getUserAvatarLetter } from '@/utils/getAvatarLeters';
import { FC } from 'react';
import {
  AvatarContainer,
  LastMessage,
  MessageBlock,
  PersonalChat,
  PersonalChatInfo,
  StatusDot,
  UnreadBadge,
  UserName,
} from '../components';

interface ChatUserItemProps {
  lastMessage?: IMessage;
  isOnline: boolean;
  user: IUser;
  unread?: number;
}

const ChatUserItem: FC<ChatUserItemProps> = ({
  user,
  lastMessage,
  isOnline,
  unread,
}) => {
  return (
    <PersonalChat>
      <AvatarContainer>
        <CustomAvatar
          diameter={46}
          avatartype={AvatarType.USER}
          alt="Remy Sharp"
          src={user?.profileImage || undefined}
        >
          {!user
            ? ''
            : getUserAvatarLetter({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
              })}
        </CustomAvatar>
        <StatusDot isOnline={isOnline}></StatusDot>
      </AvatarContainer>
      <PersonalChatInfo>
        <UserName>
          {user?.firstName
            ? `${user?.firstName} ${user.lastName}`
            : user?.email}
        </UserName>
        {lastMessage && (
          <MessageBlock>
            <LastMessage unread={!!unread}>
              {lastMessage.type === EMessageType.TEXT
                ? (lastMessage?.data as IMessageData).text
                : 'Picture'}
            </LastMessage>
            {!!unread && <UnreadBadge>{unread}</UnreadBadge>}
          </MessageBlock>
        )}
      </PersonalChatInfo>
    </PersonalChat>
  );
};

export default ChatUserItem;

import { Avatar as MuiAvatar, Box, ListItemAvatar } from '@mui/material';
import styled from 'styled-components';

export const CustomListItemAvatar = styled(ListItemAvatar)`
  align-self: baseline;
  margin-top: auto;
  margin-bottom: auto;
`;

export const SmallIndicator = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.error.main};
  width: 8px;
  height: 8px;
`;

export const NotificationBox = styled(Box)`
  border-radius: 12px;
  width: 308px;

  &:hover {
    background-color: #daeafa;
  }
`;

export const Title = styled(Box)`
  font-size: 14px;
  color: #5d6081;
  font-weight: bold;
`;

export const DateBlock = styled(Box)`
  font-size: 10px;
  color: #5d6081;
`;

import { CompanyState } from '@/redux/reducers/company';
import { RootState } from '@/redux/store';
import { getAvarageActivity } from '@/utils/arrayHelpers';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

export const getCompaniesSelector = createSelector(
  (state: RootState) => state.company,
  ({ companies }: CompanyState) => companies
);

export const companiesCount = createSelector(
  (state: RootState) => state.company,
  ({ companiesCount }: CompanyState) => companiesCount
);

export const getCompaniesLoading = createSelector(
  (state: RootState) => state.company,
  ({ companiesLoading }: CompanyState) => companiesLoading
);

export const getCompaniesTabs = createSelector(
  (state: RootState) => state.company,
  ({ companyTabs }: CompanyState) => companyTabs
);

export const getCompanyLoading = createSelector(
  (state: RootState) => state.company,
  ({ companyLoading }: CompanyState) => companyLoading
);

export const getCompany = createSelector(
  (state: RootState) => state.company,
  ({ company }: CompanyState) => company
);

export const getCompanyTeam = createSelector(
  (state: RootState) => state.company,
  ({ company }: CompanyState) => company?.users
);

export const getCompanySearchText = createSelector(
  (state: RootState) => state.company,
  ({ searchText }: CompanyState) => searchText
);

export const getSearchedCompanies = createSelector(
  (state: RootState) => state.company,
  ({ searchText, companies, filteredCompanies }: CompanyState) =>
    searchText ? filteredCompanies : companies
);

export const getFilteredCount = createSelector(
  (state: RootState) => state.company,
  ({ searchText, companiesCount, filteredCount }: CompanyState) =>
    searchText ? filteredCount : companiesCount
);

export const companyProgressSelector = createSelector(
  (state: RootState) => state.company,
  ({ progress }: CompanyState) =>
    progress
      ? getAvarageActivity(progress)
      : { mental: 0, social: 0, physical: 0 }
);

export const companyStatistic = createSelector(
  (state: RootState) => state.company,
  ({ progressStatistic }: CompanyState) => progressStatistic
);

export const companyActivityLog = createSelector(
  (state: RootState) => state.company,
  ({ activitiesLog }: CompanyState) => activitiesLog
);

export const getCompanyAvailablePoints = createSelector(
  (state: RootState) => state.company,
  ({ company }: CompanyState) => company?.points.availablePoints
);

export const getCompanyFlexPoints = createSelector(
  (state: RootState) => state.company,
  ({ company }: CompanyState) => company?.points.flexPoints
);

export const companyMessagingStats = createSelector(
  (state: RootState) => state.company,
  ({ messagesStat }: CompanyState) => messagesStat
);

export const getPointManagementHistory = createSelector(
  (state: RootState) => state.company,
  ({ pointsHistory }: CompanyState) => pointsHistory
);

export const getCountAllPointManagementHistory = createSelector(
  (state: RootState) => state.company,
  ({ countAllPointsHistory }: CompanyState) => countAllPointsHistory
);

export const getTeamDashboardStatisticsSelector = createSelector(
  (state: RootState) => state.company,
  ({ teamDashboard }: CompanyState) =>
    teamDashboard
      ? teamDashboard.map(
          ({
            userId,
            user,
            knowledgeCenterProgress,
            mentalTotal,
            totalRewardPoints,
            socialTotal,
            workspaceProgress,
            physicalTotal,
            messagesSent,
            availablePoints,
            earnedPoints,
          }) => {
            return {
              id: userId,
              email: user.email,
              kbProgress: +knowledgeCenterProgress,
              mental: +mentalTotal,
              menterTotals:
                Number(mentalTotal) +
                Number(socialTotal) +
                Number(physicalTotal),
              user: {
                firstName: user.firstName || '',
                lastName: user.lastName,
                email: user.email,
                profileImage: user.profileImage,
              },
              availablePoints: +availablePoints,
              earnedPoints: +earnedPoints,
              messagesSent: +messagesSent,
              phycical: +physicalTotal,
              rewardPoints: +totalRewardPoints,
              social: +socialTotal,
              jobTitle: user.jobTitle,
              workspaceProgress: +workspaceProgress,
              optPhone: user.optPhone ? 'Y' : 'N',
              optBirthdayHireday: user.optBirthdayHireday ? 'Y' : 'N',
              birthDate: user.birthday
                ? moment(user.birthday).utc().format('MM/DD')
                : '',
              startWorkDate: user.startWorkDate
                ? moment(user.startWorkDate).utc().format('yyy-MM-DD')
                : '',
              registrationAt: user.registrationAt
                ? moment(user.registrationAt).utc().format('yyy-MM-DD')
                : '',
            };
          }
        )
      : null
);

import useAppSelector from '@/hooks/useAppSelector';
import { adminSelectedUser } from '@/redux/selectors/users';
import { IUser } from '@/types/models';
import { Box, Tab, TabProps, Tabs } from '@mui/material';
import React from 'react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';

import styled from 'styled-components';

const Link = styled(NavLink)<TabProps & NavLinkProps>``;

const LinkTab = (props: TabProps & NavLinkProps) => {
  const user = useAppSelector(adminSelectedUser) as IUser;

  return (
    <Tab
      component={Link}
      {...props}
      disabled={
        !user?.companies[0]?.rewardPoints && props.label === 'Reward Points'
      }
    />
  );
};

const links = [
  {
    label: 'Information',
    href: 'information',
  },
  {
    label: 'Activity',
    href: 'activity',
  },
  {
    label: 'Reward Points',
    href: 'reward-points',
  },
  {
    label: 'Knowledge Base',
    href: 'knowledge-base',
  },
  {
    label: 'Workspace',
    href: 'workspace',
  },
];

const NavigationTabs = () => {
  const { pathname } = useLocation();
  const [value, setValue] = React.useState<string>(pathname);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const getHref = (pathname: string, href: string) => {
    return `${pathname.split('/').slice(0, -1).join('/')}/${href}`;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={value} onChange={handleChange} aria-label="nav tabs example">
        {links.map(({ label, href }) => (
          <LinkTab
            key={label}
            label={label}
            value={getHref(pathname, href)}
            to={getHref(pathname, href)}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default NavigationTabs;

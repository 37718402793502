import { Box, Button, Slider } from '@mui/material';
import styled from 'styled-components';

export const TotalPointRow = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Points = styled.div`
  font-size: 36px;
  line-height: 32px;
  color: ${(props) => props.theme.palette.text.secondary};
  margin-right: 5px;
`;

export const PointsLabel = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const ProgressBarTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 3px;
`;

export const RewardPointsProgressBar = styled(Slider)`
  height: 6px;
  &.Mui-disabled {
    color: #5d6081;
  }
  & .MuiSlider-rail {
    border: ${(props) => `1px solid ${props.theme.knowledgeBase.color}`};
    background-color: transparent;
  }
  & .MuiSlider-thumb {
    width: 0;
  }
  & .MuiSlider-valueLabel {
    font-size: 10px;
    bottom: 0px;
    background-color: unset;
    color: ${(props) => props.theme.palette.text.secondary};
    transform: translateY(20px);
    & * {
      background: transparent;
      color: ${(props) => props.theme.palette.text.secondary};
    }
  }
`;

export const StreakDays = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.secondary};
  font-weight: 400;
`;

export const Days = styled.div`
  width: 70px;
  text-align: end;
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  margin-left: 5px;
`;

export const RedeemButton = styled(Button)`
  color: #973bb7;
  border: 2px solid #973bb7;
  background: none;
  height: 28px;
  &:hover {
    background: ${({ theme }) => theme.palette.background.default};
  }
`;

export const PointStat = styled(Box)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-weight: 600;
`;

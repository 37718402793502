import { StyledComponentProps } from '@mui/material';
import styled from 'styled-components';

interface FlexBlockProps extends StyledComponentProps {
  direction: 'column' | 'row';
}

export const FlexBlock = styled.div<FlexBlockProps>`
  display: flex;
  flex-direction: ${(props) => `${props.direction}`};
}`;

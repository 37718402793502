import SearchToolsTabs from '@/components/admin/SearchToolsTabs';
import {
  Main,
  UsersGrid,
  UsersTableSection,
} from '@/components/admin/user/components';
import DeletedUsersTable from '@/components/admin/user/DeletedUsersTable';
import SearchBar from '@/components/admin/user/SearchBar';
import { Box, Container } from '@mui/material';
import React from 'react';

const DeletedUsers = () => {
  return (
    <Main>
      <UsersGrid>
        <Box />
        <SearchToolsTabs />
      </UsersGrid>
      <SearchBar />

      <UsersTableSection>
        <Container maxWidth="xl" sx={{ width: '95%' }}>
          <DeletedUsersTable />
        </Container>
      </UsersTableSection>
    </Main>
  );
};

export default DeletedUsers;

import { EKnowledgeBaseItemTypes } from '@/components/knowledgeBase/models';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { WithType } from '@/pages/knowledge-base/components';
import { numberWithCommas } from '@/pages/super-admin/company/helpers';
import { knowledgeBaseStat } from '@/redux/actions/knowledge-base';
import { getKnowledgeStat } from '@/redux/selectors/knowledgeBaseSelector';
import { Box, Container, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

const KnowledgeBaseCompletness = styled(Box)`
  background: #f3f6f8cc;
  border-radius: 16px;
  padding: 44px 60px;
`;

const KnowledgeType = styled(Box)<WithType>`
  font-weight: bold;
  border-radius: 2px;
  width: 43px;
  height: 18px;
  text-align: center;
  margin-right: 5px;
  color: white;
  font-size: 10px;
  text-transform: capitalize;
  background-color: ${(props) => props.theme?.knowledgeBase[props.type]?.color};
`;

const HeaderRow = styled(Box)`
  display: flex;
  border-bottom: 1px solid #e2e8ed;
  margin-bottom: 19px;
  padding-bottom: 13px;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const KnowledgeBaseStat: FC = () => {
  const knowledgeBase = useAppSelector(getKnowledgeStat);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(knowledgeBaseStat());
  }, [dispatch]);

  return (
    <Container>
      <Box width="100%" my="74px" textAlign="center">
        <Typography fontWeight={400} fontSize="20px" variant="h2">
          Knowledge base consuming stats
        </Typography>
      </Box>
      <KnowledgeBaseCompletness>
        <HeaderRow>
          <Box minWidth="95px" marginRight="20px">
            Date of Upload
          </Box>
          <Box minWidth="40px" marginRight="20px">
            Type
          </Box>
          <Box flexGrow={1}>Title</Box>
          <Box minWidth="106px" mx="20px">
            Platform/Company
          </Box>
          <Box minWidth="65px" marginRight="20px" textAlign="end">
            Unique views
          </Box>
          <Box minWidth="65px" textAlign="end">
            Total views
          </Box>
        </HeaderRow>
        {knowledgeBase?.map((item) => (
          <Box display="flex" key={item.id} marginBottom="14px">
            <Box minWidth="95px" marginRight="20px">
              {format(parseISO(item.createdAt), 'MM/dd/yy')}
            </Box>
            <Box minWidth="40px" marginRight="20px">
              <KnowledgeType
                type={(item.type as any).name as EKnowledgeBaseItemTypes}
              >
                {(item.type as any).name}
              </KnowledgeType>
            </Box>
            <Box flexGrow={1}>{item.title}</Box>
            <Box minWidth="106px" mx="20px">
              {item.companyId ? 'Company' : 'Platform'}
            </Box>
            <Box minWidth="65px" marginRight="20px" textAlign="end">
              {numberWithCommas(item.uniqueCount)}
            </Box>
            <Box minWidth="65px" textAlign="end">
              {numberWithCommas(item.totalCount)}
            </Box>
          </Box>
        ))}
      </KnowledgeBaseCompletness>
    </Container>
  );
};

export default KnowledgeBaseStat;

import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import BuildMyWorkspace from '@/pages/dashboard/MyWorkspace/BuildMyWorkspace';
import { MyWorkspaceBlock } from '@/pages/dashboard/MyWorkspace/components';
import { initialValue } from '@/pages/dashboard/MyWorkspace/constants';
import MyWorkspaceHeaderMenu from '@/pages/dashboard/MyWorkspace/MyWorkspaceHeaderMenu';
import MyWorkspaceView from '@/pages/dashboard/MyWorkspace/MyWorkspaceView';
import {
  WorkspaceItem,
  WorkspaceMenuStatus,
} from '@/pages/dashboard/MyWorkspace/types';
import { getUserWorkspaceItems } from '@/redux/actions/workSpace';
import { getUserSelector } from '@/redux/selectors/userSelectors';
import { currentItemsSelector } from '@/redux/selectors/workspaceSelector';
import React, { useCallback, useEffect, useState } from 'react';

const MyWorkspace = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(getUserSelector);
  const currentItems = useAppSelector(currentItemsSelector);
  const [state, setState] = useState<WorkspaceItem[]>(initialValue);
  const [status, setStatus] = useState<WorkspaceMenuStatus>(
    WorkspaceMenuStatus.view
  );

  const changeState = useCallback((value: WorkspaceItem[]) => {
    setState(value);
  }, []);

  useEffect(() => {
    if (currentItems.length === 0) {
      dispatch(getUserWorkspaceItems());
    }
  }, [currentItems.length, dispatch]);

  if (currentItems.length === 0 || !currentUser) {
    return null;
  }
  return (
    <MyWorkspaceBlock overflow="auto">
      <MyWorkspaceHeaderMenu status={status} setStatus={setStatus} />
      {status === WorkspaceMenuStatus.view ? (
        <MyWorkspaceView state={state} setState={changeState} status={status} />
      ) : (
        <BuildMyWorkspace state={state} setState={setState} canEdit />
      )}
    </MyWorkspaceBlock>
  );
};

export default MyWorkspace;

import { ModalStatuses } from '@/components/dialogs/ConfirmModal';
import theme from '@/theme/theme';
import { Button } from '@mui/material';
import styled from 'styled-components';

export const ConfirmModalButton = styled(Button)<{ status: string }>`
  margin-right: 10px;
  box-shadow: none;
  background-color: ${(props) =>
    `${
      props.status === ModalStatuses.WARNING
        ? theme.palette.redColor.light
        : props.status
    }`};

  &:hover {
    background-color: ${(props) =>
      `${
        props.status === ModalStatuses.WARNING
          ? theme.palette.warning.main
          : theme.palette.primary.dark
      }`};
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }
`;

import { ProgressName } from '@/pages/dashboard/MyWorkspace/MyWorkspaceHeaderMenu/constantes';
import { ProgressItem } from '@/pages/dashboard/MyWorkspace/MyWorkspaceHeaderMenu/types';

export const createProgressArray = (
  countCategories: number
): ProgressItem[] => {
  return Array.from({ length: 4 }, (_, itemNumber) => {
    let value;
    let name;
    if (
      5 * (itemNumber + 1) > countCategories &&
      5 * itemNumber < countCategories
    ) {
      value = countCategories - 5 * itemNumber;
    } else if (5 * (itemNumber + 1) > countCategories) {
      value = 0;
    } else {
      value = 5;
    }
    if (itemNumber === 0) name = ProgressName.BASIC;
    else if (itemNumber === 1) name = ProgressName.ADVANCED;
    else if (itemNumber === 2) name = ProgressName.PRO;
    else name = ProgressName.MENTOR;
    return { id: itemNumber + 1, name, value };
  });
};

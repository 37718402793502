import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import useAuth from '@/hooks/useAuth';
import {
  getMessages,
  getPinnedMassages,
  getPrevMessages,
  getUnreadMessagesByChat,
  readMessage,
} from '@/redux/actions/chat';
import {
  currentChatSelector,
  currentConversation,
  hasMoreMessagesSelector,
  messagesSelector,
  pinnedMessageSelector,
  unreadedMessagesList,
} from '@/redux/selectors/chatSelector';
import {
  EChatStatus,
  ELocalStoreKeys,
  EMessageCategory,
  EUserRole,
} from '@/types/consts';
import { IMessage, IUser } from '@/types/models';
import { idToUid, uidToId } from '@/utils/chatHelpers';
import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import ChatView from 'react-chatview';

import 'react-perfect-scrollbar/dist/css/styles.css';
import { ChatListContainer, MessageList } from '../components';
import Message from './Message';
import { useLocalStorage } from 'usehooks-ts';
import { Box } from '@mui/material';

export interface IChatMessagesListProps {
  hideHeader?: boolean;
  withNotification?: boolean;
  withPinned?: boolean;
  isScrolled?: boolean;
  setIsScrolled?: React.Dispatch<React.SetStateAction<boolean>>;
  plusHeight: number;
}

export interface IChatMessageListMethods {
  scrollToPinned: () => void;
}

const ChatMessagesList = forwardRef<
  IChatMessageListMethods,
  IChatMessagesListProps
>(
  (
    { hideHeader, isScrolled, setIsScrolled, plusHeight },
    ref: ForwardedRef<IChatMessageListMethods>
  ) => {
    const dispatch = useAppDispatch();
    const user = useAuth().user as IUser;
    const adminRw = user.companies[0]?.rwAdminId;
    const chat = useAppSelector(currentChatSelector);
    const messages = useAppSelector(messagesSelector);
    const hasMore = useAppSelector(hasMoreMessagesSelector);
    const pinnedMessage = useAppSelector(pinnedMessageSelector);
    const conversation = useAppSelector(currentConversation);
    const unreaded = useAppSelector(unreadedMessagesList);
    const [scrollRef, setScrollRef] = useState<HTMLElement | null>(null);
    const [lastMessageId, setLastMessageId] = useState<string | null>(null);
    const [isLoadMore, setLoadingMore] = useState<boolean>(false);
    const [isReading, setIsReading] = useState<boolean>(false);
    const [savedPinnedMsg, setSavedPinnedMsg] = useLocalStorage<
      string | undefined
    >(ELocalStoreKeys.PINNED_ID, undefined);
    const [draftIsScrollable, setIsScrollable] = useState<boolean | undefined>(
      true
    );

    // calc unreadMessage and setLoadingMore - false
    const lastUnreadedMessages = useMemo(() => {
      if (unreaded?.length) {
        return unreaded[0];
      }
      return null;
    }, [unreaded]);

    // for dashboard widget set isScrollable
    useEffect(() => {
      setIsScrollable(isScrolled);
    }, [isScrolled]);

    // update all when change chatId
    useEffect(() => {
      if (chat?.chatId) {
        setLastMessageId(null);
        setIsReading(false);
        dispatch(getMessages(chat))
          .unwrap()
          .then(() => {
            dispatch(getUnreadMessagesByChat(chat));
          });
      }
    }, [chat?.chatId, dispatch]);

    // get pinned message
    useEffect(() => {
      if (chat?.isGroup && chat?.chatId && messages?.length) {
        dispatch(getPinnedMassages(chat?.chatId));
      }
    }, [chat?.chatId, chat?.isGroup, dispatch, messages?.length]);

    const readMessageHandle = useCallback(
      (message: IMessage) => {
        if (!isReading || message) {
          setIsReading(true);
          setTimeout(() => {
            dispatch(readMessage(message))
              .unwrap()
              .finally(() => setIsReading(false));
          }, 3000);
        }
      },
      [dispatch, isReading]
    );

    useEffect(() => {
      if (!!messages?.length) {
        if (
          scrollRef &&
          (!lastMessageId || +lastMessageId < +messages[messages.length - 1].id)
        ) {
          scrollRef.scrollTop = scrollRef.scrollHeight;
          setLastMessageId(messages[messages.length - 1].id);
        }
      }
    }, [messages, lastMessageId, scrollRef]);

    useEffect(() => {
      if (lastUnreadedMessages) {
        readMessageHandle(lastUnreadedMessages);
      }
    }, [lastUnreadedMessages, readMessageHandle]);

    useEffect(() => {
      if (
        conversation?.unreadMessageCount &&
        +conversation?.unreadMessageCount > 0 &&
        messages
      ) {
        readMessageHandle(messages[0]);
      }
    }, [messages, conversation?.unreadMessageCount, readMessageHandle]);

    // load more messages
    const loadMore = useCallback(() => {
      setLoadingMore(true);
      if (chat) {
        return dispatch(getPrevMessages(chat))
          .unwrap()
          .finally(() => setLoadingMore(false));
      }
      return Promise.resolve();
    }, [chat, dispatch]);

    // scroll to pinned message
    const showPinnedMessage = useCallback(() => {
      const elementId = `marker-${pinnedMessage?.id}`;
      const element = document.getElementById(elementId);
      if (element) {
        scrollRef?.scroll({ top: element.offsetTop });

        if (user.role.name !== EUserRole.RW_ADMIN && pinnedMessage?.id) {
          setSavedPinnedMsg(pinnedMessage.id);
        }
      } else {
        if (!isScrolled) {
          setIsScrollable(true);
          setTimeout(additionalScroll, 2000); // this is neccessary for smoth scroll in chat widget
        } else {
          additionalScroll();
        }
      }
    }, [pinnedMessage?.id, user.role.name, scrollRef, isScrolled]);

    // this is neccessary for smoth scroll in chat widget
    const additionalScroll = () => {
      scrollRef?.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(() => {
        showPinnedMessage();
      }, 1000);
    };

    useImperativeHandle(
      ref,
      () => ({
        scrollToPinned: showPinnedMessage,
      }),
      [showPinnedMessage]
    );

    const isPinnedViewed = useMemo(
      () =>
        user.role.name !== EUserRole.RW_ADMIN &&
        pinnedMessage?.id &&
        savedPinnedMsg &&
        +savedPinnedMsg === +pinnedMessage.id,
      [user.role.name, pinnedMessage?.id, savedPinnedMsg]
    );

    return (
      <ChatListContainer
        hideHeader={hideHeader}
        withNotification={
          uidToId(chat?.user?.uid) === adminRw &&
          chat?.user?.status === EChatStatus.OFFLINE
        }
        withPinned={pinnedMessage?.chatId === chat?.chatId && !isPinnedViewed}
        plusHeight={plusHeight}
      >
        {messages?.length ? (
          <MessageList overflow={draftIsScrollable ? 'auto' : 'hidden'}>
            <ChatView
              className="message-list"
              flipped={true}
              reversed={true}
              scrollLoadThreshold={70}
              shouldTriggerLoad={() => hasMore && !isLoadMore}
              onInfiniteLoad={loadMore}
              returnScrollable={(scrollable: any) => setScrollRef(scrollable)}
            >
              {messages
                ?.filter(({ category }) => category !== EMessageCategory.ACTION)
                .map((message) => (
                  <>
                    <Box id={`marker-${message.id}`} />
                    <Message
                      key={message.id}
                      message={message}
                      isMyMessage={
                        message.sender === idToUid(user.id as number)
                      }
                      isRwAdminMessage={message.sender === idToUid(adminRw)}
                      setIsScrolled={setIsScrolled}
                      isGroupChat={!!chat?.isGroup}
                    />
                  </>
                ))}
            </ChatView>
          </MessageList>
        ) : (
          <>{messages === null ? null : 'No messages'}</>
        )}
      </ChatListContainer>
    );
  }
);

export default ChatMessagesList;

import { Box, Slider } from '@mui/material';
import styled from 'styled-components';

export const SliderBox = styled(Box)`
  min-width: 60px;
  width: 100%;
  margin-right: 5px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const BoxText = styled(Box)<{ myColor: string | undefined }>`
  color: ${(props) => (props.myColor ? props.myColor : 'unset')};
  font-style: normal;
  font-weight: 1000;
  font-size: 12px;
`;

export const SliderWrapper = styled(Box)`
  position: relative;
  top: -7px;
`;
export const WorkSpaceProgressSliderBox = styled(Slider)<{
  myColor: string;
  myDisplay: string;
}>`
  height: 10px;
  cursor: auto;
  box-sizing: border-box;

  & .MuiSlider-mark {
    width: 2pt;
    height: 10px;
    opacity: 0.2;
    color: ${(props) => `${props.theme.knowledgeBase.color}`};

    &[data-index='0'] {
      color: #fff;
      width: 0px;
    }
    &[data-index='5'] {
      color: #fff;
      width: 0px;
    }
  }
  & .MuiSlider-markActive {
    opacity: 1;
  }

  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.1);
  }

  & .MuiSlider-rail {
    border: ${(props) => `1px solid ${props.theme.knowledgeBase.color}`};
    background-color: transparent;
  }

  & .MuiSlider-thumb {
    display: none;
  }
  & .MuiSlider-track {
    display: ${(props) => `${props.myDisplay}`};
    color: ${(props) => `${props.myColor}`};
  }

  & .MuiSlider-valueLabel {
    font-size: 10px;
    bottom: 0;
    background-color: unset;
    color: ${(props) => props.theme.palette.text.secondary};
    transform: translateY(20px);

    & * {
      background: transparent;
      color: ${(props) => props.theme.palette.text.secondary};
    }
  }
`;
